(function(){
'use strict';

scBlockFactory.$inject = ["$compile", "$timeout", "$window", "scGetBlockTemplate", "scBlockTemplateBreakpoint", "scTemplateLockingService"];
function _toConsumableArray(arr) { if (Array.isArray(arr)) { for (var i = 0, arr2 = Array(arr.length); i < arr.length; i++) { arr2[i] = arr[i]; } return arr2; } else { return Array.from(arr); } }

/**
 * @kind factory
 * @name scBlock
 *
 * @description
 * Shared factory for all sc{X}Block directives. Transforms the directive
 * definition object of a directive in the following ways:
 *
 * * Coerces scope to true
 * * Places the passed object on the new child scope at $scope.options
 */
angular.module('classy').factory('scBlock', scBlockFactory);

function scBlockFactory($compile, $timeout, $window, scGetBlockTemplate, scBlockTemplateBreakpoint, scTemplateLockingService) {
  function scBlock() {
    var defObj = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};

    // Force new child (not isolate) scope
    defObj.scope = true;

    // Add interpolation and compilation to postlink
    defObj.link = adaptLink(defObj);

    return defObj;
  }

  function adaptLink(defObj) {
    var origLink = defObj.link;
    var pre = void 0,
        _post = void 0;

    if (_.isFunction(origLink)) {
      _post = origLink;
    } else if (_.isPlainObject(origLink)) {
      pre = _.get(origLink, 'pre', null);
      _post = _.get(origLink, 'post', null);
    }

    return {
      pre: pre,
      post: function post(scope, element) {
        /**
         * Swaps out template when a breakpoint is hit
         */
        var currentBreakpoint = void 0;
        angular.element($window).on('resize.window', windowResize).trigger('resize.window');

        scope.$on('$destroy', function () {
          angular.element($window).off('resize.window', windowResize);
        });

        function windowResize() {
          $timeout(function () {
            var breakpoint = _.isUndefined(defObj.responsiveSettings) ? false : scBlockTemplateBreakpoint(defObj.responsiveSettings);

            if (breakpoint != currentBreakpoint) {
              render();
              currentBreakpoint = breakpoint;
              scope.block.template = currentBreakpoint || 'desktop';
            }
          });
        }

        var template = void 0;
        function render() {
          var newTemplate = scGetBlockTemplate(scope.block, defObj.responsiveSettings);

          if (template !== newTemplate) {
            var compiled = $compile(newTemplate)(scope);
            element.html(compiled);
            template = newTemplate;
          }
        }

        render();

        if (_post) {
          for (var _len = arguments.length, args = Array(_len > 2 ? _len - 2 : 0), _key = 2; _key < _len; _key++) {
            args[_key - 2] = arguments[_key];
          }

          _post.apply(this, [scope, element].concat(_toConsumableArray(args)));
        }

        scope.getLock = function (path) {
          return scTemplateLockingService.getLock('block', path, { id: scope.block.id });
        };
      }
    };
  }

  return scBlock;
}
})();