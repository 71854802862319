(function(){
'use strict';

scAboutBlockSettings.$inject = ["scFroalaOptions"];
angular.module('classy').directive('scAboutBlockSettings', scAboutBlockSettings);

function scAboutBlockSettings(scFroalaOptions) {
  return {
    scope: true,
    templateUrl: 'global/objects/scBlock/types/about/settings',
    controller: ['$scope', function ($scope) {
      $scope.froalaOptionsHeader = scFroalaOptions({ type: 'bare-headline' });
      $scope.froalaOptions = scFroalaOptions({ type: 'bare-paragraph' });

      $scope.aboutOptions = scFroalaOptions({ type: 'inline-with-video' }, {
        ariaLabel: 'Content'
      });

      $scope.headerOptions = scFroalaOptions({ type: 'inline-header' }, {
        ariaLabel: 'Headline'
      });

      $scope.showHeadline = !$scope.block.hideHeadline;

      $scope.$watch('showHeadline', function (newVal) {
        $scope.block.hideHeadline = !newVal;
      });
    }],

    link: function link(scope) {
      scope.bgMediaManagerOptions = {
        title: 'Select a Description Background',
        image: {
          guide: {
            title: 'Description Background',
            message: "You're not tied to a color. Add a background image.",
            size: '1280 x 720 pixels'
          }
        }
      };
    }
  };
}
})();