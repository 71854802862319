(function(){
'use strict';

/**
 * @kind directive
 * @name scUsSpinner
 *
 * @description
 * Directive for spinner
 * Put the class 'submit-button' on the button you want to remove and add the text to
 * Spinner themes are definied in global/config
 */

angular.module('classy').directive('scUsSpinner', scUsSpinner);

function scUsSpinner() {
  return {
    scope: {
      scUsSpinner: '=?',
      buttonText: '=?',
      spinTheme: '@?',
      disabled: '<?'
    },
    replace: true,
    transclude: true,
    template: '<div ng-click="clickElement()" class="admin-button_wrap" ng-style="{cursor: disabled ? \'default\': \'pointer\'}">\n        <span us-spinner spinner-theme="{{ spinTheme }}" ng-show="scUsSpinner && readyToSpin"></span>\n        <ng-transclude></ng-transclude>\n      </div>',
    link: function link(scope, element) {
      scope.spinTheme = scope.spinTheme ? scope.spinTheme : 'mediumWhite';
      scope.readyToSpin = false;

      var $submitButton = element.find('.submit-button'),
          buttonText = scope.buttonText;

      function removeButtonText(button) {
        if (button.is('input')) {
          button.attr('disabled', 'disabled').val('');
        } else {
          button.attr('disabled', 'disabled').text('');
        }
      }

      function addButtonText(button) {
        if (button.is('input')) {
          button.val(buttonText);
        } else {
          button.text(buttonText);
        }
      }

      addButtonText($submitButton);

      scope.clickElement = function () {
        scope.readyToSpin = true;
      };

      // Remove text if spinner is loading & the element has been clicked
      scope.$watch('scUsSpinner', function (newValue, oldValue) {
        if (newValue != oldValue) {
          // Makes sure only this button text is affected
          if (scope.readyToSpin && scope.scUsSpinner) {
            removeButtonText($submitButton);
          } else if (!scope.scUsSpinner) {
            addButtonText($submitButton);
          }
        }
      });

      // Watch for click on element and see if api has already been called
      scope.$watch('readyToSpin', function (newValue, oldValue) {
        if (newValue != oldValue) {
          if (scope.scUsSpinner && scope.readyToSpin) {
            removeButtonText($submitButton);
          }
        }
      });
    }
  };
}
})();