(function(){
'use strict';

scTaxEntitiesService.$inject = ["$http", "$q", "scOrganizationsService", "scCampaignsService", "scCurrencyService"];
angular.module('classy').service('scTaxEntitiesService', scTaxEntitiesService);

function scTaxEntitiesService($http, $q, scOrganizationsService, scCampaignsService, scCurrencyService) {
  return {
    currentTaxableEntity: {},

    setCurrentEntity: function setCurrentEntity(entity) {
      this.currentTaxableEntity = entity;
    },


    /* ---------------------------------------------------------------- *
     * Get the org's taxable entitites, save them on the org object
     * ---------------------------------------------------------------- */
    getTaxEntities: function getTaxEntities() {
      var _this = this;

      if (scCampaignsService.active.multiCurrencyDisabled()) {
        // todo: remove this once the API deploys the new tax-entity endpoints,
        // stop gap to prevent thousands of 404s when appv2 deploys ahead of apiv2
        // dont even worry about this until the campaign is multicurrency enabled.
        return $q.resolve([]);
      }

      var organization = scOrganizationsService.active.current;
      var taxEntities = organization.taxEntities;
      if (taxEntities && taxEntities.length) {
        // If we already have the entities, don't request them
        return $q.resolve(taxEntities);
      }

      if (this.taxEntitiesPromise) {
        return this.taxEntitiesPromise;
      }

      this.taxEntitiesPromise = $http({
        method: 'GET',
        url: '/frs-api/organizations/' + organization.id + '/tax-entities'
      }).then(function (response) {
        var entities = _.get(response, 'data.data', []);
        organization.taxEntities = entities;
        _this.loading = false;
        return entities;
      }).catch(function () {
        return [];
      }); // return empty array if there's an error

      return this.taxEntitiesPromise;
    },


    /* ---------------------------------------------------------------- *
     * Get the org's taxable entity for a given currency value
     * ---------------------------------------------------------------- */
    getTaxEntity: function getTaxEntity() {
      var _this2 = this;

      var currencyCode = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : scCurrencyService.getDefaultCurrency();

      return this.getTaxEntities().then(function (entities) {
        var currencyEntity = _.filter(entities, function (entity) {
          return entity.currency_code == currencyCode;
        });

        var defaultEntity = _.filter(entities, function (entity) {
          return entity.is_default;
        });

        var entity = currencyEntity[0] || defaultEntity[0] || entities[0] || false;
        _this2.setCurrentEntity(entity);

        return entity;
      });
    },


    /* ---------------------------------------------------------------- *
     * Get the org's default tax entity for a given entity
     * (campaign, team, fundraising page) object, based on it's raw_currency_code
     * ---------------------------------------------------------------- */
    getDefaultTaxEntity: function getDefaultTaxEntity() {
      var entity = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : scCampaignsService.active.current;

      var currencyCode = scCurrencyService.getEntityCurrency(entity);
      return this.getTaxEntity(currencyCode);
    }
  };
}
})();