(function(){
'use strict';

/* made just so that a single instance of mobile menu share links open up */
angular.module('classy').service('scSingleShareService', scSingleShareService);

function scSingleShareService() {
  var isOn = false;

  this.On = function () {
    isOn = true;
  };
  this.Off = function () {
    isOn = false;
  };
  this.isOn = function () {
    return isOn;
  };
}
})();