(function(){
'use strict';

/**
 * @kind directive
 * @name scBlockBgSelect
 *
 * @description
 * Standard control for block options that merges into specified object.
 * @param sc-block-style-select attach object of styles to this attribute
 * @param sc-presets String of name of preset constant,
 *    e.g. sc-presets='scBlockCommentThumbnailPresets'
 * @param optional string or array of strings of keys from presets to ignore
 */
scBlockStyleSelect.$inject = ["scQaId", "scCampaignsTemplateService", "$stateParams"];
angular.module('classy').directive('scBlockStyleSelect', scBlockStyleSelect);

function scBlockStyleSelect(scQaId, scCampaignsTemplateService, $stateParams) {
  return {
    scope: {
      model: '=scBlockStyleSelect',
      presetConst: '@scPresets',
      omit: '=?',
      color: '=scColor',
      ariaLabel: '@?'
    },
    template: '\n      <div ng-class="{\'admin-body__lock-field\':lockField}">\n        <div ng-if="lockField"\n          data-sc-tooltip-class="admin-campaign-tooltip lock-tooltip-msg"\n          data-sc-tooltip-position="center"\n          data-sc-tooltip-position-tip="bottom center"\n          show-event="mouseenter" hide-event="mouseout" fixed-tooltip="true"\n          data-sc-tooltip="This field is locked at the template level."\n          class="admin-body__tooltip-element">\n        </div>\n        <select\n          class="{{selectClass}}"\n          qa-id="{{ scQaId.selectField }}"\n          data-ng-disabled="lockField"\n          data-ng-model="model.preset"\n          data-ng-options="name as option.label for (name,option) in presets"\n          data-ng-change="handleChange()"\n          aria-label="{{ariaLabel}}">\n        </select>\n      </div>',
    replace: true,
    controller: ['$scope', '$injector', '$attrs', function ($scope, $injector, $attrs) {
      var omitArray = [];
      var presets = $injector.get($scope.presetConst);
      $scope.selectClass = $attrs.class;

      $scope.lockField = false;

      $scope.getLockedField = function (type) {
        var lockedBlock = scCampaignsTemplateService.getBlockByType(type);

        var fieldName = $attrs.scBlockStyleSelect.split('.').splice(-1)[0];
        return lockedBlock && lockedBlock.item_attributes[fieldName] && lockedBlock.item_attributes[fieldName].preset.locked;
      };

      if (SC.campaign.campaignTemplateData) {
        var blockObj = _.find(SC.blocks, { id: $stateParams.blockId });
        if (blockObj) {
          $scope.lockField = $scope.getLockedField(blockObj.type);
        }
      }

      if (_.isArray($scope.omit)) {
        omitArray = $scope.omit;
      } else if (_.isString($scope.omit)) {
        omitArray = [$scope.omit];
      }

      $scope.scQaId = scQaId;

      $scope.presets = {};

      _.forEach(presets, function (preset, key) {
        if (_.indexOf(omitArray, key) < 0) {
          $scope.presets[key] = preset;
        }
      });

      $scope.handleChange = function () {
        if (!$scope.lockField) {
          var newVal = $scope.model.preset;
          // Apply the actual styles to the block background.
          var preset = $scope.presets[newVal];
          $scope.model = { preset: newVal };
          // save color before changing preset
          if (_.endsWith(newVal, 'Wire')) {
            $scope.model['border-color'] = $scope.color;
            $scope.model.color = $scope.color;
          } else {
            $scope.model['background-color'] = $scope.color;
            $scope.model.color = 'white';
          }
          _.merge($scope.model, preset.styles);
        }
      };
    }]
  };
}
})();