(function(){
'use strict';

scModalGroupOpener.$inject = ["$sce"];
angular.module('classy').directive('scModalGroupOpener', scModalGroupOpener);

function scModalGroupOpener($sce) {
  return {
    restrict: 'A',
    require: ['^?scModalGroup', 'scModalGroupOpener'],
    scope: true,
    controller: ['$scope', function ($scope) {
      $scope.data = {};
    }],

    link: function link(scope, element, attrs, ctrl) {
      var trigger = attrs.trigger || 'click';
      element.on(trigger, function () {
        var children = element.children('[data-sc-modal-group-content], [sc-modal-group-content]').children();

        if (!children.length) {
          children = element.parent().children('[data-sc-modal-group-content], [sc-modal-group-content]').children();
        }

        angular.forEach(children, function (child) {
          var $child = angular.element(child);

          // Add any JSON & HTML to the modal data.
          var json = angular.element($child[0]).attr('data-obj');

          var html = $child.html(),
              className = $child.attr('class');

          if (json) {
            json = JSON.parse(JSON.stringify(json));
            json = json.replace(/^[\s\r\n]*/g, '').replace(/[\s\r\n]*$/g, '');
            json = JSON.parse(json);
          }

          scope.data[className] = {
            json: json,
            html: $sce.trustAsHtml(html)
          };

          // Open the modal
          ctrl[0].openModal(scope.data, element);
        });
      });
    }
  };
}
})();