(function(){
'use strict';

/**
 * @kind factory
 * @name sass
 *
 * @description
 * Setup sass compiler linking to the worker api
 */
sassFactory.$inject = ["$log"];
angular.module('classy').factory('sass', sassFactory);

function sassFactory($log) {
  var sass = new Sass('/static/global/lib-assets/sass.worker.js');

  var base = '../../',

  // Files to import from the /static path
  files = ['global/directives/scButtonAmount/styles.css', 'global/lib-assets/_responsive.scss'];

  sass.options({
    style: Sass.style.compressed,
    comments: false
  });

  sass.preloadFiles(base, '', files, function () {
    $log.log('files loaded');
  });

  return sass;
}
})();