(function(){
'use strict';

/**
 * @kind directive
 * @name scUserDropdown
 */

scUserDropdownDirective.$inject = ["$templateCache", "linkBigTip", "scAuth", "scMembersService", "scOrganizationsService"];
angular.module('classy').directive('scUserDropdown', scUserDropdownDirective);

function scUserDropdownDirective($templateCache, linkBigTip, scAuth, scMembersService, scOrganizationsService) {
  return {
    scope: true,
    link: function link(scope, element) {
      scope.scAuth = scAuth;
      scope.members = scMembersService;
      scope.organization = scOrganizationsService.active;

      var tooltip = linkBigTip({
        element: element,
        template: $templateCache.get('global/directives/scUserDropdown/template'),
        scope: scope,
        event: 'click',
        width: 200,
        scroll: false,
        bg: '#F8F8F8'
      });
      scope.open = tooltip.open;
      scope.close = tooltip.close;
    }
  };
}
})();