(function(){
'use strict';

angular.module('classy').directive('scTarget', scTarget);

function scTarget() {
  return {
    scope: {
      condition: '=scTarget',
      attr: '@scTargetAttr'
    },
    link: function link(scope, element) {
      scope.$watch('condition', function (newVal) {
        if (newVal) {
          element.attr('target', scope.attr);
        }
      });
    }
  };
}
})();