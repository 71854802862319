(function(){
'use strict';

registrationEntityFlow.$inject = ["$rootScope", "$state", "scCampaignsService", "scFundraisingTeamsService", "scFlowModalService", "scThemesService"];
function _toConsumableArray(arr) { if (Array.isArray(arr)) { for (var i = 0, arr2 = Array(arr.length); i < arr.length; i++) { arr2[i] = arr[i]; } return arr2; } else { return Array.from(arr); } }

angular.module('classy').factory('registrationEntityFlow', registrationEntityFlow);

function registrationEntityFlow($rootScope, $state, scCampaignsService, scFundraisingTeamsService, scFlowModalService, scThemesService) {
  var SELECT_TICKETS_ROUTE = 'frs.landing.name.campaign.registration.new.tickets';

  return function () {
    var _$scope$questions;

    var target = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : null;

    // when opening the registration flow, clear out any previously selected teams
    // to join.
    window.localStorage.removeItem('reg-cart-team');

    var $scope = $rootScope.$new();
    var focusTarget = target;
    var campaign = scCampaignsService.active.current;

    /* -------------------------------------------------------------------- *
     * Model
     * -------------------------------------------------------------------- */

    $scope.registrationEntity = {
      team_id: null,
      team_name: null,
      team_answers: []
    };

    /* -------------------------------------------------------------------- *
     * Data for subviews
     * -------------------------------------------------------------------- */

    $scope.campaignId = campaign.id;
    $scope.teamsCanSetGoal = campaign.teams_can_set_goal;
    $scope.teamsCanSetAppeal = campaign.teams_can_set_appeal;

    $scope.primaryColor = scThemesService.active.current.styles.primaryColor;

    $scope.allQuestions = _.filter(scCampaignsService.active.current.questions, {
      location: 'team_creation'
    });

    $scope.customQuestion = _.sortBy(_.filter($scope.allQuestions, {
      tag: null
    }), 'weight');
    $scope.questions = _.reject($scope.allQuestions, {
      tag: null
    });
    // push custom questions inside questions to show it in the sequence on the UI
    (_$scope$questions = $scope.questions).push.apply(_$scope$questions, _toConsumableArray($scope.customQuestion));

    $scope.disableCurrencyDropdown = function () {
      return scFundraisingTeamsService.active.current.transactions_count > 0 || scFundraisingTeamsService.active.current.soft_credits_count > 0 || scFundraisingTeamsService.active.current.percent_to_goal > 0;
    };

    $scope.joinTeam = function (team) {
      // for rwf, the join team button should always go to checkout
      $scope.registrationEntity.team_id = team.id;
      $scope.registrationEntity.team_answers = null;

      // save team so that when the user refreshes the tickets-select page,
      // the team is still associated with the cart.
      window.localStorage.setItem('reg-cart-team', angular.toJson({ id: team.id, name: team.name }));

      $scope.goToCheckout();
    };

    $scope.goToCheckout = function () {
      $state.go(SELECT_TICKETS_ROUTE, $scope.registrationEntity);
    };

    $scope.goToBrowse = function () {
      scFlowModalService.to('browse');
    };

    $scope.goToCreate = function () {
      $scope.registrationEntity.team_goal = campaign.default_team_goal;
      $scope.registrationEntity.team_description = campaign.default_team_appeal || '';
      scFlowModalService.to('team_details');
    };

    $scope.goToQuestions = function () {
      if ($scope.questions.length) {
        scFlowModalService.to('team_questions');
      } else {
        $scope.goToCheckout();
      }
    };

    /* -------------------------------------------------------------------- *
     * Fundraising CTA Options
     * -------------------------------------------------------------------- */

    $scope.disableTeamCreate = function () {
      return campaign.allow_team_fundraising == false || campaign.team_membership_policy == 'prohibited';
    };

    $scope.disableTeamJoin = function () {
      return campaign.team_membership_policy == 'prohibited';
    };

    $scope.disableIndividualFundraising = function () {
      return campaign.team_membership_policy == 'required';
    };

    if (campaign.type == 'ticketed' || $scope.disableTeamJoin() && $scope.disableTeamCreate()) {
      $scope.goToCheckout();
      $scope.noModal = true;
    }

    /* -------------------------------------------------------------------- *
     * Views
     * -------------------------------------------------------------------- */

    var views = [];

    if (!($scope.disableTeamCreate() && $scope.disableIndividualFundraising())) {
      views.push({
        id: 'switch',
        template: '\n             <registration-entity-switch\n             model="registrationEntity"\n             go-to-checkout="goToCheckout()"\n             go-to-browse="goToBrowse()"\n             go-to-create="goToCreate()"\n             disable-individual-fundraising="disableIndividualFundraising()"\n             disable-team-join="disableTeamJoin()"\n             disable-team-create="disableTeamCreate()">\n             </registration-entity-switch>\n           ',
        maxWidth: 640
      });
    }

    views.push({
      id: 'browse',
      template: '\n          <registration-entity-browse\n          model="registrationEntity"\n          go-to-create="goToCreate()"\n          join-team="joinTeam(team)"\n          primary-color="primaryColor"\n          campaign-id="campaignId">\n          </registration-entity-browse>\n        ',
      maxWidth: 640
    }, {
      id: 'team_details',
      template: '\n          <registration-entity-team-details\n          model="registrationEntity"\n          go-to-questions="goToQuestions()"\n          teams-can-set-goal="teamsCanSetGoal"\n          teams-can-set-appeal="teamsCanSetAppeal"\n          primary-color="primaryColor"\n          campaign-id="campaignId">\n          </registration-entity-team-details>\n        ',
      maxWidth: 400
    });

    if ($scope.questions.length) {
      views.push({
        id: 'team_questions',
        template: '\n            <registration-entity-team-questions\n            model="registrationEntity"\n            questions="questions"\n            go-to-checkout="goToCheckout()"\n            primary-color="primaryColor"\n            campaign-id="campaignId">\n            </registration-entity-team-questions>\n          '
      });
    }

    /* -------------------------------------------------------------------- *
     * Open modal
     * -------------------------------------------------------------------- */
    if (!$scope.noModal) {
      scFlowModalService.register('regEntitySwitchFlow', $scope, views);
      scFlowModalService.open('regEntitySwitchFlow', {
        animate: true,
        closable: true,
        elementToFocusOnClose: focusTarget
      });
    }

    return $scope;
  };
}
})();