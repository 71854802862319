(function(){
'use strict';

/**
 * @kind directive
 * @name scValidateTeamName
 * @requires ngModel
 *
 * @description
 * Async validator that ensures the given team name is not taken.
 */

scValidateTeamName.$inject = ["$http", "$q", "$timeout", "scFundraisingTeamsService"];
angular.module('classy').directive('scValidateTeamName', scValidateTeamName);

function scValidateTeamName($http, $q, $timeout, scFundraisingTeamsService) {
  'use strict';

  return {
    require: 'ngModel',

    scope: {
      campaignId: '=scValidateTeamName',
      nameRequired: '=?',
      count: '=?'
    },
    // eslint-disable-next-line no-unused-vars
    link: function link(scope, element, attrs, ngModel) {
      var pendingRequest = false;

      function checkName() {
        var team = _.get(scFundraisingTeamsService, 'active.current', false);
        var encodedFilter = encodeURIComponent('name=' + scope.currentName);

        if (!scope.nameRequired && !scope.currentName) {
          return $q.resolve();
        }

        return $http.get('/frs-api/campaign/' + scope.campaignId + '/fundraising-teams', {
          params: { filter: encodedFilter }
        }).then(function (response) {
          return response.data.data;
        }).then(function (teams) {
          // Already have more than 1 by that name; reject.
          if (teams.length > 1) {
            ngModel.duplicate = team && teams[0].id === team.id ? teams[1] : teams[0];
            scope.count = teams[0].name.length;
            return $q.reject();
          }

          // Already have 1 by that name and it's not current one; reject.
          if (teams.length === 1 && (!team || teams[0].id !== team.id)) {
            ngModel.duplicate = teams[0];
            scope.count = teams[0].name.length;
            return $q.reject();
          }
          // Good to go.

          return $q.resolve();
        });
      }

      ngModel.$asyncValidators.teamNameAvailable = function (value) {
        scope.currentName = value;
        $timeout.cancel(pendingRequest);
        pendingRequest = $timeout(checkName, 400);
        return pendingRequest;
      };

      ngModel.$validators.teamNameInvalidChars = function (value) {
        var invalidChars = /,/gi;
        scope.currentName = value;
        if (scope.currentName && _.isString(scope.currentName) && scope.currentName.match(invalidChars)) {
          $timeout.cancel(pendingRequest);
          return false;
        }
        return true;
      };
    }
  };
}
})();