(function(){
'use strict';

/*
  Basic Tooltip
*/

scNoAnimate.$inject = ["$animate"];
angular.module('classy').directive('scNoAnimate', scNoAnimate);

function scNoAnimate($animate) {
  // candidate for removal
  return {
    restrict: 'C',
    // eslint-disable-next-line no-unused-vars
    link: function link(scope, element) {
      $animate.enabled(element, false);
    }
  };
}
})();