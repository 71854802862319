(function(){
'use strict';

scLocationBlock.$inject = ["scBlock", "scLocationBlockDefaults", "$sce"];
angular.module('classy').directive('scLocationBlock', scLocationBlock);

function scLocationBlock(scBlock, scLocationBlockDefaults, $sce) {
  return scBlock({
    link: function link(scope) {
      var defaultLocation = 'Uncertain';

      initializeGoogleMapsUrl();

      // if there is a venue and the headline is still the default, use the venue value
      if (scope.block.headline === scLocationBlockDefaults.headline && scope.campaign.venue) {
        scope.block.headline = scope.campaign.venue;
      }

      scope.getOverlay = function () {
        var color = scope.block.overlay.color || scope.theme.styles.primaryColor;
        var opacity = scope.block.overlay.opacity != null ? scope.block.overlay.opacity / 100 : 0.12;

        return {
          'background-color': color,
          opacity: opacity,
          'pointer-events': !scope.editable ? 'none' : 'auto' // needed to be able to click through overlay
        };
      };

      function getLatestEncodedLocation() {
        if (_.get(scope.block.map, 'customMarker.lat') && _.get(scope.block.map, 'customMarker.lng')) {
          return encodeURI(scope.block.map.customMarker.lat + ', ' + scope.block.map.customMarker.lng);
        }
        return defaultLocation;
      }

      function refreshGoogleMapsEmbedUrl() {
        var locationOverride = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : '';

        var url = 'https://www.google.com/maps/embed/v1/place?key=' + SC.gapi + '&q=' + (locationOverride || getLatestEncodedLocation()) + '&zoom=' + _.get(scope.block, 'map.zoomLevel', 12);

        scope.googleMapsEmbedUrl = $sce.trustAsResourceUrl(url);
      }

      function initializeGoogleMapsUrl() {
        var location = getLatestEncodedLocation();

        // attempt to use the campaign address if no location is set as a last resort, otherwise set to default
        if (location === defaultLocation) {
          var _scope$campaign = scope.campaign,
              address1 = _scope$campaign.address1,
              city = _scope$campaign.city,
              state = _scope$campaign.state,
              postalCode = _scope$campaign.postal_code;

          var prettyVenue = (address1 || '') + ', ' + (city || '') + ', ' + (state || '') + ' ' + (postalCode || '');

          var addressExists = prettyVenue.replace(/\s/g, '') !== ',,';

          if (addressExists) {
            location = encodeURI(prettyVenue);
          }
        }

        refreshGoogleMapsEmbedUrl(location);
      }

      scope.$watchGroup(['block.map.customMarker.lat', 'block.map.customMarker.lng', 'block.map.zoomLevel'], function () {
        initializeGoogleMapsUrl();
      });
    }
  });
}
})();