(function(){
'use strict';

angular.module('classy').directive('scFooterBlockSettings', scFooterBlockSettings);

function scFooterBlockSettings() {
  return {
    scope: true,
    templateUrl: 'global/objects/scBlock/types/footer/settings',
    link: function link(scope) {
      scope.logoMediaManagerOptions = {
        title: 'Select a Footer Logo',
        image: {
          guide: {
            title: 'Footer Logo',
            message: 'Use your campaign or organization logo.',
            size: '300 x 50 pixels'
          }
        }
      };

      scope.bgMediaManagerOptions = {
        title: 'Select a Footer Background',
        image: {
          guide: {
            title: 'Footer Background',
            message: "You're not tied to a color. Add a background image.",
            size: '1280 x 720 pixels'
          }
        }
      };
    }
  };
}
})();