(function(){
'use strict';

scLocationBlockSettings.$inject = ["scFroalaOptions"];
angular.module('classy').directive('scLocationBlockSettings', scLocationBlockSettings);

function scLocationBlockSettings(scFroalaOptions) {
  return {
    scope: true,
    templateUrl: 'global/objects/scBlock/types/location/settings',
    controller: ['$scope', function ($scope) {
      $scope.contentOptions = scFroalaOptions({ type: 'inline-with-video' }, {
        ariaLabel: 'Description'
      });
      if (!$scope.block.map.customMarker) {
        $scope.block.map.customMarker = {
          lat: null,
          lng: null
        };
      }
    }],
    link: function link(scope) {
      scope.bgImgUploadOptions = {
        hideActionButtons: true,
        description: 'For best quality, use a 1280 x 720 image'
      };
    }
  };
}
})();