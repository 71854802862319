(function(){
'use strict';

frsDonationDesignationCtrl.$inject = ["$scope"];
angular.module('classy').controller('frsDonationDesignationCtrl', frsDonationDesignationCtrl);

function frsDonationDesignationCtrl($scope) {
  'use strict';

  /* ---------------------------------------------------------------------- *
   * Constants
   * ---------------------------------------------------------------------- */

  $scope.CONSTANTS = {
    SELECT_DESIGNATION_REQUIRED_ERROR_TEXT: 'Please select a designation from the list.'
  };

  /* ---------------------------------------------------------------------- *
   * Collections
   * ---------------------------------------------------------------------- */

  // TODO: This is basically a placeholder. We don't yet know how multiple
  // designations will be expressed in the API output for a single campaign.
  // This expects the same structure as SC.projectList in ClassyApp.
  $scope.list = {
    designations: $scope.campaign.current.designations
  };

  /* ---------------------------------------------------------------------- *
   * Init
   * ---------------------------------------------------------------------- */

  _.merge($scope.MODEL, {
    designation: null
  });
}
})();