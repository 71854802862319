(function(){
'use strict';

/**
 * @kind directive
 * @name scBlockBg
 *
 * @description
 * Display the block background.
 */
scBlockBg.$inject = ["scUserAgent", "scBlockBackgroundPresets"];
angular.module('classy').directive('scBlockBg', scBlockBg);

function scBlockBg(scUserAgent, scBlockBackgroundPresets) {
  return {
    scope: {
      bg: '=scBlockBg',
      fallbackColor: '=',
      imageBgColor: '=?',
      fallbackImg: '=?',
      backgroundPreview: '<?'
    },
    template: '\n    <div class="sc-block_bg">\n      <div class=\'sc-block_bg-color\' data-ng-style=\'getBgColorStyles()\'></div>\n      <div class=\'sc-block_bg-image\' data-ng-style=\'getBgImageStyles()\'></div>\n    </div>\n    ',
    replace: true,
    link: function link(scope, element) {
      // This function builds styles for the "color" div in the block's background.
      // This div will either be overlayed over the "bg-image" div to tint the image
      // a certain color, or will be a plain color without an image.
      // The color div's styles include a backgroundColor and an opacity.
      scope.getBgColorStyles = function () {
        var bgColor = void 0;

        if (scope.bg.image && scope.imageBgColor) {
          bgColor = scope.bg.color || scope.imageBgColor;
        } else {
          bgColor = scope.bg.color || scope.fallbackColor;
        }

        var styles = {
          backgroundColor: bgColor,
          opacity: scope.bg.colorOpacity / 100
        };

        return styles;
      };

      scope.getBgImageStyles = function () {
        if (!scope.bg.image && (!scope.fallbackImg || scope.bg.color || scope.imageBgColor)) {
          return '';
        }

        var userAgent = _.get(scUserAgent.getUa(), 'os.name', '');
        var bgAttachment = void 0;

        if (scope.bg.fixed && userAgent !== 'iOS' && scope.$parent.public) {
          bgAttachment = 'fixed';
        } else {
          bgAttachment = 'local';
        }

        // if the react image editor was used, a "position" property will be set
        // in the image's "canvasData" object (default is x: 0.5, y: 0.5)
        var bgPosition = void 0;
        if (scope.bg.image && scope.bg.image.cropData) {
          var bgPositionX = scope.bg.image.cropData.position.x * 100;
          var bgPositionY = scope.bg.image.cropData.position.y * 100;

          bgPosition = bgPositionX + '% ' + bgPositionY + '%';
        } else {
          bgPosition = scope.bg.position;
        }

        var fallbackImg = _.get(scope, 'fallbackImg.src', '');

        var styles = {
          backgroundAttachment: bgAttachment,
          backgroundImage: 'url(' + _.get(scope.bg, 'image.src', fallbackImg) + ')',
          backgroundPosition: bgPosition,
          backgroundSize: scope.bg.size,
          backgroundRepeat: scope.bg.repeat,
          opacity: scope.bg.imageOpacity / 100
        };

        if (scope.bg.blur) {
          styles.filter = 'blur(' + scope.bg.blur + 'px)';
          styles['-webkit-filter'] = 'blur(' + scope.bg.blur + 'px)';
          styles['-moz-filter'] = 'blur(' + scope.bg.blur + 'px)';
          styles['-o-filter'] = 'blur(' + scope.bg.blur + 'px)';
          styles['-ms-filter'] = 'blur(' + scope.bg.blur + 'px)';
        }

        if (scope.bg.image && scope.backgroundPreview) {
          scope.bg.size = 'cover';
        } else if (scope.bg.preset && scope.bg.image) {
          var preset = scBlockBackgroundPresets[scope.bg.preset];

          _.merge(scope.bg, preset.styles);
          if (scope.bg.preset == 'adaptive') {
            if (element[0].offsetWidth > scope.bg.image.width || element[0].offsetHeight > scope.bg.image.height) {
              scope.bg.size = 'cover';
            }
          }
        } else if (scope.fallbackImg) {
          var presetSelection = scope.bg.preset || 'adaptive';
          var _preset = scBlockBackgroundPresets[presetSelection];

          _.merge(scope.bg, _preset.styles);
          if (presetSelection == 'adaptive' && (document.documentElement.clientWidth > scope.fallbackImg.width || element[0].clientHeight > scope.fallbackImg.height)) {
            scope.bg.size = 'cover';
          }
        }

        return styles;
      };
    }
  };
}
})();