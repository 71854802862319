(function(){
'use strict';

/**
 * @name scBitpayService
 * @kind service
 *
 * @description
 * Handles all interactions with the Bitpay external script.
 */
scBitpayService.$inject = ["$window", "$q", "scEnv"];
angular.module('classy').service('scBitpayService', scBitpayService);

function scBitpayService($window, $q, scEnv) {
  this.init = function (cancelCallback) {
    // Create the script DOM object.
    var script = document.createElement('script');
    script.type = 'text/javascript';
    script.async = 'async';
    script.defer = 'defer';
    var deferred = $q.defer();
    script.onload = function () {
      // When the script is ready, configure the 'bitpay' object in the script.
      if (scEnv !== 'production') {
        $window.bitpay.enableTestMode();
      }
      // If the user closes the modal, send a halting signal. If the user
      // has not yet paid, this will halt the payment processing procedure.
      $window.bitpay.onModalWillLeave(cancelCallback);
      deferred.resolve();
    };
    script.src = 'https://www.bitpay.com/bitpay.js';
    document.body.appendChild(script);
    return deferred.promise;
  };

  this.showInvoice = function (id) {
    $window.bitpay.showInvoice(id);
  };
}
})();