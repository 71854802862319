(function(){
'use strict';

scSupporterSearchModel.$inject = ["scResourceSearchModel"];
var _createClass = function () { function defineProperties(target, props) { for (var i = 0; i < props.length; i++) { var descriptor = props[i]; descriptor.enumerable = descriptor.enumerable || false; descriptor.configurable = true; if ("value" in descriptor) descriptor.writable = true; Object.defineProperty(target, descriptor.key, descriptor); } } return function (Constructor, protoProps, staticProps) { if (protoProps) defineProperties(Constructor.prototype, protoProps); if (staticProps) defineProperties(Constructor, staticProps); return Constructor; }; }();

var _get = function get(object, property, receiver) { if (object === null) object = Function.prototype; var desc = Object.getOwnPropertyDescriptor(object, property); if (desc === undefined) { var parent = Object.getPrototypeOf(object); if (parent === null) { return undefined; } else { return get(parent, property, receiver); } } else if ("value" in desc) { return desc.value; } else { var getter = desc.get; if (getter === undefined) { return undefined; } return getter.call(receiver); } };

function _classCallCheck(instance, Constructor) { if (!(instance instanceof Constructor)) { throw new TypeError("Cannot call a class as a function"); } }

function _possibleConstructorReturn(self, call) { if (!self) { throw new ReferenceError("this hasn't been initialised - super() hasn't been called"); } return call && (typeof call === "object" || typeof call === "function") ? call : self; }

function _inherits(subClass, superClass) { if (typeof superClass !== "function" && superClass !== null) { throw new TypeError("Super expression must either be null or a function, not " + typeof superClass); } subClass.prototype = Object.create(superClass && superClass.prototype, { constructor: { value: subClass, enumerable: false, writable: true, configurable: true } }); if (superClass) Object.setPrototypeOf ? Object.setPrototypeOf(subClass, superClass) : subClass.__proto__ = superClass; }

angular.module('classy').factory('scSupporterSearchModel', scSupporterSearchModel);

function scSupporterSearchModel(scResourceSearchModel) {
  return function (_scResourceSearchMode) {
    _inherits(SupporterSearchModel, _scResourceSearchMode);

    function SupporterSearchModel(fields, queryParams) {
      _classCallCheck(this, SupporterSearchModel);

      return _possibleConstructorReturn(this, (SupporterSearchModel.__proto__ || Object.getPrototypeOf(SupporterSearchModel)).call(this, 'supporters', fields, queryParams));
    }

    /**
     * {@inheritdoc}
     */


    _createClass(SupporterSearchModel, [{
      key: 'getReadableTypeName',
      value: function getReadableTypeName() {
        return 'supporters';
      }

      /**
       * {@inheritdoc}
       */

    }, {
      key: 'getLogo',
      value: function getLogo(resource) {
        return _.get(resource, 'member.thumbnail_medium', _get(SupporterSearchModel.prototype.__proto__ || Object.getPrototypeOf(SupporterSearchModel.prototype), 'getLogo', this).call(this, resource));
      }

      /**
       * {@inheritdoc}
       */

    }, {
      key: 'getName',
      value: function getName(resource) {
        return resource.first_name + ' ' + resource.last_name;
      }

      /**
       * {@inheritdoc}
       */

    }, {
      key: 'getDescription',
      value: function getDescription() {
        return '';
      }
    }]);

    return SupporterSearchModel;
  }(scResourceSearchModel);
}
})();