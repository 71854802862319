(function(){
'use strict';

scAuth.$inject = ["$http", "$window", "$timeout", "$q", "authFlow", "scMembersService", "scSsoService", "scAnalytics", "ipCookie"];
angular.module('classy').service('scAuth', scAuth);

function scAuth($http, $window, $timeout, $q, authFlow, scMembersService, scSsoService, scAnalytics, ipCookie) {
  'use strict';

  /* ---------------------------------------------------------------------- *
   * API
   * ---------------------------------------------------------------------- */

  this.checkStatus = function () {
    var _this = this;

    var deferred = $q.defer();
    $timeout(function () {
      Classy.ready(function () {
        Classy.status(function (status) {
          _this.status = status;
          if (status.is_logged_in && _.get(scMembersService, 'active.current.id', false) !== status.id) {
            scSsoService.stealthLogin().then(function () {
              return deferred.resolve();
            });
          } else if (!_.isEmpty(SC.member) > 0 && !status.is_logged_in) {
            _this.logout();
            $('.login-link').removeClass('hidden');
            deferred.resolve();
          } else {
            $('.login-link').removeClass('hidden');
            deferred.resolve();
          }
        });
      });
    });

    return deferred.promise;
  };

  this.logout = function (destination) {
    var _this2 = this;

    if (ipCookie('ucl') === true) {
      var logOutUrl = '' + ($window.location.origin || '') + (SC.classyLogin.prefix || '') + '/logout';
      this.redirectAfterLogout(destination, logOutUrl);
    } else {
      // Remove this else block once ClassyLogin is ready
      Classy.logout(function () {
        var logOutUrl = '' + (SC.sso.url || '') + (SC.sso.prefix || '') + '/logout';
        _this2.redirectAfterLogout(destination, logOutUrl);
      });
    }
  };

  this.redirectAfterLogout = function (destination, logoutUrl) {
    $http({
      method: 'POST',
      url: '/frs-api/logout'
    }).finally(function () {
      scAnalytics.identifyMember(false); // reset analytics ids
      var destHref = logoutUrl;
      if (destination) {
        destHref += '?r=' + destination;
      } else {
        destHref += '?r=' + $window.location.href;
      }
      $window.location.href = destHref;
    });
  };

  this.login = function () {
    var options = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};

    // _hideAuth(options.hidden);
    authFlow('login', options.callback, options.exitModal);
  };

  this.loginOnly = function () {
    var options = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};

    // _hideAuth(options.hidden);
    authFlow('login_only', options.callback);
  };

  this.resetPasswordByLink = function () {
    var options = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};

    // Uses timeout and classy.ready to ensure that the flow is ready to be created.
    $timeout(function () {
      Classy.ready(function () {
        authFlow('reset_password_by_link', options.callback);
      });
    });
  };

  this.signup = function () {
    var options = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};

    // _hideAuth(options.hidden);
    authFlow('signup', options.callback, options.exitModal);
  };

  /**
   * Stealth mode for auth
   */
  // function _hideAuth(hide) {
  //   if (hide) {
  //     $('.sc-flow-modal').addClass('hidden');
  //     $rootScope.$on('unhide-flow-modal', function () {
  //       $('.sc-flow-modal').removeClass('hidden');
  //     });
  //   }
  // }
}
})();