(function(){
'use strict';

/**
 * @kind service
 * @name scThemesService
 *
 * @description
 * Stores an object full o' themes
 */
scThemesService.$inject = ["scThemeModel"];
angular.module('classy').service('scThemesService', scThemesService);

function scThemesService(scThemeModel) {
  this.themes = {};

  this.add = function (themeData) {
    this.themes[themeData.id] = new scThemeModel(themeData);
  };

  this.get = function (id) {
    return _.get(this, 'themes.' + id, false);
  };

  this.setActive = function (id) {
    this.active = this.get(id);
  };

  this.init = function (themes) {
    var _this = this;

    _.forEach(themes, function (themeData) {
      _this.add(themeData);
    });
  };

  this.updateActiveBlocks = function (blocksService) {
    _.forEach(this.active.current.pages, function (page) {
      _.forEach(page.block_sections, function (blockSection) {
        _.forEach(blockSection.blocks, function (block, key) {
          blockSection.blocks[key] = blocksService.get(block.id).current;
        });
      });
    });
  };

  this.getPage = function (page) {
    return this.active.current.pages[page];
  };
}
})();