(function(){
'use strict';

angular.module('classy').filter('adjustColorOpacity', adjustColorOpacity);

function adjustColorOpacity() {
  return function adjustColor(color, opacity) {
    var flatten = arguments.length > 2 && arguments[2] !== undefined ? arguments[2] : true;

    // make sure we're matching an "rgb..." or "rgba..." string,
    // and assume that whitespaces can happen anywhere.
    // capture the three groups of color values and ignore the opacity value if an RGBA value
    // is fed into this function.
    var rgbaRegex = /(?!rgba?)\s*(\d{1,3}\s*,\s*)(\d{1,3}\s*,\s*)(\d{1,3}\s*\s*)/g;
    var ogColor = color.match(rgbaRegex);

    // CL-31278, some campaigns use named color strings instead of an rgb string, leading to unhandled exceptions
    if (!ogColor) {
      switch (color) {
        case 'red':
          ogColor = 'rgb(255, 0, 0)';
          break;
        case 'green':
          ogColor = 'rgb(0, 255, 0)';
          break;
        case 'blue':
          ogColor = 'rgb(0, 0, 255)';
          break;
        default:
          // might cause things to fail later, but current behavior is to throw a nullpointer exception in this case...
          // eslint-disable-next-line no-console
          console.warn('received color: ' + color + ', but expected RGB string');
          return color;
      }
    }

    if (flatten) {
      // flatten assumes white background (255, 255, 255)
      var bgColor = { r: 255, g: 255, b: 255 };
      var splitColor = ogColor[0].split(', ');

      var r = opacity * splitColor[0] + (1 - opacity) * bgColor.r;
      var g = opacity * splitColor[1] + (1 - opacity) * bgColor.g;
      var b = opacity * splitColor[2] + (1 - opacity) * bgColor.b;

      return 'rgb(\'' + r + ', ' + g + ', ' + b + '\')';
    }

    // use opacity on the client's primary color and stringify
    var newColor = ogColor.concat(' ' + opacity + ')').join();
    return 'rgba('.concat(newColor);
  };
}
})();