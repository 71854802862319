(function(){
'use strict';

campaignOverviewDataFactory.$inject = ["ChartistDataFactory", "$filter"];
angular.module('classy').factory('CampaignOverviewDataFactory', campaignOverviewDataFactory);

function campaignOverviewDataFactory(ChartistDataFactory, $filter) {
  return {
    get: function get(campaign, stats) {
      return getGraphDataForCampaign(campaign, stats,
      // using .bind here allows us to create a copy of the formatCurrency method
      // which can be passed around without having to pass around the $filter or campaign
      // objects
      formatCurrency.bind(undefined, $filter('scFormatCurrency'), campaign)).map(ChartistDataFactory);
    },
    // These methods are exposed for testing, please only use the .get method
    getGraphDataForCampaign: getGraphDataForCampaign,
    getGraphTypesForCampaign: getGraphTypesForCampaign,
    getGraph: getGraph,
    getRaisedData: getRaisedData,
    getTotalDonorsData: getTotalDonorsData,
    getRecurringDonorsData: getRecurringDonorsData,
    getTotalFundraisersData: getTotalFundraisersData,
    processData: processData,
    getChartLabels: getChartLabels,
    formatCurrency: formatCurrency
  };
}

var processData = function processData(loading, graphData) {
  return _.merge(graphData, {
    // for each chart key, get the result of the data function, which returns a value read
    // from the stats object
    data: CHART_KEYS.map(graphData.dataFn),
    labels: getChartLabels(),
    loading: loading,
    withPointLabels: true
  });
};

var getGraphDataForCampaign = function getGraphDataForCampaign(campaign, stats, formatCurrency) {
  return getGraphTypesForCampaign(campaign).map(getGraph.bind(undefined, formatCurrency, stats)).map(processData.bind(undefined, stats.loading));
};

function getGraph(formatCurrency, stats, graphName) {
  switch (graphName) {
    case 'raised':
      return getRaisedData(formatCurrency, stats);
    case 'totalDonors':
      return getTotalDonorsData(stats);
    case 'recurringDonors':
      return getRecurringDonorsData(stats);
    case 'totalFundraisers':
      return getTotalFundraisersData(stats);
    default:
      return {};
  }
}

var getGraphTypesForCampaign = function getGraphTypesForCampaign(campaign) {
  if (campaign.current.type === 'p2p') {
    return ['raised', 'totalFundraisers', 'totalDonors'];
  }

  return ['raised', 'totalDonors', 'recurringDonors'];
};

var getRaisedData = function getRaisedData(formatCurrency, stats) {
  return {
    title: 'Raised',
    dataFn: function dataFn(key) {
      return stats[key].overview.total_gross_amount;
    },
    // This function is called on the y-axis values, which for this graph represent money.
    // We call the formatCurrency method to handle m11y formatting if needed
    labelInterpolationFnc: formatCurrency
  };
};

var getTotalDonorsData = function getTotalDonorsData(stats) {
  return {
    title: 'Total Donors',
    dataFn: function dataFn(key) {
      return stats[key].overview.donors_count;
    }
  };
};

var getRecurringDonorsData = function getRecurringDonorsData(stats) {
  return {
    title: 'Recurring Donors',
    dataFn: function dataFn(key) {
      return stats[key].recurring.total;
    }
  };
};

var getTotalFundraisersData = function getTotalFundraisersData(stats) {
  return {
    title: 'Total Fundraisers',
    dataFn: function dataFn(key) {
      return stats[key].overview.fundraisers_count;
    }
  };
};

var formatCurrency = function formatCurrency(scFormatCurrency, campaign, value) {
  if (campaign.multiCurrencyDisabled()) {
    return '$' + value;
  }

  return scFormatCurrency(value, campaign.current.raw_currency_code, 0);
};

var getChartLabels = function getChartLabels() {
  return [moment().subtract(3, 'days'), moment().subtract(2, 'days'), moment().subtract(1, 'days'), moment()].map(function (day) {
    return day.format('MM/DD');
  });
};

var CHART_KEYS = ['today_minus_3', 'today_minus_2', 'today_minus_1', 'today'];
})();