(function(){
'use strict';

scCampaignsTemplateService.$inject = ["$http"];
angular.module('classy').service('scCampaignsTemplateService', scCampaignsTemplateService);

function scCampaignsTemplateService($http) {
  return {
    get: function get(campaignTemplateId) {
      return $http({
        method: 'GET',
        url: '/frs-api/campaign-templates/' + campaignTemplateId,
        loadingScope: true
      });
    },
    getBlockByType: function getBlockByType(type) {
      var templateData = SC.campaign.campaignTemplateData;
      return templateData && templateData.components.block && templateData.components.block.component_items.find(function (item) {
        return item.value.type === type;
      });
    },
    getCampaignCustomizationLock: function getCampaignCustomizationLock(propName) {
      var templateData = SC.campaign.campaignTemplateData;
      return _.find(templateData && templateData.components.campaign_customization && templateData.components.campaign_customization.component_items[0].item_attributes, function (prop, obj) {
        return obj === propName && prop.locked;
      });
    },
    getCampaignLock: function getCampaignLock(propName) {
      var templateData = SC.campaign.campaignTemplateData;
      return _.find(templateData && templateData.components.campaign && templateData.components.campaign.component_items[0].item_attributes, function (prop, obj) {
        return obj === propName && prop.locked;
      });
    },
    getCampaignSettingLock: function getCampaignSettingLock(propName) {
      var templateData = SC.campaign.campaignTemplateData;
      return _.find(templateData && templateData.components.campaign_setting && templateData.components.campaign_setting.component_items[0].item_attributes, function (prop, obj) {
        return obj === propName && prop.locked;
      });
    },
    getAddContentToPageBlock: function getAddContentToPageBlock(addContentToPageBlockId) {
      var templateBlockId = void 0;

      var componentSetInstance = _.filter(SC.campaign.campaignTemplateData.component_set_instances, function (data) {
        return data.campaign_id === SC.campaign.id;
      });

      if (componentSetInstance.length > 0) {
        componentSetInstance[0].component_instances.block.forEach(function (componentSetInfo) {
          if (componentSetInfo.id === addContentToPageBlockId) {
            templateBlockId = componentSetInfo.component_item_id.$oid;
          }
        });
      }

      return _.find(SC.campaign.campaignTemplateData.components.block.component_items, function (data) {
        return data._id === templateBlockId && data.locked;
      });
    },
    getEmailComponent: function getEmailComponent(urlParamId) {
      var componentSetCampaignIndex = 0;

      /**
       * We need to retrieve the campaign using the campaign_id from
       * the component_set_instances array since each campaign has a
       * distinct component_set_instance id.
       */
      SC.campaign.campaignTemplateData.component_set_instances.forEach(function (item, idx) {
        if (item.campaign_id === SC.campaign.id) {
          componentSetCampaignIndex = idx;
        }
      });

      /**
       * Once we have the correct component set index,
       * we need to filter the corresponding component_set_instance
       */
      return _.filter(SC.campaign.campaignTemplateData.component_set_instances[componentSetCampaignIndex].component_instances.message, function (data) {
        return data.id === urlParamId;
      });
    },
    getBlockComponent: function getBlockComponent(urlParamId) {
      var componentSetCampaignIndex = 0;

      /**
       * We need to retrieve the campaign using the campaign_id from
       * the component_set_instances array since each campaign has a
       * distinct component_set_instance id.
       */
      SC.campaign.campaignTemplateData.component_set_instances.forEach(function (item, idx) {
        if (item.campaign_id === SC.campaign.id) {
          componentSetCampaignIndex = idx;
        }
      });

      /**
       * Once we have the correct component set index,
       * we need to filter the corresponding component_set_instance
       */
      return _.filter(SC.campaign.campaignTemplateData.component_set_instances[componentSetCampaignIndex].component_instances.block, function (data) {
        return data.id === urlParamId;
      });
    },
    getActiveEmail: function getActiveEmail(foundEmailId) {
      var foundCurrentEmail = null;
      if (foundEmailId && foundEmailId[0] && foundEmailId[0].component_item_id) {
        foundCurrentEmail = _.filter(SC.campaign.campaignTemplateData.components.message.component_items, function (data) {
          return data._id === foundEmailId[0].component_item_id.$oid;
        });
      }
      return foundCurrentEmail ? foundCurrentEmail[0] : foundCurrentEmail;
    },
    getActiveBlock: function getActiveBlock(foundBlockId) {
      var foundCurrentBlock = null;
      if (foundBlockId && foundBlockId[0] && foundBlockId[0].component_item_id) {
        foundCurrentBlock = _.filter(SC.campaign.campaignTemplateData.components.block.component_items, function (data) {
          return data._id === foundBlockId[0].component_item_id.$oid;
        });
      }
      return foundCurrentBlock ? foundCurrentBlock[0] : foundCurrentBlock;
    },
    combineBlocks: function combineBlocks(activeEmail) {
      var activeEmailCopy = Object.assign({}, activeEmail);
      var primaryBlocks = activeEmailCopy.item_attributes.blocks;
      var secondaryBlocks = activeEmailCopy.value.blocks;
      var result = [];

      _.forIn(primaryBlocks, function (value, key) {
        result.push({ locked: value.locked, blockProp: secondaryBlocks[key] });
      });

      return result;
    },
    fetchCurrentBlockId: function fetchCurrentBlockId(windowInfo) {
      var urlParams = windowInfo.location.pathname.split('/');
      var urlParamId = urlParams[urlParams.length - 2];
      var urlParamIdReload = urlParams[urlParams.length - 3];
      return urlParamId.length === 24 ? urlParamId : urlParamIdReload;
    },
    isCampaignTemplateView: function isCampaignTemplateView($window) {
      return $window.location.search.substr(1).indexOf('campaign-templating-view=true') !== -1;
    },
    getItemLocks: function getItemLocks(itemType) {
      if (SC.campaign && SC.campaign.campaignTemplateData) {
        var item = this.getBlockByType(itemType);
        return item && item.item_attributes ? item.item_attributes : {};
      }
      return {};
    },


    /**
     * Query the SC global object for the status of template locks.
     * @param {object} query The query object is a representation of the required data with default values eg. { impact: { locked: false } }
     * @param {object} __locks__ This SHOULD NOT be pass manually, only used in case of recursion.
     * @returns {object} The response of the query containing the locks. eg. { impact: { locked: true } }
     */
    getLocks: function getLocks(query, __locks__) {
      var _this = this;

      return Object.keys(query).reduce(function (response, key) {
        var lock = __locks__ ? __locks__[key] : _this.getItemLocks(key);
        if (typeof lock === 'undefined') return query;
        response[key] = query[key] instanceof Object ? _this.getLocks(query[key], lock) : lock;
        return response;
      }, {});
    }
  };
}
})();