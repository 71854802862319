(function(){
'use strict';

dedicationSettingsService.$inject = ["$http", "$q"];
angular.module('classy').factory('dedicationSettingsService', dedicationSettingsService);

function dedicationSettingsService($http, $q) {
  return {
    getEcards: function getEcards(campaignId) {
      return $http({
        method: 'GET',
        url: '/frs-api/campaign/' + campaignId + '/ecard',
        loadingScope: true
      });
    },
    put: function put(campaignId, postData) {
      return $http({
        method: 'PUT',
        url: '/frs-api/dedication/' + campaignId,
        data: postData,
        loadingScope: true
      });
    },
    getSortEcards: function getSortEcards(campaignId) {
      return this.getEcards(campaignId).then(function (response) {
        var data = _.sortBy(response.data.data, 'order');
        data = _.filter(data, function (ecard) {
          return !ecard.is_deleted;
        });

        _.each(data, function (ecard, index) {
          ecard.order = index;
        });

        return $q.resolve(data);
      }).catch(function (error) {
        return $q.reject(error);
      });
    }
  };
}
})();