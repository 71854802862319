(function(){
'use strict';

countryBlockService.$inject = ["$rootScope", "$http", "scOrganizationsService", "scCampaignsService", "scFlowModalService", "ipCookie", "$q", "COUNTRY_LIST"];
angular.module('classy').service('countryBlockService', countryBlockService);

function countryBlockService($rootScope, $http, scOrganizationsService, scCampaignsService, scFlowModalService, ipCookie, $q, COUNTRY_LIST) {
  'use strict';

  var $scope = $rootScope.$new(true);

  var restrictedCountriesDeferred = null;

  var currentOrg = scOrganizationsService.active.current;

  $scope.data = {
    currentOrgData: currentOrg.restricted_country_information,
    currentOrgId: currentOrg.id,
    campaignId: scCampaignsService.active.current.id
  };

  this.checkOrganizationRestrictions = function () {
    var callback = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : function () {};

    $scope.data.handleContinue = callback;

    $scope.data.cookieKey = 'country-block-' + $scope.data.currentOrgId + '-' + $scope.data.campaignId;

    var cookie = ipCookie($scope.data.cookieKey);

    if ($scope.data.currentOrgData !== null) {
      if (!cookie) {
        launchCountryBlockModal();
      } else {
        $scope.data.handleContinue();
      }
    } else {
      $scope.data.handleContinue();
    }
  };

  var launchCountryBlockModal = function launchCountryBlockModal() {
    scFlowModalService.open({
      template: '\n            <country-Block-Modal\n              cookie-key="data.cookieKey"\n              data="data.currentOrgData"\n              handle-continue="data.handleContinue()">\n            </country-Block-Modal>',
      context: $scope,
      maxWidth: 520
    }, {
      animate: true,
      closable: false,
      closeOnClickOut: false,
      elementToFocusOnClose: document.activeElement
    });
  };

  var getRestrictedCountries = function getRestrictedCountries() {
    if (restrictedCountriesDeferred) return restrictedCountriesDeferred.promise;

    restrictedCountriesDeferred = $q.defer();

    if (_.get($scope, 'data.currentOrgData.restricted_countries_count', -1) > 0) {
      $http.get('/frs-api/organizations/' + $scope.data.currentOrgId + '/restricted-countries').then(function (response) {
        restrictedCountriesDeferred.resolve(response.data.data);
      }).catch(function () {
        restrictedCountriesDeferred.reject(null);
      });
    } else {
      restrictedCountriesDeferred.resolve(null);
    }

    return restrictedCountriesDeferred.promise;
  };

  this.getCountryBlockedList = function () {
    return getRestrictedCountries().then(formatCountries);
  };

  var formatCountries = function formatCountries(blackListedCountries) {
    var countries = _.cloneDeep(COUNTRY_LIST);
    var isRestricted = void 0;
    if (blackListedCountries) {
      isRestricted = blackListedCountries.reduce(function (accumulator, currentValue) {
        accumulator[currentValue.country] = true;
        return accumulator;
      }, {});

      countries.forEach(function (country) {
        if (isRestricted[country.value]) {
          country.label += ' - Unsupported';
          country.disable = true;
        }
      });
    }

    return countries;
  };
}
})();