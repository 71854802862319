(function(){
'use strict';

scTeamBlock.$inject = ["$http", "$log", "$q", "$rootScope", "$document", "$state", "$timeout", "$window", "$filter", "classyQL", "CFPMultiplePagesView", "CFPJoinTeamView", "createFundraisingPageFlow", "createFundraisingTeamFlow", "scFlowModalService", "scFroalaOptions", "scBlock", "scFundraisingTeamsService", "scMembersService", "scPagesService", "scCampaignsService", "scFundraisingPagesService", "scThemesService", "scButtonStyleUpdate", "scSocialShareLinks", "scProgressDataFactory", "teamBlockDemoData", "countryBlockService", "accessibilityService"];
function _toConsumableArray(arr) { if (Array.isArray(arr)) { for (var i = 0, arr2 = Array(arr.length); i < arr.length; i++) { arr2[i] = arr[i]; } return arr2; } else { return Array.from(arr); } }

angular.module('classy').directive('scTeamBlock', scTeamBlock);

function scTeamBlock($http, $log, $q, $rootScope, $document, $state, $timeout, $window, $filter, classyQL, CFPMultiplePagesView, CFPJoinTeamView, createFundraisingPageFlow, createFundraisingTeamFlow, scFlowModalService, scFroalaOptions, scBlock, scFundraisingTeamsService, scMembersService, scPagesService, scCampaignsService, scFundraisingPagesService, scThemesService, scButtonStyleUpdate, scSocialShareLinks, scProgressDataFactory, teamBlockDemoData, countryBlockService, accessibilityService) {
  return scBlock({
    responsiveSettings: {
      tablet: 'tablet'
    },

    controller: ['$scope', function ($scope) {
      // initial state of button colors
      var primary = _.get($scope.theme, 'styles.primaryColor', 'white');

      if (_.isNull($scope.block.donateButtonColor)) {
        $scope.block.donateButtonColor = primary;
      }

      if (_.isNull($scope.block.joinButtonColor)) {
        $scope.block.joinButtonColor = primary;
      }

      $scope.scButtonStyleUpdate = scButtonStyleUpdate;
      $scope.public = $scope.$parent.public;

      $scope.isDisabled = !$scope.public;

      if (Modernizr.prefixed('matchMedia', window)) {
        $scope.isMobile = Modernizr.mq('(max-width: 568px)');
      } else {
        $scope.isMobile = angular.element($window).width() <= 568;
      }

      $scope.scFundraisingTeamsService = scFundraisingTeamsService;
    }],

    link: function link(scope) {
      // Manage button (mobile only)

      var SELECT_TICKETS_ROUTE = 'frs.landing.name.campaign.registration.new.tickets';

      scope.manage = function () {
        $state.go('frs.team.dashboardindex.dashboard.overview');
      };

      scope.updatesPerPage = 5;

      scope.loadAdditionalUpdates = function () {
        scope.updatesPerPage += 5;
      };

      scope.canManage = false;

      scope.$watch(function () {
        return scMembersService.active.current.id;
      }, function () {
        scope.team.checkDashboardAccess().then(function (result) {
          scope.canManage = result;
        });
      });

      scope.isPreview = function () {
        return $state.is('admin.manage.campaign.step.setup') || $state.is('admin.manage.campaign.step.design.views.block');
      };

      if (_.isEmpty(scope.team) && scope.demo) {
        scope.team = scFundraisingTeamsService.useExample();
        scope.members = scope.team.current.members;
        scope.totalMembers = 2;
      }

      var teamProgress = scProgressDataFactory.getEntityProgress(scope.team.current);
      scope.teamPercentToGoal = teamProgress.percentToGoal * 100;
      scope.teamGoal = teamProgress.goal;

      scope.campaignLink = function () {
        return scPagesService.getUrl({
          pageType: 'landing',
          campaignId: scCampaignsService.active.current.id,
          campaignName: scCampaignsService.active.current.name,
          status: scCampaignsService.active.current.status
        }).url;
      };

      scope.scrollToComments = function () {
        $timeout(function () {
          var commentInput = angular.element('.activity_comment-input');
          if (commentInput.length) {
            commentInput.focus();

            $('html, body').animate({
              scrollTop: commentInput.offset().top
            }, 400);
          }
        });
      };

      if (scope.team.current.parent_id) {
        var parentTeamQuery = '\n          query {\n            fundraisingTeamSearchPublic(\n              filter: { id: { eq: ' + scope.team.current.parent_id + '}}\n            ) {\n              results {\n                id\n                campaign_id\n                organization_id\n                name\n                description\n                status\n                created_date\n                team_profile_image\n                team_cover_image\n                currency_code\n                goal_raw\n                goal_normalized\n                total_raised\n                total_raised_raw\n                percent_to_goal\n              }\n            }\n          }\n        ';
        classyQL.query({ query: parentTeamQuery }).then(function (response) {
          scope.parentTeam = _.get(response, 'data.data.fundraisingTeamSearchPublic.results[0]', undefined);
          scope.parentTeamLink = '/team/' + scope.parentTeam.id;
          scFundraisingTeamsService.add(scope.parentTeam);
        }).catch(function (error) {
          return $log.error(error);
        });
      }

      scope.confirmJoin = function (teamId) {
        var frPage = scFundraisingPagesService.getCurrentMemberPage();
        var pageId = _.get(frPage, 'current.id', false);
        var fundraisingPage = scFundraisingPagesService.fundraisers[pageId];
        scope.loading = true;

        fundraisingPage.joinTeam(teamId).then(function () {
          fundraisingPage.current.fundraising_team_id = teamId;
          scFundraisingPagesService.add(fundraisingPage.current);
          scope.loading = false;
          scFlowModalService.to({
            templateUrl: 'global/flows/join-team/views/success',
            maxWidth: 396,
            transition: 'slideLeft',
            duration: 1000,
            context: scope
          });

          $timeout(function () {
            scFlowModalService.close(true);
          }, 3000);

          scope.team.refreshData();

          // update total members count
          scope.totalMembers += 1;
        });
      };

      scope.handleJoinTeam = function (team, showOptions) {
        countryBlockService.checkOrganizationRestrictions(function () {
          return scope.joinTeam(team, showOptions);
        });
      };

      scope.joinTeam = function (team, showOptions) {
        if (scope.demo) {
          $rootScope.SC.status.banner = {
            type: 'error',
            msg: 'Whoops, this is just a preview of a team page. You can join a team from your <a class="status-banner_link" target="_self" href="/campaign/c' + scope.campaign.id + (SC.preview ? '?preview=true' : '') + '">landing page.</a>'
          };
          return;
        }

        scope.displayJoinTeamDropdown = false;

        if (showOptions && scope.team.current.team_policy.allow_subteams) {
          scope.displayJoinTeamDropdown = !scope.displayJoinTeamDropdown;
          return;
        }

        if (!scope.team.current.team_policy.allow_subteams || scope.team.current.team_policy.allow_subteams && scope.displayJoinTeamDropdown) {
          scope.displayJoinTeamDropdown = false;
          var frPage = scFundraisingPagesService.getCurrentMemberPage();
          if (frPage && !scope.campaign.allow_duplicate_fundraisers) {
            // already has a fundraising page, just move it
            var pageId = _.get(frPage, 'current.id', false);
            var teamId = scFundraisingTeamsService.active.current.id;
            var fundraisingPage = scFundraisingPagesService.fundraisers[pageId];
            var currentTeamId = _.get(frPage, 'current.fundraising_team_id', false);

            scope.currentTeam = _.get(scFundraisingTeamsService, 'teams[' + currentTeamId + '].current', {});
            scope.newTeam = scFundraisingTeamsService.active.current;
            scope.fundraisingPage = fundraisingPage ? fundraisingPage.current : null;

            if (currentTeamId !== teamId) {
              scope.teamName = scFundraisingTeamsService.active.current.name;
              scope.teamPurpose = scFundraisingTeamsService.active.current.description;
              scope.teamId = scFundraisingTeamsService.active.current.id;
              scope.ui = {
                primaryColor: _.get(scThemesService, 'active.current.styles.primaryColor', '#36bff7')
              };

              scFlowModalService.open({
                templateUrl: 'global/flows/join-team/views/join-team',
                maxWidth: 396,
                transition: 'slideLeft',
                duration: 1000,
                context: scope
              });
            }
          } else if (scope.campaign.type !== 'reg_w_fund') {
            createFundraisingPageFlow({ teamId: team.current.id });
          } else if (scope.campaign.type === 'reg_w_fund' && frPage) {
            // fundraisingPagesList needs to exist on this scope since the
            // multiple fundraising pages view pulls it from the parent scope for its UI
            // using lodash instead of Object.values here bc fundraiser model not enumberable.
            scope.fundraisingPagesList = _.values(scFundraisingPagesService.userFundraisers);
            scope.flow = {
              views: [],
              template: function template(slug) {
                return 'global/flows/create-fundraising-page/views/' + slug + '/template';
              },
              to: function to(dest, transition) {
                scFlowModalService.to(dest, transition);
              }
            };

            scope.options = {
              teamId: scFundraisingTeamsService.active.current.id
            };

            scope.flow.views.push(new CFPMultiplePagesView(scope));
            scope.flow.views.push(new CFPJoinTeamView(scope));

            scFlowModalService.register('multiplePagesFlow', scope, scope.flow.views);
            scFlowModalService.open('multiplePagesFlow', {
              closeOnClickOut: false,
              animate: true
            });
          } else if (scope.campaign.type === 'reg_w_fund') {
            // ELSE IS REGISTRATION WITH FUNDRAISING, still take them to multiple fundraising pages modal
            createFundraisingPageFlow({ teamId: team.current.id });

            // logged out or no registration
            var registrationEntity = {
              team_id: team.current.id,
              team_name: team.current.name,
              team_answers: null,
              campaignId: scope.campaign.id,
              eventName: $filter('scSlugify')(scope.campaign.name).toLowerCase()
            };

            $state.go(SELECT_TICKETS_ROUTE, registrationEntity);
          }
        } else {
          scope.displayJoinTeamDropdown = !scope.displayJoinTeamDropdown;
        }
      };

      scope.createSubteam = function () {
        if (scope.team.current.team_policy.allow_subteams) {
          createFundraisingTeamFlow({ parentId: scope.team.current.id });
        }
      };

      scope.donateLink = function () {
        if (!scope.public) {
          return undefined;
        }

        var donateStr = void 0;

        if (scope.SC.preview || scope.demo) {
          donateStr = '/give/' + scope.campaign.id + '/' + (SC.preview ? '?preview=true' : '') + '#!/donation/checkout';
        } else {
          donateStr = '/give/t' + scope.team.current.id + '/#!/donation/checkout';
        }

        return donateStr;
      };

      /* ---------------------------------------------------------------- *
       * Sharing
       * ---------------------------------------------------------------- */

      scSocialShareLinks.getSocialData().then(function (socialData) {
        scope.twitterShareUrl = socialData.twitter_link;
        scope.emailUrl = socialData.email_link;
        scope.fbShare = socialData.facebook_share;
        scSocialShareLinks.getInstagramLink().then(function (instagramData) {
          scope.instagramUrl = instagramData.instagram_link;
        });
      });

      /* ---------------------------------------------------------------- *
       * Froala
       * ---------------------------------------------------------------- */

      scope.froalaOptionsPreview = scFroalaOptions({ type: 'edit-inline' });

      /* ---------------------------------------------------------------- *
       * Logo
       * ---------------------------------------------------------------- */

      scope.logoSrc = function () {
        return scFundraisingTeamsService.getTeamPhoto(scope.team.current);
      };

      /* ---------------------------------------------------------------- *
       * Posts
       * ---------------------------------------------------------------- */

      scope.posts = {
        data: [],
        total: 0,
        ui: {
          deferred: $q.defer(),
          loading: true,
          active: null,
          mode: $state.is('frs.team.index.post') ? 'post' : 'list'
        },
        fetch: function fetch() {
          if (!scope.team) {
            return;
          }

          scope.team.getPosts().then(function (response) {
            if (!response) {
              return;
            }

            var posts = response.data;

            // Assign rank and latest status
            posts.data.forEach(function (post, index) {
              post.rank = posts.total - (posts.from + index) + 1;
              post.latest = posts.rank === posts.total;
            });

            // Fill out metadata
            scope.posts.data = posts.data;
            scope.posts.total = posts.total;
            scope.posts.ui.active = $state.is('frs.team.index.post') ? scope.posts.getById($state.params.postId) : null;

            scope.campaign.default_team_post_title = scope.campaign.default_team_post_title || _.get(scope.posts, 'data[0].title', null);
            scope.campaign.default_team_post_body = scope.campaign.default_team_post_body || _.get(scope.posts, 'data[0].body', null);

            scope.posts.ui.deferred.resolve();
          }, function () {
            scope.posts.ui.deferred.reject();
          }).finally(function () {
            scope.posts.ui.loading = false;
          });
        },
        getById: function getById(id) {
          var found = scope.posts.data.find(function (post) {
            return post.id.toString() === id.toString();
          });
          return found || false;
        },
        toPost: function toPost(post) {
          scope.posts.ui.mode = 'post';
          scope.posts.ui.active = post;
        },
        toList: function toList() {
          scope.posts.ui.mode = 'list';
          scope.posts.ui.active = null;
        }
      };

      /* ---------------------------------------------------------------- *
       * Stories
       * ---------------------------------------------------------------- */

      scope.stories = {
        data: [],
        total: 0,
        ui: {
          deferred: $q.defer(),
          loading: true,
          active: null,
          mode: $state.is('frs.fundraiser.index.post') ? 'post' : 'list'
        },
        fetch: function fetch() {
          if (!scope.team) {
            return;
          }

          scope.team.getStory().then(function (response) {
            if (!response) {
              return;
            }

            var story = response.data;

            // Fill out metadata
            scope.stories.data = story.data[0];
            scope.stories.total = story.total;
            scope.stories.ui.active = $state.is('frs.fundraiser.index.post') ? scope.stories.getById($state.params.storyId) : null;

            scope.stories.ui.deferred.resolve();
          }, function () {
            scope.stories.ui.deferred.reject();
          }).finally(function () {
            scope.stories.ui.loading = false;
          });
        },
        getById: function getById(id) {
          var stories = scope.stories.data[0];
          if (stories.id.toString() === id.toString()) {
            return stories;
          }

          return false;
        }
      };

      /* ---------------------------------------------------------------- *
       * Watchers
       * ---------------------------------------------------------------- */

      scope.$watch('posts.ui.mode', function (newMode, oldMode) {
        if (newMode !== oldMode) {
          // Bail if this is a create mode, or an admin mode, or ...
          if (!$state.includes('frs.team.index')) {
            return;
          }

          // Bail if this is the preview version of the team page
          if (scope.demo) {
            return;
          }

          switch (newMode) {
            case 'list':
              $state.go('frs.team.index');
              break;
            case 'post':
              $state.go('frs.team.index.post', { postId: scope.posts.ui.active.id });
              $timeout(function () {
                angular.element('html, body').animate({
                  scrollTop: angular.element('.post').offset().top
                }, 400);
              });
              break;
            default:
            // do nothing
          }
        }
      });

      /* ---------------------------------------------------------------- *
       * Init
       * ---------------------------------------------------------------- */
      scope.topThreeTeams = [];
      scope.posts.fetch();
      if ($state.current.name !== 'frs.team.index.post') {
        scope.stories.fetch();
      }

      // Captain
      // scope.team.getCaptain()
      // .then(function(response) {
      //   if (!response) {
      //     scope.captain = null;
      //   }
      //   scope.captain = response.data;
      // })
      // .catch(function(error) {
      //   $log.error(error);
      // });
      if (scope.team.current.id) {
        $http.get('/frs-api/fundraising-teams/' + scope.team.current.id + '/fundraising-pages', {
          params: {
            filter: 'team_role=primary-captain',
            with: 'member'
          }
        }).then(function (response) {
          if (response.data.total > 0) {
            scope.teamCaptain = response.data.data[0];
            scope.captainImage = scFundraisingPagesService.getPagePhoto(scope.teamCaptain);
          } else {
            scope.teamCaptain = null;
            scope.captainImage = scope.getCaptainImage();
          }
        });
      }

      scope.campaignSlug = $filter('scSlugify')(scope.campaign.name);

      scope.team.getSupporterCounts().then(function (response) {
        scope.totalMembers = response.fundraiserCount;
      });
      scope.membersEndpoint = '/fundraising-teams/' + scope.team.current.id + '/fundraising-pages?aggregates=true';
      scope.membersPerPage = 20;
      scope.membersFilter = { alias: {} };
      scope.membersSearch = { input: '' };

      scope.$watch('membersSearch.input', function (newVal) {
        if (newVal !== '') {
          scope.membersFilter.alias = {
            operand: '*=',
            value: newVal
          };
        } else {
          scope.membersFilter = {};
        }
      }, true);

      scope.sortMembers = function (sortOption) {
        if (_.isUndefined(sortOption) || scope.displaySortOptions === false) {
          scope.displaySortOptions = true;
          return;
        }

        scope.currentSort = sortOption.text;
        scope.membersSort = sortOption.sort;
        scope.currentFilter = sortOption;
        scope.displaySortOptions = false;
      };

      $document.on('click', function () {
        if (scope.displaySortOptions) {
          scope.displaySortOptions = false;
          scope.$apply();
        }
      });

      scope.keyPressed = function (event, filter) {
        if (event.keyCode === accessibilityService.CONSTANTS.ESC_KEY) {
          scope.displaySortOptions = false;
        } else if (event.keyCode === accessibilityService.CONSTANTS.ENTER_KEY || event.keyCode === accessibilityService.CONSTANTS.SPACE_KEY) {
          event.preventDefault();
          scope.displaySortOptions = true;
          if (filter) scope.sortMembers(filter);
        }
      };

      scope.memberFilters = [{
        text: 'Most Raised',
        sort: {
          total_raised: 'desc'
        }
      }, {
        text: 'Least Raised',
        sort: {
          total_raised: 'asc'
        }
      }, {
        text: 'Recently Active',
        sort: {
          updated_at: 'desc'
        }
      }, {
        text: 'Newest Member',
        sort: {
          created_at: 'desc'
        }
      }];

      scope.currentSort = scope.memberFilters[0].text;
      scope.sortMembers(scope.memberFilters[0]);

      /* ---------------------------------------------------------------- *
       * Set up subteams, top teams, and team leaderboard.
       * ---------------------------------------------------------------- */
      scope.leaders = [];

      if (scope.demo) {
        setTeamDemoData();
      } else {
        // populate the Top 3 Teams rankings by total_raised in left sidebar
        queryTopTeams();
      }

      /* ---------------------------------------------------------------- *
       * Team Demo Data
       * ---------------------------------------------------------------- */
      function setTeamDemoData() {
        scope.team.current.id = 1;
        scope.topThreeTeams = teamBlockDemoData.topThreeTeams;
        scope.leaders = teamBlockDemoData.leaders;
      }

      /* ---------------------------------------------------------------- *
       * Top 3 Teams Ranking
       * ---------------------------------------------------------------- */

      // Queries for the current team's ranking amongst other teams according to total raised.
      function queryTopTeams() {
        $http.get('/frs-api/fundraising-teams/' + scope.team.current.id + '/raised-rankings').then(function (_ref) {
          var rankingsList = _ref.data.rankingsList;

          scope.topThreeTeams = rankingsList;

          // Display team goals in their currency code
          scope.topThreeTeams.forEach(function (team) {
            var progressData = scProgressDataFactory.getEntityProgress(team);
            team.display_total_raised = progressData.totalRaised;
          });
        }).catch(function (err) {
          if (err.status === 404 && ($state.params.is_new || scope.team.current.total_raised === 0)) {
            // If for some reason the current team is not available via ClassyQL + Search yet
            // (used by the getTeamRaisedRankings endpoint) and it is a newly created team,
            // we can assume that the team has not raised anything yet, and can append it to
            // the list of last ranked teams.
            // We can't check for $state.params.is_new by itself because we immediately
            // remove that param after firing a GA page view. (FRS-8430).
            $http.get('/frs-api/campaign/' + scope.team.current.campaign_id + '/least-raised-teams').then(function (_ref2) {
              var data = _ref2.data;

              scope.topThreeTeams = [].concat(_toConsumableArray(data), [Object.assign(scope.team.current, {
                overallRanking: _.get(data, '[1].overallRanking', 0) + 1
              })]);
            });
          } else {
            scope.topThreeTeams = [];
            scope.topThreeTeamsError = 'Error loading team rankings.';
          }
        });
      }

      scope.setActiveTab = function (activeTab) {
        scope.activeActivityTab = activeTab;
      };

      scope.setActiveUpdatesTab = function (activeTab) {
        scope.activeUpdatesTab = activeTab;
      };

      scope.getCaptainImage = function () {
        if (_.get(scope, 'teamCaptain.member.thumbnail_medium', false)) {
          return scope.teamCaptain.member.thumbnail_medium;
        }

        return '/static/global/images/individual_default_image.png';
      };

      scope.getCaptainName = function () {
        if (scope.demo) {
          return 'Captain Name';
        }

        if (_.get(scope, 'teamCaptain.alias', false)) {
          return scope.teamCaptain.alias;
        }
        // this is a special case that happens in RWF
        return 'No current captain';
      };

      /**
       * Check if captain has their own fundraising page for this team.
       */
      function getCaptainPageId() {
        scope.captainId = false;

        var query = {
          filter: 'team_role=primary-captain'
        };

        if (scope.team.current.team_lead_id) {
          $http({
            method: 'GET',
            url: '/frs-api/fundraising-teams/' + scope.team.current.id + '/fundraising-pages',
            params: query
          }).then(function (response) {
            scope.captainId = _.get(response.data, 'data[0].id', false);

            if (scope.captainId) {
              scope.captainLink = '/fundraiser/' + scope.captainId;
            }
          });
        }
      }
      getCaptainPageId();

      /** Default tab for activity area */
      scope.activeActivityTab = scope.block.showLeaderboard ? 'leaderboard' : 'team_members';
      scope.activeUpdatesTab = 'story';

      /* Do not display join team button if already on this team */
      scope.enableTeamJoin = function () {
        var frPage = scFundraisingPagesService.getCurrentMemberPage();
        var allowSubteams = _.get(scope, 'team.current.team_policy.allow_subteams', false);
        var hasPageOnTeam = frPage && frPage.current.fundraising_team_id === scope.team.current.id;
        var you = scMembersService.active.current;
        var fundraisers = scFundraisingPagesService.fundraisers;
        scope.hasFrPage = _.findKey(fundraisers, function (o) {
          return o.current.member_id == you.id;
        });

        return (allowSubteams || !hasPageOnTeam || scope.campaign.allow_duplicate_fundraisers) && (scope.campaign.allow_fundraising_pages || scope.hasFrPage) && scope.campaign.team_membership_policy !== 'prohibited';
      };

      scope.getButtonStyle = function () {
        var style = scope.campaign.allow_fundraising_pages || scope.enableTeamJoin() ? scope.theme.styles.primaryColor : 'lightgray';
        return { color: style, 'border-color': style };
      };

      scope.enableIndividualJoin = function () {
        var frPage = scFundraisingPagesService.getCurrentMemberPage();
        var allowSubteams = scope.team.current.team_policy.allow_subteams;
        var hasPageOnTeam = frPage && frPage.current.fundraising_team_id === scope.team.current.id;

        return !(allowSubteams && hasPageOnTeam && !scope.campaign.allow_duplicate_fundraisers);
      };

      // multicurrency and display goal/raised
      var currency = _.get(scope, 'team.current.raw_currency_code', false);
      scope.team.current.display_total_raised = teamProgress.totalRaised;
      if (!scope.isPreview() && currency) {
        if (_.isUndefined(scope.team.current.total_raised)) {
          scope.team.current.total_raised = 0;
        }
        scope.currency = currency;
      } else {
        scope.currency = scCampaignsService.active.current.raw_currency_code;
      }

      scope.toggleMembersPerPage = function () {
        scope.membersPerPage = scope.membersPerPage == 20 ? 40 : 20;
      };

      /* ---------------------------------------------------------------- *
       * Testing
       * ---------------------------------------------------------------- */

      $window.deleteTeam = function () {
        if (
        // eslint-disable-next-line no-alert
        window.confirm('No, seriously, this will delete this fundraising team. Are you cool with that?')) {
          $http({
            method: 'DELETE',
            url: '/frs-api/fundraising-teams/' + scope.team.current.id
          }).then(function () {
            $state.go('frs.landing.name.campaign', {
              campaignId: scope.team.current.campaign_id,
              eventType: 'campaign'
            });
          }).catch(function (err) {
            $log.error(err);
          });
        }
      };
      scope.isPreviewHeading = function () {
        return $state.is('admin.manage.campaign.step.setup') || $state.is('admin.manage.campaign.step.design.views.content-blocks') || $state.is('admin.manage.campaign.step.design.views.block');
      };
    }
  });
}
})();