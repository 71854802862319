(function(){
'use strict';

var _slicedToArray = function () { function sliceIterator(arr, i) { var _arr = []; var _n = true; var _d = false; var _e = undefined; try { for (var _i = arr[Symbol.iterator](), _s; !(_n = (_s = _i.next()).done); _n = true) { _arr.push(_s.value); if (i && _arr.length === i) break; } } catch (err) { _d = true; _e = err; } finally { try { if (!_n && _i["return"]) _i["return"](); } finally { if (_d) throw _e; } } return _arr; } return function (arr, i) { if (Array.isArray(arr)) { return arr; } else if (Symbol.iterator in Object(arr)) { return sliceIterator(arr, i); } else { throw new TypeError("Invalid attempt to destructure non-iterable instance"); } }; }();

/**
 * @name scDateDropdown
 * @kind directive
 * @requires ngModel
 *
 * @description
 * Renders month, day, and year dropdowns, for choosing a birthday or any date.
 * Combines its inputs to set the $viewValue of the required ngModelController.
 * Intelligently populates the days array based on current year and month.
 * Adds a partialDate validator to the required ngModelController if not all
 * of the inputs are complete.
 */
angular.module('classy').directive('scDateDropdown', scDateDropdown);

function scDateDropdown() {
  'use strict';

  return {
    replace: true,
    require: ['ngModel', '^?form'],
    scope: {
      classStem: '='
    },
    template: '<span class="{{::classStem}}">' + '<select name="scDateDropdownMonth" class="{{::classStem}}_select {{::classStem}}_select-month" data-ng-options="month.value as month.name for month in months" data-ng-model="month" aria-label="month">' + '</select>' + '<select name="scDateDropdownDay" class="{{::classStem}}_select {{::classStem}}_select-day" data-ng-options="day.value as day.name for day in days" data-ng-model="day" aria-label="day">' + '</select>' + '<select name="scDateDropdownYear" class="{{::classStem}}_select {{::classStem}}_select-year" data-ng-options="year.value as year.name for year in years" data-ng-model="year" aria-label="year">' + '</select>' + '</span>',
    // eslint-disable-next-line no-unused-vars
    link: function link(scope, element, attrs, _ref) {
      var _ref2 = _slicedToArray(_ref, 2),
          ngModelCtrl = _ref2[0],
          formCtrl = _ref2[1];

      if (formCtrl) {
        formCtrl.$removeControl(formCtrl.scDateDropdownMonth);
        formCtrl.$removeControl(formCtrl.scDateDropdownDay);
        formCtrl.$removeControl(formCtrl.scDateDropdownYear);
      }

      // Sub-Models

      scope.month = '';
      scope.day = '';
      scope.year = '';

      // Collections

      scope.months = [{ name: 'Month', value: '' }, { name: 'January', value: 'January' }, { name: 'February', value: 'February' }, { name: 'March', value: 'March' }, { name: 'April', value: 'April' }, { name: 'May', value: 'May' }, { name: 'June', value: 'June' }, { name: 'July', value: 'July' }, { name: 'August', value: 'August' }, { name: 'September', value: 'September' }, { name: 'October', value: 'October' }, { name: 'November', value: 'November' }, { name: 'December', value: 'December' }];

      scope.fillDays = function (days) {
        scope.days = [{ name: 'Day', value: '' }];
        for (var d = 1; d <= days; d += 1) {
          scope.days.push({ name: d.toString(), value: d.toString() });
        }
      };

      scope.fillDays(31);

      scope.years = [{ name: 'Year', value: '' }];
      var thisYearStr = moment().format('YYYY');
      var thisYear = parseInt(thisYearStr, 10);
      var firstYear = thisYear - 120;
      for (var y = thisYear; y >= firstYear; y -= 1) {
        scope.years.push({ name: y.toString(), value: y.toString() });
      }

      // Utils

      scope.populateDays = function () {
        var month = scope.month ? Date.getMonthNumberFromName(scope.month) : 0;
        var totalDays = scope.year ? Date.getDaysInMonth(parseInt(scope.year, 10), month) : Date.getDaysInMonth(month);
        scope.fillDays(totalDays);
      };

      scope.parseDate = function (values) {
        var month = values[0];
        var day = parseInt(values[1], 10);
        var year = parseInt(values[2], 10);
        var allNull = !month && !day && !year;
        // If everyone's null, everyone's valid.
        ngModelCtrl.$setValidity('monthRequired', allNull || !!month);
        ngModelCtrl.$setValidity('dayRequired', allNull || !!day);
        ngModelCtrl.$setValidity('yearRequired', allNull || !!year);
        if (month || day || year) {
          ngModelCtrl.$setTouched();
        }
        if (month && day && year) {
          ngModelCtrl.$setDirty();
          var parsed = Date.parse(month + ' ' + day + ', ' + year);
          return parsed instanceof Date ? parsed : false;
        }
        return false;
      };

      // Init

      scope.$watchGroup(['month', 'day', 'year'], function (newValues) {
        scope.populateDays();
        var parsed = scope.parseDate(newValues) || null;
        ngModelCtrl.$setViewValue(parsed);
      });
    }
  };
}
})();