(function(){
'use strict';

scCdnSrc.$inject = ["generateAssetUrl"];
angular.module('classy').directive('scCdnSrc', scCdnSrc);

function scCdnSrc(generateAssetUrl) {
  return {
    scope: {
      scCdnSrc: '@'
    },
    link: function link(scope, element) {
      element.attr('src', generateAssetUrl(scope.scCdnSrc));
    }
  };
}
})();