(function(){
'use strict';

scMemberModelFactory.$inject = ["$http", "$log"];
function _toConsumableArray(arr) { if (Array.isArray(arr)) { for (var i = 0, arr2 = Array(arr.length); i < arr.length; i++) { arr2[i] = arr[i]; } return arr2; } else { return Array.from(arr); } }

/**
 * @kind factory
 * @name scMemberModel
 *
 * @description
 * A member constructor with some common
 * prototype methods.
 */
angular.module('classy').factory('scMemberModel', scMemberModelFactory);

function scMemberModelFactory($http, $log) {
  function scMemberModel(data) {
    this.saved = _.cloneDeep(data);
    this.current = _.cloneDeep(data);
  }

  /**
   * Merge new member into existing instance, for seamless updating of you
   */
  scMemberModel.prototype.update = function () {
    var _this = this;

    var diffObject = scMemberModel.differenceObject(this.current, this.saved);

    return $http.put('/frs-api/members/' + this.saved.id, diffObject).then(function (response) {
      _.merge(_this.saved, response.data);
      _.merge(_this.current, response.data);
    }).catch(function (error) {
      $log.log('scMemberModelFactory, update ', error);
    });
  };

  scMemberModel.prototype.delete = function () {
    return $http({
      url: '/frs-api/members/' + this.current.id + '/deactivate',
      method: 'POST'
    });
  };

  /**
   *  I grabbed this functionality from methods pre-existing in scObjectModel because including it
   *  as a dependency was causing a "circular dependency" problem. In the future, if feasible,
   *  it would be good for someone to conduct a refactoring of the existing architecture such
   *  that all <sc...Models>  could inherit from scObjectModel.
   */
  scMemberModel.differenceObject = function () {
    var current = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
    var saved = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : {};

    // delete properties that are not stored on database
    delete saved.queries_log;
    delete saved.mongo_queries_log;
    delete saved.updated_at;
    delete current.queries_log;
    delete current.mongo_queries_log;
    delete current.updated_at;

    var diff = [].concat(_toConsumableArray(Object.keys(saved).filter(function (key) {
      return saved[key] !== current[key];
    })), _toConsumableArray(Object.keys(current).filter(function (key) {
      return !(key in saved);
    })));

    var data = {};

    diff.forEach(function (key) {
      data[key] = current[key];
    });

    return data;
  };

  /*
   * Discard any unsaved changes. Revert to
   * most recently saved version.
   * @param  {string} property Pass to discard changes to
   *  specific member properties.
   */
  scMemberModel.prototype.discardChanges = function (property) {
    if (_.isUndefined(property)) {
      this.current = _.cloneDeep(this.saved);
    } else {
      _.set(this, 'current.' + property, _.get(this, 'saved.' + property, null));
    }
  };

  scMemberModel.prototype.isGod = function () {
    return this.isAdmin && window.sessionStorage && window.sessionStorage.getItem('scGodMode');
  };

  scMemberModel.prototype.getOrgs = function () {
    return $http({
      method: 'GET',
      url: '/frs-api/members/' + this.current.id + '/organizations'
    });
  };

  return scMemberModel;
}
})();