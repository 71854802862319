(function(){
'use strict';

/**
 * @kind directive
 * @name scInfiniteScrollViewport
 * @scope
 *
 * @description
 * Used in conjunction with scInfiniteScrollViewport. Placed on the scroll
 * viewport. When the content element is within 10 px of the bottom of the
 * viewport element, the provided callback is fired (presumably to load more
 * rows into the content element).
 *
 * @todo Need a control to stop watching scroll, e.g. if all rows have loaded.
 */
scInfiniteScrollViewport.$inject = ["$timeout"];
angular.module('classy').directive('scInfiniteScrollViewport', scInfiniteScrollViewport);

function scInfiniteScrollViewport($timeout) {
  'use strict';

  return {
    scope: {
      callback: '&scInfiniteScrollViewport'
    },
    controller: ['$scope', function ($scope) {
      $scope.content = null;
      this.setContent = function (element) {
        $scope.content = element;
      };
    }],

    link: function link(scope, element) {
      var debouncer = void 0;

      function maybeTrigger() {
        $timeout.cancel(debouncer);
        var container = element;
        var content = scope.content;
        var contentBottom = content.offset().top + content.outerHeight();
        var containerBottom = container.offset().top + container.outerHeight();
        var difference = contentBottom - containerBottom;
        if (difference <= 10) {
          scope.$apply(function () {
            scope.callback();
          });

          return true;
        }
        return false;
      }

      element.on('scroll wheel', function () {
        // Bail if we aren't fully hooked up yet
        if (!scope.content) {
          return;
        }

        var triggered = maybeTrigger();

        if (!triggered) {
          // If you scroll too rapidly, you may not emit enough scroll
          // events to capture one late enough to trigger the callback, so we
          // schedule a promise to check once scrolling is complete.
          debouncer = $timeout(maybeTrigger, 10);
        }
      });
    }
  };
}
})();