(function(){
'use strict';

/**
 * @kind factory
 * @name scImgAssetExpressUpload
 * @package scImg
 *
 * @description
 * Fallback image upload for browsers that do not support File API.
 *
 * NGF uses a Flash fallback for these browsers, but Flash does not support the
 * PUT method (nice fallback), which means it cannot modify the S3 object OR
 * the API asset record. Instead, the full process is handled by Express.
 *
 * @param {File} file The file object, via ng-file-upload.
 */
scImgAssetExpressUploadFactory.$inject = ["$q", "Upload", "scMembersService"];
angular.module('classy').factory('scImgAssetExpressUpload', scImgAssetExpressUploadFactory);

function scImgAssetExpressUploadFactory($q, Upload, scMembersService) {
  function scImgAssetExpressUpload(file) {
    var uploadDeferred = $q.defer();

    var memberId = scMembersService.you.current.id;

    Upload.upload({
      url: '/frs-api/asset',
      data: {
        file: file,
        memberId: memberId
      }
    }).then(function (response) {
      uploadDeferred.notify({
        progress: 100
      });
      uploadDeferred.resolve(response.data);
    }, function (error) {
      uploadDeferred.reject(error);
    }, function (update) {
      uploadDeferred.notify({
        progress: parseInt(100 * update.loaded / update.total, 10)
      });
    });

    return uploadDeferred.promise;
  }

  return scImgAssetExpressUpload;
}
})();