(function(){
'use strict';

scTimeMachineService.$inject = ["scCampaignsService"];
angular.module('classy').service('scTimeMachineService', scTimeMachineService);

function scTimeMachineService(scCampaignsService) {
  return {
    utc: function utc(timeStamp) {
      var utc = moment.utc(timeStamp);
      return utc.format();
    },
    zoneShift: function zoneShift(momentObject, newTimezone) {
      return momentObject.tz(newTimezone || moment.tz.guess());
    },
    zoneSwap: function zoneSwap(momentObject, newTimezone) {
      if (momentObject) {
        var offset1 = momentObject.zone();
        momentObject.tz(newTimezone || moment.tz.guess());

        var offset2 = momentObject.zone();
        var final = momentObject;

        final.add(offset2 - offset1, 'm');

        return final;
      }

      return undefined;
    },
    campaignTime: function campaignTime(momentObject) {
      var timezone = _.get(scCampaignsService, 'active.current.timezone_identifier', moment.tz.guess());
      return this.zoneShift(momentObject, timezone);
    },
    justDate: function justDate(datetime, options) {
      var ts = moment(datetime);
      if (options.campaignTime) {
        var timezone = _.get(scCampaignsService, 'active.current.timezone_identifier', moment.tz.guess());
        ts = this.zoneSwap(ts, timezone);
      }
      ts.startOf('day');
      if (options.inclusive) {
        ts.add(1, 'd');
      }
      if (options.returnTimestampAsObject) {
        return ts;
      }
      return ts.format();
    }
  };
}
})();