(function(){
'use strict';

angular.module('classy').directive('scPaymentTypes', scPaymentTypes);

function scPaymentTypes() {
  'use strict';

  return {
    replace: true,

    templateUrl: 'global/directives/scPaymentTypes/template',

    transclude: false,

    scope: {
      scPaymentTypes: '=',
      paypal: '&',
      paypalSubmit: '&'
    },

    link: function link(scope) {
      scope.cardClass = function (card) {
        return {
          visa: card === 'Visa',
          mastercard: card === 'MasterCard',
          amex: card === 'American Express',
          discover: card === 'Discover'
        };
      };
    }
  };
}
})();