(function(){
'use strict';

/**
 * @name scDaysRemaining
 * @kind filter
 */
angular.module('classy').filter('scDaysRemaining', scDaysRemaining);

function scDaysRemaining() {
  return function (endDate) {
    if (endDate) {
      var milsRemaining = Date.parse(endDate).getElapsed();
      return milsRemaining > 0 ? 0 : Math.floor(milsRemaining * -1 * (1 / 1000) * (1 / 60) * (1 / 60) * (1 / 24));
    }
    return null;
  };
}
})();