(function(){
'use strict';

createGTM.$inject = ["$q", "$window", "scAnalyticsServiceSettings", "pageNameService", "scMembersService", "scTransientsService", "whitelistedPropertyService", "scUserAgent", "scAnalyticsUtils", "EmbeddedGivingUtil"];
function _toConsumableArray(arr) { if (Array.isArray(arr)) { for (var i = 0, arr2 = Array(arr.length); i < arr.length; i++) { arr2[i] = arr[i]; } return arr2; } else { return Array.from(arr); } }

function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

angular.module('classy').factory('gtmService', createGTM);

function createGTM($q, $window, scAnalyticsServiceSettings, pageNameService, scMembersService, scTransientsService, whitelistedPropertyService, scUserAgent, scAnalyticsUtils, EmbeddedGivingUtil) {
  var gtmService = { name: 'google_tag_manager' };
  var getCampaign = scAnalyticsUtils.getCampaign,
      isFunnelConversionEvent = scAnalyticsUtils.isFunnelConversionEvent,
      getUtmParameters = scAnalyticsUtils.getUtmParameters,
      getRegistrationQuantity = scAnalyticsUtils.getRegistrationQuantity;


  var eventLabelMapper = _.extend({
    fundraising_page_sign_up: 'New Fundraising Page',
    fundraising_team_sign_up: 'New Team',
    supporter_loaded: 'supporter_loaded',
    cart: 'cart',
    transaction: 'transaction',
    custom_page_view: 'custom_page_view',
    ga_add_to_cart: 'add to cart',
    ga_remove_from_cart: 'remove from cart',
    ga_purchase: 'ga_purchase'
  }, EmbeddedGivingUtil.getGTMEventLabelMapper());

  /* Interface Methods
  ========================================================= */
  gtmService.registerSuperProperties = function () {};
  gtmService.identifyMember = function () {};

  gtmService.loaded = function () {
    return $q(function (resolve, reject) {
      if (gtmService.hasGTM()) return resolve();
      return reject();
    });
  };

  gtmService.eventBeacon = function (data) {
    var label = _.get(data, 'label', '').toLowerCase();
    var gtmOptions = data;

    if (label.includes('new fundraising page') || label.includes('new team')) {
      var dataType = label.includes('fundraising') ? 'fundraiser' : 'team';
      gtmOptions = _.pick(data, 'event', dataType);
      gtmService.updateDataLayer({ event: data.label, data: gtmOptions });
    } else if (label === 'add to cart' || label === 'remove from cart') {
      gtmService.buildEcommerceObject({
        event: label,
        items: [{
          id: data.ticket_id || data.id,
          name: data.product_name,
          price: data.price,
          quantity: 1
        }]
      });
    }
  };

  gtmService.recordPageView = function () {
    var pageViewParams = {
      event: 'custom_page_view'
    };

    var EXTRA_GTM_PAGEVIEW_DATA = scTransientsService.get('EXTRA_GTM_PAGEVIEW_DATA') || {};
    var memberId = _.get(scMembersService, 'active.current.id', undefined);

    Object.assign(pageViewParams, {
      member: {
        id: memberId,
        loggedIn: !!memberId
      },
      page: {
        name: pageNameService.getPageName()
      }
    }, EXTRA_GTM_PAGEVIEW_DATA);

    gtmService.updateDataLayer({ data: pageViewParams });
  };

  gtmService.track = function (event, data) {
    if (isFunnelConversionEvent(event)) {
      trackTransactionEvent(event, data);
      return;
    } else if (event == 'transaction') {
      mapTransactionFields(data);
    }
    gtmService.updateDataLayer({ event: event, data: data });
  };

  gtmService.hasGTM = function () {
    return scAnalyticsServiceSettings.getByName('organization', 'google_tag_manager') && $window.dataLayer;
  };

  // event: String
  // data: Object in the form of { `dataType`: dataObject, dataType: object }
  // e.g. { custom_page_view: 'home', member: {}, ... }
  //
  // Try not to call this function in components, because we don't want the components
  // to keep track of specific analytics services. Instead, this function should be called from
  // scAnalytics.
  gtmService.updateDataLayer = function () {
    var _ref = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {},
        event = _ref.event,
        _ref$data = _ref.data,
        data = _ref$data === undefined ? {} : _ref$data;

    if (!gtmService.hasGTM()) return;

    // create a clone of the data to prevent side-effects
    var finalData = Object.assign({}, data);

    // transform the event label into something gtm expects
    var gtmEvent = data.event || event;

    // if the item is not in the eventLabelMapper, then it's not a valid gtm
    // event so this property will be set to undefined causing exit below
    finalData.event = _.findKey(eventLabelMapper, function (entry) {
      return entry === gtmEvent;
    });

    // don't perform analytics for prerender,
    // or if the pushed object doesn't have an associated event
    if (scUserAgent.isBot() || !finalData.event) return;

    Object.keys(finalData).forEach(function (key) {
      finalData[key] = whitelistedPropertyService.getSanitizedData(key, finalData[key]);
    });

    var utmParameters = getUtmParameters();
    if (utmParameters) finalData.utm = utmParameters;

    $window.dataLayer.push(finalData);
  };

  // transaction: object
  // items: array
  // This builds the object that we add to our dataLayer on transaction success
  // in order for the data to be sent to GA for ecommerce tracking
  // Documentation: https://classydev.atlassian.net/wiki/spaces/FRS/pages/653328454/Setting+up+Integrations+Tags#SettingupIntegrations/Tags-EnhancedEcommerceTrackingwithGoogleAnalytics(link no longer works)
  gtmService.buildEcommerceObject = function (_ref2) {
    var event = _ref2.event,
        _ref2$transaction = _ref2.transaction,
        transaction = _ref2$transaction === undefined ? {} : _ref2$transaction,
        _ref2$items = _ref2.items,
        items = _ref2$items === undefined ? [] : _ref2$items;

    if (!event || !gtmService.hasGTM()) {
      return;
    }

    if (transaction.actionField && (!transaction.actionField.revenue || transaction.actionField.revenue <= 0)) {
      transaction.actionField.revenue = calculateValue(items);
    }

    var type = '';
    if (event === eventLabelMapper.ga_purchase) {
      type = 'purchase';
    } else if (event === eventLabelMapper.ga_add_to_cart) {
      type = 'add';
    } else if (event === eventLabelMapper.ga_remove_from_cart) {
      type = 'remove';
    }

    var ecommerceObject = {
      ecommerce: _defineProperty({}, type, {
        products: items
      })
    };

    Object.assign(ecommerceObject.ecommerce[type], transaction);

    gtmService.updateDataLayer({ event: event, data: ecommerceObject });
  };

  return gtmService;

  /* Private Methods
  ========================================================= */
  function buildTransactionItems(options) {
    var registrations = options.items || [];
    var campaign = getCampaign();
    var allCampaignRegistrations = campaign.current.ticketTypes || [];
    var items = [];

    _.forEach(registrations, function (registration) {
      var registrationTypeMatch = _.find(allCampaignRegistrations, function (ticket) {
        return ticket.id === registration.product_id;
      });
      var registrationName = _.get(registrationTypeMatch, 'name', null);
      var registrationPrice = _.get(registrationTypeMatch, 'price', 0);
      var registrationQuantity = getRegistrationQuantity(registration);

      var dataObject = {
        id: registration.id,
        name: registrationName,
        sku: registration.id.toString(),
        category: 'Ticket',
        price: registrationPrice,
        quantity: registrationQuantity
      };

      items.push(dataObject);
    });

    return items;
  }

  function trackTransactionEvent(event) {
    var options = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : {};

    var amount = options.amount || 0;
    var donationAmount = options.donation_amount;
    var rwfCompletionEvent = event === 'registration/create/complete';

    var transactionId = rwfCompletionEvent ? options.registration_transaction_id : options.transactionId;

    gtmService.loaded().then(function () {
      var productArray = [];

      if (!rwfCompletionEvent || donationAmount > 0) {
        var dataObject = {
          id: transactionId,
          name: 'Donation',
          sku: '1',
          category: rwfCompletionEvent ? 'Additional Donation' : 'Direct Donation',
          price: rwfCompletionEvent ? donationAmount : amount,
          quantity: 1
        };
        productArray.push(dataObject);
      }

      if (rwfCompletionEvent) {
        var items = buildTransactionItems(Object.assign(options, { transactionId: transactionId }));
        productArray = [].concat(_toConsumableArray(productArray), _toConsumableArray(items));
      }

      gtmService.buildEcommerceObject({
        event: 'ga_purchase',
        transaction: {
          actionField: {
            id: transactionId,
            revenue: amount,
            coupon: options.promo_code_code
          }
        },
        items: productArray
      });
    });
  }

  function calculateValue(items) {
    return _.reduce(items, function (sum, item) {
      return sum + (parseFloat(item.price) || 0);
    }, 0);
  }

  // data: object
  // Maps some of the fields on the cart, and donation transaction events
  // for sending as a part of the gtm objects
  function mapTransactionFields(data) {
    if (Object.prototype.hasOwnProperty.call(data, 'transaction')) {
      if (Object.prototype.hasOwnProperty.call(data.transaction, 'items')) {
        _.forEach(data.transaction.items, function (item) {
          _.forEach(item.registrants, function (registrant) {
            registrant.email = registrant.email_address;
          });
        });
      } else {
        data.transaction.first_name = data.transaction.billing_first_name;
        data.transaction.last_name = data.transaction.billing_last_name;
        data.transaction.email = data.transaction.member_email_address;
      }
    }
  }
}
})();