(function(){
'use strict';

CFPSuccessViewFactory.$inject = ["scFlowModalService"];
angular.module('classy').factory('CFPSuccessView', CFPSuccessViewFactory);

function CFPSuccessViewFactory(scFlowModalService) {
  return function CFPSuccessView(parentScope) {
    var $scope = parentScope.$new();

    $scope.done = function () {
      scFlowModalService.close();
    };

    /* ---------------------------------------------------------------------- *
     * View object
     * ---------------------------------------------------------------------- */

    return {
      id: 'indiv.success',
      templateUrl: $scope.flow.template('success'),
      maxWidth: 385,
      context: $scope
    };
  };
}
})();