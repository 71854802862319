(function(){
'use strict';

/**
 * @kind directive
 * @name scRenderBlock
 *
 * @description
 * Renders a block from scBlocksService based on the
 * passed blockId. Also adds campaign, page, and utils
 * to all block scopes.
 */
scRenderBlock.$inject = ["$compile", "$state", "scBlocksService", "scMembersService", "scOrganizationsService", "scQaId", "CHAR_LIMITS", "PERMISSIONS"];
angular.module('classy').directive('scRenderBlock', scRenderBlock);

function scRenderBlock($compile, $state, scBlocksService, scMembersService, scOrganizationsService, scQaId, CHAR_LIMITS, PERMISSIONS) {
  return {
    require: '?^scDesignerScrollingPanes',
    scope: {
      blockId: '=scRenderBlock',
      campaign: '=',
      theme: '=',
      page: '=?',
      layout: '=?',
      team: '=?',
      fundraiser: '=?',
      public: '=',
      demo: '=?',
      editable: '=?',
      firstVisibleBlockId: '=?'
    },
    link: function link(scope, element, attrs, designScrollCtrl) {
      scope.CHAR_LIMITS = CHAR_LIMITS;
      /* ---------------------------------------------------------------- *
       * UI
       * ---------------------------------------------------------------- */

      scope.ui = {
        settingsMode: !!attrs.settings,
        sortableOptions: {
          handle: '.sc-block_reorder-handle'
        }
      };

      /* ---------------------------------------------------------------- *
       * Scope vars
       * ---------------------------------------------------------------- */

      scope.block = scBlocksService.get(scope.blockId).current;
      scope.you = scMembersService.you;
      scope.organization = scOrganizationsService.active;
      scope.SC = SC;
      scope.scQaId = scQaId;

      scope.$watch(
      // Mapping the blocks ids to a string so then the $watch will only trigger when the order changes.
      function (_scope) {
        if (_scope.page) {
          return _scope.page.block_sections[1].blocks.map(function (block) {
            return block.id;
          }).join();
        } else {
          return null;
        }
      }, function () {
        if (scope.page && scope.page.block_sections.length) {
          var notVisibleBlocks = ['global-css'];
          var bodyBlocks = scope.page.block_sections[1].blocks;
          var firstVisibleBlock = bodyBlocks.find(function (block) {
            return !notVisibleBlocks.includes(block.type);
          }) || {};
          scope.firstVisibleBlockId = firstVisibleBlock.id;
        }
      });

      /* ---------------------------------------------------------------- *
       * Build
       * ---------------------------------------------------------------- */

      var blockHTML = '';

      scope.updateCustomCodePermission = PERMISSIONS.campaign.update_custom_code;

      if (scope.editable) {
        blockHTML = '<div class="sc-block_design-menu">\n          <gate permissions="updateCustomCodePermission">\n            <authorized>\n              <i class="sc-block_design-menu-button sc-block_design-menu-sort fa fa-bars"></i>\n            </authorized>\n            <unauthorized>\n              <i data-ng-if="!block.unsortable" class="sc-block_design-menu-button sc-block_design-menu-sort fa fa-bars"></i>\n            </unauthorized>\n          </gate>\n        </div>';

        if (scBlocksService.checkBlockAccess(scope.block)) {
          element.on('click', function () {
            scope.$apply(function () {
              $state.go('admin.manage.campaign.step.design.views.block', {
                blockId: scope.blockId
              });
            });
          });
        }
      }

      scope.scrollToElement = function (id) {
        if (id === 'sc-target-to-top') document.getElementById('header-block_navigation').focus();
        if ($state.includes('admin.manage.campaign.step.design')) {
          designScrollCtrl.scrollTo(id);
        }

        return true;
      };

      // Directive attribute used to display the block
      var blockDirectiveAttribute = getBlockDirectiveAttribute(scope.block.type);
      blockHTML += '<div ' + blockDirectiveAttribute + (scope.ui.settingsMode ? '-settings' : '') + '></div>';

      var blockElement = angular.element(blockHTML);

      /* ---------------------------------------------------------------- *
       * Compile
       * ---------------------------------------------------------------- */

      element.append(blockElement);
      $compile(blockElement)(scope);
    }
  };
}

// We should use frs-donation-block for organizations that have Classy Pay, and
// sc-donation-block for organizations that do not have Classy Pay.
function getBlockDirectiveAttribute(type) {
  if (type === 'donation') {
    return 'frs-donation-block';
  }
  return 'sc-' + type + '-block';
}
})();