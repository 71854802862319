(function(){
'use strict';

/**
 * Function to show the top status banner
 * @param {string} message - The message to be displayed
 * @param {string|undefined} status - The status type, defaults to 'error'
 * @param {boolean|undefined} noTimeout - set to true if you want the banner to stay permanently
 */
scBanner.$inject = ["$rootScope"];
angular.module('classy').factory('scBanner', scBanner);

/**
 * The factory
 */
function scBanner($rootScope) {
  function banner(message) {
    var status = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : 'error';
    var noTimeout = arguments[2];
    var timeoutLength = arguments.length > 3 && arguments[3] !== undefined ? arguments[3] : 10000;
    var customColor = arguments[4];
    var onClose = arguments[5];

    $rootScope.SC.status.banner = {
      type: status,
      msg: message,
      timeout: !noTimeout,
      timeoutLength: timeoutLength,
      color: customColor,
      onClose: onClose
    };
  }

  banner.close = function () {
    $rootScope.SC.status.banner = {};
  };

  return banner;
}
})();