(function(){
'use strict';

scThankYouBlockSettings.$inject = ["scFroalaOptions"];
angular.module('classy').directive('scThankYouBlockSettings', scThankYouBlockSettings);

function scThankYouBlockSettings(scFroalaOptions) {
  return {
    scope: true,
    templateUrl: 'global/objects/scBlock/types/thank-you/settings',
    controller: ['$scope', function ($scope) {
      $scope.headerOptions = scFroalaOptions({ type: 'inline-header' }, {
        ariaLabel: 'Headline'
      });

      $scope.messageOptions = scFroalaOptions({ type: 'inline-with-video' }, {
        ariaLabel: 'Thank You'
      });

      $scope.thankYouPhotoMediaManagerOptions = {
        title: 'Select a Background Image',
        image: {
          guide: {
            title: 'Thank You Page Background Image',
            message: 'Brand your thank you page with a unique background image.',
            size: '1280 x 720 pixels'
          }
        }
      };
    }],
    link: function link() {}
  };
}
})();