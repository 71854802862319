(function(){
'use strict';

CFPWelcomeBackViewFactory.$inject = ["scFlowModalService"];
angular.module('classy').factory('CFPWelcomeBackView', CFPWelcomeBackViewFactory);

function CFPWelcomeBackViewFactory(scFlowModalService) {
  return function CFPWelcomeBackView(parentScope) {
    var $scope = parentScope.$new();
    $scope.continueToPage = function () {
      scFlowModalService.close();
      $scope.flow.abort({
        state: 'frs.fundraiser.index',
        params: { fundraisingPageId: $scope.redirectToPageId }
      });
    };

    /* ---------------------------------------------------------------------- *
     * View object
     * ---------------------------------------------------------------------- */

    return {
      id: 'indiv.welcome-back',
      templateUrl: $scope.flow.template('multiple-fundraisers/welcome-back'),
      maxWidth: 384,
      context: $scope
    };
  };
}
})();