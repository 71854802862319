(function(){
'use strict';

/**
 * @kind directive
 * @name scClipboard
 * @scope
 *
 * @description
 * Copy to clipboard using native browser clipboard API (only works on HTTPS)
 *
 * @param {text} scClipboard The value to copy.
 * @param {function} scClipboardSuccess The success function
 * @param {function} scClipboardError The function to run on error
 * @param {text} scClipTarget The selector (ideally an id) of the element whose text will be copied
 * @param {text} scCopiedTarget The selector of the element which
 *    will get the class 'copied' or 'not-copied' after success/fail
 * @param {text} scErrorTooltipText The text to show as a tooltip if copy fails
 * @param {object} scErrorTooltipPlacement Where and which corner to put the error
 *    tooltip see http://qtip2.com/options#position for options
 * @param {text} scErrorTooltipClass Class to add to the error tooltip
 * @param {bool} scSuppressErrorTooltip if set, the error tooltip will not trigger
 */

angular.module('classy').directive('scClipboard', scClipboard);

function scClipboard() {
  'use strict';

  return {
    scope: {
      clipText: '=scClipboard',
      clipTarget: '@scClipTarget',
      copiedTarget: '@scCopiedTarget',
      tooltipText: '@scErrorTooltipText',
      tooltipPlacement: '=scErrorTooltipPlacement',
      tooltipClass: '@scErrorTooltipClass',
      suppressTooltip: '@scSuppressErrorTooltip',
      scClipboardSuccess: '&',
      scClipboardError: '&'
    },

    link: function link(scope, element) {
      function handleClick() {
        navigator.permissions.query({ name: 'clipboard-write' }).then(function (result) {
          if (result.state === 'granted' || result.state === 'prompt') {
            if (scope.clipText) {
              return navigator.clipboard.writeText(scope.clipText);
            } else if (scope.clipTarget) {
              var richTxt = angular.element(scope.clipTarget)[0].innerHTML;
              return navigator.clipboard.write([new window.ClipboardItem({
                'text/html': new Blob([richTxt], { type: 'text/html' })
              })]);
            } else {
              return Promise.reject(new Error('Missing copy text or target'));
            }
          }
          return Promise.reject(new Error('Clipboard permission denied'));
        }).then(success, error);
      }

      element.on('click', handleClick);

      function flashClass(tempClass) {
        var copiedTarget = element;
        if (scope.copiedTarget) {
          copiedTarget = angular.element(scope.copiedTarget);
        }
        copiedTarget.addClass(tempClass);
        setTimeout(function () {
          copiedTarget.removeClass(tempClass);
        }, 3000);
      }

      var success = function success(e) {
        scope.$apply(function () {
          flashClass('copied');
          scope.scClipboardSuccess({ e: e });
        });
      };

      var error = function error(e) {
        var $trigger = $(e.trigger);

        var defaultPlacement = {
          my: 'left center',
          at: 'right center',
          target: $trigger
        };

        var tooltipPlacement = _.defaults(scope.tooltipPlacement, defaultPlacement);

        if (!scope.suppressTooltip) {
          $trigger.qtip({
            content: {
              text: scope.tooltipText
            },
            position: tooltipPlacement,
            style: {
              classes: 'qtip-plain qtip-dark ' + scope.tooltipClass
            },
            show: {
              event: function event() {
                setTimeout(function () {
                  $trigger.qtip('destroy', true);
                }, 3000);
              }
            }
          }).qtip('show');
        }

        scope.$apply(function () {
          flashClass('not-copied');
          scope.scClipboardError({ e: e });
        });
      };
    }
  };
}
})();