(function(){
'use strict';

/**
 * @name replaceUnderscoreWithBlank
 * @kind filter
 * To replace Underscore with Blank.
 */
angular.module('classy').filter('replaceUnderscoreWithBlank', replaceUnderscoreWithBlank);

function replaceUnderscoreWithBlank() {
  return function (input) {
    return input.replace(/_/g, ' ');
  };
}
})();