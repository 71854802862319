(function(){
'use strict';

/**
 * @kind directive
 * @name scCompile
 *
 * @description
 * Simply compile passed in string template and render to html
 *
 * Used in:
 * Appv2
 *
 */
scCompile.$inject = ["$compile", "$parse"];
angular.module('classy').directive('scCompile', scCompile);

function scCompile($compile, $parse) {
  return {
    link: function link(scope, element, attrs) {
      function compileContent() {
        var content = $parse(attrs.scCompile)(scope),
            contentWrapped = '<div>' + content + '</div>';

        var compiledContent = $compile(angular.element(contentWrapped))(scope);

        element.html(compiledContent);
      }

      compileContent();

      scope.$watch(attrs.scCompile, function (newVal, oldVal) {
        if (newVal !== oldVal) {
          compileContent();
        }
      });
    }
  };
}
})();