(function(){
'use strict';

donationDedicationCtrl.$inject = ["$scope", "scFlowModalService"];
angular.module('classy').controller('donationDedicationCtrl', donationDedicationCtrl);

function donationDedicationCtrl($scope, scFlowModalService) {
  var stripTags = function stripTags(input) {
    return input.replace(/(<([^>]+)>)/gi, '');
  };
  /**
   * Dedication ECards setup
   */
  $scope.dedicationPreview = function () {
    var previewUrl = '/checkout/dedication-preview';
    var message = $scope.Donation.dedication.emailMsg ? stripTags($scope.Donation.dedication.emailMsg).replace(/(?:\r\n|\r|\n)/g, '<br />') : 'Your Message here.';

    var name = void 0;
    if (!$scope.Donation.name.anonymous) {
      if ($scope.Donation.payment.name.first && $scope.Donation.payment.name.last) {
        name = $scope.Donation.payment.name.first + ' ' + $scope.Donation.payment.name.last;
      } else {
        name = 'Your Name';
      }
    } else {
      name = 'An anonymous donor';
    }

    previewUrl += $scope.Donation.dedication.emailImg > 0 ? '?ecid=' + $scope.Donation.dedication.emailImg : '?img=0';
    previewUrl += '&cid=' + SC.organization.id;
    previewUrl += '&type=' + ($scope.Donation.dedication.type || 'honor');
    previewUrl += '&name=' + stripTags(name);
    previewUrl += '&dname=' + stripTags($scope.Donation.dedication.name || "Honoree's Name");
    previewUrl += '&message=' + message;

    return previewUrl;
  };

  /* ---------------------------------------------------------------------- *
   * Collections
   * ---------------------------------------------------------------------- */

  $scope.list = {
    ecards: $scope.campaign.current.ecards
  };

  $scope.build = {
    previewLink: function previewLink() {
      var message = void 0;

      $scope.activeEcard = _.filter($scope.list.ecards, function (ecard) {
        return ecard.id == $scope.Donation.dedication.emailImg;
      })[0];

      if ($scope.Donation.dedication.emailMsg) {
        message = stripTags($scope.Donation.dedication.emailMsg).replace(/(?:\r\n|\r|\n)/g, '<br />');
      } else {
        message = 'Your Message here.';
      }

      $scope.emailMessage = message;

      scFlowModalService.open({
        templateUrl: 'global/objects/scBlock/types/donation/dedication/ecard-preview-modal',
        context: $scope,
        maxWidth: 520
      });
    }
  };
}
})();