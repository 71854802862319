(function(){
'use strict';

/**
 * @kind directive
 * @name scFitFullScreen
 * @scope
 *
 * @description
 * Fixes positioning within a responsive parent.
 *
 * Element will not pass top of its container.
 * Element will have 'fixed' class if container top is offscreen.
 * Element will have 'absolute' class when container is moving offscreen so
 * that it remains within its container.
 * Element takes the width of sibling with class 'fix-neat_placeholder'.
 */

scFixNeat.$inject = ["$window", "$timeout", "$state"];
angular.module('classy').directive('scFixNeat', scFixNeat);

function scFixNeat($window, $timeout, $state) {
  return {
    scope: {
      offset: '=',
      fixOn: '&',
      fixOff: '&'
    },
    link: function link(scope, element) {
      var container = element.parent(),
          placeholder = element.siblings('.fix-neat_placeholder');

      var elementNaturalTop = void 0;

      $timeout(function () {
        elementNaturalTop = element.offset().top;
      });

      /* ------------------------------------------------------------------ *
       * Animation cycle
       * ------------------------------------------------------------------ */

      angular.element($window).bind('scroll', function () {
        scrollEvent();
      });

      scrollEvent();

      /* ------------------------------------------------------------------ *
       * Class calculation
       * ------------------------------------------------------------------ */

      function scrollEvent() {
        var userOffset = scope.offset || 0;

        var containerBottom = container.offset().top + container.height();
        var elementFootprint = element.height() + userOffset;
        var elementNaturalTopWithOffset = elementNaturalTop - userOffset;
        var scrollTop = angular.element($window).scrollTop();

        // If remaining visible space in container is not enough to display
        // element and its offset, switch it to absolute.
        if (containerBottom <= elementFootprint) {
          element.removeClass('fixed');
          element.addClass('absolute');
          placeholder.height(0);
          scope.fixOff();

          // If the window is scrolled past the element's natural top offset
          // with user's offset factored in, switch to fixed.
        } else if (scrollTop >= elementNaturalTopWithOffset) {
          element.addClass('fixed');
          element.removeClass('absolute');
          placeholder.height(element.outerHeight());
          scope.fixOn();

          // Otherwise, remove all classes.
        } else {
          element.removeClass('fixed');
          element.removeClass('absolute');
          placeholder.height(0);

          scope.fixOff();
        }
      }

      /* ------------------------------------------------------------------ *
       * Width maintenance
       * ------------------------------------------------------------------ */

      /*
       * Watch resize, recalculate element width based on sibling
       */
      angular.element($window).on('resize', function () {
        element.css(calculateWidth());
      });

      /*
       * Get element's width based on sibling.
       * I used getBoundingClientRect to avoid rounding.
       */
      function calculateWidth() {
        var css = {};

        // Don't resize for admin use
        if (!$state.includes('admin')) {
          css.width = placeholder[0].getBoundingClientRect().width;
        }

        return css;
      }

      $timeout(function () {
        element.css(calculateWidth());
      });
    }
  };
}
})();