(function(){
'use strict';

/**
 * @name scBroadcastFormErrors
 * @kind directive
 * @requires ngForm
 *
 * @description
 * Enables event-based validation by watching a form's $error property and
 * $broadcasting error notifications to descendant DOM and controls.
 */
scBroadcastFormErrors.$inject = ["formDirective"];
angular.module('classy').directive('scBroadcastFormErrors', scBroadcastFormErrors);

function scBroadcastFormErrors(formDirective) {
  'use strict';

  function populateErrors(formCtrl, errors) {
    var prefix = arguments.length > 2 && arguments[2] !== undefined ? arguments[2] : '';

    _.forEach(formCtrl.$error, function (ctrlArray, validationKey) {
      _.forEach(ctrlArray, function (ctrl) {
        // Recursively descend into subforms
        if (ctrl instanceof formDirective[0].controller) {
          populateErrors(ctrl, errors, ctrl.$name + '.');
          return;
        }

        // Ignore $pristine, $untouched or $validOnFocus controls
        if (!formCtrl.$submitted && (ctrl.$pristine || ctrl.$untouched || ctrl.$validOnFocus)) {
          return;
        }

        // Add error

        errors.push(prefix + ctrl.$name);
        errors.push(prefix + ctrl.$name + '|' + validationKey);
      });
    });
  }

  return {
    require: 'form',
    // eslint-disable-next-line no-unused-vars
    link: function link(scope, element, attrs, formCtrl) {
      formCtrl.$visibleErrors = [];

      scope.$watch(function () {
        var currentErrors = [];

        populateErrors(formCtrl, currentErrors);

        currentErrors = _.uniq(currentErrors);

        var resolved = _.difference(formCtrl.$visibleErrors, currentErrors);

        var emergent = _.difference(currentErrors, formCtrl.$visibleErrors);

        _.forEach(resolved, function (key) {
          var pieces = key.split('|');
          scope.$broadcast('VALIDATION_CHANGE:' + pieces[0], pieces[1], true);
        });

        _.forEach(emergent, function (key) {
          var pieces = key.split('|');
          scope.$broadcast('VALIDATION_CHANGE:' + pieces[0], pieces[1], false);
        });

        formCtrl.$visibleErrors = currentErrors;
      });
    }
  };
}
})();