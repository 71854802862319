(function(){
'use strict';

/**
 * @kind directive
 * @name scSelectExpirationMonths
 * @requires ngModel
 *
 * @description
 * Renders a dropdown for credit card expiration months in MM format.
 *
 * WARNING: ngModel is NOT optional as presented in the directive. It must be
 * present on the original element, or else Angular will not compile the select
 * options.
 *
 * Used in:
 *
 * * application/views/scripts/checkout/donation.phtml
 * * application/views/scripts/npo/cancelled-account.phtml
 * * application/views/scripts/npo/payment-info.phtml
 * * application/views/scripts/sales/payment.phtml
 * * public/partials/reports/recurring-modal.html
 *
 * @todo Isolate scope or namespace months property; right now this
 * directive will overwrite a scope property with the name 'months'.
 */

scSelectExpirationMonths.$inject = ["$parse", "$filter"];
angular.module('classy').directive('scSelectExpirationMonths', scSelectExpirationMonths);

function scSelectExpirationMonths($parse, $filter) {
  'use strict';

  return {
    require: '?ngModel',

    replace: true,

    template: '<select ng-options="month.val as month.val for month in months"></select>',
    // eslint-disable-next-line no-unused-vars
    link: function link(scope, element, attrs) {
      var model = $parse(attrs.ngModel)(scope);

      scope.months = [];
      for (var m = 1; m <= 12; m += 1) {
        scope.months.push({
          val: m < 10 ? '0' + m : '' + m
        });
      }

      // Pre-select current month, if model is not defined
      if (angular.isUndefined(model)) {
        var month = parseInt($filter('date')(new Date(), 'MM'), 10);
        month = month < 10 ? '0' + month : '' + month;
        $parse(attrs.ngModel).assign(scope, month);
      }
    }
  };
}
})();