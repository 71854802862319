(function(){
'use strict';

/**
 * @name scSlugify
 * @kind filter
 */
angular.module('classy').filter('scSlugify', scSlugify);

function scSlugify() {
  return function (input) {
    return window.getSlug(input, {
      custom: {
        "'": '',
        '"': ''
      },
      maintainCase: true
    }) || '-';
  };
}
})();