(function(){
'use strict';

/**
 * @name scCheckbox
 * @kind directive
 * @requires ngModel
 *
 * @description
 * Creates a styled checkbox. Uses buttons rather than hidden form controls,
 * which keeps the control in the tab flow, requires less markup, and frees
 * styling from markup structure.
 */
scCheckbox.$inject = ["$parse"];
angular.module('classy').directive('scCheckbox', scCheckbox);

function scCheckbox($parse) {
  'use strict';

  return {
    replace: true,
    require: 'ngModel',
    scope: true,
    transclude: true,
    template: '<button type="button" class="{{::classStem}} {{checked}}" data-ng-click="toggle()" role="checkbox" aria-checked="{{ !!checked }}">' + '<span class="{{::classStem}}_input"></span>' + '<span class="{{::classStem}}_label" data-ng-transclude aria-hidden="true"></span>' + '</button>',
    // eslint-disable-next-line no-unused-vars
    link: function link(scope, element, attrs, ngModelCtrl) {
      scope.classStem = attrs.classStem ? $parse(attrs.classStem)(scope) : 'sc-checkbox';

      scope.checked = '';

      scope.toggle = function () {
        var active = !ngModelCtrl.$modelValue;
        ngModelCtrl.$setViewValue(active);
        ngModelCtrl.$setTouched();
        ngModelCtrl.$setDirty();
        scope.checked = ngModelCtrl.$modelValue ? 'checked' : '';
      };

      ngModelCtrl.$isEmpty = function (value) {
        return !value;
      };

      ngModelCtrl.$formatters.push(function (modelValue) {
        if (modelValue) {
          scope.checked = 'checked';
        } else {
          scope.checked = '';
        }
      });
    }
  };
}
})();