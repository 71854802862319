(function(){
'use strict';

/* 
Suggested Amounts apiv2 response shape (apiv2): 
    {
      suggested_donation_amount: 73.89
      suggested_recurring_amount: 81.77
    }
*/

// Load Event
// grid_values - An array that contains the values (<Float>) of each of amounts in the amount grid, starting at the top left and going left-to-right, top-to-bottom (like reading a book). Values should be floats.
// other_value - The amount in the Other field (<Float>)
// default_value - Amount that is preselected (<Float>)
// default_frequency - one-time, daily, weekly, monthly, bi-weekly, quarterly, semi-annually, annually
// iaa_enabled_onetime - <Boolean>
// iaa_enabled_recurring - <Boolean>
// iaa_fallback_shown - <Boolean>
// url_amount_used - Whether or not the ?amount= query param was used in the URL (<Boolean>)
// DCF enabled/opt-in setting

// Later Event
// -- Which grid item was selected when proceeding to next step ← We want to capture this, but perhaps this needs to be captured in a later event.
// -- Upon successful donate event, include -> Which dollar amount was donated
angular.module('classy').constant('CP_INTELLIGENT_ASK_MOCK', {
  CASE_1: {
    grid: {
      oneTime: [80, 175, 405, 540],
      recurring: [40, 75, 205, 2250]
    },
    oneTime: '269.4429626464844',
    recurring: '73.4429626464844'
  },
  CASE_2: {
    grid: {
      oneTime: [80, 175, 405, 540],
      recurring: null
    },
    oneTime: '269.4429626464844',
    recurring: null
  },
  CASE_3: {
    grid: {
      oneTime: null,
      recurring: [40, 75, 205, 2250]
    },
    oneTime: null,
    recurring: '73.4429626464844'
  }
}).constant('IA_HEAP_PREFIX', 'intelligent-ask/').service('cpIntelligentAsk', ["$http", "$q", "$log", "$rootScope", "IA_HEAP_PREFIX", "EmbeddedGivingUtil", "heapService", "scOrganizationsService", function ($http, $q, $log, $rootScope, IA_HEAP_PREFIX, EmbeddedGivingUtil, heapService, scOrganizationsService) {
  var _this2 = this;

  var self = this;
  var isFetching = false;
  var campaignId = null;
  var heapLoadEventData = null;
  this.isInit = false;
  this.campaign = null;

  var amountsDeferred = $q.defer();
  this.amountsPromise = amountsDeferred.promise;
  this.amounts = {
    oneTime: undefined,
    recurring: undefined,
    grid: {
      oneTime: undefined,
      recurring: undefined
    }
  };

  function isValidAmounts(amts) {
    return amts && !_.isEmpty(amts) && _.has(amts, 'oneTime') && _.has(amts, 'recurring') &&
    // has "donation amounts" grid
    _.has(amts, 'grid') && _.has(amts, 'grid.oneTime') && _.has(amts, 'grid.recurring');
  }

  /* Public API */
  // (init must be called first)
  this.init = function (campaign) {
    this.campaign = campaign;
    // FIRST CHECK FOR ACCESS (SC access still is in play)
    if (!this.hasAccess()) {
      $log.debug('[cpIntelligentAsk:init] access denied!');
      return $q.reject();
    }

    // THEN WIRE UP CAMPAIGN
    if (!campaign || !campaign.saved || !campaign.saved.id) {
      // check if instance of?
      $log.debug('[cpIntelligentAsk:init] campaign is required');
      return $q.reject();
    }
    campaignId = this.campaign.saved.id;

    // MOCK
    // if (true) {
    //   this.setAmounts(CP_INTELLIGENT_ASK_MOCK.CASE_1);
    //   this.isInit = true;
    //   $rootScope.$broadcast('cpIntelligentAsk:init');
    //   return $q.when(self);
    // }

    /* Check if either amounts.oneTime or amounts.recurring is set
      - If yes: use the ones provided
      - If no: fetch new 
    */
    if (this.isInit) {
      return $q.when(self);
    } else {
      return this.fetch().then(function () {
        self.isInit = true;
        return self;
      });
    }
  };

  this.fetch = function (campaign) {
    var _this = this;

    if (isFetching) {
      $log.debug('[cpIntelligentAsk:fetch] already fetching...');
      return $q.reject();
    }

    var c = campaign || this.campaign;
    if (!c || !campaignId) {
      $log.debug('[cpIntelligentAsk:fetch] campaign required');
      return $q.reject();
    }

    isFetching = true;
    // set to mock for now?

    return $http.get('/frs-api/campaigns/' + campaignId + '/intelligent-ask').then(function (response) {
      $log.debug('[cpIntelligentAsk:fetch] success! response: ', response);
      var data = response.data || {};
      // grid?

      // cast to number and round to hundredth place (if value exists)
      _this.setAmounts({
        oneTime: data.suggested_donation_amount,
        recurring: data.suggested_recurring_amount,
        grid: {
          oneTime: data.grid.suggested_donation_amount,
          recurring: data.grid.suggested_recurring_amount
        }
      });
      return self;
    }, function (err) {
      $log.error('[cpIntelligentAsk:fetch] err! response: ', err);
      // cant fail here if we want to use resolver
      return $q.reject(err);
    }).finally(function () {
      isFetching = false;
    });
  };

  this.hasAccess = function () {
    return this.hasAccessAmounts() || this.hasAccessSC();
  };

  // Has access to update donation grid?
  this.hasAccessAmounts = function () {
    // For now only campaigns of type 'donation' have access to IAA
    return !!(this.campaign && this.campaign.current.type === 'donation' && !scOrganizationsService.getTag('data-use-restriction', true));
  };

  // has access to the SC layer (for A/B testing)
  this.hasAccessSC = function () {
    return !!scOrganizationsService.getTag('iaa-abtest-prerelease', true);
  };

  // Get one time suggested amounts (the donation grid)
  this.getAmountLevelsOneTime = function () {
    var amounts = _this2.amounts.grid.oneTime;
    amounts = amounts.map(function (amount) {
      return { amount: amount, display_on_page: true, displayAmount: amount };
    });
    amounts.push({ amount: 'Custom', display_on_page: true });
    return amounts;
  };

  // Get recurring suggested amounts (the donation grid)
  this.getAmountLevelsRecurring = function () {
    var amounts = _this2.amounts.grid.recurring;
    amounts = amounts.map(function (amount) {
      return { amount: amount, display_on_page: true, displayAmount: amount };
    });
    amounts.push({ amount: 'Custom', display_on_page: true });
    return amounts;
  };

  // Get suggested one time amount
  this.getAmountOneTime = function () {
    return _this2.amounts.oneTime;
  };

  // Get suggested recurring amount
  this.getAmountRecurring = function () {
    return _this2.amounts.recurring;
  };

  this.sendHeapEvent = function (name) {
    var model = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : {};
    var stateSnapshot = arguments[2];

    if (!name) {
      $log.debug('[cpIntelligentAsk:sendHeapEvent] @param name required');
      return;
    } else if (!_this2.campaign) {
      $log.debug('[cpIntelligentAsk:sendHeapEvent] no campaign present, init() first');
      return;
    }

    if (name === 'load') {
      heapLoadEventData = EmbeddedGivingUtil.getIntelligentAskEventData(_this2.campaign, model, stateSnapshot, _this2.amounts);
      return;
    }

    // "load" event is required
    if (_.isEmpty(heapLoadEventData)) {
      $log.debug('[cpIntelligentAsk:sendHeapEvent] "load" event must be sent first');
      return;
    }

    var eventData = EmbeddedGivingUtil.getIntelligentAskEventData(_this2.campaign, model, stateSnapshot, _this2.amounts);
    eventData = _.extend({}, heapLoadEventData, eventData);
    heapService.trackIntelligentAskEvent('' + IA_HEAP_PREFIX + name, eventData);
  };

  function __handleHeapLoadEvent() {
    if (heapLoadEventData) {
      heapService.trackIntelligentAskEvent(IA_HEAP_PREFIX + 'load', _.extend({}, heapLoadEventData));
      // all previous events should require heapLoadEventData to be set
      // heapLoadEventData = null; // don't do this. load should happen only once per session
    }
  }

  this.setAmounts = function (amts) {
    if (!isValidAmounts(amts)) {
      $log.debug('[cpIntelligentAsk:setAmounts] either "oneTime" or "recurring" is required: ', amts);
      amountsDeferred.reject();
      return;
    }

    $log.debug('[cpIntelligentAsk:setAmounts] setting amounts to: ', amts);
    this.amounts = {
      oneTime: amts.oneTime,
      recurring: amts.recurring,
      grid: {
        oneTime: amts.grid.oneTime,
        recurring: amts.grid.recurring
      }
    }; // always override both

    amountsDeferred.resolve(this.amounts);

    // if has SC org tag (crometrics) for AB testing, then also set SC object
    if (this.hasAccessSC()) {
      this.setSC(this.amounts);
    }
  };

  this.setSC = function (amounts) {
    if (!this.hasAccessSC()) {
      $log.debug('[cpIntelligentAsk:setSC] access denied');
      return;
    } else if (!isValidAmounts(amounts)) {
      $log.debug('[cpIntelligentAsk:setSC] amts not valid, unable to set ', amounts);
      return;
    } else if (!this.campaign) {
      $log.debug('[cpIntelligentAsk:setSC] no campaign present, init() first');
      return;
    }

    SC = SC || {};
    SC.intelligentAsk = _.extend({}, amounts, {
      hasOneTime: this.campaign.hasIntelligentAskOneTime(),
      hasRecurring: this.campaign.hasIntelligentAskRecurring()
    });
    // also set campaign bools
    $log.debug('[cpIntelligentAsk:setSC] SC.intelligentAsk set: ', SC.intelligentAsk);
  };

  this.reset = function () {
    $log.debug('[cpIntelligentAsk:reset] resetting...');

    this.amounts = {
      oneTime: undefined,
      recurring: undefined,
      grid: {
        oneTime: undefined,
        recurring: undefined
      }
    };
  };

  // Should this be fired every time opened?
  $rootScope.$on('heapService:load', __handleHeapLoadEvent);
}]);
})();