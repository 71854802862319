(function(){
'use strict';

/**
 * @kind directive
 * @name scSortBlocks
 *
 * @description
 * Enables reordering of blocks.
 */
angular.module('classy').directive('scSortBlocks', scSortBlocks);

function scSortBlocks() {
  return {
    scope: {
      section: '=scSortBlocks'
    },

    link: function link(scope, element, attrs) {
      var startIndex = null;
      var endIndex = null;

      element.sortable({
        axis: 'y',

        handle: attrs.scSortBlocksHandle,

        // eslint-disable-next-line no-unused-vars
        start: function start(e, ui) {
          startIndex = ui.item.index();
        },


        // eslint-disable-next-line no-unused-vars
        stop: function stop(e, ui) {
          // prevent sorting above the hero block in the UI.
          // hacky and will need to be generalized later if
          // scSortBlocks is used in more places
          if (ui.item.index() === 0) {
            $(this).sortable('cancel');
          }
        },


        // eslint-disable-next-line no-unused-vars
        update: function update(e, ui) {
          scope.$apply(function () {
            endIndex = ui.item.index();

            // prevent reordering hero block in the model.
            if (endIndex === 0) {
              $(this).sortable('cancel');
              endIndex = startIndex;
            }

            var removed = scope.section.blocks.splice(startIndex, 1);
            scope.section.blocks.splice(endIndex, 0, removed[0]);
          });
        }
      });
    }
  };
}
})();