(function(){
'use strict';

/**
 * @kind factory
 * @name scImgAssetProcess
 * @package scImg
 *
 * @description
 * Handles client transformation via API.
 *
 * @param {File} file The file object, via ng-file-upload.
 */
scImgAssetProcessFactory.$inject = ["$http", "$rootScope"];
angular.module('classy').factory('scImgAssetProcess', scImgAssetProcessFactory);

function scImgAssetProcessFactory($http, $rootScope) {
  function scImgAssetProcess(assetId, transformQueue) {
    var lastError = '';

    var validTransforms = void 0;

    if (_.isArray(transformQueue)) {
      validTransforms = transformQueue.filter(function (transform) {
        if (transform.operation === 'scale') {
          if (transform.args.height > 4096 || transform.args.width > 4096) {
            lastError = 'Image scaling height and width must both be less than 4096px.';
            return false;
          }
        } else if (transform.operation === 'crop') {
          if (transform.args.height > 1024 || transform.args.width > 1024) {
            lastError = 'Image scaling height and width must both be less than 1024px.';
            return false;
          }
        }

        return true;
      });
    } else {
      validTransforms = transformQueue;
    }

    if (lastError) {
      $rootScope.SC.status.banner = { type: 'error', msg: lastError };
    }

    return $http.post('/frs-api/asset/process', {
      assetId: assetId,
      transformQueue: validTransforms
    });
  }

  return scImgAssetProcess;
}
})();