(function(){
'use strict';

/**
 * @kind directive
 * @name scSelectExpirationYears
 * @requires ngModel
 *
 * @description
 * Renders a dropdown for credit card expiration years. Starts with the current
 * year and includes the following 12 years.
 *
 * WARNING: ngModel is NOT optional as presented in the directive. It must be
 * present on the original element, or else Angular will not compile the select
 * options.
 *
 * Used in:
 *
 * * application/views/scripts/checkout/donation.phtml
 * * application/views/scripts/npo/cancelled-account.phtml
 * * application/views/scripts/npo/payment-info.phtml
 * * application/views/scripts/sales/payment.phtml
 * * public/partials/reports/recurring-modal.html
 *
 * @todo Isolate scope or namespace years property; right now this
 * directive will overwrite a scope property with the name 'years'.
 */

scSelectExpirationYears.$inject = ["$parse", "$filter"];
angular.module('classy').directive('scSelectExpirationYears', scSelectExpirationYears);

function scSelectExpirationYears($parse, $filter) {
  'use strict';

  return {
    require: '?ngModel',
    replace: true,
    template: '<select ng-options="year.val as year.val for year in years"></select>',
    // eslint-disable-next-line no-unused-vars
    link: function link(scope, element, attrs) {
      scope.years = [];

      var model = $parse(attrs.ngModel)(scope),
          thisYear = parseInt($filter('date')(new Date(), 'yyyy'), 10);

      if (!angular.isUndefined(model) && model) {
        // Pre-fill 12 years from set year in model
        for (var y = model; y <= model + 12; y += 1) {
          scope.years.push({
            val: y
          });
        }
      } else {
        // Pre-fill 12 years from today
        for (var y2 = thisYear; y2 <= thisYear + 12; y2 += 1) {
          scope.years.push({
            val: y2
          });
        }

        $parse(attrs.ngModel).assign(scope, thisYear);
      }
    }
  };
}
})();