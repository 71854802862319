(function(){
'use strict';

scFooterBlock.$inject = ["$window", "scBlock", "scCampaignsService", "scOrganizationsService", "scTaxEntitiesService", "orgContactService", "scCurrencyService", "scAuth", "scMembersService"];
angular.module('classy').directive('scFooterBlock', scFooterBlock);

function scFooterBlock($window, scBlock, scCampaignsService, scOrganizationsService, scTaxEntitiesService, orgContactService, scCurrencyService, scAuth, scMembersService) {
  return scBlock({
    controller: ['$scope', '$state', function ($scope, $state) {
      $scope.$state = $state;

      $scope.campaign = scCampaignsService.active.current;

      var organization = scOrganizationsService.active.current;

      if (!scOrganizationsService.active.multiCurrencyDisabled() && $state.is('frs.donation.thankYou')) {
        $scope.orgName = scTaxEntitiesService.currentTaxableEntity.name;
      }

      function getOrgName(taxEntity) {
        if (scOrganizationsService.active.multiCurrencyDisabled() || !taxEntity.name) {
          return scOrganizationsService.active.current.name;
        }

        return taxEntity.name;
      }

      function getOrgPhone(taxEntity) {
        if (scOrganizationsService.active.multiCurrencyDisabled() || !taxEntity.contact_phone) {
          return scOrganizationsService.active.current.contact_phone;
        }

        return taxEntity.contact_phone;
      }

      function getOrgFooterInfo(taxEntity) {
        var entity = taxEntity && !_.isEmpty(taxEntity) ? taxEntity : organization;
        $scope.orgName = getOrgName(taxEntity);
        // Construct address fields
        $scope.address = entity.address;
        orgContactService.getContactEmail().then(function (email) {
          $scope.contactEmail = email;
        });
        orgContactService.getContactPhoneNumber().then(function (phone) {
          $scope.contactPhone = phone;
        });
        $scope.phone = getOrgPhone(entity);
        $scope.location = generateLocationString(entity.city, [entity.province || entity.state, entity.postal_code, entity.country]);
      }

      $scope.currencyService = scCurrencyService;
      // If m11y is off or we aren't on the checkout, we are fine to pick the entity once and
      // display information based on that. However, if m11y is enabled and we're on the checkout
      // page, the user will have access to the currency picker. If the user is changing
      // into a currency which has a different tax entity from the default, we need to switch the
      // footer so that the footer displays information for that entity.
      if (scCampaignsService.active.multiCurrencyDisabled() || !$state.is('frs.donation.checkout')) {
        scTaxEntitiesService.getDefaultTaxEntity($scope.campaign).then(function (defaultTaxEntity) {
          var entity = scTaxEntitiesService.currentTaxableEntity && !_.isEmpty(scTaxEntitiesService.currentTaxableEntity) ? scTaxEntitiesService.currentTaxableEntity : defaultTaxEntity;

          getOrgFooterInfo(entity);
        });
      } else {
        var onPromise = void 0;
        scTaxEntitiesService.getTaxEntity(organization.currency).then(function (entity) {
          if (!onPromise) {
            getOrgFooterInfo(entity);
          }
        });

        // eslint-disable-next-line no-unused-vars
        $scope.$on('donationRawCurrencyChange', function (event, data) {
          onPromise = scTaxEntitiesService.getTaxEntity(data.currency).then(function (entity) {
            getOrgFooterInfo(entity);
            onPromise = null;
          });
        });
      }

      $scope.scAuth = scAuth;
      $scope.loggedIn = !!scMembersService.active.current.id;
      $scope.login = function () {
        scAuth.loginOnly({ callback: $window.location.href });
      };
      $scope.$watch(function () {
        return _.get(scMembersService, 'active.current.id');
      }, function (newVal, oldVal) {
        if (newVal !== oldVal) {
          $scope.loggedIn = !!newVal;
        }
      });
    }]
  });
}

/**
 * This function takes a city and a set of other address fields and combines them.
 * If city and at least one "locationArray" item are present, city is followed by a comma
 * and a space. We need to filter out null values before joining with a comma because
 * the comma wouldn't make sense if either city or "end" is null.
 *
 * @param  {string} city          The organization or tax entity's city (optional)
 * @param  {array} locationArray  An array of location items:
 *                                - province/state (optional)
 *                                - postal code (optional)
 *                                - country (optional)
 * @return {string}               Returns a string which joins the params as necessary
 */
function generateLocationString(city, locationArray) {
  var end = locationArray.filter(Boolean).join(' ');
  return [city, end].filter(Boolean).join(', ');
}
})();