(function(){
'use strict';

scRegistrationBlockSettings.$inject = ["scFroalaOptions"];
angular.module('classy').directive('scRegistrationBlockSettings', scRegistrationBlockSettings);

function scRegistrationBlockSettings(scFroalaOptions) {
  return {
    scope: true,
    templateUrl: 'global/objects/scBlock/types/registration/settings',

    link: function link(scope) {
      scope.bgImgUploadOptions = {
        hideActionButtons: true,
        description: 'For best quality, use a 1280 x 720 image'
      };

      scope.froalaOptionsTitle = scFroalaOptions({
        type: 'edit-inline-about-title'
      }, {
        charCounterCount: true,
        charCounterMax: 180,
        editorClass: 'no-counter'
      });
    },


    controller: ['$scope', function ($scope) {
      $scope.froalaHeadline = scFroalaOptions({
        type: 'bare-headline-max-length'
      }, {
        editorClass: 'advanced-designer',
        ariaLabel: 'Heading'
      });
    }]
  };
}
})();