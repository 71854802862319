(function(){
'use strict';

scReceiptModelFactory.$inject = ["$q", "$http", "$window", "$state", "$interval", "ipCookie", "scCampaignsService", "scMembersService", "bugsnagClient"];
var _createClass = function () { function defineProperties(target, props) { for (var i = 0; i < props.length; i++) { var descriptor = props[i]; descriptor.enumerable = descriptor.enumerable || false; descriptor.configurable = true; if ("value" in descriptor) descriptor.writable = true; Object.defineProperty(target, descriptor.key, descriptor); } } return function (Constructor, protoProps, staticProps) { if (protoProps) defineProperties(Constructor.prototype, protoProps); if (staticProps) defineProperties(Constructor, staticProps); return Constructor; }; }();

function _classCallCheck(instance, Constructor) { if (!(instance instanceof Constructor)) { throw new TypeError("Cannot call a class as a function"); } }

angular.module('classy').factory('scReceiptModel', scReceiptModelFactory);

function scReceiptModelFactory($q, $http, $window, $state, $interval, ipCookie, scCampaignsService, scMembersService, bugsnagClient) {
  var ReceiptModel = function () {
    function ReceiptModel(cartId, verify, total) {
      _classCallCheck(this, ReceiptModel);

      // Params
      this.cartId = cartId;

      // If there is no verify token in the params or in a cookie, and there is not
      // a logged in user, this will redirect before any api calls are made
      this.setVerifyTokenFromParamsOrCookie(verify);

      // State
      this.totalsLoading = true;
      this.registrantsLoading = true;
      this.error = false;
      this.unauthorized = false;

      // Totals
      this.registrationTotal = 0;
      this.donationTotal = 0;
      this.transactionTotal = total || 0;

      // Registrants
      this.registrants = [];
      this.fundraisingTeam = {};
      this.pollingRegistrants = undefined;
      this.pollingRegistrantsPromise = undefined;

      // Setup
      this.init();
    }

    _createClass(ReceiptModel, [{
      key: 'init',
      value: function init() {
        var _this = this;

        var totalsPromise = this.fetchTotals();
        var itemsPromise = this.fetchCartItems();
        var registrantPromise = this.pollRegistrants();

        // Remove the verify string from the url and set the cookie so that the purchaser
        // can return to the thank you page, but if they share it with anybody else, they
        // will be redirected to the landing page.
        Promise.all([totalsPromise, registrantPromise, itemsPromise]).then(function () {
          _this.setVerifyCookie();
          $state.go($state.$current.name, { verify: null, total: null });
        }).catch(function (err) {
          return bugsnagClient.notify(err);
        });
      }
    }, {
      key: 'setError',
      value: function setError(err) {
        var forceRedirect = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : false;

        // if the user is not authorized to view receipt details, redirect back to event page
        if (err.status > 400 && err.status < 500 || forceRedirect) {
          this.unauthorized = true;
          var eventSlug = $window.getSlug(scCampaignsService.active.current.name);
          $window.location.href = '/event/' + eventSlug + '/e' + (scCampaignsService.active.current.id + $window.location.search);
        }
        this.error = true;
      }
    }, {
      key: 'fetchCartItems',
      value: function fetchCartItems() {
        var _this2 = this;

        this.itemsLoading = true;
        this.items = [];

        $http.get('frs-api/carts/' + this.cartId).then(function (response) {
          _this2.items = response.data.items;

          // append product names to cart items given product ID
          var tickets = _.get(scCampaignsService, 'active.current.ticketTypes', []);
          var counts = {};

          _this2.items.forEach(function (i) {
            i.name = _.find(tickets, function (t) {
              return t.id == i.product_id;
            }).name;

            // Count the number of times this cart item shows up in cart. Useful for when
            // we only want to group duplicate orders of a product in one line.
            counts[i.product_id] = (counts[i.product_id] || 0) + 1;

            // Return the item price as total including any discounts/promo codes.
            i.final_price = i.adjustment_amount ? Math.max(0, _.parseInt(i.price) - _.parseInt(i.adjustment_amount)) : i.price;
          });

          _this2.items = _this2.items.map(function (i) {
            i.counts = counts[i.product_id];
            return i;
          });
        }).catch(function (err) {
          _this2.setError(err);
        }).finally(function () {
          _this2.itemsLoading = false;
        });
      }
    }, {
      key: 'fetchTotals',
      value: function fetchTotals() {
        var _this3 = this;

        this.totalsLoading = true;

        return $http.get('/frs-api/carts/' + this.cartId + '/receipt-totals?verify=' + this.verify).then(function (response) {
          // donation fees are tax deductable so we consider them as part of the donation
          // when shown on the receipt page
          _this3.donationTotal = response.data.donation_total + response.data.donation_fees;
          _this3.registrationTotal = response.data.registration_total;
          _this3.registrationFees = response.data.registration_fees;
          _this3.feeTotal = response.data.total_fees;

          // On initial load of the receipt, the total may not be synced yet and will be null which shows blank on the receipt.
          // We use the cart total that is set on this model. If the user refreshes, the total should be synched by then (hopefully)
          _this3.transactionTotal = response.data.transaction_total !== null ? response.data.transaction_total : _this3.transactionTotal;
        }).catch(function (err) {
          _this3.setError(err);
        }).finally(function () {
          _this3.totalsLoading = false;
        });
      }
    }, {
      key: 'fetchRegistrants',
      value: function fetchRegistrants() {
        var _this4 = this;

        return $http.get('/frs-api/carts/' + this.cartId + '/receipt-registrants?verify=' + this.verify).then(function (response) {
          _this4.registrants = response.data.registrants;
          _this4.fundraisingTeam = response.data.fundraising_team;
          return response;
        }).catch(function (err) {
          _this4.setError(err);
        });
      }
    }, {
      key: 'pollRegistrants',
      value: function pollRegistrants() {
        var _this5 = this;

        var attempt = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : 10;

        // if a poll is already going, don't start another one
        if (this.pollingRegistrantsPromise) {
          return this.pollingRegistrantsPromise;
        }

        var calls = 0;
        this.registrantsLoading = true;
        var deferred = $q.defer();

        this.pollingRegistrants = $interval(function () {
          _this5.fetchRegistrants().then(function (res) {
            calls += 1;
            if (!!res && res.data || calls >= attempt) {
              _this5.stopPollRegistrants();
              deferred.resolve(_this5.registrants);
            }
          });
        }, 500);

        this.pollingRegistrantsPromise = deferred.promise;
        return this.pollingRegistrantsPromise;
      }
    }, {
      key: 'stopPollRegistrants',
      value: function stopPollRegistrants() {
        if (this.pollingRegistrants) {
          $interval.cancel(this.pollingRegistrants);
          this.pollingRegistrants = undefined;
          this.pollingRegistrantsPromise = undefined;
          this.registrantsLoading = false;
        }
      }

      /* Verify string reading and writing methods
      ========================================================= */

    }, {
      key: 'getVerifyCookieKey',
      value: function getVerifyCookieKey() {
        return 'cart-' + this.cartId + '-verify';
      }
    }, {
      key: 'setVerifyCookie',
      value: function setVerifyCookie() {
        if (!this.verify) return;
        ipCookie(this.getVerifyCookieKey(), this.verify, { expires: 365 // defaults to 'days', so this cookie should be maintained for 1 year
        });
      }
    }, {
      key: 'getVerifyFromCookie',
      value: function getVerifyFromCookie() {
        return ipCookie(this.getVerifyCookieKey());
      }

      // This method is used above during receipt initialization. Essentially it's looking for
      // the passed verify string from the params first, if not found, it checks to see if a cookie
      // was set prior (user returning to their receipt on the same device), and if that's not found
      // we will still try to retreive the receipt information if there is ANY member logged in. The check
      // to see if it's the correct member is on the express layer, since it needs the retreived cart info.

    }, {
      key: 'setVerifyTokenFromParamsOrCookie',
      value: function setVerifyTokenFromParamsOrCookie(verifyParam) {
        var memberId = _.get(scMembersService, 'you.current.id');

        if (verifyParam) {
          this.verify = verifyParam;
          return;
        }

        this.verify = this.getVerifyFromCookie();

        if (!this.verify && !memberId) {
          this.setError(new Error('unauthorized to view receipt'), true);
        }
      }
    }]);

    return ReceiptModel;
  }();

  return ReceiptModel;
}
})();