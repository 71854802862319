(function(){
'use strict';

scResponsiveWidth.$inject = ["$window", "scResponsive"];
angular.module('classy').service('scResponsiveWidth', scResponsiveWidth);

function scResponsiveWidth($window, scResponsive) {
  var _this = this;

  var mobileWidth = _.find(scResponsive, function (obj) {
    return obj.name == 'mobile-ls';
  }).width;

  function isMobileWidth() {
    var isMobile = void 0;
    if (Modernizr.prefixed('matchMedia', window)) {
      isMobile = Modernizr.mq('(max-width:' + mobileWidth + 'px)');
    } else {
      isMobile = angular.element($window).width() <= mobileWidth;
    }

    return isMobile;
  }

  var resizeWindow = function resizeWindow() {
    _this.isMobile = isMobileWidth();
  };

  var resizeDebounce = _.debounce(resizeWindow, 100);
  angular.element($window).on('resize orientationchange', resizeDebounce);

  resizeWindow();
}
})();