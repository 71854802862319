(function(){
'use strict';

/**
 * @kind service
 * @name pageNameService
 *
 * @description
 * Provides a human friendly page name which tells us what type of page we are on,
 * and the entity associated with the page (fundraising page, team, campaign, event...)
 * Is used to send page names as data to analytics services.
 */
pageNameService.$inject = ["$location"];
angular.module('classy').service('pageNameService', pageNameService);

function pageNameService($location) {
  var PATH_REGEXES = {
    CAMPAIGN: /.*(campaign|event).*/,
    CART: /.*register.*/,
    DONATE: /.*give\/([\d\w]+\/)?.*/,
    FUNDRAISER: /.*fundraiser.*/,
    RECEIPT: /.*thank-you.*/,
    SEARCH: /.*search.*/,
    TEAM: /.*team.*/
  };

  this.getDonationEntity = getDonationEntity;
  this.getPageName = getPageName;

  var getFullPath = function getFullPath() {
    return $location.absUrl().split($location.host())[1];
  };

  function getDonationEntity() {
    var url = getFullPath();
    var id = (PATH_REGEXES.DONATE.exec(url) || [])[1] || '';

    // if the capture group returns a campaign id, lets check to see what type
    // of page we are on
    if (id) {
      switch (id.charAt(0)) {
        case 't':
          {
            return 'team';
          }
        case 'f':
          {
            return 'fundraiser';
          }
        default:
          {
            return 'campaign';
          }
      }
    }

    // if there was nothing in the capture group, we are on a regular old
    // donation page, so return empty
    return '';
  }

  function getPageName() {
    // returns everying after the host, including hashes, e.g.
    // /some/path/#!/with/a/hash
    var url = getFullPath();
    var pageType = void 0,
        entityType = void 0;

    switch (true) {
      case PATH_REGEXES.RECEIPT.test(url):
        {
          pageType = 'Receipt';

          if (url.includes('event')) {
            entityType = 'Event';
            break;
          }

          entityType = _.capitalize(this.getDonationEntity());
          pageType = 'Donation Receipt';
          break;
        }

      case PATH_REGEXES.DONATE.test(url):
        {
          pageType = 'Donate';
          entityType = _.capitalize(this.getDonationEntity());
          break;
        }

      case PATH_REGEXES.TEAM.test(url):
        {
          entityType = 'Team';
          pageType = url.includes('/dashboard') ? 'Dashboard' : '';
          break;
        }

      case PATH_REGEXES.FUNDRAISER.test(url):
        {
          entityType = 'Fundraiser';
          pageType = url.includes('/dashboard') ? 'Dashboard' : '';
          break;
        }

      case PATH_REGEXES.CART.test(url):
        {
          entityType = '';
          pageType = 'Cart';
          break;
        }

      case PATH_REGEXES.SEARCH.test(url):
        {
          entityType = '';
          pageType = 'Search';
          break;
        }

      case PATH_REGEXES.CAMPAIGN.test(url):
        {
          entityType = 'Campaign';
          pageType = '';
          break;
        }

      default:
        {
          entityType = '';
          pageType = 'Unknown';
          break;
        }
    }

    // Avoid weird spacing, don't return an empty string if either entity or page type DNE
    if (entityType === '') {
      return '' + pageType;
    } else if (pageType === '') {
      return '' + entityType;
    }

    return entityType + ' ' + pageType;
  }
}
})();