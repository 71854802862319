(function(){
'use strict';

/**
 * @name scFlowModalDimensions
 * @kind service
 * @package FlowModal
 *
 * @guide ./FlowModal.guide.md
 *
 * @description
 * A service that stores window and viewport dimensions for flowModal and
 * allows for global configuration of some properties.
 */
scFlowModalDimensions.$inject = ["$window"];
angular.module('classy').service('scFlowModalDimensions', scFlowModalDimensions);

function scFlowModalDimensions($window) {
  /**
   * @name padding
   * @kind property
   * @type object
   * @parent scFlowModalDimensions
   *
   * @description
   * Padding to leave around the viewport. Modify these values to change
   * the minimum amount of space between a modal and the window edge.
   * Will be ignored as soon as the available room is < 80% of the window width.
   */
  this.padding = {
    top: 25,
    right: 50,
    bottom: 25,
    left: 50
  };

  /**
   * @name border
   * @kind property
   * @type object
   * @parent scFlowModalDimensions
   *
   * @description
   * If viewport is given a border (it doesn't have one currently), this
   * should be updated so the service can compensate for it when animating.
   */
  this.border = {
    top: 0,
    right: 0,
    bottom: 0,
    left: 0
  };

  /**
   * @name window
   * @kind property
   * @type object
   * @parent scFlowModalDimensions
   *
   * @description
   * Current window dimensions. Updated automatically.
   */
  this.window = {
    width: 0,
    height: 0
  };

  /**
   * @name available
   * @kind property
   * @type object
   * @parent scFlowModalDimensions
   *
   * @description
   * Width and height that are available to the viewport after padding and
   * border are subtracted from the window dimensions. Updated
   * automatically.
   */
  this.available = {
    width: 0,
    height: 0
  };

  /**
   * @name update
   * @kind method
   * @parent scFlowModalDimensions
   *
   * @description
   * Queries and calculates tracked dimensions.
   */
  this.update = function () {
    this.window.width = angular.element($window).width();
    this.window.height = angular.element($window).height();
    this.available.width = this.window.width - this.padding.left - this.padding.right - this.border.left - this.border.right;

    if (this.available.width < 0.8 * this.window.width) {
      this.available.width = this.window.width - 30;
    }
    this.available.height = this.window.height - this.padding.top - this.padding.bottom - this.border.top - this.border.bottom;
    if (this.available.height < 0.8 * this.window.height) {
      this.available.height = this.window.height - 30;
    }
  };

  this.update();
}
})();