(function(){
'use strict';

nl2br.$inject = ["$sce"];
angular.module('classy').filter('nl2br', nl2br);

function nl2br($sce) {
  return function (input) {
    return $sce.getTrustedHtml(input).replace(/&#10;/g, '<br />');
  };
}
})();