(function(){
'use strict';

/**
 * @ngDoc factory
 * @name scFundraisingTeamActivityDemo
 *
 * @description
 * Generates demo activity data for a fundraising team.
 */
scFundraisingTeamActivityDemo.$inject = ["scOrganizationsService", "scCampaignsService", "scMembersService"];
angular.module('classy').factory('scFundraisingTeamActivityDemo', scFundraisingTeamActivityDemo);

function scFundraisingTeamActivityDemo(scOrganizationsService, scCampaignsService, scMembersService) {
  return function (teamPageId) {
    return {
      current_page: 1,
      data: [{
        fundraising_team_id: null,
        link_id: 1,
        link_text: scMembersService.you.current.first_name + ' ' + scMembersService.you.current.last_name,
        member_name: 'undefined undefined',
        id: 1,
        type: 'creation',
        member_id: 1,
        organization_id: scOrganizationsService.active.current.id,
        campaign_id: scCampaignsService.active.current.id,
        fundraising_page_id: teamPageId,
        created_at: new Date().toISOString(),
        transaction_id: 0,
        organization: scOrganizationsService.active.current,
        member: scMembersService.you.current,
        fundraising_team: null,
        transaction: null,
        campaign: scCampaignsService.active.current,
        comments: [],
        business: null,
        feedable_type: 'fundraising_team',
        designation: {
          id: 1,
          name: 'General Fund Project'
        },
        demo: true
      }],
      from: 1,
      last_page: 1,
      next_page_url: null,
      per_page: 20,
      prev_page_url: null,
      to: 1,
      total: 1
    };
  };
}
})();