(function(){
'use strict';

frsDonationWelcomeCtrl.$inject = ["$scope", "scFroalaOptions", "$state", "$sce"];
angular.module('classy').controller('frsDonationWelcomeCtrl', frsDonationWelcomeCtrl);

function frsDonationWelcomeCtrl($scope, scFroalaOptions, $state, $sce) {
  $scope.headerOptions = scFroalaOptions({ type: 'edit-inline' }, { ariaLabel: 'Donation Page Body Headline' });
  $scope.subheaderOptions = scFroalaOptions({ type: 'edit-inline-subheader' }, { ariaLabel: 'Donation Page Body Sub Headline' });
  if (typeof $scope.block !== 'undefined') {
    $scope.trustedBlockHeadlineHtml = $sce.trustAsHtml($scope.block.headline);
  }
  $scope.isPreview = function () {
    return $state.is('admin.manage.campaign.step.setup') || $state.is('admin.manage.campaign.step.design.views.content-blocks') || $state.is('admin.manage.campaign.step.design.views.block');
  };
}
})();