(function(){
'use strict';

mobileMenuGroups.$inject = ["mobileMenuActions"];
angular.module('classy').service('mobileMenuGroups', mobileMenuGroups);

function mobileMenuGroups(mobileMenuActions) {
  /* ------------------------------------------------------------------------ *
   * Header group
   *
   * Includes profile and auth links
   * ------------------------------------------------------------------------ */
  this.header = function (api) {
    var menu = [];
    if (api.loggedIn) {
      menu.push(mobileMenuActions.profile(api), mobileMenuActions.logout(api));
    } else {
      menu.push(mobileMenuActions.login(api));
    }
    return {
      title: '',
      menu: menu
    };
  };

  /* ------------------------------------------------------------------------ *
   * Campaign group
   *
   * Shows various actions depending on if a visitor is logged out and/or if they
   * are logged in but don't have any active fundraising pages. If the campaign
   * is a crowdfunding page, it will show the donate action regardless as there
   * is no concept of fundraising pages.
   * ------------------------------------------------------------------------ */
  this.campaign = function (api) {
    var menu = [];

    switch (api.campaignType) {
      case 'p2p':
        if (!api.loggedIn || !this.fundraisingPages(api)) {
          menu.push(mobileMenuActions.startFundraising(api), mobileMenuActions.donate(api));
        }
        break;

      case 'crowdfunding':
        menu.push(mobileMenuActions.donate(api));
        break;

      case 'reg_w_fund':
        if (!api.loggedIn || !this.fundraisingPages(api)) {
          menu.push(mobileMenuActions.register(api), mobileMenuActions.donate(api));
        }
        break;

      default:
      // do nothing
    }

    if (!menu.length) return null;

    return {
      title: 'Campaign',
      menu: menu
    };
  };

  /* ------------------------------------------------------------------------ *
   * Fundraising Pages
   *
   * Matches the desktop version of the dropdown menu. It will show the member
   * fundraising pages, starting with 2 visible, then paginated by
   * 5 henceforth.
   * ------------------------------------------------------------------------ */

  this.fundraisingPages = function (api) {
    var menu = [];

    if (api.loggedIn && ['p2p', 'reg_w_fund'].includes(api.campaignType)) {
      Array.prototype.push.apply(menu, mobileMenuActions.userFundraisingPages(api));
    }

    if (!menu.length) return null;

    return {
      title: 'Fundraising Pages',
      menu: menu
    };
  };

  /* ------------------------------------------------------------------------ *
   * Fundraising Teams
   *
   * Matches the desktop version of the dropdown menu. It will show the member
   * fundraising teams, paginated by 5.
   * ------------------------------------------------------------------------ */
  this.fundraisingTeams = function (api) {
    var menu = [];

    if (api.loggedIn && ['p2p', 'reg_w_fund'].includes(api.campaignType)) {
      Array.prototype.push.apply(menu, mobileMenuActions.userFundraisingTeams(api));
    }

    if (!menu.length) return null;

    return {
      title: 'Fundraising Teams',
      menu: menu
    };
  };

  /* ------------------------------------------------------------------------ *
   * Share menu
   *
   * Triggers share overlay.
   * ------------------------------------------------------------------------ */
  this.share = function (api) {
    var menu = [];
    menu.push(mobileMenuActions.share(api));
    return {
      title: '',
      menu: menu
    };
  };
}
})();