(function(){
'use strict';

LinkBigTipFactory.$inject = ["$compile", "$window", "scRequestAnimationFrame", "accessibilityService"];
angular.module('classy').factory('linkBigTip', LinkBigTipFactory);

function LinkBigTipFactory($compile, $window, scRequestAnimationFrame, accessibilityService) {
  return function linkBigTip(opts) {
    var ev = opts.event;
    var tpl = opts.template;
    var ctx = opts.scope;
    var ref = opts.element;
    var width = opts.width;
    var scroll = opts.scroll;
    var bg = opts.bg || '#ffffff';

    var TOOLTIP_TPL = '\n      <div class="big-tip">\n        <div class="big-tip_content" style="background-color:' + bg + ';">\n        ' + tpl + '\n        </div>\n      </div>\n    ';

    var CARET_TPL = '\n      <div class="big-tip_caret" style="color:' + bg + ';"></div>\n    ';

    var ui = {
      active: false,
      frameRequested: false,
      deregister: _.noop,
      bg: bg
    };

    var el = {
      tooltip: null,
      caret: null,
      win: angular.element($window),
      body: angular.element('body')
    };

    ref.attr('aria-expanded', false);

    function toggle() {
      if (ui.active) {
        close();
      } else {
        open();
      }
    }

    function close() {
      var focusRef = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : false;

      var focusEl = opts.element;

      if (focusRef) {
        focusEl.attr('tabindex', '0').focus();
      }

      if (ui.active) {
        ui.active = false;
        if (el.tooltip) {
          el.tooltip.remove();
          el.tooltip = null;
          ref.attr('aria-expanded', false);
        }
      }
    }

    function open() {
      ref.attr('aria-expanded', true);
      if (!ref.is(':visible')) {
        return;
      }
      if (!el.tooltip) {
        build();
      }
      ui.active = true;
      style();
      el.tooltip.appendTo('body');

      if (ref.attr('aria-expanded') !== undefined) {
        ref.attr('aria-expanded', true);
        el.tooltip.attr('tabindex', '0').focus();
      }

      el.tooltip.attr('tabindex', '0').focus();
      if (scroll) {
        angular.element('html, body').animate({ scrollTop: ref.offset().top - 15 });
      }
      var modalElements = document.querySelector('.big-tip');
      modalElements.addEventListener(accessibilityService.CONSTANTS.KEYDOWN_EVENT, ctx.handleKeyEvents(modalElements));
    }

    function build() {
      el.tooltip = $compile(TOOLTIP_TPL)(ctx);
      el.caret = angular.element(CARET_TPL);
      el.tooltip.append(el.caret);
    }

    function style() {
      var innerWidth = window.innerWidth;
      var maxWidth = innerWidth - 30;

      var tipWidth = Math.min(maxWidth, width);
      var offset = ref.offset();
      var elWidth = ref.outerWidth();
      var elHeight = ref.outerHeight();
      var elTop = offset.top;
      var elLeft = offset.left;
      var elBottom = elTop + elHeight;
      var refPoint = elLeft + elWidth / 2 - 11; // Caret left, rel to element
      var tipRight = refPoint + 31; // Some space btw caret and right edge
      /**
       * ! CL-8219 fix - modify tooltip location when width is mobile to position correctly
       *
       * Determine if the width is mobile or tablet view.
       * 768 is the breakpoint when the mobile template is used.
       */
      var isMobileWidth = innerWidth <= 768;
      var tipLeft = isMobileWidth ? (innerWidth - tipWidth) / 2 : tipRight - tipWidth;
      var caretLeft = refPoint - tipLeft;
      var tipTop = elBottom + 15;

      el.tooltip.css({
        top: tipTop,
        left: tipLeft,
        width: tipWidth
      });
      el.caret.css({
        left: caretLeft
      });
    }

    function debounceStyle() {
      if (ui.frameRequested) {
        return;
      }

      ui.frameRequested = true;
      scRequestAnimationFrame(function () {
        ui.frameRequested = false;
        if (!ui.active) {
          return;
        }

        if (!ref.is(':visible')) {
          close();
        } else {
          style();
        }
      });
    }

    function destroy() {
      el.body.off('resize orientationchange', debounceStyle);
      ui.deregister();
    }

    function clickOut(e) {
      var target = angular.element(e.target);
      if (target.closest(ref).length) {
        return;
      }
      if (target.closest(el.tooltip).length) {
        return;
      }
      close();
    }

    /* ---------------------------------------------------------------------- *
     * Wire Events
     * ---------------------------------------------------------------------- */

    switch (ev) {
      case 'click':
        ref.on(ev, toggle);
        el.win.on(ev, clickOut);
        ui.deregister = function () {
          ref.off(ev, toggle);
          el.win.off(ev, clickOut);
        };
        break;

      case 'hover':
        ref.on('mouseover', open);
        ref.on('mouseout', close);
        ui.deregister = function () {
          ref.off('mouseover', open);
          ref.off('mouseout', close);
        };
        break;

      default:
        ui.deregister = ctx.$on(ev, toggle);
    }

    el.win.on('resize orientationchange', debounceStyle);
    ctx.$on('$destroy', function () {
      close();
      destroy();
    });

    /* ---------------------------------------------------------------------- *
     * Return useful things
     * ---------------------------------------------------------------------- */

    return {
      open: open,
      style: style,
      close: close,
      destroy: destroy,
      ui: ui,
      el: el
    };
  };
}
})();