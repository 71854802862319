(function(){
'use strict';

scImgPicker.$inject = ["$parse", "$q", "launchMediaManager", "accessibilityService"];
angular.module('classy').directive('scImgPicker', scImgPicker);

function scImgPicker($parse, $q, launchMediaManager, accessibilityService) {
  'use strict';

  return {
    require: 'ngModel',
    link: function link(scope, element, attrs, ngModel) {
      var mediaManagerOptions = void 0;

      function updateOptions(data) {
        mediaManagerOptions = data;
        _.set(mediaManagerOptions, 'image.model', attrs.ngModel);
      }

      scope.$watch(attrs.scImgPicker, updateOptions, true);

      element.on('click keydown', function (event) {
        if (event.type == 'click' || accessibilityService.isValidKeyBoardEvent(event)) {
          scope.$apply(function () {
            launchMediaManager({ scope: scope, config: mediaManagerOptions }).then(function (result) {
              if (result.images.length) {
                updateModel(result.images[0]);
              } else {
                ngModel.$setViewValue(null);
              }
            });
          });
        }
      });

      function updateModel(img) {
        if (img.is(ngModel.$modelValue)) {
          return;
        }

        if (attrs.autosave) {
          ngModel.$setViewValue(img);
        }

        if (attrs.scImgInit) {
          $parse(attrs.scImgInit)(scope, {
            $scImg: img
          });
        }
      }

      ngModel.$asyncValidators.uploaded = function (modelValue) {
        if (!modelValue) {
          var stupidDeferred = $q.defer();
          var stupidPromise = stupidDeferred.promise;
          stupidDeferred.resolve();
          return stupidPromise;
        }
        var promise = modelValue.on('save');
        return promise;
      };

      scope.$watch(attrs.ngModel, function () {
        ngModel.$validate();
      });
    }
  };
}
})();