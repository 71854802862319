(function(){
'use strict';

authFlowFactory.$inject = ["$http", "$location", "$log", "$q", "$rootScope", "$window", "scCampaignsService", "scOrganizationsService", "scFlowModalService", "scThemesService", "scSsoService", "scAnalytics", "CFPExitView", "$timeout"];
angular.module('classy').factory('authFlow', authFlowFactory);

function authFlowFactory($http, $location, $log, $q, $rootScope, $window, scCampaignsService, scOrganizationsService, scFlowModalService, scThemesService, scSsoService, scAnalytics, CFPExitView, $timeout) {
  function authFlow(action, successDest) {
    var exitModalOption = arguments.length > 2 && arguments[2] !== undefined ? arguments[2] : false;

    var $scope = $rootScope.$new();

    /* ---------------------------------------------------------------------- *
     * API
     * ---------------------------------------------------------------------- */

    $scope.authFlow = action;
    $scope.orgId = _.get(scOrganizationsService, 'active.current.id', null);
    $scope.campaignId = _.get(scCampaignsService, 'active.current.id', null);
    $scope.original_url = $window.location.href;
    $scope.passwod_reset_code = $location.search().passwod_reset_code;
    // We must notify to the backend that the password reset is requested from an angularjs application because there is a problem
    // with the angularjs Router that can't handle querystring before the # sign in the url. For that reason with this flag the backend
    // will know that and will create the password reset url with the fragment before the querystring
    $scope.is_angularjs_mode = true;
    $scope.member_id = $location.search().member_id;
    $scope.state = '{}';
    // when coming from error pages, none of these services are synced/needed
    // we will need to skip this step and wait for sso() to avoid undef errors
    if (!_.isEmpty(SC.member)) {
      $scope.orgId = scOrganizationsService.active.current.id;
      $scope.campaignId = scCampaignsService.active.current.id;

      $scope.ui = {
        isCampaign: function isCampaign() {
          return scCampaignsService.active;
        },
        campaignImg: function campaignImg() {
          return _.get(scThemesService, 'active.current.headerLogo.src', null);
        }
      };
    }

    $scope.reroute = function () {
      if (_.isFunction(successDest)) {
        $scope.hardExit = true;
        successDest();
      } else if (_.isString(successDest)) {
        scFlowModalService.close();
        if (successDest != $window.location.href) {
          $window.location.href = successDest;
        } else {
          $window.location.reload();
        }
      } else if ($location.path().substring(0, 6) === '/oops/' && !!successDest) {
        $window.location.href = '/profile';
      } else {
        scFlowModalService.close();
      }
    };

    $scope.sso = function () {
      Classy.ready(function () {
        Classy.parseDom(function (response) {
          if (response.authorization_code) {
            var xhrFields = {};
            if (SC.env === 'development') {
              xhrFields = {
                xhrFields: {
                  withCredentials: true
                }
              };
            }
            $http(Object.assign({
              method: 'GET',
              url: '/oauth2/iframe_callback?code=' + response.authorization_code
            }, xhrFields)).then(function (oauthResponse) {
              $log.log('SSO success: ');
              $log.log($location.search().redirect, oauthResponse.data);

              // Basically the only way we can check to see if someone signs up vs
              // logs in -- check the created_at date
              var createdAt = oauthResponse.data.member.created_at;
              if (moment().diff(createdAt, 'seconds') < 5) {
                scAnalytics.eventBeacon({
                  category: 'frs',
                  action: 'completion',
                  label: 'Sign Up'
                });
              } else {
                scAnalytics.eventBeacon({
                  category: 'frs',
                  action: 'completion',
                  label: 'Sign in'
                });
              }

              /* Exclude Members from Adroll Segmenting */
              try {
                __adroll.record_user({
                  adroll_segments: 'member'
                });
              } catch (err) {}

              if (oauthResponse.data.member) {
                scSsoService.initMember(oauthResponse.data.member);
              }

              $log.log('rerouting');
              $scope.reroute();
            });
          } else if (response.idp_url) {
            // IDP Login
            $window.top.location.assign(response.idp_url);
          } else {
            // handle error.
            $log.log('SSO failed: ' + response.error);
            $rootScope.SC.status.banner = {
              type: 'error',
              msg: "I'm sorry, there was a problem signing you in."
            };
          }
        });
      });
    };

    /* ---------------------------------------------------------------------- *
     * Registration and Launch
     * ---------------------------------------------------------------------- */

    // Need this for exit view usage
    $scope.flow = {
      template: function template(slug) {
        return 'global/flows/create-fundraising-page/views/' + slug + '/template';
      },
      abort: function abort() {
        // The reset_password_by_link flow uses the source and password_reset_code params to render an iframe
        // with the passwordRestForm provided by SSO. After that form is completed, that two parameters must be removed and reload the page.
        // If the flow if different than reset_passowrd_by_link, the page will reload using the current url and the same parameters.
        var params = new URLSearchParams(window.location.search);
        params.delete('source');
        params.delete('passwod_reset_code');
        $window.location.assign(window.location.href.split('?')[0] + '?' + params.toString());
      },
      to: function to(dest) {
        scFlowModalService.to(dest);
        $scope.ui.currentView = dest;
      }
    };

    scFlowModalService.register('auth', $scope, [{
      id: 'auth',
      templateUrl: 'global/flows/auth/views/template',
      maxWidth: 380
    }, Object.assign(new CFPExitView($scope), { id: 'auth.exit' })]);

    $scope.ui = {
      currentView: 'auth',
      primaryColor: _.get(scThemesService, 'active.current.styles.primaryColor', '#36bff7')
    };

    var options = {
      closeOnClickOut: false,
      startingView: 'auth'
    };

    // Use promise instead of callback to allow the function to know if the user close the modal with the button or the modal
    // was closed programatically because the flow is complete.
    scFlowModalService.open('auth', options).then(function () {
      if (exitModalOption && !$scope.hardExit && $scope.ui.currentView === 'auth') {
        $scope.ui.previousView = 'auth';
        $scope.flow.to('auth.exit');
        $scope.closeOutPromise = $q.defer();
        return $scope.closeOutPromise.promise;
      }
      if ($scope.closeOutPromise) {
        $scope.closeOutPromise.resolve();
      }

      // Uses timeout before abort to execute it after all digest were complete
      $timeout(function () {
        $scope.flow.abort();
      });
      return undefined;
    }).catch(function () {});

    // Force this property to true to subscribe the scFlowModalService.open promise
    // and know if the user close the modal with the close button or
    // the modal was closed automatically by the function.
    scFlowModalService.deferred.caught = true;

    var off = $scope.$on('flowModal.load', function () {
      $scope.sso();
      $scope.$on('flowModal.transitionStart', off());
    });
  }

  return authFlow;
}
})();