(function(){
'use strict';

/* -------------------------------------------------------------------------- *
 * scImgCrop
 * -------------------------------------------------------------------------- */

angular.module('classy').directive('scImgCrop', scImgCrop);

function scImgCrop() {
  'use strict';

  return {
    replace: true,
    template: '<input type="file" ngf-select ngf-change="onFileSelect($files, index)" class="ng-hide" />',
    transclude: false,
    scope: {
      images: '=scImgList',
      callback: '=callback',
      index: '=?index'
    },
    link: function link(scope, element) {
      element.bind('click', function (e) {
        e.stopPropagation();
      });

      scope.triggerFileInput = function () {
        element.trigger('click');
      };

      scope.onFileSelect = function (files, index) {
        if (files.length) {
          scope.callback(files, index);
        }
      };
    }
  };
}
})();