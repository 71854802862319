(function(){
'use strict';

/**
 * @kind directive
 * @name scCtxBind
 *
 * @description
 * One way bind meant to render text in block templates. Trusts implicitly.
 * One-time-binds in public contexts, establishes a watch in other contexts.
 *
 * Inherits and inspects the scope of its environment.
 */
scCtxBind.$inject = ["$parse"];
angular.module('classy').directive('scCtxBind', scCtxBind);

function scCtxBind($parse) {
  return function (scope, element, attrs) {
    var prop = attrs.scCtxBind;
    function write() {
      element.text($parse(prop)(scope));
    }
    if (scope.public) {
      write();
    } else {
      scope.$watch(prop, write);
    }
  };
}
})();