(function(){
'use strict';

/**
 * @kind directive
 * @name scModal
 * @scope
 *
 * @requires scModalLink
 *
 * @description
 * Renders mand controls modal display.
 */

scModal.$inject = ["$rootScope", "$window"];
angular.module('classy').directive('scModal', scModal);

function scModal($rootScope, $window) {
  'use strict';

  return {
    require: '^scModalLink',
    replace: true,
    templateUrl: 'global/directives/modal/scModal',
    transclude: true,
    scope: {
      modalClass: '@',
      modalTitle: '@',
      closeCallback: '&',
      hideOverlay: '=?',
      contentMarginTop: '@?'
    },
    controller: ['$scope', '$element', function ($scope, $element) {
      $scope.modalContentStyle = function () {
        var content = $element.find('.modal-content');
        var marginTop = $scope.contentMarginTop || (angular.element($window).innerHeight() - content.height() - 120) / 2;
        return {
          'margin-top': parseInt(marginTop, 10)
        };
      };

      this.closeModal = function () {
        $scope.closeModal();
      };

      $scope.closeModal = function () {
        if (!$scope.hideCloseButton) {
          $rootScope.modal.isOpen = false;

          // Run optional callback
          $scope.closeCallback();
          this.show = false;

          $scope.unbindEscape();

          if (!$rootScope.$$phase) {
            $scope.$apply();
          }
        }

        angular.element('body').removeClass('hide-overflow');
      };
    }],

    // eslint-disable-next-line no-unused-vars
    link: function link(scope, element, attrs, modalCtrl) {
      modalCtrl.addModal(scope);

      scope.hideCloseButton = !!modalCtrl.disableClose;

      scope.unbindEscape = modalCtrl.unbindEscape;
      var content = element.find('.modal-content');

      scope.contentMarginTop = scope.contentMarginTop ? scope.contentMarginTop : ($window.innerHeight - content.height() - 120) / 2;

      // Reset form hack
      scope.$on('modal-reset', function () {
        var form = element.find('form').eq(0).controller('form');
        element.find('form').removeClass('submitted');
        form.$setPristine();
      });

      scope.$on('sc-close-modal', function () {
        scope.closeModal();
      });
    }
  };
}
})();