(function(){
'use strict';

/**
 * @kind directive
 * @name scNpoHeader
 *
 * @description
 * Renders the "Top Bar" header for the admin area.
 */
scNpoHeader.$inject = ["scAuth", "scMembersService", "scCampaignsService", "scOrganizationsService", "$location"];
angular.module('classy').directive('scNpoHeader', scNpoHeader);

function scNpoHeader(scAuth, scMembersService, scCampaignsService, scOrganizationsService, $location) {
  return {
    scope: {
      organization: '=?'
    },
    replace: true,
    templateUrl: 'global/directives/scNpoHeader/template',
    transclude: true,
    link: function link(scope) {
      scope.members = scMembersService;
      scope.hasCampaign = !_.isUndefined(scCampaignsService.active);
      scope.hasOrg = !_.isUndefined(scOrganizationsService.active);
      scope.scAuth = scAuth;

      if ($location.search().source === 'sso_reset_password_link') {
        scAuth.resetPasswordByLink();
      }
    }
  };
}
})();