(function(){
'use strict';

scDonationBlockSettings.$inject = ["scFroalaOptions"];
angular.module('classy').directive('scDonationBlockSettings', scDonationBlockSettings);

function scDonationBlockSettings(scFroalaOptions) {
  return {
    scope: true,
    templateUrl: 'global/objects/scBlock/types/donation/settings',
    controller: ['$scope', function ($scope) {
      $scope.fieldOptions = {
        headline: {
          model: 'block.headline',
          required: false,
          placeholder: 'Thank you for your gift',
          characterCounter: 120
        }
      };

      $scope.appealOptions = scFroalaOptions({ type: 'inline-with-video' });
      $scope.headerOptions = scFroalaOptions({ type: 'inline-header' }, {
        charCounterCount: true,
        charCounterMax: 120,
        ariaLabel: 'Headline'
      });

      $scope.donationPhotoMediaManagerOptions = {
        title: 'Select a Background Image',
        image: {
          guide: {
            title: 'Donation Page Background Image',
            message: 'Brand your donation page with a unique background image.',
            size: '1280 x 720 pixels'
          }
        }
      };
    }],
    link: function link() {}
  };
}
})();