(function(){
'use strict';

scRestrictInput.$inject = ["$window"];
angular.module('classy').directive('scRestrictInput', scRestrictInput);

function scRestrictInput($window) {
  return {
    require: 'ngModel',
    // eslint-disable-next-line no-unused-vars
    link: function link(scope, element, attrs, ngModelCtrl) {
      var cachedValue = ngModelCtrl.$viewValue || '';

      ngModelCtrl.$parsers.push(function (viewValue) {
        var pattern = new RegExp(attrs.scRestrictInput);

        if (!viewValue || !_.isString(viewValue) || pattern.test(viewValue)) {
          cachedValue = viewValue;
          return viewValue;
        }
        ngModelCtrl.$setViewValue(cachedValue);
        ngModelCtrl.$render();

        // Keep/move cursor to end
        try {
          var range = document.createRange();
          range.setStartAfter(element[0].childNodes[0]);
          range.setEndAfter(element[0].childNodes[0]);
          var sel = $window.getSelection();
          sel.removeAllRanges();
          sel.addRange(range);
        } catch (err) {}

        return cachedValue;
      });
    }
  };
}
})();