(function(){
'use strict';

CFPAuthSwitchViewFactory.$inject = ["scAuth", "scAnalytics"];
angular.module('classy').factory('CFPAuthSwitchView', CFPAuthSwitchViewFactory);

function CFPAuthSwitchViewFactory(scAuth, scAnalytics) {
  return function CFPAuthSwitchView(parentScope) {
    var $scope = parentScope.$new();

    $scope.login = function () {
      scAuth.login($scope.authOptions);
    };

    $scope.signup = function () {
      scAuth.signup($scope.authOptions);
    };

    $scope.eventBeacon = function () {
      scAnalytics.eventBeacon({
        category: 'frs ' + $scope.campaign.current.type,
        action: 'triggers',
        label: 'Login/Signup Step'
      });
    };

    /* ---------------------------------------------------------------------- *
     * View object
     * ---------------------------------------------------------------------- */

    return {
      id: 'indiv.auth.switch',
      templateUrl: $scope.flow.template('auth-switch'),
      maxWidth: 410,
      context: $scope,
      eventBeacon: $scope.eventBeacon
    };
  };
}
})();