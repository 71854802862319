(function(){
'use strict';

frsDonationBlockComponents.$inject = ["scCampaignsService", "scOrganizationsService"];
angular.module('classy').factory('frsDonationBlockComponents', frsDonationBlockComponents);

function frsDonationBlockComponents(scCampaignsService, scOrganizationsService) {
  var blockPath = 'global/objects/scBlock/types/frs-donation/components/';

  var componentDefaults = {
    welcome: {
      active: true,
      section: 'main',
      order: 1,
      templateUrl: blockPath + 'welcome/template'
    },
    credit: {
      active: function active() {
        return scCampaignsService.active.DESIGNATIONS_ENABLED || scCampaignsService.active.HAS_FUNDRAISING;
      },

      section: 'main',
      order: 6,
      templateUrl: blockPath + 'credit/template'
    },
    amount: {
      active: true,
      section: 'main',
      order: 2,
      templateUrl: blockPath + 'amount/template'
    },
    designation: {
      active: function active() {
        return _.isArray(scCampaignsService.active.current.designations) && scCampaignsService.active.current.designations.length > 1;
      },

      section: 'main',
      order: 4,
      templateUrl: blockPath + 'designation/template'
    },
    matching: {
      active: function active() {
        return scOrganizationsService.active.current.has_employer_match;
      },
      section: 'main',
      order: 5,
      templateUrl: blockPath + 'matching/template'
    },
    doubleTheDonation: {
      active: function active() {
        return SC.organization.has_double_the_donation_employer_match;
      },
      section: 'main',
      order: 5,
      templateUrl: blockPath + 'double-the-donation/template'
    },
    dedication: {
      active: function active() {
        return !scCampaignsService.active.current.hide_dedications;
      },

      section: 'main',
      order: 3,
      templateUrl: blockPath + 'dedication/template'
    },
    giftaid: {
      active: function active() {
        return scOrganizationsService.active.current.is_gift_aid_available;
      },
      section: 'main',
      order: 7,
      templateUrl: blockPath + 'gift-aid/template'
    },
    donor: {
      active: true,
      section: 'main',
      order: 8,
      templateUrl: blockPath + 'donor/template'
    },
    shipping: {
      active: function active() {
        return scCampaignsService.active.current.collect_shipping_address;
      },

      section: 'main',
      order: 9,
      templateUrl: blockPath + 'shipping/template'
    },
    payment: {
      active: true,
      section: 'main',
      order: 10,
      templateUrl: blockPath + 'payment/template'
    },
    footer: {
      active: true,
      section: 'main',
      order: 11,
      templateUrl: blockPath + 'footer/template'
    },
    faq: {
      active: true,
      section: 'aside',
      order: 1,
      templateUrl: blockPath + 'faq/template'
    }
  };

  return function () {
    var components = _.cloneDeep(componentDefaults);
    return components;
  };
}
})();