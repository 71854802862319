(function(){
'use strict';

/**
 * @name scFormatCurrency
 * @kind filter
 */
scFormatCurrency.$inject = ["scCurrencyService", "scIntlService"];
angular.module('classy').filter('scFormatCurrency', scFormatCurrency);

function scFormatCurrency(scCurrencyService, scIntlService) {
  return function (val) {
    var currency = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : scCurrencyService.getDefaultCurrency();
    var decimalPlaces = arguments[2];
    var displayType = arguments.length > 3 && arguments[3] !== undefined ? arguments[3] : 'symbol';
    var skipNaNFormatting = arguments.length > 4 && arguments[4] !== undefined ? arguments[4] : false;

    var language = scCurrencyService.getLanguagePreference();
    var decimals = _.min([decimalPlaces || 0, scCurrencyService.getAllowedDecimals(currency)]);

    var options = {
      style: 'currency',
      currency: currency,
      currencyDisplay: displayType,
      maximumFractionDigits: decimals,
      minimumFractionDigits: decimals
    };

    if (skipNaNFormatting && (!_.isNumber(parseFloat(val)) || _.isNaN(parseFloat(val)))) {
      return val;
    }

    var value = !_.isNumber(parseFloat(val)) || _.isNaN(parseFloat(val)) ? 0 : val;

    var formatted = scIntlService.NumberFormat(language, options).format(value);
    var localizedCurrency = scCurrencyService.localeCurrency || scCurrencyService.getDefaultCurrency();
    if (localizedCurrency !== currency && scCurrencyService.getBrowserDefaultCurrencySymbol(currency) === '$') {
      // FRS-5207 unless we are in the same currency as the user users,
      // we should not display a plain $ (looking at you IE)
      formatted = formatted.replace('$', currency);
    }
    return formatted;
  };
}
})();