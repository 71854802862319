(function(){
'use strict';

scTooltip.$inject = ["$interpolate", "accessibilityService"];
angular.module('classy').directive('scTooltip', scTooltip);

function scTooltip($interpolate, accessibilityService) {
  'use strict';

  return {
    scope: {
      text: '=?scTooltip',
      displayOnLoad: '<?',
      showEvent: '<?',
      hideEvent: '<?',
      scTooltipTipStyle: '<?',
      fixedTooltip: '<?',
      disableTooltip: '<?'
    },
    link: function link(scope, element, attrs) {
      var template = scope.text || element.text();
      var tooltipClass = attrs.scTooltipClass;

      var hideOptions = void 0;
      var showOptions = void 0;

      // Dismiss the tooltip after (scTooltipHideDelay) ms if defined,
      // otherwise dismiss the tooltip only on the hide event passed in.
      // If neither are defined, dismiss tooltip on unfocus or click event.
      if (attrs.scTooltipHideDelay) {
        hideOptions = {
          event: scope.hideEvent || false,
          inactive: parseInt(attrs.scTooltipHideDelay, 10) || false
        };
      } else if (attrs.fixedTooltip) {
        hideOptions = {
          event: scope.hideEvent || false,
          delay: attrs.scTooltipDelay || 90,
          fixed: true
        };
      } else {
        hideOptions = {
          event: scope.hideEvent || 'unfocus click keydown'
        };
      }

      if (scope.displayOnLoad) {
        showOptions = {
          delay: attrs.scTooltipDelay || 90,
          ready: scope.displayOnLoad
        };
      } else {
        showOptions = {
          delay: attrs.scTooltipDelay || 90,
          event: scope.showEvent || 'click keydown'
        };
      }

      if (!scope.disableTooltip) {
        element.qtip({
          content: {
            // Runs on every hover
            text: function text() {
              return $interpolate(template)(scope.$parent);
            }
          },
          style: {
            classes: 'qtip-plain qtip-dark ' + tooltipClass,
            tip: scope.scTooltipTipStyle || undefined
          },
          position: {
            at: attrs.scTooltipPosition || undefined,
            my: attrs.scTooltipPositionTip || undefined
          },
          show: showOptions,
          events: {
            show: function show(event) {
              if (event.keyCode !== accessibilityService.CONSTANTS.ENTER_KEY && event.originalEvent.type !== 'click' && event.originalEvent.type !== 'mouseover' && event.keyCode !== accessibilityService.CONSTANTS.SPACE_KEY) event.preventDefault();
            },
            hide: function hide(event) {
              if (event.keyCode !== accessibilityService.CONSTANTS.ENTER_KEY && event.originalEvent.type !== 'click' && event.originalEvent.type !== 'mousedown' && event.originalEvent.type !== 'mouseout' && event.keyCode !== accessibilityService.CONSTANTS.TAB_KEY && event.keyCode !== accessibilityService.CONSTANTS.SPACE_KEY) event.preventDefault();
            }
          },
          hide: hideOptions
        });
      }

      if (!scope.text) {
        element.empty();
      }

      // qTip2 with multiple events does not work.
      // qtp2 does work with click and keydown but with when we specify "click focus keydown" it not works properly on cick and focus.
      // So to fix on focus we have added below code just as an workaround.
      element.keyup(function (e) {
        if (e.originalEvent.keyCode === accessibilityService.CONSTANTS.TAB_KEY) {
          element.trigger('click');
        }
      });
    }
  };
}
})();