(function(){
'use strict';

scSwitchFlowPickerService.$inject = ["$rootScope", "scCampaignsService", "fundraiseSwitchFlow", "scAnalytics"];
angular.module('classy').service('scSwitchFlowPickerService', scSwitchFlowPickerService);

function scSwitchFlowPickerService($rootScope, scCampaignsService, fundraiseSwitchFlow, scAnalytics) {
  var campaign = scCampaignsService.active.current;

  this.fundraiseSwitch = function (options) {
    if (campaign.status != 'active') {
      $rootScope.SC.status.banner = {
        type: 'error',
        msg: 'Whoops, this is just a preview of your campaign page. Please publish your page first.'
      };
      return;
    }

    scAnalytics.eventBeacon({
      category: 'frs ' + campaign.type,
      action: 'click',
      label: 'Fundraise CTA'
    });

    fundraiseSwitchFlow(options);
  };
}
})();