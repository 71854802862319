(function(){
'use strict';

frsDonationBlock.$inject = ["$animate", "$http", "$location", "$log", "$q", "$rootScope", "scSourceCodes", "$state", "$timeout", "$window", "scAnalytics", "frsDonationBlockComponents", "formDirective", "cpIntelligentAsk", "CryptoGiving", "scBlock", "scCampaignsService", "scFlowModalService", "scFundraisingPagesService", "scFundraisingTeamsService", "scOrganizationsService", "scDigitalWalletsService", "PayPalSDK", "scPagesService", "scPrepareBlockComponents", "scThemesService", "scFeeOnTopService", "scBanner", "scGetQueryParams", "countryBlockService", "bugsnagClient", "scTemplateLockingService", "metaService", "recaptchaService"];
function _toConsumableArray(arr) { if (Array.isArray(arr)) { for (var i = 0, arr2 = Array(arr.length); i < arr.length; i++) { arr2[i] = arr[i]; } return arr2; } else { return Array.from(arr); } }

angular.module('classy').directive('frsDonationBlock', frsDonationBlock);

function buildQueryParams(data) {
  return {
    status: data.status,
    order_id: data.id,
    amount: data.charged_total_gross_amount,
    sc_fee: data.charged_fees_amount,
    first_name: data.billing_first_name,
    recurring: data.recurring_donation_plan_id ? 1 : 0,
    event_id: data.campaign_id,
    charged_currency_code: data.charged_currency_code
  };
}

function frsDonationBlock($animate, $http, $location, $log, $q, $rootScope, scSourceCodes, $state, $timeout, $window, scAnalytics, frsDonationBlockComponents, formDirective, cpIntelligentAsk, CryptoGiving, scBlock, scCampaignsService, scFlowModalService, scFundraisingPagesService, scFundraisingTeamsService, scOrganizationsService, scDigitalWalletsService, PayPalSDK, scPagesService, scPrepareBlockComponents, scThemesService, scFeeOnTopService, scBanner, scGetQueryParams, countryBlockService, bugsnagClient, scTemplateLockingService, metaService, recaptchaService) {
  'use strict';

  return scBlock({
    link: function link($scope, element) {
      $scope.preventRecurringFrequency = scOrganizationsService.active.preventRecurringFrequencies();
      $scope.CryptoGiving = CryptoGiving;

      $animate.enabled(element, false);

      //  check CountryBlock on page load
      countryBlockService.checkOrganizationRestrictions();

      /* ------------------------------------------------------------------ *
       * Block globals
       * ------------------------------------------------------------------ */

      /**
       * @name MODEL
       * @parent frsDonationBlock
       *
       * @description
       * Global object representing the transaction data that will actually
       * be submitted to the API. Keys match FORM keys exactly.
       */
      $scope.MODEL = {
        payment: {}
      };

      /**
       * Payment api - this object is passed down to the payment widget inside partials/payment. Methods are set onto
       * the api by the child component so that we can get payment information to be passed to the server for transaction
       * creation
       */
      $scope.API = {};

      /**
       * @name META
       * @parent frsDonationBlock
       *
       * @description
       * Global object for data related to but not included in the actual
       * donation model, such as the complete creditRecipient object (whereas
       * only the creditRecipient's ID is needed to submit the donation).
       */
      $scope.META = {
        errorList: []
      };

      /**
       * @name CONTEXT
       * @parent frsDonationBlock
       *
       * @description
       * Global object with flags for the current environment of this block.
       */
      $scope.CONTEXT = function () {
        var context = _.get($window, 'SC.donationContext', 'campaign');
        var availableCardTypes = ['Visa', 'MasterCard'];

        if (scOrganizationsService.active.current.currency != 'CAD') {
          availableCardTypes.push('American Express');
          availableCardTypes.push('Discover');
        }

        return {
          isFundraisingPage: context === 'fundraisingPage',
          isFundraisingTeam: context === 'fundraisingTeam',
          isCampaign: context === 'campaign',
          type: context,
          autofill: scGetQueryParams(),
          availableCardTypes: availableCardTypes
        };
      }();

      /**
       * @name UTIL
       * @parent frsDonationBlock
       *
       * @description
       * Global object with global utilities. May not be necessary.
       */
      $scope.UTIL = {
        showError: function showError(formKey, validationKey) {
          if ($scope.FORM) {
            if (validationKey) {
              return $scope.FORM.$visibleErrors.indexOf(formKey + '|' + validationKey) > -1;
            }
            return $scope.FORM.$visibleErrors.indexOf(formKey) > -1;
          }

          return undefined;
        }
      };

      /**
       * @name PREFLIGHT
       * @parent frsDonationBlock
       *
       * @description
       * Global object of actions to take when the submit button is pressed
       * and passes initial validation. Intended for actions like
       * concatenating two form fields into a single model value, or
       * retrieving payment information from TokenEx. If the registered
       * function returns a promise, submit will wait until the promise is
       * resolved, or cancel submit if it is rejected.
       */
      $scope.PREFLIGHT = {};

      /* ------------------------------------------------------------------ *
       * Normalization
       * ------------------------------------------------------------------ */

      $scope.campaign = scCampaignsService.active;
      $scope.organization = scOrganizationsService.active;
      $scope.theme = scThemesService.active;
      $scope.team = scFundraisingTeamsService.active;
      $scope.fundraiser = scFundraisingPagesService.active;

      /**
       * @name CURRENCY
       * @parent frsDonationBlock
       *
       * @description
       * Global object containing organization's currency code and symbol.
       */
      $scope.CURRENCY = $scope.organization.currency();

      /* ------------------------------------------------------------------ *
       * UI State
       * ------------------------------------------------------------------ */

      $scope.globalState = {
        processing: false,
        feeProcessing: false,
        isPreview: !!$scope.demo,
        isPublic: !!$scope.public
      };

      $scope.setFocusVariable = function (path) {
        _.set($scope, path, true);
      };

      $scope.resetFocusVariable = function (path) {
        _.set($scope, path, false);
      };

      /* ------------------------------------------------------------------ *
       * Collections
       * ------------------------------------------------------------------ */

      $scope.list = {
        components: scPrepareBlockComponents(frsDonationBlockComponents(), $scope.block.components)
      };

      $scope.getLock = function (path) {
        return scTemplateLockingService.getLock('block', path, { id: $scope.block.id });
      };

      /* ------------------------------------------------------------------ *
       * API
       * ------------------------------------------------------------------ */

      $scope.launchProcessingModal = function () {
        var processingModalView = {
          templateUrl: 'global/objects/scBlock/types/frs-donation/processing-modal',
          maxWidth: 520,
          context: $scope
        };
        scFlowModalService.open(processingModalView, {
          animate: true,
          closable: false,
          transitionWhitelist: ['frs.donation.thankYou']
        });
      };

      $scope.runPreflight = function () {
        try {
          var results = {};
          _.forEach($scope.PREFLIGHT, function (fn, key) {
            results[key] = fn();
          });
          $scope.preflightResults = $q.all(results); // should be 1 line: return $q.all(results);
          return $scope.preflightResults;
        } catch (e) {
          bugsnagClient.notify(e, {
            metaData: {
              userData: _.omit($scope.MODEL, 'payment')
            }
          });
          return $q(function (res, rej) {
            return (
              // wrapping in a $timeout to force a separate change detection cycle tick
              // RE: but its not in a $timeout? also, you can just return "$q.reject" right? instead of this callback
              // so the code would be return $q.reject({status: 500, e}); on line 259
              setTimeout(function () {
                rej({
                  status: 500,
                  e: e
                });
              })
            );
          });
        }
      };

      $scope.resetGlobalState = function () {
        $scope.globalState = Object.assign({}, $scope.globalState, {
          feeProcessing: false
        });
        setProcessingFlag(false);
      };

      /**
       * When submitting a digital wallets donation, we need to ignore
       * invlaid credit card field, and address autocomplete errors.
       *
       * @param {Array} errList list of errors
       * @returns {Boolean} whether all errors can be ignored for DW donation
       */
      var ignoreErrorsForDigitalWallets = function ignoreErrorsForDigitalWallets(errList) {
        var numOfErrsToIgnore = 0;

        if (errList.find(function (e) {
          return e.$name === 'credit card';
        })) {
          numOfErrsToIgnore += 1;
        }

        if (errList.find(function (e) {
          return e.$name === 'billing address auto';
        })) {
          numOfErrsToIgnore += 1;
        }

        return errList.length === numOfErrsToIgnore;
      };

      $scope.submitDigitalWallets = function (event) {
        // wrapping in a $timeout to force a separate change detection cycle tick
        $timeout(function () {
          scDigitalWalletsService.onDigitalWalletsSubmit();
          $scope.MODEL.payment.stripe.source = event.paymentMethod;
          $scope.FORM.$setSubmitted();
        }).then(function () {
          event.complete('success');
          $scope.submit();
        });
      };

      $scope.submit = function () {
        if ($scope.globalState.processing) {
          return undefined;
        }

        if ($scope.MODEL.z || scOrganizationsService.active.current === 'trail') {
          $location.url('/checkout/error');
          return false;
        }

        if (!$scope.organization.preventRecurringFrequencies()) {
          var donationLevel = $scope.block['donation-levels'];

          var isOtherInputPresent = donationLevel.some(function (level) {
            return level.amount === 'Custom' && level.display_on_page;
          });
          // Throw error and focus on first donation amount, if other input field is not present and none of the donation amount is selected.
          if ($scope.MODEL.items[0].raw_final_price < 1 && !isOtherInputPresent) {
            $rootScope.SC.status.banner = {
              type: 'error',
              msg: 'Oops! Please select the donation amount.'
            };

            var level = donationLevel.filter(function (item) {
              return item.display_on_page;
            });
            $('#D-A' + level[0].amount).focus();
            return false;
          }
        }

        if ($scope.META.fixedDonationAmount && $scope.MODEL.items[0].raw_final_price > 1 && !$scope.preventRecurringFrequency && $scope.FORM.amount) {
          $scope.FORM.amount.$setValidity('required', true);
          $scope.FORM.amount.$setValidity('min', true);
          $scope.FORM.amount.$setValidity('max', true);
        }

        // Subforms are not automatically set to submitted
        Object.keys($scope.FORM).filter(function (key) {
          return $scope.FORM[key] instanceof formDirective[0].controller;
        }).forEach(function (key) {
          $scope.FORM[key].$setSubmitted();
        });

        if (!_.isEmpty($scope.FORM.$error)) {
          var ccFields = ['token', 'expirationMonth', 'expirationYear', 'securityCode'];
          var fullErrorList = getFormErrorCount($scope.FORM);
          // since we can't focus any fields within the credit card frame, filter them out and add a custom element instead
          $scope.META.errorList = fullErrorList.filter(function (error) {
            return !_.includes(ccFields, error.$name);
          });

          if ($scope.META.errorList.length !== fullErrorList.length) {
            $scope.META.errorList.push({ $name: 'credit card', $element: $('#cc-entry-el') });
          }

          // If credit card error is the only error and we are using digital wallets to pay
          // skip validation so the submission can go through
          if (!scDigitalWalletsService.isDigitalWalletSubmission && !PayPalSDK.isPayPalCommerceSubmission || !ignoreErrorsForDigitalWallets($scope.META.errorList)) {
            $rootScope.SC.status.banner = {
              type: 'error',
              msg: 'Oops! Please complete the required fields.'
            };
            $timeout(function () {
              // we will want to focus the 'other' text field which may not be visible
              if ($scope.META.errorList.find(function (error) {
                return error.$name === 'amount';
              })) {
                if ($scope.organization.preventRecurringFrequencies()) {
                  $('#D-AOther')[0].click();
                }
                $('input[name="amount"]').focus();
              }

              $scope.META.focusFormErrorsLink = true;

              var errorElementOffset = element.find('.sc-form-error').offset();
              if (errorElementOffset) {
                var offset = element.find('.sc-form-error').offset().top - 100;
                angular.element('html, body').animate({ scrollTop: offset }, 1000);
              }
            });

            scDigitalWalletsService.setIsDigitalWalletsSubmission(false);
            return undefined;
          }
        }

        // Get the billing_potal_code and postal_code and check if it has the sc-form-error class
        var billingPostalCode = document.getElementsByName('billing_postal_code')[0];
        var postalCode = document.getElementsByName('postal_code')[0];

        if (billingPostalCode && billingPostalCode.classList.contains('sc-form-error') || postalCode && postalCode.classList.contains('sc-form-error')) {
          $rootScope.SC.status.banner = {
            type: 'error',
            msg: 'Oops! Please complete the required fields.'
          };

          if (billingPostalCode && billingPostalCode.classList.contains('sc-form-error')) {
            billingPostalCode.focus();
          }

          if (postalCode && postalCode.classList.contains('sc-form-error')) {
            postalCode.focus();
          }

          return undefined;
        }

        // Only do basic form validation for crypto
        if ($scope.META.paymentType === 'CRYPTO') {
          return undefined;
        }

        $scope.META.numberOfErrors = 0;

        if ($scope.globalState.isPreview) {
          var previewUrl = scPagesService.getUrl({
            status: 'preview',
            pageType: 'thank-you'
          }).url;
          $rootScope.SC.status.banner = {
            type: 'error',
            msg: 'Whoops, this is just a preview. Would you like to <a class="status-banner_link" target="_self" href="' + previewUrl + '">preview the thank you page?</a>'
          };
          scDigitalWalletsService.setIsDigitalWalletsSubmission(false);
          return undefined;
        }

        setProcessingFlag(true);
        $scope.launchProcessingModal();
        angular.element('[sc-autofocus]').removeAttr('sc-autofocus');

        $scope.runPreflight().then(function () {
          return recaptchaService.createToken('DONATE');
        }).then(function (recaptchaToken) {
          // eslint-disable-next-line prefer-promise-reject-errors
          if (!recaptchaToken) return Promise.reject('RECAPTCHA');

          if ($scope.MODEL.recur_until) {
            $scope.MODEL.recur_until = moment($scope.MODEL.recur_until).format('YYYY-MM-DD');
          }
          if ($scope.MODEL.comment) {
            var tempDivElement = document.createElement('div');
            // Convert break lines into spaces.
            tempDivElement.innerHTML = $scope.MODEL.comment.replace(/<\/?p>|<\/?br>/g, ' ');
            // This will convert the HTML to textContent.
            $scope.MODEL.comment = tempDivElement.textContent || tempDivElement.innerText || '';
          }
          if (_.get($scope, 'MODEL.answers', []).length !== 0) {
            _.forEach($scope.MODEL.answers, function (answers) {
              if (answers.answer && answers.question_type === 'text') {
                answers.answer = answers.answer.replace(/<\/?p>|&nbsp;/g, '') || null;
              }
            });
          }

          if (window.doublethedonation) {
            var doubleTheDonationCompanyId = _.get(document.getElementsByName('doublethedonation_company_id'), '[0].defaultValue');
            var doubleTheDonationCompanyName = _.get(document.getElementsByName('doubledonation_company_name'), '[0].defaultValue');
            var doubleTheDonationSearchText = _.get(document.getElementsByName('doublethedonation_entered_text'), '[0].defaultValue');

            if (doubleTheDonationCompanyId && doubleTheDonationCompanyName && doubleTheDonationSearchText) {
              $scope.MODEL.employer_match = {
                employer_id: doubleTheDonationCompanyId,
                employer_name: doubleTheDonationCompanyName,
                employer_search_text: doubleTheDonationSearchText
              };
              SC.organization.doubleTheDonationChannel.companyId = doubleTheDonationCompanyId;
            } else {
              $scope.MODEL.employer_match = doubleTheDonationSearchText ? {
                employer_search_text: doubleTheDonationSearchText
              } : null;
              SC.organization.doubleTheDonationChannel.companyId = null;
            }
          }

          var cSrc = scSourceCodes.newData;
          if (cSrc.c_src || cSrc.c_src2) {
            $scope.MODEL.c_src = [cSrc];
          }

          return $http({
            method: 'POST',
            url: '/frs-api/campaign/' + $scope.campaign.current.id + '/checkout',
            data: Object.assign($scope.MODEL, { token: recaptchaToken })
          });
        }).then(function (response) {
          $scope.globalState.redirecting = true;
          $scope.globalState.submitDisabled = true;
          scDigitalWalletsService.setIsLoaded(false);
          scDigitalWalletsService.setIsDigitalWalletsSubmission(false);
          setProcessingFlag(false);
          setPaymentType(response.data);

          // Send IAA heap event if init
          if (cpIntelligentAsk.isInit) {
            cpIntelligentAsk.sendHeapEvent('thank-you', $scope.MODEL, {
              transactionId: response.data.id,
              amount: response.data.total_gross_amount,
              frequency: response.data.frequency
            });
          }

          var conversionData = {
            amount: response.data.total_gross_amount,
            transactionId: response.data.id,
            raw_currency_code: response.data.raw_currency_code,
            digitalWalletsPresent: $scope.META.digitalWalletsEnabled,
            digitalWalletType: $scope.META.digitalWalletType,
            paymentSource: response.data.payment_source,
            campaignName: $scope.campaign.current.name,
            designationId: $scope.campaign.current.designation_id,
            country: $scope.campaign.current.country,
            frequency: response.data.frequency,
            promoCode: response.data.promo_code_code
          };

          scAnalytics.track('transaction', { transaction: response.data });

          if ($scope.MODEL.frequency !== 'one-time') {
            scAnalytics.track('recurring-donation/create/complete', conversionData);
          } else {
            scAnalytics.track('donation/create/complete', conversionData);
          }

          $rootScope.$broadcast('donation.success', response.status);

          if (response.status === 202) {
            $rootScope.SC.status.banner = {
              type: 'success',
              msg: _.get(response, 'data.message', 'Sorry, something went wrong.'),
              timeout: false
            };
            $log.warn(response.data);
          }

          var returnUrlFromParam = _.get($scope, 'CONTEXT.autofill.return_url', '').replace(/~2F/g, '/');
          var returnUrlFromResponse = _.get(response, 'data.return_url', '');

          $timeout(function () {
            $scope.$destroy();
            if (returnUrlFromParam) {
              $window.location.assign(returnUrlFromParam);
            } else if (returnUrlFromResponse) {
              var queryParams = buildQueryParams(response.data);
              var queryString = toQueryString(queryParams);
              $window.location.assign(returnUrlFromResponse + '?' + queryString);
            } else {
              $state.go('^.thankYou', { tid: response.data.id, response: response.data });
            }
          });
        }).catch(function (response) {
          scDigitalWalletsService.setIsDigitalWalletsSubmission(false);
          setProcessingFlag(false);
          scFlowModalService.close();
          angular.element('[name="token"]').attr('sc-autofocus', 1);

          angular.element('[name="expirationMonth"]').attr('sc-autofocus', 2);

          angular.element('[name="expirationYear"]').attr('sc-autofocus', 3);

          angular.element('[name="securityCode"]').attr('sc-autofocus', 4);

          angular.element('[name="postalCode"]').attr('sc-autofocus', 5);
          // If the error response comes back with a fixed_fot_percent attached
          // this means that the org has changed the percentage while the donor
          // was in the middle of checkout, an unlikely ocurrance, to say the least.
          if (_.get(response, 'data.fixed_fot_percent', false)) {
            // emit a bad request event so that the user can get a fresh new iFrame
            // and input their card information again (new total, new card). This should
            // be extremely rare (.00001% odds) and its cumbersomeness is warranted.
            $rootScope.$broadcast('donation.failure', 400);
            return handleFixedFotUpdate(response.data.fixed_fot_percent);
          }

          $rootScope.$broadcast('donation.failure', response.status);

          if (response.status && response.status !== '504') {
            scBanner(response.data || 'Sorry, something went wrong.', 'error', false);
          } else if (response === 'RECAPTCHA') {
            scBanner('Sorry, something went wrong.', 'error', false);
          } else if (response.message) {
            scBanner(response.message, 'error', false);
          } else {
            scBanner('Your request timed out. Please try again soon.', 'error', false);
          }

          $log.error(response.data || response.message);

          return undefined;
        });

        return undefined;
      };

      // For sticky donate footer
      $rootScope.$on('cpDonateFooter:donate:submit', function () {
        // TODO do I need to wrap this?
        if (!$scope.globalState.processing) {
          $scope.submit();
          $rootScope.$broadcast('cpDonateFooter:donate:resolved');
        }
      });

      /* ------------------------------------------------------------------ *
       * Private Helpers
       * ------------------------------------------------------------------ */

      /**
       * Sets $scope.globalState.processing to the provided argument.
       * If state changed, either starts or stops the processing timer.
       * @param {boolean} processing whether processing or not.
       */
      function setProcessingFlag(processing) {
        if (processing && !$scope.globalState.processing) {
          initProcessingTimer();
        } else if (!processing && $scope.globalState.processing) {
          stopProcessingTimer();
        }
        $scope.globalState.processing = processing;
      }

      function setPaymentType(data) {
        if ((data.payment_gateway === 'Dwolla' || data.payment_gateway === 'Stripe') && data.account_number) {
          data.payment_type = 'ACH';
        } else if (data.payment_gateway === 'BrainTree' && !data.card_last_four) {
          data.payment_type = 'PayPal';
        } else if (data.card_last_four) {
          data.payment_type = 'Credit Card';
        } else if (data.payment_gateway === 'Classy Pay' && data.payment_source === 'PAYPAL') {
          data.payment_type = 'PayPal Commerce';
        }
      }

      /**
       * initalizes a timer to send a bugsnag after
       * two minutes of processing.
       */
      function initProcessingTimer() {
        if ($scope.processingTimer) {
          stopProcessingTimer();
          $scope.processingTimer = null;
        }

        var promise = new Promise(function (_resolve, reject) {
          $scope.processingTimer = setTimeout(function () {
            scFlowModalService.close();
            scBanner('Your payment is taking longer to process. You’ll receive an email receipt once it processes. Please contact the organization if you do not receive an email receipt.', 'error', false);
            reject(new Error('ProcessingTimeout'));
          }, 300000); //  FRS takes longer than 5 minutes, throw error
        });
        promise.catch(function (e) {
          bugsnagClient.notify(e, {
            metaData: {
              userData: _.omit($scope.MODEL, 'payment')
            }
          });
        });
      }

      /**
       * stops the timeout timer so we don't create
       * a bugsnag unnecessarily.
       */
      function stopProcessingTimer() {
        clearTimeout($scope.processingTimer);
      }

      function handleFixedFotUpdate(newFixedFotPercent) {
        scFeeOnTopService.updateFixedFotPercent(newFixedFotPercent);
        $scope.resetGlobalState();
        var msg = 'Oops. The organization\'s fees have changed to ' + newFixedFotPercent + '%. See new calulated total below.';
        return scBanner(msg, 'neutral', false);
      }

      /* ------------------------------------------------------------------ *
       * Init
       * ------------------------------------------------------------------ */
      PayPalSDK.setAutoSubmitPayPalCommerceHandler(function () {
        $timeout(function () {}).then(function () {
          $scope.FORM.$setSubmitted();
          $scope.submit();
        });
      });

      metaService.initMeta($scope.META);
      _.set(SC, 'donation.isClassyPay', true); // Why is this necessary?

      if (!$scope.demo) {
        var funnelData = {
          organizationId: '' + $scope.organization.current.id,
          paymentProcessor: 'Classy Pay',
          customQLength: $scope.campaign.current && $scope.campaign.current.questions && $scope.campaign.current.questions.custom && $scope.campaign.current.questions.custom.length,
          campaignType: $scope.campaign.current.type,
          campaignId: '' + $scope.campaign.current.id,
          donationTarget: _.get($window, 'SC.donationContext', 'campaign')
        };

        scAnalytics.track('donation/create/begin', funnelData);

        $timeout(function () {
          if ($state.params.scroll) {
            var targetName = $state.params.scroll == 'CreditCardNumber' ? 'payment_pan' : $state.params.scroll;

            $('html, body').animate({
              scrollTop: $('[name=' + targetName + ']').offset().top
            }, 1000);
          }
        });
      }
    }
  });
}

function toQueryString(obj) {
  return _.map(obj, function (value, key) {
    return encodeURIComponent(key) + '=' + encodeURIComponent(value);
  }).join('&');
}

/**
 * Iterates through a form's controls and recursively calls itself if a control is also a form. Returns a count of errors
 * @param {Object} form
 * @returns {Number} The number of errors in the form and all subforms
 */
function getFormErrorCount(form) {
  var errorList = [];
  if (form && form.$$controls && form.$$controls.length) {
    form.$$controls.forEach(function (control) {
      if (control.$$controls && control.$$controls.length) {
        // if a control is another form, let's get the errors in that form
        errorList.push.apply(errorList, _toConsumableArray(getFormErrorCount(control)));
      } else if (control.$invalid) {
        errorList.push({
          $name: control.$name.replace(/_/g, ' '),
          $element: control.$$element
        });
      }
    });
  }
  return errorList;
}
})();