(function(){
'use strict';

validatePromoCodeInput.$inject = ["$timeout", "$q", "scPromoCodeService"];
angular.module('classy').directive('scValidatePromoCodeInput', validatePromoCodeInput);

function validatePromoCodeInput($timeout, $q, scPromoCodeService) {
  return {
    require: 'ngModel',

    scope: {
      promoCodeError: '=?'
    },
    // eslint-disable-next-line no-unused-vars
    link: function link(scope, element, attrs, ngModel) {
      var pendingRequest = false;

      function checkPromoCodeInput() {
        if (!scope.code) return $q.resolve();

        return scPromoCodeService.checkCodeAvailability({ promoCode: scope.code }).then(function (_ref) {
          var state = _ref.state;

          if (state == 'invalid' || state == 'expired') {
            scope.promoCodeError.state = state;
            return $q.reject(state);
          }

          return $q.resolve();
        });
      }

      // Validates the promo code as the field changes.
      // This is used to disable the input and display an error message
      // if the promo code is expired or invalid
      ngModel.$asyncValidators.promoCodeValid = function (modelValue, viewValue) {
        scope.code = modelValue || viewValue;
        $timeout.cancel(pendingRequest);
        pendingRequest = $timeout(checkPromoCodeInput, 400);
        return pendingRequest;
      };
    }
  };
}
})();