(function(){
'use strict';

/**
 * @kind directive
 * @name scSocialButtons
 *
 * @description
 * Outputs social share buttons
 */

scSocialButtons.$inject = ["$compile", "$parse", "$templateCache", "$location", "linkBigTip", "scSocialShareLinks", "scPagesService", "scSingleShareService", "$state", "accessibilityService"];
angular.module('classy').directive('scSocialButtons', scSocialButtons);

function scSocialButtons($compile, $parse, $templateCache, $location, linkBigTip, scSocialShareLinks, scPagesService, scSingleShareService, $state, accessibilityService) {
  return {
    scope: {},

    link: function link(scope, element, attrs) {
      scope.twitterUrl = '';
      scope.mailToUrl = '';
      scope.smsUrl = '';
      scope.instagramUrl = '';

      scope.instagramDataId = $location.url().includes('thank') && 'thankyou-share-meta_ig';

      scope.cta = $parse(attrs.callToAction)(scope);
      scope.mode = $parse(attrs.scSocialButtons)(scope);

      scope.modeClass = function () {
        return scope.mode + '-mode';
      };

      var template = $templateCache.get('global/directives/scSocialButtons/template');
      var compiled = null;

      switch (scope.mode) {
        case 'tooltip':
          {
            var tooltip = linkBigTip({
              element: element,
              template: template,
              scope: scope,
              event: 'click',
              width: 140,
              scroll: false,
              bg: '#F8F8F8'
            });
            scope.open = tooltip.open;
            scope.close = tooltip.close;
            break;
          }

        case 'overlay':
          {
            scope.open = function () {
              if (scSingleShareService.isOn() != true) {
                compiled = $compile(template)(scope);
                compiled.appendTo('body').attr('tabindex', '0').focus();
                scSingleShareService.On();
                if ($state.is('frs.fundraiser.index')) {
                  var modalElements = document.querySelector('#social-dropdown-menu');
                  modalElements.addEventListener(accessibilityService.CONSTANTS.KEYDOWN_EVENT, scope.handleKeyEvents(modalElements));
                }
                document.querySelector('.public-page').setAttribute('aria-hidden', 'true');
              }
            };
            scope.close = function () {
              scSingleShareService.Off();
              document.querySelector('.public-page').removeAttribute('aria-hidden');
              element.focus();
              if (compiled) {
                compiled.remove();
              }
            };
            element.on('click', scope.open);
            scope.$on('scSocialButtons.open', scope.open);
            scope.$on('$destroy', function () {
              element.off('click', scope.open);
            });
            break;
          }

        case 'icons':
          compiled = $compile(template)(scope);
          compiled.appendTo(element);
          break;

        default:
        // do nothing
      }

      /* -------------------------------------------------------------------- *
       * Init
       * -------------------------------------------------------------------- */

      scope.previousUrl = scPagesService.getShareableUrl();

      scSocialShareLinks.getSocialData().then(function (data) {
        scope.twitterUrl = data.twitter_link;
        scope.mailToUrl = data.email_link;
        scope.smsUrl = data.sms_link;
        scope.fbShare = data.facebook_share;
        scSocialShareLinks.getInstagramLink().then(function (additionalData) {
          var combinedData = Object.assign({}, data, additionalData);

          scope.twitterUrl = combinedData.twitter_link;
          scope.mailToUrl = combinedData.email_link;
          scope.smsUrl = combinedData.sms_link;
          scope.fbShare = combinedData.facebook_share;
          scope.instagramUrl = combinedData.instagram_link;
        });
      });

      scope.isPreview = function () {
        if ($state.is('admin.manage.campaign.step.setup') || $state.is('admin.manage.campaign.step.design.views.content-blocks')) {
          return true;
        }
        return false;
      };

      scope.setCancelButton = function ($event) {
        if ($event) {
          if ($event.keyCode === accessibilityService.CONSTANTS.ENTER_KEY || $event.keyCode === accessibilityService.CONSTANTS.SPACE_KEY) {
            this.close();
          }
        }
      };

      scope.handleKeyEvents = function (modalElements) {
        return function (event) {
          /**
          getting all the focusable elements in the modal.
          get the first and last focusable element in the modal
          */
          var focusableModalElements = modalElements.querySelectorAll(accessibilityService.CONSTANTS.ELEMENTS_TO_FOCUS);
          var firstFocusableElement = focusableModalElements[0];
          var lastFocusableElement = focusableModalElements[focusableModalElements.length - 1];
          function handleBackwardTab() {
            if (document.activeElement === firstFocusableElement) {
              event.preventDefault();
              lastFocusableElement.focus();
            }
          }
          function handleForwardTab() {
            if (document.activeElement === lastFocusableElement) {
              event.preventDefault();
              firstFocusableElement.focus();
            }
          }

          switch (event.keyCode) {
            case accessibilityService.CONSTANTS.TAB_KEY:
              // If there is only one focusable element in the modal, there is no need to handle tabbing events
              if (focusableModalElements.length === 1) {
                event.preventDefault();
                break;
              }
              if (event.shiftKey) {
                handleBackwardTab();
              } else {
                handleForwardTab();
              }
              break;
            case accessibilityService.CONSTANTS.ESC_KEY:
              scope.close();
              element.focus();
              break;
            default:
              break;
          }
        };
      };
    }
  };
}
})();