(function(){
'use strict';

angular.module('classy').directive('scModalGroupContent', scModalGroupContent);

function scModalGroupContent() {
  return {
    restrict: 'A',
    // eslint-disable-next-line no-unused-vars
    link: function link(scope, element) {
      element.hide();
    }
  };
}
})();