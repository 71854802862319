(function(){
'use strict';

angular.module('classy').component('cpPlaidWidget', {
  require: {
    ngModelCtrl: 'ngModel'
  },
  template: '\n  <button type="button"\n    data-id="paymethod-ach"\n    data-tracked-element="donation-page_checkout_ach-plaid-btn" \n    class="payment-method-select-btn__ach"\n    ng-style="{ \'background\': $ctrl.primaryColor }"\n    ng-show="$ctrl.model.status !== $ctrl.constants.STATUS.INACTIVE"\n    ng-click="$ctrl.openPlaidDialog()"\n    tabindex="0">\n    Bank Transfer\n  </button>\n      ',
  bindings: {
    model: '<',
    onSuccess: '&',
    onCancel: '&',
    currency: '<',
    style: '<',
    validateForm: '&'
  },
  controller: ["cpAPI", "cpConstants", "bugsnagClient", "scThemesService", "scDigitalWalletsService", "$state", "$rootScope", function cpPlaidWidget(cpAPI, cpConstants, bugsnagClient, scThemesService, scDigitalWalletsService, $state, $rootScope) {
    var _this = this;

    this.constants = cpConstants;
    this.config = {};
    this.primaryColor = scThemesService.active.current.styles.primaryColor;
    this.isDonationAchTerms = !!$state.current.name.includes('frs.donation.checkout');

    this.$onChanges = function () {
      if (this.currency && this.model.status !== cpConstants.STATUS.INACTIVE) this.setupPlaid();
    };

    $rootScope.$on('openPaymentModal', function (_event, paymentMethod) {
      if (paymentMethod === _this.constants.PAYMENT_METHODS.ACH) {
        _this.openPlaidDialog();
      }
    });

    this.setupPlaid = function () {
      var _this2 = this;

      cpAPI.getPlaidToken(this.currency).then(function (_ref) {
        var environment = _ref.environment,
            publicKey = _ref.publicKey,
            version = _ref.version;

        _this2.model.status = cpConstants.STATUS.READY;
        _this2.config.environment = environment;
        _this2.config.publicKey = publicKey;
        _this2.config.version = version;
      }).catch(bugsnagClient.notify);
    };

    this.openPlaidDialog = function () {
      this.onCancel();
      var _config = this.config,
          environment = _config.environment,
          version = _config.version,
          publicKey = _config.publicKey;


      if (!this.validateForm()) {
        return;
      }

      Plaid.create({
        env: environment,
        apiVersion: version,
        clientName: 'Classy Pay',
        key: publicKey,
        product: version === 'v1' ? 'auth' : ['auth'],
        selectAccount: true,
        onSuccess: this.onPlaidSuccess.bind(this),
        onExit: this.onPlaidCancel.bind(this)
      }).open();
    };

    this.onPlaidSuccess = function (publicToken, metadata) {
      this.model.status = cpConstants.STATUS.SUCCESS;
      this.model.accountId = metadata.account_id;
      this.model.accountName = metadata.account.name;
      this.model.publicToken = publicToken;
      this.model.achTermsAccepted = false;
      this.ngModelCtrl.$setValidity('required', true);
      this.ngModelCtrl.$setDirty();
      var lastFour = metadata.account.mask;
      var accountType = metadata.account.subtype;
      this.onSuccess({ lastFour: lastFour, accountType: accountType });
    };

    scDigitalWalletsService.addOnDigitalWalletsSubmitListener(function () {
      if (_this.model.status !== cpConstants.STATUS.SUCCESS) {
        return;
      }
      _this.onPlaidCancel();
    });

    this.onPlaidCancel = function () {
      this.ngModelCtrl.$setViewValue(cpConstants.STATUS.READY);
      this.ngModelCtrl.$setPristine();
      this.ngModelCtrl.$setUntouched();
      this.onCancel();
    };

    this.onPlaidReady = function () {
      console.log('==============================================\n'); // eslint-disable-line no-console
      console.log('plaid ready'); // eslint-disable-line no-console
      console.log('==============================================\n'); // eslint-disable-line no-console
    };
  }]
});
})();