(function(){
'use strict';

/**
 * @name scFlowModalView
 * @kind factory
 * @package FlowModal
 *
 * @guide ./FlowModal.guide.md
 *
 * @description
 * Represents a single flowModal view, i.e. the compiled content, its immediate
 * frame, and some helper metadata and methods. Views are instantiated when the
 * modal is opened and left for GC when the modal is closed.
 */
scFlowModalViewFactory.$inject = ["$rootScope", "$templateCache", "$compile", "scFlowModalDimensions", "scFlowModalElements"];
angular.module('classy').factory('scFlowModalView', scFlowModalViewFactory);

function scFlowModalViewFactory($rootScope, $templateCache, $compile, scFlowModalDimensions, scFlowModalElements) {
  function scFlowModalView(definition, defaultContext) {
    var _this = this;

    /* ------------------------------------------------------------------ *
     * Model
     * ------------------------------------------------------------------ */

    /**
     * @name template
     * @kind property
     * @type string
     * @parent scFlowModalView
     *
     * @description
     * A template string to compile for this view.
     */
    this.template = '';

    /**
     * @name templateUrl
     * @kind property
     * @type string
     * @parent scFlowModalView
     *
     * @description
     * The templateUrl to fetch and compile for this view.
     */
    this.templateUrl = '';

    /**
     * @name context
     * @kind property
     * @type Scope
     * @parent scFlowModalView
     *
     * @description
     * The scope to compile this view against.
     */
    this.context = defaultContext;

    /**
     * @name maxWidth
     * @kind property
     * @type number
     * @parent scFlowModalView
     *
     * @description
     * The "ideal width" for this view.
     */
    this.maxWidth = 480;

    /**
     * @name id
     * @kind property
     * @type string
     * @parent scFlowModalView
     *
     * @description
     * An optional string ID for this view.
     */
    this.id = null;

    /**
     * @name $frame
     * @kind property
     * @type jQuery
     * @parent scFlowModalView
     *
     * @description
     * The frame element, i.e. the outer container of the view which is
     * animated by GSAP.
     */
    this.$frame = null;

    /**
     * @name $content
     * @kind property
     * @type jQuery
     * @parent scFlowModalView
     *
     * @description
     * The compiled view content.
     */
    this.$content = null;

    /**
     * @name defaults
     * @kind property
     * @type object
     * @parent scFlowModalView
     *
     * @description
     * Defaults for this view.
     */
    this.defaults = {
      transition: 'slideLeft',
      duration: 400,
      ease: Power4.easeInOut
    };

    /* ------------------------------------------------------------------ *
     * Calculated properties
     * ------------------------------------------------------------------ */

    /**
     * @name dim
     * @kind property
     * @type object
     * @parent scFlowModalView
     *
     * @description
     * Tracks content and frame dimensions and positioning.
     */
    this.dim = {
      content: {
        width: 0,
        height: 0
      },
      frame: {
        width: 0,
        height: 0,
        top: 0,
        left: 0
      }
    };

    /* ------------------------------------------------------------------ *
     * State
     * ------------------------------------------------------------------ */

    /**
     * @name staged
     * @kind property
     * @type boolean
     * @parent scFlowModalView
     *
     * @description
     * True if this view is currently appended to the staging element.
     */
    this.staged = false;

    /**
     * @name loaded
     * @kind property
     * @type boolean
     * @parent scFlowModalView
     *
     * @description
     * True if this view is currently appended to the viewport element.
     */
    this.loaded = false;

    /* ------------------------------------------------------------------ *
     * Apply options
     * ------------------------------------------------------------------ */

    if (_.isString(definition)) {
      this.templateUrl = definition;
    } else if (_.isPlainObject(definition)) {
      _.merge(this, definition);
    }

    /* ------------------------------------------------------------------ *
     * Build view
     *
     * section.sc-flow-modal_frame (absolute, js size/position)
     * └ div.sc-flow-modal_content (relative, auto size/position)
     *   └ (compiled content)
     * ------------------------------------------------------------------ */

    var template = this.templateUrl ? $templateCache.get(this.templateUrl) : this.template;

    var $compiled = $compile(template)(this.context).wrapAll('<div></div>').parent();

    this.$frame = angular.element('<section>').addClass('sc-flow-modal_frame');

    this.$content = angular.element('<div>').addClass('sc-flow-modal_content').appendTo(this.$frame).append($compiled);

    /* ------------------------------------------------------------------ *
     * API
     * ------------------------------------------------------------------ */

    /**
     * @name stage
     * @kind method
     * @parent scFlowModalView
     *
     * @description
     * Appends this view to the staging element.
     */
    this.stage = function () {
      this.$content.css('max-width', this.maxWidth);
      this.$content.appendTo(scFlowModalElements.$staging);
      this.staged = true;
    };

    /**
     * @name unstage
     * @kind method
     * @parent scFlowModalView
     *
     * @description
     * Detaches this view from the staging element.
     */
    this.unstage = function () {
      _this.$content.appendTo(_this.$frame);
      _this.staged = false;
    };

    /**
     * @name getDimensions
     * @kind method
     * @parent scFlowModalView
     *
     * @description
     * Query the staged view and calculate frame and content dimensions.
     */
    this.getDimensions = function () {
      _this.dim.content.width = Math.min(scFlowModalDimensions.available.width, _this.maxWidth);
      _this.$content.width(_this.dim.content.width);

      // this is needed because sometimes this isn't in the DOM
      // and because of that the modal view's height gets set to 0
      // all part of the weird new height/width stuff in jQuery 3.x
      if (_this.$content.children().first().outerHeight()) {
        _this.dim.content.height = _this.$content.children().first().outerHeight();
      }
      // Safari bug FRS-2145: Safari will only allow overflow of elements
      // with display:flex if the child has a min-height (not height!)
      // larger than the container.
      _this.$content.css('min-height', _this.dim.content.height);
      _this.dim.frame.width = _this.dim.content.width;
      _this.dim.frame.height = Math.min(_this.dim.content.height, scFlowModalDimensions.available.height);

      if (_this.dim.frame.width > 25 && _this.dim.frame.height > 25) {
        $rootScope.$broadcast('flowModal.viewReady');
        scFlowModalElements.$views.addClass('view-ready');
      }

      _this.dim.frame.left = Math.round((scFlowModalDimensions.window.width - _this.dim.frame.width) / 2);
      _this.dim.frame.top = Math.round((scFlowModalDimensions.window.height - _this.dim.frame.height) / 2);
      _this.$frame.width(_this.dim.frame.width);
      if (_this.dim.content.height <= _this.dim.frame.height) {
        _this.$frame.addClass('vcenter');
      } else {
        _this.$frame.removeClass('vcenter');
      }
    };
  }

  return scFlowModalView;
}
})();