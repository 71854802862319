(function(){
'use strict';

/**
 * @name scFlowModalGroup
 * @kind factory
 * @package FlowModal
 *
 * @guide ./FlowModal.guide.md
 *
 * @description
 * Represents a group of modal views that are instantiated together.
 *
 * Views don't have to be associated with a group, but when they are, all views
 * in a group are laid out at once on modal open so that animations are as fast
 * and smooth as possible.
 *
 * Groups are instantiated when the modal is opened and left for GC when the
 * modal is closed.
 *
 * @param {array} viewDefs A group of view definitions.
 * @param {Scope} defaultContext A default scope to use if any included views
 * do not have one defined.
 */
scFlowModalGroupFactory.$inject = ["$timeout", "scFlowModalView"];
angular.module('classy').factory('scFlowModalGroup', scFlowModalGroupFactory);

function scFlowModalGroupFactory($timeout, scFlowModalView) {
  function scFlowModalGroup(viewDefs, defaultContext, name) {
    var _this = this;

    /* ------------------------------------------------------------------ *
     * Model
     * ------------------------------------------------------------------ */

    this.name = name;

    /**
     * @name views
     * @kind property
     * @parent scFlowModalGroup
     *
     * @description
     * An array of compiled views.
     */
    this.views = [];

    /* ------------------------------------------------------------------ *
     * API
     * ------------------------------------------------------------------ */

    /**
     * @name layout
     * @kind method
     * @parent scFlowModalGroup
     *
     * @description
     * Iterates through this group's registered views one by one, appends
     * them to the staging element, and updates their dimensions.
     */
    this.layout = function () {
      return $timeout(function () {
        _.forEach(_this.views, function (view) {
          view.stage();
          view.getDimensions();
          view.unstage();
        });
      });
    };

    /**
     * @name merge
     * @kind method
     * @parent scFlowModalGroup
     *
     * @description
     * Merges another group's instantiated views into this group's views
     * array.
     */
    this.merge = function (group) {
      this.views = _.union(this.views, group.views);
      this.name = group.name;
    };

    /* ------------------------------------------------------------------ *
     * Initialization
     * ------------------------------------------------------------------ */

    _.forEach(viewDefs, function (viewDef) {
      _this.views.push(new scFlowModalView(viewDef, defaultContext));
    });
  }

  return scFlowModalGroup;
}
})();