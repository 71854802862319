(function(){
'use strict';

createFundraisingPageFlowFactory.$inject = ["$http", "$q", "$rootScope", "$state", "$timeout", "$window", "scAnalytics", "CFPAuthSwitchView", "CFPDetailsView", "CFPLogoView", "CFPQuestionsView", "CFPSuccessView", "CFPExistingPageView", "CFPExitView", "CFPJoinTeamView", "CFPMultiplePagesView", "CFPWelcomeBackView", "scBlocksService", "scCampaignsService", "scOrganizationsService", "scFlowModalService", "scFundraisingPageModel", "scFundraisingPagesService", "scFundraisingTeamsService", "scImg", "scImgAssetsService", "scMembersService", "scPagesService", "scThemesService", "scTimeMachineService", "scTransientsService", "scDomainsService", "scSourceCodes", "countryBlockService"];
angular.module('classy').factory('createFundraisingPageFlow', createFundraisingPageFlowFactory);

function createFundraisingPageFlowFactory($http, $q, $rootScope, $state, $timeout, $window, scAnalytics, CFPAuthSwitchView, CFPDetailsView, CFPLogoView, CFPQuestionsView, CFPSuccessView, CFPExistingPageView, CFPExitView, CFPJoinTeamView, CFPMultiplePagesView, CFPWelcomeBackView, scBlocksService, scCampaignsService, scOrganizationsService, scFlowModalService, scFundraisingPageModel, scFundraisingPagesService, scFundraisingTeamsService, scImg, scImgAssetsService, scMembersService, scPagesService, scThemesService, scTimeMachineService, scTransientsService, scDomainsService, scSourceCodes, countryBlockService) {
  function createFundraisingPageFlow(pageOptions, parentScope) {
    var $scope = parentScope ? parentScope.$new() : $rootScope.$new();

    /* ---------------------------------------------------------------------- *
     * Cancel and redirect if necessary
     *
     * If the user is not currently on a .create-fundraising-page state, they
     * are redirected there and the flow is re-launched.
     * ---------------------------------------------------------------------- */

    if (pageOptions && pageOptions.fundraisingPages) {
      // used for the multiple fundraising pages modal
      $scope.lastPageOfFundraisers = _.get(pageOptions.fundraisingPages, 'data.last_page', 1);
      $scope.fundraisingPagesList = _.get(pageOptions.fundraisingPages, 'data.data', []);
    }

    if (!$state.includes('**.create-fundraising-page')) {
      var flowConfig = {
        options: pageOptions,
        returnAddr: {
          state: $state.current.name,
          params: $state.params,
          url: $window.location.href
        }
      };

      scTransientsService.set('createFundraisingPageFlowConfig', flowConfig);

      // Go to team page builder state if specified
      if (pageOptions && pageOptions.teamId) {
        $state.go('frs.team.index.create-fundraising-page', {
          campaignId: scCampaignsService.active.current.id,
          fundraisingTeamId: pageOptions.teamId
        });

        return undefined;
      }

      // current page for the multiple fundraising pages infinite scroll,
      // have to track it here because going back and forth between the modals
      // would reset it and lose the spot
      $scope.multipleFundraisingCurrentPage = 1;

      // Go to campaign page builder state if it exists, otherwise replace the
      // URL directly.
      if (!pageOptions || pageOptions && !pageOptions.startingView) {
        try {
          $state.go('frs.landing.name.campaign.create-fundraising-page', {
            campaignId: scCampaignsService.active.current.id,
            amount: Math.round(pageOptions.goal)
          });
        } catch (err) {
          $window.location.assign(scPagesService.getUrl({ pageType: 'campaign' }).url + '/fundraiser/create');
        }

        return undefined;
      }
    }

    // Opt In/consent
    $scope.optIn = {
      opt_in_wording: _.get(scOrganizationsService.active, 'current.opt_in_wording', "It's okay to contact me in the future."),
      opted_in: false
    };

    scAnalytics.track('fundraising-page/create/begin');

    /* ---------------------------------------------------------------------- *
     * Flow globals
     *
     * This file and each CFP_*View factory are essentially controllers, just
     * operating outside the normal Angular flow. Each creates a new child
     * scope and transforms it, then each view is compiled against its scope
     * by scFlowModal.
     *
     * In addition to the standard models, $scope.list, $scope.ui, and
     * (especially) $scope.flow are shared by all pseudo-controllers.
     * ---------------------------------------------------------------------- */

    var config = scTransientsService.get('createFundraisingPageFlowConfig') || {};
    var options = Object.assign({}, pageOptions, config.options);

    $scope.options = options;
    $scope.campaign = scCampaignsService.active;
    $scope.block = scBlocksService.getBlockByType('fundraiser');
    $scope.team = scFundraisingTeamsService.active;

    if (!_.isNumber($state.params.amount)) {
      $state.params.amount = $scope.campaign.current.default_page_goal;
    }

    scFundraisingPagesService.active = new scFundraisingPageModel();
    $scope.fundraiser = scFundraisingPagesService.active;
    $scope.fundraiser.current = {
      goal: options.goal || $state.params.amount || $scope.campaign.current.default_page_goal,
      intro_text: $scope.campaign.current.default_page_appeal,
      fundraising_team_id: options.teamId || null,
      logo: $scope.block.current.defaultFundraiserLogo,
      alias: '',
      campaign_id: $scope.campaign.current.id,
      // by default, designation id is the campaign default
      designation_id: $scope.campaign.current.designation_id
    };

    /* ---------------------------------------------------------------------- *
     * Collections
     * ---------------------------------------------------------------------- */

    $scope.list = {
      questions: [],
      answers: {}
    };

    /* ---------------------------------------------------------------------- *
     * UI
     * ---------------------------------------------------------------------- */

    $scope.ui = {
      primaryColor: _.get(scThemesService, 'active.current.styles.primaryColor', '#36bff7'),
      processing: false,
      questionsLoading: true,
      submitted: false,
      currentView: null,
      isFinalView: null,
      previousView: null
    };

    function getFundraisingPage() {
      var page = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : 1;

      // the status=active filter might need to be changed if we ever start using inactives
      // because we want to start showing completed pages on P2P campaigns for the
      // multiple fundraising pages project and beyond
      if (_.get(scMembersService, 'active.current.id')) {
        return $http({
          method: 'GET',
          url: '/frs-api/campaign/' + scCampaignsService.active.current.id + '/fundraising-pages',
          params: {
            filter: 'member_id=' + scMembersService.active.current.id,
            status: 'active',
            page: '' + page,
            per_page: 5,
            aggregates: true
          }
        });
      }
      return $q.reject();
    }

    // wrapper that lets child modal scopes grab fundraising pages
    // used for multiple fundraising pages
    $scope.getPages = function (page) {
      return getFundraisingPage(page);
    };

    // If the text opt in custom question is the only custom question enabled, it doesn't
    // make sense to display it because we need to collect cell # to enable text opt in.
    $scope.hideCustomQ = function () {
      var textOptIn = _.find($scope.list.questions, function (q) {
        return q.tag == 'text_opt_in';
      });
      var cellPhone = _.find($scope.list.questions, function (q) {
        return q.tag == 'cellphone';
      });
      if (textOptIn && !cellPhone && $scope.list.questions.length == 1) {
        return true;
      }
      return false;
    };

    /* ---------------------------------------------------------------------- *
     * Flow Management
     * ---------------------------------------------------------------------- */
    var modalOptions = {
      closeOnClickOut: false,
      startingView: 'indiv.details',
      animate: true,
      onClose: function onClose() {
        if ($scope.ui.currentView === 'indiv.success') {
          $rootScope.$broadcast('bring_home_the_bacon');
        } else if ($scope.ui.currentView === 'indiv.exit' || $scope.ui.currentView === 'indiv.auth.switch') {
          $scope.flow.abort();
          if ($scope.closeOutPromise) {
            $scope.closeOutPromise.resolve();
          }
        } else if ($scope.ui.currentView !== 'indiv.existing-page' && $scope.ui.currentView !== 'indiv.join-team.success' && $scope.ui.currentView !== 'indiv.welcome-back') {
          $scope.ui.previousView = $scope.ui.currentView;
          $scope.flow.to('indiv.exit');
          $scope.closeOutPromise = $q.defer();
          return $scope.closeOutPromise.promise;
        }
        return undefined;
      },


      transitionWhitelist: ['frs.fundraiser.index', 'frs.team.index'],
      matte: '#f5f5f5'
    };

    $scope.authOptions = {
      callback: function callback() {
        // gets member pages
        getFundraisingPage().then(function (result) {
          var activeFundraiser = null;

          if (result && result.data.total) {
            activeFundraiser = result.data.data.find(function (_ref) {
              var status = _ref.status;
              return status === 'active';
            });

            $scope.lastPageOfFundraisers = result.data.last_page;
            $scope.fundraisingPagesList = result.data.data;
          }

          // IF A FUNDRAISING PAGE ALREADY EXISTS -------------------------
          if (activeFundraiser) {
            // JOINING TEAM
            if (options && options.teamId && !scCampaignsService.active.current.allow_duplicate_fundraisers) {
              var teamId = options.teamId;
              var currentTeamId = activeFundraiser.fundraising_team_id;

              $scope.fundraisingPage = activeFundraiser;
              $scope.newTeam = _.get(scFundraisingTeamsService, 'teams[' + teamId + '].current', {});
              $scope.teamId = teamId;
              $scope.currentTeam = _.get(scFundraisingTeamsService, 'teams[' + currentTeamId + '].current', {});

              if (!currentTeamId || currentTeamId != teamId) {
                $scope.ui.currentView = 'indiv.join-team';
                modalOptions.startingView = 'indiv.join-team';
                scFlowModalService.open('createFundraisingPageFlow', modalOptions);
              } else {
                $state.go('frs.team.index', { fundraisingTeamId: teamId });
                $scope.flow.to('indiv.join-team.success', 'slideLeft');
              }
            } else if (!scCampaignsService.active.current.allow_duplicate_fundraisers) {
              // this is the default flow for non multiple fundraising pages p2p campaigns
              $state.go('frs.fundraiser.index', {
                fundraisingPageId: activeFundraiser.id
              });

              scAnalytics.addEventProperties({
                existing_fundraiser: true
              });

              $scope.flow.to('indiv.existing-page');
            } else {
              // this is the multiple fundraising pages p2p campaigns flow
              $scope.ui.currentView = 'indiv.multiple-pages';
              modalOptions.startingView = 'indiv.multiple-pages';
              scFlowModalService.open('createFundraisingPageFlow', modalOptions);
            }

            _.forEach(scFundraisingPagesService.userFundraisers, function (page) {
              if (page.current.id !== activeFundraiser.id) {
                var pageId = page.current.id;
                delete scFundraisingPagesService.fundraisers[pageId];
              }
            });
            return;
          }

          // IF NO FUNDRAISING PAGE EXISTS FOR MEMBER ---------------------
          $scope.ui.currentView = 'indiv.details';
          modalOptions.startingView = 'indiv.details';
          scFlowModalService.open('createFundraisingPageFlow', modalOptions);
        }).catch(function () {
          $scope.ui.currentView = 'indiv.logo';
          $scope.flow.to('indiv.logo');
        });
      },

      exitModal: true
    };

    $scope.flow = {
      views: [],

      // Add a view and return self for chaining. This allows views to add
      // subviews of their own, using $scopes that inherit from their own,
      // imitating the controller model. See the logo view for an example.)
      // It also allows views to kick off configuration immediately rather than
      // when they are activated.
      addView: function addView(view) {
        $scope.flow.views.push(view);
        return this;
      },
      template: function template(slug) {
        return 'global/flows/create-fundraising-page/views/' + slug + '/template';
      },
      getStartingView: function getStartingView() {
        if (options && options.startingView) {
          return options.startingView;
        } else if (!scMembersService.active.current.id) {
          return undefined;
        }
        return 'indiv.details';
      },
      getNextView: function getNextView() {
        switch ($scope.ui.currentView) {
          case 'indiv.details':
            return 'indiv.logo';

          case 'indiv.logo':
            if ($scope.ui.questionsLoading) {
              return 'indiv.wait';
            } else if ($scope.hideCustomQ()) {
              return false;
            } else if ($scope.list.questions.length) {
              return 'indiv.questions';
            }
            return false;

          case 'indiv.success':
            return false;

          case 'indiv.existing-page':
            return false;

          case 'indiv.join-team':
            return false;

          case 'indiv.exit':
            return false;

          case 'indiv.questions':
          default:
            return false;
        }
      },
      isFinalView: function isFinalView() {
        return !$scope.flow.getNextView();
      },
      to: function to(dest, transition, duration, ease, keyboardEvent) {
        scFlowModalService.to(dest, transition, duration, ease, keyboardEvent);
        $scope.ui.currentView = dest;
        $scope.ui.isFinalView = $scope.flow.isFinalView();
      },
      next: function next() {
        var nextView = $scope.flow.getNextView();
        if (nextView) {
          $scope.flow.to(nextView);
        } else {
          $scope.flow.end();
        }
      },
      end: function end() {
        $scope.flow.to('indiv.wait');
        submitFundraiser(options);
      },
      success: function success(result) {
        scFundraisingPagesService.add(result.data);
        $scope.ui.processing = false;
        $scope.flow.to('indiv.success');
        $state.go('frs.fundraiser.index', { fundraisingPageId: result.data.id, is_new: true });
      },
      abort: function abort() {
        var abortOptions = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};

        // FRS-2815
        scFundraisingPagesService.active = null;
        delete scFundraisingPagesService.userFundraisers[undefined];
        var returnOptions = void 0;

        if (_.isEmpty(abortOptions)) {
          returnOptions = config.returnAddr;
        } else {
          returnOptions = abortOptions;
        }

        if (abortOptions && abortOptions.state) {
          // need to whitelist in scFlowModalService because if you don't for each
          // valid state transition, it will just do default behavior instead of exactly
          // what you want it to do
          scFlowModalService.whitelist(abortOptions.state);
        }
        if (returnOptions) {
          // Most situations
          if (returnOptions.state === 'frs.landing.name.campaign') {
            // often times, the try will go on for a while and cause the state.go to
            // happen twice when it is finally caught. This avoid that altogether
            $window.location.assign('/campaign/c' + $scope.campaign.current.id);
          } else {
            try {
              $state.go(returnOptions.state, returnOptions.params);
              // User started from donation page
            } catch (err) {
              $window.location.assign(returnOptions.url);
            }
          }
        } else if ($state.includes('frs.landing') || $state.includes('frs.team') || $state.includes('frs.fundraiser')) {
          // User navigated directly to builder page but can move to parent
          scFlowModalService.whitelist($state.$current.parent.name);
          $state.go('^');
        } else {
          // Who knows
          $window.location.assign('/campaign/c' + $scope.campaign.current.id);
        }
      }
    };

    /* ---------------------------------------------------------------------- *
     * Views
     * ---------------------------------------------------------------------- */

    $scope.flow.addView(new CFPDetailsView($scope)).addView(new CFPLogoView($scope)).addView(new CFPQuestionsView($scope)).addView({
      id: 'indiv.wait',
      templateUrl: $scope.flow.template('wait'),
      maxWidth: 300
    }).addView(new CFPJoinTeamView($scope)).addView(new CFPExistingPageView($scope)).addView(new CFPExitView($scope)).addView(new CFPSuccessView($scope)).addView(new CFPMultiplePagesView($scope)).addView(new CFPWelcomeBackView($scope)).addView(new CFPAuthSwitchView($scope));

    /* ---------------------------------------------------------------------- *
     * Submit
     * ---------------------------------------------------------------------- */

    function fetchProfileAsset() {
      return $http.get('/frs-api/asset/profile').then(function (response) {
        var asset = scImgAssetsService.hydrate(response.data);
        $scope.fundraiser.current.logo = new scImg(asset);
      });
    }

    function awaitLogo() {
      if (!$scope.fundraiser.current.logo) {
        return $q.resolve();
      }

      if ($scope.fundraiser.current.logo.isProfileImg) {
        return fetchProfileAsset();
      }
      return $scope.fundraiser.current.logo.on('save');
    }

    function submitFundraiser(createOptions) {
      // Prevent double submit
      if ($scope.ui.submitted) {
        return;
      }

      // Update UI
      $scope.ui.submitted = true;
      $scope.ui.processing = true;

      // Populate start and end dates
      $scope.fundraiser.current.started_at = scTimeMachineService.justDate(new Date(), {
        inclusive: false
      });
      if ($scope.fundraiser.current.endDate) {
        $scope.fundraiser.current.ended_at = scTimeMachineService.justDate($scope.fundraiser.current.endDate, {
          inclusive: false,
          campaignTime: true
        });
      }

      // Fetch fresh member in case user logged in mid-flow
      var member = scMembersService.active.current;
      $scope.fundraiser.current.member_id = member.id;
      if (!$scope.fundraiser.current.is_tribute) {
        $scope.fundraiser.current.alias = member.first_name + ' ' + member.last_name;
      }

      $scope.fundraiser.current.title = member.first_name + ' ' + member.last_name;

      // Populate answers
      $scope.fundraiser.current.answers = [];
      _.forEach($scope.list.answers, function (answer, key) {
        var qid = key.substring(4); // 'qid_xxxxx'
        var parsedAnswer = answer;
        if (answer != null && $scope.list.questions.length) {
          var textTypeQuestionObj = _.find($scope.list.questions, function (q) {
            return q.id == qid && q.type === 'text';
          });
          if (textTypeQuestionObj) parsedAnswer = answer.replace(/<\/?p>|&nbsp;/g, '').trim() || null;
          if (parsedAnswer !== null) {
            $scope.fundraiser.current.answers.push({
              question_id: qid,
              answer: parsedAnswer
            });
          }
        }
      });

      // Wait for logo upload to finish
      awaitLogo()
      // Submit fundraiser using model
      .then(function () {
        var pageOpts = { campaignId: $scope.campaign.current.id };
        $scope.fundraiser.current.logo_id = _.get($scope, 'fundraiser.current.logo.assetId', null);
        if (createOptions.teamId) {
          pageOpts.team = true;
          pageOpts.teamId = createOptions.teamId;
        }

        return $scope.fundraiser.create(pageOpts, $scope.optIn);
      })
      // Success
      .then(function (result) {
        if ($scope.custom_url) {
          scDomainsService.postSlug('fundraising-pages', result.data.id, $scope.custom_url);
        }

        // grab source codes and submit to analytics
        scAnalytics.eventBeacon({
          category: 'frs ' + $scope.campaign.current.type,
          action: 'creation',
          label: 'New Fundraising Page',
          meta: scSourceCodes.getLatest($scope.campaign.current.id),
          entity_id: scFundraisingPagesService.active.current.id,
          fundraiser: scFundraisingPagesService.active.current
        });

        scAnalytics.track('fundraising-page/create/complete');

        $scope.flow.success(result);
      })
      // Failure
      .catch(function () {
        $scope.ui.processing = false;
        $scope.ui.error = 'Sorry, your page could not be created right now. Please try again later.';
      });
    }

    /* ---------------------------------------------------------------------- *
     * Init
     * ---------------------------------------------------------------------- */

    // Init flow
    $scope.ui.currentView = $scope.flow.getStartingView();
    $scope.ui.isFinalView = $scope.flow.isFinalView();

    // Register views and keys
    scFlowModalService.register('createFundraisingPageFlow', $scope, $scope.flow.views);

    function launchFundraisingPageFlow() {
      // Set options and launch -----------------------------------------------
      if (!scMembersService.active.current.id) {
        $scope.ui.currentView = 'indiv.auth.switch';
        modalOptions.startingView = 'indiv.auth.switch';
        scFlowModalService.open('createFundraisingPageFlow', modalOptions);
      } else {
        modalOptions.startingView = $scope.flow.getStartingView();
        scFlowModalService.open('createFundraisingPageFlow', modalOptions);
        if ($scope.ui.currentView == 'indiv.auth.switch') {
          $scope.flow.to('indiv.logo');
        }
      }
    }

    $timeout(function () {
      countryBlockService.checkOrganizationRestrictions(launchFundraisingPageFlow);
    });

    // Return fundraiser model to the calling $scope
    return $scope.fundraiser;
  }

  return createFundraisingPageFlow;
}
})();