(function(){
'use strict';

angular.module('classy').directive('scTruncateView', scTruncateView);

function scTruncateView() {
  return {
    scope: {
      truncateView: '=scTruncateView',
      truncateStyle: '@?'
    },
    link: function link(scope, element) {
      scope.$watch('truncateView', function (newVal) {
        var truncateClass = scope.truncateStyle + '_truncate';

        if (newVal.hide) {
          element.addClass(truncateClass);
        } else {
          element.removeClass(truncateClass);
        }
      }, true);
    }
  };
}
})();