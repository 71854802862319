(function(){
'use strict';

/**
 * @ngDoc factory
 * @name scObjectModel
 *
 * @description
 * A base object constructor with some common
 * prototype methods.
 */
scObjectModelFactory.$inject = ["$http", "$log", "$q", "scCloneDeep", "scDeepDiff", "scPreflight"];
angular.module('classy').factory('scObjectModel', scObjectModelFactory);

function scObjectModelFactory($http, $log, $q, scCloneDeep, scDeepDiff, scPreflight) {
  function scObjectModel() {}

  /**
   * Discard any unsaved changes. Revert to
   * most recently saved version.
   * @param  {string} property Pass to discard changes to
   *  specific Object properties.
   */
  scObjectModel.prototype.discardChanges = function (property) {
    if (_.isUndefined(property)) {
      this.current = scCloneDeep(this.saved);
    } else {
      _.set(this, 'current.' + property, _.get(this, 'saved.' + property, null));
    }
  };

  /**
   * Revert object to defaults defined by Classy.
   * @param  {string} property Pass to revert specific Object properties
   *  to their defaults.
   */
  scObjectModel.prototype.restoreDefaults = function (property) {
    if (_.isUndefined(property)) {
      var defaults = scCloneDeep(this.defaults);

      if (this.current.id.indexOf('temp_') === 0) {
        defaults.id = this.current.id;
      }

      this.current = defaults;
    } else {
      _.set(this, 'current.' + property, _.get(this, 'defaults.' + property, null));
    }
  };

  scObjectModel.prototype.mongoDiff = function () {
    delete this.current.queries_log;
    delete this.current.mongo_queries_log;
    delete this.current.updated_at;

    delete this.saved.queries_log;
    delete this.saved.mongo_queries_log;
    delete this.saved.updated_at;

    var savedCopy = angular.fromJson(angular.toJson(this.saved));
    var currentCopy = angular.fromJson(angular.toJson(this.current));

    var deepDiff = scDeepDiff(savedCopy, currentCopy);

    deepDiff = _.filter(deepDiff, function (diff) {
      return _.last(diff.path) != 'mongo_queries_log' && _.last(diff.path) != 'queries_log' && _.last(diff.path) != 'updated_at';
    });

    if (deepDiff && deepDiff.length) {
      $log.log('------------------------------------------');
      $log.log('Mongo diff in ' + _.get(this, 'current.type') + ' ' + this.type + ':');
      _.forEach(deepDiff, function (diff) {
        var msg = void 0;
        switch (diff.kind) {
          case 'N':
            msg = ' added with value ';
            break;
          case 'D':
            msg = ' deleted';
            break;
          case 'E':
            msg = ' changed to ';
            break;
          case 'A':
            msg = ' modified to ';
            break;
          default:
            msg = ' unknown change ';
        }
        $log.log('%c - ' + diff.path.join(' > '), 'color:magenta;', msg, diff.rhs);
      });

      return deepDiff;
    }
    return undefined;
  };

  scObjectModel.prototype.mysqlDiff = function () {
    var _this = this;

    if (this.type == 'campaign') {
      // is there a better way to say these types of changes dont count?
      this.saved.faqs = this.current.faqs;
      this.saved.overview = this.current.overview;
      this.saved.type = this.current.type;
      this.saved.end = this.current.end;
      this.saved.start = this.current.start;
      this.saved.noEndDate = this.current.noEndDate;
      this.saved.noLocation = this.current.noLocation;

      this.saved.location = this.current.location;
      this.saved.organization = this.current.organization;

      if (new Date(this.current.started_at + '+00').getTime() == new Date(this.saved.started_at).getTime()) {
        this.saved.started_at = this.current.started_at;
      }

      if (new Date(this.current.ended_at + '+00').getTime() == new Date(this.saved.ended_at).getTime()) {
        this.saved.ended_at = this.current.ended_at;
      }
    }

    delete this.saved.queries_log;
    delete this.saved.mongo_queries_log;
    delete this.saved.updated_at;
    delete this.current.queries_log;
    delete this.current.mongo_queries_log;
    delete this.current.updated_at;

    var diff = _.reduce(this.saved, function (result, value, key) {
      return _.isEqual(value, _this.current[key]) ? result : result.concat(key);
    }, []);

    // eslint-disable-next-line no-unused-vars
    _.forEach(this.current, function (value, key) {
      if (!(key in _this.saved)) {
        diff.push(key);
      }
    });

    return diff;
  };

  scObjectModel.prototype.create = function () {
    var _this2 = this;

    var deferred = $q.defer();

    $http({
      url: '/frs-api/' + this.type,
      method: 'POST',
      data: this.current
    }).then(function (response) {
      _this2.current = scCloneDeep(response.data);
      _this2.saved = scCloneDeep(response.data);
      deferred.resolve(response);
    }, function (error) {
      deferred.reject(error);
    });

    return deferred.promise;
  };

  scObjectModel.prototype.update = function () {
    var _this3 = this;

    var changedKeys = this.mysqlDiff(),
        data = {};

    _.forEach(changedKeys, function (key) {
      data[key] = _this3.current[key];
    });

    return scPreflight.prepare(data).then(function (payload) {
      /**
       * PUT to theme endpoint actually replaces the current theme
       * object with the new one. Protected attributes cannot be changed.
       * We need to merge current theme and payload to preserve data.
       */
      var mergedPayload = _.merge({}, _this3.current, payload);

      return $http({
        method: 'PUT',
        url: '/frs-api/' + _this3.type + '/' + _this3.current.id,
        data: _this3.type === 'theme' ? mergedPayload : payload,
        loadingScope: true
      }).then(function (response) {
        _.merge(_this3.saved, scCloneDeep(response.data));
        _.merge(_this3.current, scCloneDeep(response.data));
        return response;
      });
    });
  };

  scObjectModel.prototype.delete = function () {
    return $http({
      url: '/frs-api/' + this.type + '/' + this.current.id,
      method: 'DELETE'
    });
  };

  scObjectModel.prototype.getAnswersDiff = function () {
    var _this4 = this;

    var diff = this.current.answers.filter(function (answer) {
      var correspondingAnswer = _.find(_this4.saved.answers, function (savedAnswer) {
        return savedAnswer.id == answer.id;
      }) || {};
      return answer.answer !== correspondingAnswer.answer;
    });

    return diff;
  };

  scObjectModel.prototype.updateAnswers = function () {
    var answersDiff = this.getAnswersDiff();
    var answersToUpdate = [];
    var promises = [];

    answersDiff.forEach(function (answer) {
      if (answer.answer || answer.answer === 0) {
        answersToUpdate.push({
          question_id: answer.question_id,
          answer: answer.answer
        });
      } else if (answer.id) {
        promises.push($http({
          method: 'DELETE',
          url: '/frs-api/answers/' + answer.id
        }));
      }
    });

    if (!_.isEmpty(answersToUpdate)) {
      promises.push($http.post('/frs-api/' + this.type + '/' + this.current.id + '/answers', answersToUpdate));
    }

    return $q.all(promises);
  };

  return scObjectModel;
}
})();