(function(){
'use strict';

/**
 * @kind service
 * @name scMembersService
 *
 * @description
 * Stores an object full o' stuff about the logged in user
 */
scMembersService.$inject = ["$http", "$q", "scMemberModel", "scOrganizationsService"];
angular.module('classy').service('scMembersService', scMembersService);

function scMembersService($http, $q, scMemberModel, scOrganizationsService) {
  this.you = {};
  this.active = {};
  this.nextTeamRequestPageNumbers = {};
  this.nextPageRequestPageNumbers = {};

  var onInitDeferred = $q.defer();
  var onInitPromise = onInitDeferred.promise;

  this.registerInitCallback = function (callback) {
    onInitPromise.then(callback);
  };

  this.init = function (memberData) {
    this.you = new scMemberModel(memberData);
    this.active = this.you;

    _.forEach(memberData.organizations, function (organization) {
      scOrganizationsService.add(organization);
    });

    onInitDeferred.resolve(this.active.current);
  };

  this.sync = function (isLoad) {
    if (isLoad) {
      this.init(SC.member);
    }
    return $q.resolve(this.active.current);
  };

  this.fetch = function (id) {
    return $http.get('/frs-api/members/' + id);
  };

  this.getMemberFirstInitial = function (memberObj) {
    // For feed items
    if (memberObj.member && memberObj.member.first_name) {
      return memberObj.member.first_name.charAt(0).toUpperCase();
    }

    if (memberObj.member_name) {
      return memberObj.member_name.charAt(0).toUpperCase();
    }

    if (memberObj.first_name) {
      return memberObj.first_name.charAt(0).toUpperCase();
    }

    if (memberObj.agent_name) return memberObj.agent_name.charAt(0).toUpperCase();

    return 'A';
  };

  this.getMemberTeams = function (memberId, campaignId) {
    var _this = this;

    var page = arguments.length > 2 && arguments[2] !== undefined ? arguments[2] : 1;

    // these can probably use the same things as the frsCtrl does
    return $http({
      method: 'GET',
      url: '/frs-api/campaign/' + campaignId + '/fundraising-teams',
      params: {
        aggregates: true,
        filter: 'team_lead_id=' + memberId,
        per_page: 5,
        page: page
      }
    }).then(function (_ref) {
      var data = _ref.data;

      var nextPage = data.last_page > data.current_page ? data.current_page + 1 : null;
      _this.nextTeamRequestPageNumbers[campaignId] = nextPage;
      return data.data;
    });
  };

  this.getMemberPages = function (memberId, campaignId) {
    var _this2 = this;

    var page = arguments.length > 2 && arguments[2] !== undefined ? arguments[2] : 1;

    return $http({
      method: 'GET',
      url: '/frs-api/campaign/' + campaignId + '/fundraising-pages',
      params: {
        aggregates: true,
        filter: 'member_id=' + memberId,
        per_page: 5,
        page: page
      }
    }).then(function (_ref2) {
      var data = _ref2.data;

      var nextPage = data.last_page > data.current_page ? data.current_page + 1 : null;
      _this2.nextPageRequestPageNumbers[campaignId] = nextPage;
      return data.data;
    });
  };

  this.getAllMemberPages = function (memberId, campaignId) {
    var _this3 = this;

    if (!memberId) {
      return $q.resolve({
        fundraisingPages: {},
        fundraisingTeams: {}
      });
    }

    var deferred = $q.defer();

    $q.all({
      fundraisingPages: this.getMemberPages(memberId, campaignId),
      fundraisingTeams: this.getMemberTeams(memberId, campaignId)
    }).then(function (result) {
      return _this3.handlePageRequest(result);
    }).then(function (result) {
      return deferred.resolve(result);
    });

    return deferred.promise;
  };

  this.getNextTeamsPage = function (campaignId) {
    if (!this.nextTeamRequestPageNumbers[campaignId]) return $q.resolve([]);
    return this.getMemberTeams(this.you.current.id, campaignId, this.nextTeamRequestPageNumbers[campaignId]);
  };

  this.getNextPagesPage = function (campaignId) {
    if (!this.nextPageRequestPageNumbers[campaignId]) return $q.resolve([]);
    return this.getMemberPages(this.you.current.id, campaignId, this.nextPageRequestPageNumbers[campaignId]);
  };

  this.handlePageRequest = function (_ref3) {
    var _ref3$fundraisingTeam = _ref3.fundraisingTeams,
        teams = _ref3$fundraisingTeam === undefined ? [] : _ref3$fundraisingTeam,
        _ref3$fundraisingPage = _ref3.fundraisingPages,
        pages = _ref3$fundraisingPage === undefined ? [] : _ref3$fundraisingPage;

    var captainPagePromises = [];
    var captainPages = _.filter(pages, function (page) {
      return page.team_role === 'primary-captain';
    });

    _.forEach(captainPages, function (page) {
      if (!_.some(teams, 'id', page.fundraising_team_id)) {
        var promise = $http({
          method: 'GET',
          url: '/frs-api/fundraising-teams/' + page.fundraising_team_id
        });
        captainPagePromises.push(promise);
      }
    });

    var dataObj = {};

    return $q.all(captainPagePromises).then(function (resp) {
      _.forEach(resp, function (page) {
        dataObj = page.data;
        dataObj.captainCase = true;
        teams.push(dataObj);
      });

      return {
        fundraisingPages: pages,
        fundraisingTeams: teams
      };
    });
  };
}
})();