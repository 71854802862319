(function(){
'use strict';

angular.module('classy').directive('scAccordionList', scAccordionList);

function scAccordionList() {
  return function (scope, element, attrs) {
    function toggleElement(title) {
      var $title = angular.element(title);
      $title.bind('click', function () {
        if ($title.hasClass('on')) {
          $title.removeClass('on');
          $title.next().removeClass('on');
        } else {
          if (attrs.collapseRest) {
            element.find('.on').removeClass('on');
          }

          $title.addClass('on');
          $title.next().addClass('on');
        }
      });
    }

    // Only for Definition Lists
    scope.$watch(function () {
      return element[0].childNodes.length;
    }, function () {
      if (element[0].tagName == 'DL') {
        element.find('dt').toArray().forEach(toggleElement);
      }
    });
  };
}
})();