(function(){
'use strict';

angular.module('classy').factory('CFPExitView', CFPExitViewFactory);

function CFPExitViewFactory() {
  return function CFPExitView(parentScope) {
    var $scope = parentScope.$new();

    $scope.closeOut = function () {
      $scope.flow.abort();
      $scope.closeOutPromise.resolve();
    };

    $scope.goBack = function () {
      $scope.closeOutPromise.reject();
      $scope.flow.to($scope.ui.previousView);
    };

    /* ---------------------------------------------------------------------- *
     * View object
     * ---------------------------------------------------------------------- */

    return {
      id: 'indiv.exit',
      templateUrl: $scope.flow.template('exit'),
      maxWidth: 500,
      context: $scope
    };
  };
}
})();