(function(){
'use strict';

angular.module('classy').directive('scCommentsButton', scCommentsButton);

function scCommentsButton() {
  return {
    replace: true,
    scope: {
      commentable: '=scCommentsButton',
      action: '&?',
      showCount: '=?'
    },
    templateUrl: 'global/directives/interaction/scCommentsButton/template',
    link: function link(scope) {
      scope.action = scope.action || _.noop;
    }
  };
}
})();