(function(){
'use strict';

/**
 * @kind directive
 * @name scScaleToContainer
 *
 * @description
 * CSS-transforms the element to fit within its parent. Resizes on window resize
 * (does not establish a watch).
 */
scScaleToContainer.$inject = ["$window", "$timeout"];
angular.module('classy').directive('scScaleToContainer', scScaleToContainer);

function scScaleToContainer($window, $timeout) {
  return function (scope, element) {
    function resize() {
      var $element = element;
      var $container = element.parent();
      var elementWidth = $element.outerWidth();
      var containerWidth = $container.width();
      var factor = Math.round(containerWidth / elementWidth * 1000) / 1000;
      $element.css({
        '-webkit-transform': 'scale(' + factor + ')',
        '-moz-transform': 'scale(' + factor + ')',
        '-ms-transform': 'scale(' + factor + ')',
        '-o-transform': 'scale(' + factor + ')',
        transform: 'scale(' + factor + ')'
      });

      // Set parent height to offset white space due to transform
      $timeout(function () {
        $element.parent().height(element.height() * factor);
      });
    }

    var resizeDebounce = _.debounce(resize, 50);
    angular.element($window).on('resize orientationchange', resizeDebounce);
    scope.$watch('divHeight', resizeDebounce);
    scope.$on('scaleToContainer', resizeDebounce);

    // Execute every $digest to update the height scope variable.
    scope.$watch(function () {
      scope.divHeight = element.height();
    });

    resize();
  };
}
})();