(function(){
'use strict';

scFeaturedBlock.$inject = ["scBlock"];
angular.module('classy').directive('scFeaturedBlock', scFeaturedBlock);

function scFeaturedBlock(scBlock) {
  return scBlock({
    link: function link() {}
  });
}
})();