(function(){
'use strict';

scHtmlBlock.$inject = ["scBlock", "scFroalaOptions", "scPagesService", "scQaId"];
angular.module('classy').directive('scHtmlBlock', scHtmlBlock);

function scHtmlBlock(scBlock, scFroalaOptions, scPagesService, scQaId) {
  return scBlock({
    link: function link(scope) {
      scope.public = scope.$parent.public;
      scope.scQaId = scQaId;

      scope.froalaOptionsPreview = scFroalaOptions({
        type: 'edit-inline'
      });

      scope.donationUrl = scPagesService.getUrl({
        pageType: 'donation',
        includeBase: true
      }).url;
    }
  });
}
})();