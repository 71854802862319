(function(){
'use strict';

createUtils.$inject = ["$window", "$log", "ipCookie", "$state", "MetaTags", "ANALYTICS_EVENTS", "scMembersService", "scUserAgent"];
angular.module('classy').factory('scAnalyticsUtils', createUtils);

function createUtils($window, $log, ipCookie, $state, MetaTags, ANALYTICS_EVENTS, scMembersService, scUserAgent) {
  var scAnalyticsUtils = {};
  var REGISTRATION_CREATE_BEGIN = ANALYTICS_EVENTS.REGISTRATION_CREATE_BEGIN,
      REGISTRATION_CREATE_COMPLETE = ANALYTICS_EVENTS.REGISTRATION_CREATE_COMPLETE,
      DONATION_CREATE_BEGIN = ANALYTICS_EVENTS.DONATION_CREATE_BEGIN,
      DONATION_CREATE_COMPLETE = ANALYTICS_EVENTS.DONATION_CREATE_COMPLETE,
      RECURRING_DONATION_CREATE_COMPLETE = ANALYTICS_EVENTS.RECURRING_DONATION_CREATE_COMPLETE,
      DIGITAL_WALLETS_LOADED = ANALYTICS_EVENTS.DIGITAL_WALLETS_LOADED;


  var campaignData = {};

  var removeParamsFromQueryString = function removeParamsFromQueryString(queryString) {
    // if an org redirects to their donation page using passthrough parameters,
    // we want to strip the query string of any PII fields so it isn't sent to GA
    var piiFields = ['first', 'last', 'email', 'street', 'city', 'state', 'zip', 'country', 'phone'].join('|');

    return queryString.replace(new RegExp('(' + piiFields + ')=[^&]+&*', 'gi'), '') // remove parameters matching piiFields
    // eslint-disable-next-line prettier/prettier
    .replace(new RegExp('(&|\\?)$', 'g'), '') // remove any trailing & or ? chars after param deletion
    ;
  };

  scAnalyticsUtils.isPublicPage = function () {
    return SC.public;
  };

  scAnalyticsUtils.debugLog = function (event, options) {
    if ($window.DEBUG_ANALYTICS) {
      $log.log(_.cloneDeep(event), _.cloneDeep(options));
    }
  };

  scAnalyticsUtils.getUtmParameters = function () {
    if ($state && $state.params && $state.params.utm_source && $state.params.utm_medium && $state.params.utm_campaign) {
      var utmObject = {
        utm_source: $state.params.utm_source,
        utm_medium: $state.params.utm_medium,
        utm_campaign: $state.params.utm_campaign
      };
      if ($state.params.utm_term) utmObject.utm_term = $state.params.utm_term;
      if ($state.params.utm_content) utmObject.utm_content = $state.params.utm_content;
      return utmObject;
    }
    return false;
  };

  scAnalyticsUtils.buildChannelEventObject = function (event, data) {
    var channelEventObject = {
      member_id: _.get(scMembersService, 'active.current.id') || null,
      event: {
        event_name: event,
        facebook_browser_id: ipCookie('_fbp'),
        facebook_click_id: ipCookie('_fbc'),
        event_time: data.event_id.split('.')[0],
        event_id: data.event_id,
        event_source_url: window.location.origin + window.location.pathname + window.location.search + window.location.hash,
        action_source: 'website',
        client_user_agent: scUserAgent.getUa().ua,
        meta_test_event_code: ipCookie('cl_meta_event_code')
      }
    };

    switch (event) {
      case 'fundraising-page/create/complete':
        channelEventObject.event.meta_event_name = 'CompleteRegistration';
        channelEventObject.event.content_name = 'Fundraising Page Signup';
        break;

      case 'donation/create/begin':
        channelEventObject.event.meta_event_name = 'InitiateCheckout';
        break;

      case 'donation/create/complete':
        channelEventObject.event.meta_event_name = 'Purchase';
        channelEventObject.event.content_name = 'Donation';
        channelEventObject.event.content_category = 'one-time';
        channelEventObject.event.value = _.get(data, 'amount', 0);
        channelEventObject.event.currency = _.get(data, 'currency', 'USD');
        break;

      case 'registration/create/begin':
        channelEventObject.event.meta_event_name = 'AddToCart';
        channelEventObject.event.content_name = 'Registration';
        channelEventObject.event.value = _.get(data, 'amount', 0);
        channelEventObject.event.currency = _.get(data, 'currency', 'USD');
        channelEventObject.event.items = scAnalyticsUtils.calculateNumOfItems(data);
        break;

      case 'registration/create/complete':
        channelEventObject.event.meta_event_name = 'CompleteRegistration';
        channelEventObject.event.content_name = 'Registration';
        channelEventObject.event.value = _.get(data, 'amount', 0);
        channelEventObject.event.currency = _.get(data, 'currency', 'USD');
        channelEventObject.event.items = scAnalyticsUtils.calculateNumOfItems(data);
        channelEventObject.event.donation_frequency = _.get(data, 'donation_frequency', '');
        break;

      case 'recurring-donation/create/complete':
        channelEventObject.event.meta_event_name = 'Purchase';
        channelEventObject.event.content_name = 'Donation';
        channelEventObject.event.content_category = 'recurring';
        channelEventObject.event.value = _.get(data, 'amount', 0);
        channelEventObject.event.currency = _.get(data, 'currency', 'USD');
        break;

      case 'ViewContent':
        channelEventObject.event.meta_event_name = 'ViewContent';
        channelEventObject.event.content_name = data.title;
        channelEventObject.event.content_url = window.location.origin + window.location.pathname + window.location.search + window.location.hash;
        break;

      default:
        break;
    }

    return channelEventObject;
  };

  scAnalyticsUtils.buildPageViewData = function () {
    var options = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};

    var pageViewTitle = MetaTags.title;

    if ($state.is('frs.donation.checkout')) {
      if (window.location.pathname.includes('give/f')) {
        pageViewTitle = 'Fundraiser Donation Page';
      } else if (window.location.pathname.includes('give/t')) {
        pageViewTitle = 'Team Donation Page';
      } else if (window.location.pathname.includes('give')) {
        pageViewTitle = 'Donation Page';
      }
    }

    var sanitizedHash = removeParamsFromQueryString(window.location.hash);

    var fullPagePath = window.location.pathname + window.location.search + sanitizedHash;

    var defaultPageOptions = {
      page: fullPagePath,
      title: pageViewTitle,
      userAgent: navigator.userAgent
    };

    return _.merge(defaultPageOptions, options);
  };

  scAnalyticsUtils.generateEventId = function () {
    return scAnalyticsUtils.getEventTime() + '.' + Math.floor(Math.random() * 1000000000000);
  };

  scAnalyticsUtils.getEventTime = function () {
    return Math.round(new Date().getTime() / 1000);
  };

  scAnalyticsUtils.setCampaign = function (value) {
    campaignData = value;
  };

  scAnalyticsUtils.getCampaign = function () {
    return campaignData;
  };

  scAnalyticsUtils.getRegistrationQuantity = function (registration) {
    return registration.registrants.length > 0 ? registration.registrants.length / registration.entries : registration.entries;
  };

  scAnalyticsUtils.isFunnelStartEvent = function (event) {
    var funnelStartEvents = [DONATION_CREATE_BEGIN, REGISTRATION_CREATE_BEGIN];
    return funnelStartEvents.includes(event);
  };

  scAnalyticsUtils.isFunnelConversionEvent = function (event) {
    var funnelConversionEvents = [RECURRING_DONATION_CREATE_COMPLETE, DONATION_CREATE_COMPLETE, REGISTRATION_CREATE_COMPLETE];
    return funnelConversionEvents.includes(event);
  };

  scAnalyticsUtils.isDigitalWalletsLoadedEvent = function (event) {
    var dwLoad = DIGITAL_WALLETS_LOADED;
    return event === dwLoad;
  };

  scAnalyticsUtils.isEmbeddedGivingEvent = function (event) {
    return !!event && event.indexOf('embedded-giving') >= 0;
  };

  scAnalyticsUtils.calculateNumOfItems = function (data) {
    return _.get(data, 'items.length', 0) + (_.get(data, 'donation_amount', null) ? 1 : 0);
  };

  return scAnalyticsUtils;
}
})();