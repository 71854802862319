(function(){
'use strict';

scTimeTo.$inject = ["$window", "$timeout", "scAnalytics"];
angular.module('classy').service('scTimeTo', scTimeTo);

function scTimeTo($window, $timeout, scAnalytics) {
  this.timing = { preloaded: false };
  this.session = new Date().getTime();

  this.waiting = false;

  this.setupLogging = function () {
    var _this = this;

    if (!this.waiting) {
      this.waiting = true;
      $timeout(function () {
        _this.timing.viewFinalized = new Date().getTime();
        var start = _this.timing.preloaded ? _this.timing.stateChange : $window.performance.timing.navigationStart;

        if (!_this.timing.preloaded) {
          _this.logTimingEvent('latency', $window.performance.timing.connectEnd - start);
          _this.logTimingEvent('first byte', $window.performance.timing.responseStart - start);
          _this.logTimingEvent('last byte', $window.performance.timing.responseEnd - start);
          _this.logTimingEvent('document ready', $window.performance.timing.loadEventEnd - start);
        }
        _this.logTimingEvent('view rendered', _this.timing.viewFinalized - start);

        imagesLoaded(document.body, function () {
          _this.timing.imagesLoaded = new Date().getTime();
          _this.logTimingEvent('fully loaded', _this.timing.imagesLoaded - start);
          _this.timing.preloaded = true;
          _this.waiting = false;
        });
      }, 0);
    }
  };

  this.logTimingEvent = function (event, time) {
    if (time / 1000 < 1000) {
      scAnalytics.timeTo({
        time: time,
        preloaded: this.timing.preloaded ? 'preloaded' : 'fresh',
        event: event,
        session: this.session
      });
    }
  };
}
})();