(function(){
'use strict';

frsDonationDedicationCtrl.$inject = ["$scope", "$timeout", "scFlowModalService"];
angular.module('classy').controller('frsDonationDedicationCtrl', frsDonationDedicationCtrl);

function frsDonationDedicationCtrl($scope, $timeout, scFlowModalService) {
  'use strict';

  /* ---------------------------------------------------------------------- *
   * Constants
   * ---------------------------------------------------------------------- */

  $scope.CONSTANTS = {
    ADDRESS_REQUIRED_ERROR_TEXT: 'Don\'t forget to include a dedication street address.',
    COUNTRY_REQUIRED_ERROR_TEXT: 'Don\'t forget to include a dedication country.',
    CITY_REQUIRED_ERROR_TEXT: 'Don\'t forget to include a dedication city.',
    EMAIL_ADDRESS_FORMAT_ERROR_TEXT: 'Please enter dedication recipient email in the format: name@domain.com.',
    NAME_REQUIRED_ERROR_TEXT: 'Don\'t forget to give us a name for the dedication.',
    OPTIONAL_RECIPIENT_EMAIL: 'Optionally send a notification email'
  };

  /* ---------------------------------------------------------------------- *
   * Setup
   * ---------------------------------------------------------------------- */

  $scope.setup = {
    country: function country() {
      return $scope.CURRENCY.code === 'CAD' ? 'CA' : 'US';
    }
  };

  /* ---------------------------------------------------------------------- *
   * UI State
   * ---------------------------------------------------------------------- */

  $scope.state = {
    dedicationFormEnabled: false,
    postcardEnabled: false
  };

  /* ---------------------------------------------------------------------- *
   * Collections
   * ---------------------------------------------------------------------- */

  $scope.list = {
    ecards: $scope.campaign.current.ecards
  };

  /* ---------------------------------------------------------------------- *
   * Helper functions
   * ---------------------------------------------------------------------- */

  $scope.helpers = {
    isZipValid: function isZipValid(value) {
      if (!value) {
        var formCtrl = $scope.dedicationForm;
        var field = formCtrl.postal_code;

        if (!field || !field.$dirty) {
          return true;
        } else if (field && field.$dirty && !field.$modelValue) {
          return false;
        }
      }

      // If the country is not supported by i18n-zipcodes, it will throw an exception.
      // We will not validate the zip code and assume it is valid.
      try {
        // eslint-disable-next-line no-undef
        return i18nZipcodes($scope.dedicationModel.country, value || $scope.dedicationModel.postal_code);
      } catch (e) {
        return true;
      }
    }
  };

  /* ---------------------------------------------------------------------- *
   * Display flags
   * ---------------------------------------------------------------------- */

  $scope.show = {
    dedicationForm: function dedicationForm() {
      return $scope.state.dedicationFormEnabled;
    },
    emailForm: function emailForm() {
      return $scope.campaign.current.send_dedication_emails;
    },
    postcardToggle: function postcardToggle() {
      return $scope.campaign.current.offer_dedication_postal_notifications;
    },
    postcardForm: function postcardForm() {
      return $scope.state.postcardEnabled;
    },
    states: function states() {
      return $scope.dedicationModel.country === 'US';
    },
    provinces: function provinces() {
      return $scope.dedicationModel.country === 'CA';
    },
    genericLevel1: function genericLevel1() {
      return !_.includes(['US', 'CA'], $scope.dedicationModel.country);
    },
    ecardsForm: function ecardsForm() {
      return $scope.campaign.current.allow_ecards;
    },
    requiredDedicationInfo: function requiredDedicationInfo() {
      return $scope.dedicationModel.message || $scope.dedicationModel.email_address || $scope.dedicationModel.name || $scope.dedicationModel.ecard_id;
    }
  };

  /* ---------------------------------------------------------------------- *
   * Constructed bindings
   * ---------------------------------------------------------------------- */

  $scope.build = {
    recipientInfoRequiredError: function recipientInfoRequiredError() {
      var name = $scope.UTIL.showError('dedicationForm.name', 'required') ? 'name ' : '';
      var email = $scope.UTIL.showError('dedicationForm.email_address', 'required') ? 'email ' : '';
      var and = name && email ? 'and ' : '';
      return 'Don\'t forget to include the ' + name + and + email + 'of your recipient.';
    },
    regionLabel: function regionLabel() {
      if ($scope.dedicationModel.country === 'US') {
        return 'State';
      } else if ($scope.dedicationModel.country === 'CA') {
        return 'Province';
      }

      return 'Region';
    },
    zipPlaceholder: function zipPlaceholder() {
      if ($scope.dedicationModel.country === 'US') {
        return 'ZIP';
      }

      return 'Postal Code';
    },
    regionType: function regionType() {
      if ($scope.dedicationModel.country === 'US') {
        return 'state';
      } else if ($scope.dedicationModel.country === 'CA') {
        return 'province';
      }

      return '';
    },
    postalType: function postalType() {
      if ($scope.dedicationModel.country === 'US') {
        return 'ZIP code';
      }

      return 'postal code';
    },
    postalCodeError: function postalCodeError() {
      var postalType = $scope.build.postalType();
      var isZipValid = $scope.helpers.isZipValid();

      if ($scope.UTIL.showError('dedicationForm.postal_code', 'required')) {
        var postalCode = $scope.UTIL.showError('dedicationForm.postal_code', 'required') ? postalType : '';

        return 'Don\'t forget to include a ' + postalCode + '.';
      } else if (!isZipValid) {
        return 'Please enter a valid ' + postalType + '.';
      }

      return undefined;
    },
    previewLink: function previewLink() {
      // TODO: Surely Angular has an app for that
      var stripTags = function stripTags(input) {
        return input.replace(/(<([^>]+)>)/gi, '');
      };

      var message = void 0;

      if ($scope.dedicationModel.ecard_message) {
        message = stripTags($scope.dedicationModel.ecard_message).replace(/(?:\r\n|\r|\n)/g, '<br />');
      } else {
        message = 'Your Message here.';
      }

      $scope.activeEcard = _.filter($scope.list.ecards, function (ecard) {
        return ecard.id == $scope.dedicationModel.ecard_id;
      })[0];

      $scope.emailMessage = message;

      scFlowModalService.open({
        templateUrl: 'global/objects/scBlock/types/donation/dedication/ecard-preview-modal',
        context: $scope,
        maxWidth: 520
      });
    }
  };

  /* ---------------------------------------------------------------------- *
   * API
   * ---------------------------------------------------------------------- */

  $scope.template = function (name) {
    return 'global/objects/scBlock/types/frs-donation/components/dedication/partials/' + name;
  };

  $scope.selectEcard = function (id) {
    $scope.dedicationModel.ecard_id = id;
  };

  $scope.parseRecipientAddressAutocomplete = function (response) {
    // Update billing fields
    $timeout(function () {
      // Build the address string, if response.subpremise is empty, it will not be included
      var address = response.subpremise ? response.address + ', ' + response.subpremise : response.address;

      _.merge($scope.dedicationModel, {
        address: address,
        postal_code: response.postalCode,
        city: response.city,
        state: response.state,
        country: response.country
      });
    });
  };

  /* ---------------------------------------------------------------------- *
   * Init
   * ---------------------------------------------------------------------- */

  $scope.dedicationModel = {
    type: 'honor',
    honoree_name: null,
    name: null,
    email_address: null,
    address: null,
    city: null,
    state: null,
    country: $scope.setup.country(),
    postal_code: null,
    ecard_id: null,
    ecard_message: null
  };

  $scope.$watch('state.dedicationFormEnabled', function (enabled) {
    if (enabled) {
      $scope.MODEL.dedication = $scope.dedicationModel;
      $scope.FORM.$addControl($scope.dedicationForm);
      // PAY-1289: Propagate validation to parent form once added
      _.forEach(['name', 'email_address', 'honoree_name', 'country', 'address', 'city', 'state', 'postal_code'], function (key) {
        if ($scope.dedicationForm[key]) {
          $scope.dedicationForm[key].$validate();
        }
      });
    } else {
      $scope.MODEL.dedication = null;
      $scope.FORM.$removeControl($scope.dedicationForm);
    }
  });

  $scope.applyZipCodeValidation = function (value) {
    var isZipValid = $scope.helpers.isZipValid(value);
    var form = document.getElementById('donation-page_checkout_dedication-form');

    // Get the element named postal_code_label
    var label = form ? form.querySelector('label[name="postal_code_label"]') : null;

    // Get the element named postal_code
    var input = form ? form.querySelector('input[name="postal_code"]') : null;

    // Get the element named postal_code_error
    var error = form ? form.querySelector('span[name="postal_code_error"]') : null;

    if (!label || !input || !error) {
      return;
    }

    $timeout(function () {
      if (isZipValid) {
        label.classList.remove('sc-form-error');

        input.classList.remove('sc-form-error');
        input.setAttribute('aria-invalid', false);

        error.classList.remove('sc-form-error');
      } else {
        label.classList.add('sc-form-error');

        input.classList.add('sc-form-error');
        input.setAttribute('aria-invalid', true);

        error.classList.add('sc-form-error');
      }
    });
  };

  $scope.$watch('dedicationModel.postal_code', function (newVal) {
    $scope.applyZipCodeValidation(newVal);
  });

  // Watch for the country to change
  $scope.$watch('dedicationModel.country', function () {
    $scope.applyZipCodeValidation($scope.dedicationModel.postal_code);
  });
}
})();