(function(){
'use strict';

scImpactBlockSettings.$inject = ["scFroalaOptions", "scCampaignsTemplateService"];
angular.module('classy').directive('scImpactBlockSettings', scImpactBlockSettings);

function scImpactBlockSettings(scFroalaOptions, scCampaignsTemplateService) {
  return {
    scope: true,
    templateUrl: 'global/objects/scBlock/types/impact/settings',
    controller: ['$scope', function ($scope) {
      $scope.locks = scCampaignsTemplateService.getItemLocks('impact');
      $scope.froalaOptions = scFroalaOptions({ type: 'bare-headline' });

      $scope.headerOptions = scFroalaOptions({ type: 'inline-header' }, { ariaLabel: 'Headline' });

      $scope.showHeadline = !$scope.block.hideHeadline;

      $scope.$watch('showHeadline', function (newVal) {
        $scope.block.hideHeadline = !newVal;
      });
    }],

    link: function link(scope) {
      scope.bgMediaManagerOptions = {
        title: 'Select an Impact Background',
        image: {
          guide: {
            title: 'Impact Background',
            message: "You're not tied to a color. Add a background image.",
            size: '1280 x 720 pixels'
          }
        }
      };
    }
  };
}
})();