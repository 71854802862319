(function(){
'use strict';

/**
 * @kind directive
 * @name scScrollToTop
 *
 * @description
 * Scrolls to top.
 */

angular.module('classy').directive('scScrollToTop', scScrollToTop);

function scScrollToTop() {
  return {
    link: function link(scope, element) {
      var isPublic = scope.$parent.public;
      element.on('click', function () {
        // TODO: Work in animations and stuff.
        if (isPublic) {
          $('html, body').animate({
            scrollTop: 0
          }, 400);
        }
      });
    }
  };
}
})();