(function(){
'use strict';

loadYoutubeAPIFactory.$inject = ["$window", "$q"];
angular.module('classy').service('loadYoutubeAPI', loadYoutubeAPIFactory);

function loadYoutubeAPIFactory($window, $q) {
  function loadYoutubeAPI() {
    var apiLoaded = $q.defer();

    $window.onYouTubeIframeAPIReady = function () {
      apiLoaded.resolve('success');
    };

    var tag = document.createElement('script');
    tag.src = 'https://www.youtube.com/iframe_api';
    tag.async = 'async';
    tag.defer = 'defer';
    var firstScriptTag = document.getElementsByTagName('script')[0];
    firstScriptTag.parentNode.insertBefore(tag, firstScriptTag);

    return apiLoaded.promise;
  }

  return loadYoutubeAPI;
}
})();