(function(){
'use strict';

/**
 * Creates a readable description for a feed item.
 * @callback scFeedItemText
 * @param {FeedItem} - The feed item
 * @returns {string} - The readable description
 */
scFeedItemText.$inject = ["$state", "$filter", "scOrganizationsService", "scFeedItemAmount", "scThemesService", "ticketTerms"];
angular.module('classy').factory('scFeedItemText', scFeedItemText);

/**
 * @private
 * @param {$log} $log
 * @returns {method}
 */
function scFeedItemText($state, $filter, scOrganizationsService, scFeedItemAmount, scThemesService, ticketTerms) {
  return getText;

  /**
   * @param {FeedItem} feedItem
   * @returns {string}
   */
  function getText(feedItem) {
    var text = '';
    var template = '';
    var entity = '';
    feedItem.hideActivity = false;
    var lType = _.get(feedItem, 'linkable_type', null);
    var channelName = lType ? _.capitalize(_.get(lType.split('_'), '[2]', '')) : null;
    var hideAmount = feedItem.linkable && feedItem.linkable.hide_amount;
    var donorCoveredFee = feedItem.linkable && feedItem.linkable.is_donor_covered_fee;
    if (feedItem.agent_type === 'business') {
      /* Check if it is a matching donation */
      text = 'matched the previous donation' + (!hideAmount ? ' of ' + scFeedItemAmount(feedItem) : '');
    } else if ((lType === 'donation' || lType === 'offline_donation') && feedItem.type === 'creation') {
      var additionalInformation = '';
      if ($state.current.name.indexOf('team') > -1 && !_.isEmpty(feedItem.feedable) && feedItem.feedable.alias) {
        var feedableId = '/fundraiser/' + feedItem.feedable.id;
        additionalInformation = 'to <a href="' + feedableId + '">' + feedItem.feedable.alias + '</a> ';
      }

      /* Check if it is a online/offline donation or page creation */
      if (feedItem.member_name && feedItem.agent_name) {
        text = 'donated ' + scFeedItemAmount(feedItem, hideAmount) + ' ' + additionalInformation;
        if (donorCoveredFee) {
          text += 'and generously covered the transaction fee';
        }
      } else {
        var feedAmount = scFeedItemAmount(feedItem, hideAmount);

        if (hideAmount) {
          text = 'An anonymous donation was made ' + additionalInformation;
        } else if (donorCoveredFee) {
          text = feedAmount + ' was anonymously donated ' + additionalInformation + 'and fees were generously covered';
        } else {
          text = feedAmount + ' was donated anonymously ' + additionalInformation;
        }
      }
    } else if (feedItem.type === 'creation' && lType === 'registration') {
      /* Check for purchasing a registration */
      var ticketTermSelection = scThemesService.active ? scThemesService.active.getTicketTerm() : 'registrations';
      var ticketTerm = ticketTerms[ticketTermSelection].singular;
      var ticketAmount = feedItem.linkable_value ? parseFloat(feedItem.linkable_value.split(' ')[0].replace(/[$,]/g, '')) : null;
      if (ticketTerm === 'entry') {
        text = (ticketAmount === 0 ? 'reserved' : 'purchased') + ' an entry';
      } else {
        text = (ticketAmount === 0 ? 'reserved' : 'purchased') + ' a ' + ticketTerm;
      }
    } else if ((lType === 'good' || lType === 'auction') && feedItem.type === 'creation') {
      var isAnonymous = feedItem.linkable && feedItem.linkable.is_anonymous;
      var itemType = lType === 'auction' ? 'auction item' : 'item';
      if (isAnonymous) {
        text = 'A ' + scFeedItemAmount(feedItem) + ' ' + itemType + ' was purchased anonymously';
      } else {
        text = 'purchased a ' + scFeedItemAmount(feedItem) + ' ' + itemType;
      }
    } else if (feedItem.type === 'creation' && feedItem.member_name === 'undefined undefined') {
      /* Check if feedItem.type is page creation but member name is undefined */
      entity = getEntity(feedItem.feedable_type);
      template = 'The ? was created';
      text = template.replace('?', entity);
      feedItem.showName = false;
    } else if (lType === 'commitment_charge') {
      // We show admin triggered commitment charges as donations from charged user
      var chargeAmount = parseFloat(feedItem.linkable_value.split(' ')[0]);
      var chargedAmountFormatted = $filter('scFormatCurrency')(chargeAmount, scOrganizationsService.active.current.currency, 2);
      text = 'donated ' + chargedAmountFormatted;
    } else if (feedItem.type === 'creation' && feedItem.linkable_type === 'soft_credit' && feedItem.feedable_type === 'fundraising_page') {
      feedItem.showName = false;
      var entityText = '';
      if ($state.current.name.indexOf('frs.fundraiser') > -1) {
        entityText += 'the';
      } else {
        entityText += '<a href="/fundraiser/' + feedItem.feedable_id + '">' + (feedItem.feedable_value + '\'s') + '</a>';
      }
      var registrationMemo = _.get(feedItem, 'linkable.memo', '').indexOf('from campaign');
      if (registrationMemo > -1) {
        text = 'A ' + scFeedItemAmount(feedItem) + ' registration was credited to ' + entityText + ' page';
      } else {
        text = scFeedItemAmount(feedItem) + ' was credited to ' + entityText + ' page by the team';
      }
    } else if (channelName) {
      feedItem.showName = false;
      if (channelName === 'Doublethedonation') {
        if (feedItem.member_name || feedItem.agent_name) text = (feedItem.member_name || feedItem.agent_name) + ' matched a donation of ' + scFeedItemAmount(feedItem);else feedItem.hideActivity = true;
      } else text = scFeedItemAmount(feedItem) + ' was donated on ' + (channelName.match('^[aieouAIEOU].*') ? 'an' : 'a') + ' ' + channelName + ' Fundraiser';
    } else {
      template = getTemplate(feedItem.type);
      entity = getEntity(feedItem.feedable_type);
      text = template ? template.replace('?', entity) : '';
    }

    return text;
  }

  /**
   * Creates a template for the feed item action, uses a ? to represent the position
   * the entity should be placed.
   * @param {string} type - Type of the feed item
   * @returns {string} - the template
   */
  function getTemplate(type) {
    switch (type) {
      case 'onboarding':
        return 'was onboarded for the ?';
      case 'creation':
        return 'created the ?';
      case 'update':
        return 'updated their ?';
      case 'association':
        return 'joined the ?';
      default:
        return undefined;
    }
  }

  /**
   * Returns readable format of entity name
   * @param {string} type - Feedable type
   * @returns {string}
   */
  function getEntity(type) {
    switch (type) {
      case 'fundraising_page':
        return 'page';
      case 'fundraising_team':
        return 'team';
      default:
        return type;
    }
  }
}
})();