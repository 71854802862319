(function(){
'use strict';

/**
 * @name encodeURI
 * @kind filter
 */

angular.module('classy').filter('encodeURI', encodeURIFilter);

function encodeURIFilter() {
  return function (input) {
    return encodeURI(input);
  };
}
})();