(function(){
'use strict';

decorateDirectiveFactory.$inject = ["$templateCache", "$compile", "$interpolate", "$parse"];
function _toConsumableArray(arr) { if (Array.isArray(arr)) { for (var i = 0, arr2 = Array(arr.length); i < arr.length; i++) { arr2[i] = arr[i]; } return arr2; } else { return Array.from(arr); } }

/**
 * @name decorateDirective
 * @kind factory
 *
 * @description
 * A factory that transforms and optionally precompiles directive definitions.
 *
 * @param {object} defObj The directive definition to transform.
 * @param {object} config A configuration object.
 *
 * The config object is mostly a clone of the directive definition. Primitive
 * properties defined in the config (even if defined as falsy) will overwrite
 * properties of the directive definition. Controller and link functions will
 * be "merged", in the sense that they will be called in sequence in the
 * context of a new function.
 *
 * The config object also accepts a "precompile" property. If defined, the
 * directive's template will be interpolated against the provided context, then
 * compiled normally against the local scope. The precompile property should be
 * an object with the following properties:
 *
 * | Property | Type | Description |
 * |--|--|--|
 * | context | string|function | If a string, this is the name of an attribute to
 *    $parse to get the precompilation context. If a function, it will be called in the
 *    controller with $scope, $element, and $attrs as arguments, and should return the context. |
 * | contextName | string | The name on scope where the compilation context should be stored.
 *    Defaults to 'options'. |
 * | templateUrl | string | The templateURL to compile. It's best to define the templateUrl
 *    here rather than in the directive definition, so it is not erroneously compiled once
 *    before interpolation. If not defined here, falls back to (in order): defObj.templateUrl,
 *    defObj.template, element.html(). |
 *
 * The context will be available on scope in the controller and both link
 * functions, and will have two methods, bind() and id(), on its prototype.
 * Precompilation itself occurs in the beginning of the postLink.
 *
 * # bind()
 *
 * bind() is a utility that allows the user to use {{}} interpolation in the
 * final template, even when using precompilation. A template expression such
 * as:
 *
 * {{ bind(model + '.asset.sizes.full') }}
 *
 * will, after precompilation, and assuming options.model is 'myImage', read as:
 *
 * {{ myImage.asset.sizes.full }}
 *
 * thus binding to the final scope of the directive. If you pass in true as the
 * second option of bind(), the precompiled expression will be a one-time bind:
 *
 * {{:: myImage.asset.sizes.full }}
 *
 * # id()
 *
 * id() is a utility that will generate a unique ID in the precompilation step.
 * This can be handy when generating classes directive using decorateDirective.
 * id() accepts one required argument, which is an element identifier, so that
 * you can look up the unique ID for your element on scope.__ids.
 *
 * For example, a template expression such as:
 *
 * <button id="{{ id('myButton') }}"></button>
 *
 * will, after precompilation, be converted to a unique id prefixed with sc-id-:
 *
 * <button id="sc-id-1783"></button>
 *
 * and the value of scope.__ids.myButton will be 'sc-id-1783'.
 */
angular.module('classy').factory('decorateDirective', decorateDirectiveFactory);

function decorateDirectiveFactory($templateCache, $compile, $interpolate, $parse) {
  function decorateDirective() {
    var defObj = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
    var config = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : {};

    var newObj = {};

    adaptPrimitives(defObj, newObj, config);

    adaptScope(defObj, newObj, config);

    adaptCtrl(defObj, newObj, config);

    adaptLink(defObj, newObj, config);

    return newObj;
  }

  /**
   * @name adaptPrimitives
   * @parent decorateDirective
   *
   * @description
   * Overwrites primitive properties of the definition object if requested.
   * These include:
   *
   * * priority
   * * replace
   * * template
   * * templateUrl
   * * transclude
   * * restrict
   * * templateNamespace
   * * controllerAs
   * * bindToController
   */
  function adaptPrimitives(defObj, newObj, config) {
    _.forEach(['priority', 'replace', 'template', 'templateUrl', 'transclude', 'restrict', 'templateNamespace', 'controllerAs', 'bindToController'], function (property) {
      newObj[property] = _.isUndefined(config[property]) ? defObj[property] : config[property];
    });
  }

  /**
   * @name adaptScope
   * @parent decorateDirective
   *
   * @description
   * Adapts the scope property of the directive definition object.
   *
   * If both the config and definition define an object for scope (i.e. define
   * an isolate scope), the objects will be merged. Otherwise, the scope
   * property will be overwritten with the config value.
   * * bindToController
   */
  function adaptScope(defObj, newObj, config) {
    if (_.isPlainObject(defObj.scope) && _.isPlainObject(config.scope)) {
      newObj.scope = _.merge(defObj.scope, config.scope);
    } else {
      newObj.scope = config.scope;
    }
  }

  /**
   * @name adaptCtrl
   * @parent decorateDirective
   *
   * @description
   * Merges the directive definition controller with the function provided
   * by config.
   *
   * The config's function is called first; the definition's is called second.
   *
   * If precompilation is selected, places the precompilation context on
   * scope.
   *
   * Important: The directive definition controller, if defined, must use
   * explicit DI notation (be wrapped in an array).
   */
  function adaptCtrl(defObj, newObj, config) {
    var defCtrlArr = defObj.controller || false,
        newCtrlArr = [];

    var confCtrlFn = void 0,
        defCtrlFn = void 0,
        dependencies = void 0,
        scopeIndex = void 0,
        elementIndex = void 0,
        attrsIndex = void 0;

    if (!_.isUndefined(config.controller)) {
      var confCtrlFnIndex = config.controller.indexOf(config.controller.filter(function (item) {
        return typeof item === 'function';
      })[0]);

      confCtrlFn = config.controller[confCtrlFnIndex];
    }

    /* -------------------------------------------------------------------- *
     * Dependency management
     *
     * Ensure that $scope, $element, and $attrs are injected, and get their
     * indices in the argument list.
     * -------------------------------------------------------------------- */

    if (defCtrlArr) {
      defCtrlFn = defCtrlArr[defCtrlArr.length - 1];
      dependencies = defCtrlArr.slice(0, defCtrlArr.length - 1);
      scopeIndex = dependencies.indexOf('$scope');
      if (scopeIndex === -1) {
        dependencies.push('$scope');
        scopeIndex = dependencies.length - 1;
      }

      elementIndex = dependencies.indexOf('$element');
      if (elementIndex === -1) {
        dependencies.push('$element');
        elementIndex = dependencies.length - 1;
      }

      attrsIndex = dependencies.indexOf('$attrs');
      if (attrsIndex === -1) {
        dependencies.push('$attrs');
        attrsIndex = dependencies.length - 1;
      }
    } else {
      dependencies = ['$scope', '$element', '$attrs'];
      scopeIndex = 0;
      elementIndex = 1;
      attrsIndex = 2;
    }

    _.forEach(dependencies, function (dep) {
      newCtrlArr.push(dep);
    });

    function newCtrlFn() {
      for (var _len = arguments.length, args = Array(_len), _key = 0; _key < _len; _key++) {
        args[_key] = arguments[_key];
      }

      var $scope = args[scopeIndex];
      var $element = args[elementIndex];
      var $attrs = args[attrsIndex];

      /* ------------------------------------------------------------------ *
       * Precompilation
       * ------------------------------------------------------------------ */
      if (config.precompile) {
        var context = void 0;
        var contextName = config.precompile.contextName || 'options',
            contextProto = {
          bind: function bind(property, oneTime) {
            return '{{' + (oneTime ? '::' : '') + property + '}}';
          },
          id: function id(identifier) {
            /* eslint-disable no-underscore-dangle */
            if (!this._ids) {
              this._ids = {};
            }

            var newId = this._ids[identifier] || 'sc-id-' + _.uniqueId();
            this._ids[identifier] = newId;
            /* eslint-enable no-underscore-dangle */
            return newId;
          }
        };

        if (_.isString(config.precompile.context)) {
          context = $parse($attrs[config.precompile.context])($scope);
          $scope[contextName] = _.create(contextProto, context);
        } else if (_.isFunction(config.precompile.context)) {
          context = config.precompile.context($scope, $element, $attrs);
          $scope[contextName] = _.create(contextProto, context);
        } else {
          config.precompile = false;
        }
      }

      /* ------------------------------------------------------------------ *
       * Call config controller if defined
       * ------------------------------------------------------------------ */

      if (confCtrlFn) {
        confCtrlFn.call(this, $scope, $element, $attrs);
      }

      /* ------------------------------------------------------------------ *
       * Call definition controller if defined
       * ------------------------------------------------------------------ */

      if (defCtrlFn) {
        defCtrlFn.apply(this, args);
      }
    }

    newCtrlArr.push(newCtrlFn);

    newObj.controller = newCtrlArr;
  }

  /**
   * @name adaptLink
   * @parent decorateDirective
   *
   * @description
   * Merges the directive definition link functions with the functions
   * provided by config.
   *
   * The config's functions are called first; the definition's are called
   * second.
   *
   * If precompilation is selected, precompiles the template against the
   * previously (in controller) defined context.
   */
  function adaptLink(defObj, newObj, config) {
    var confLink = config.link,
        defLink = defObj.link;

    var confPre = void 0,
        confPost = void 0,
        defPre = void 0,
        defPost = void 0;

    if (_.isFunction(confLink)) {
      confPre = null;
      confPost = confLink;
    } else if (_.isPlainObject(confLink)) {
      confPre = _.get(confLink, 'pre', null);
      confPost = _.get(confLink, 'post', null);
    }

    if (_.isFunction(defLink)) {
      defPre = null;
      defPost = defLink;
    } else if (_.isPlainObject(defLink)) {
      defPre = _.get(defLink, 'pre', null);
      defPost = _.get(defLink, 'post', null);
    }

    var newLink = {
      pre: function pre() {
        for (var _len2 = arguments.length, args = Array(_len2), _key2 = 0; _key2 < _len2; _key2++) {
          args[_key2] = arguments[_key2];
        }

        /* ---------------------------------------------------------------- *
         * Call config and definition preLinks if defined
         * ---------------------------------------------------------------- */

        if (confPre) {
          confPre.apply(this, args);
        }

        if (defPre) {
          defPre.apply(this, args);
        }
      },
      post: function post(scope, element) {
        /* ---------------------------------------------------------------- *
         * Precompilation
         * ---------------------------------------------------------------- */
        if (config.precompile) {
          var contextName = config.precompile.contextName || 'options';

          // Get text version of template
          var raw = void 0;
          if (config.precompile.templateUrl) {
            raw = $templateCache.get(config.precompile.templateUrl);
          } else if (newObj.templateUrl) {
            raw = $templateCache.get(newObj.templateUrl);
          } else if (newObj.template) {
            raw = newObj.template;
          } else {
            raw = element.html();
          }

          // Precompile
          var tpl = $interpolate(raw)(scope[contextName]);

          // Compile
          var compiled = $compile(tpl)(scope);

          element.html(compiled);
        }

        /* ---------------------------------------------------------------- *
         * Call config and definition postLinks if defined
         * ---------------------------------------------------------------- */

        for (var _len3 = arguments.length, args = Array(_len3 > 2 ? _len3 - 2 : 0), _key3 = 2; _key3 < _len3; _key3++) {
          args[_key3 - 2] = arguments[_key3];
        }

        if (confPost) {
          confPost.apply(this, [scope, element].concat(_toConsumableArray(args)));
        }

        if (defPost) {
          defPost.apply(this, [scope, element].concat(_toConsumableArray(args)));
        }
      }
    };

    newObj.link = newLink;
  }

  return decorateDirective;
}
})();