(function(){
'use strict';

/**
 * @kind service
 * @name scBlockBackground
 *
 * @description
 * Creates a style object with an rgba()
 * background color when passed a hex
 * and opacity.
 */
angular.module('classy').factory('scBlockStyles', scBlockStylesFactory);

function scBlockStylesFactory() {
  return function scBlockStyles(theme, pageType) {
    return {
      backgroundColor: theme.styles.background.color,
      backgroundImage: theme.pages[pageType].background.image ? 'url(' + theme.pages[pageType].background.image.src + ')' : 'none'
    };
  };
}
})();