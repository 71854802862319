(function(){
'use strict';

scProgressCircleBlock.$inject = ["$filter", "registrationEntityFlow", "scCartService", "scFroalaOptions", "scBlock", "scCampaignsService", "scThemesService", "scButtonStyleUpdate", "registrationNavigator", "scProgressDataFactory", "accessibilityService", "countryBlockService"];
angular.module('classy').directive('scProgressCircleBlock', scProgressCircleBlock);

function scProgressCircleBlock($filter, registrationEntityFlow, scCartService, scFroalaOptions, scBlock, scCampaignsService, scThemesService, scButtonStyleUpdate, registrationNavigator, scProgressDataFactory, accessibilityService, countryBlockService) {
  return scBlock({
    link: function link(scope) {
      var campaign = scCampaignsService.active.current;

      scope.theme = scThemesService.active;

      scope.handleRegistrationSwitch = function (event) {
        countryBlockService.checkOrganizationRestrictions(function () {
          return scope.registrationSwitch(event);
        });
      };

      scope.registrationSwitch = function (event) {
        if (event && event.type !== 'click' && !accessibilityService.isValidKeyBoardEvent(event)) return;

        // capture event trigger to refocus on after modal is closed
        var eventTarget = _.get(event, 'currentTarget', null);

        if (scCampaignsService.active.IS_TICKETED && _.get(scCartService, 'active.isReserved', false)) {
          registrationNavigator.goToLastState();
        } else {
          registrationEntityFlow(eventTarget);
        }
      };

      scope.froalaOptionsPreview = scFroalaOptions({
        type: 'edit-inline'
      }, {
        scImgAssetFroalaUpload: true,
        charCounterCount: true,
        charCounterMax: 180,
        editorClass: 'no-counter',
        ariaLabel: 'Progress Circle Headline'
      });

      scope.froalaOptionsContent = scFroalaOptions({
        type: 'edit-inline-about'
      }, {
        scImgAssetFroalaUpload: true,
        ariaLabel: 'Progress Circle Sub Heading'
      });

      scope.froalaOptionsColors = scFroalaOptions({
        type: 'inline-just-colors'
      }, { ariaLabel: 'Progress Circle Register Button' });

      scope.donateNowFroalaOptionsColors = scFroalaOptions({
        type: 'inline-just-colors'
      }, { ariaLabel: 'Progress Circle Donate Now button' });

      scope.progressData = scProgressDataFactory.getCampaignProgress(campaign);

      if (scope.$parent.public == false && !scope.progressData.percentToGoal) {
        // If we're just in demo mode, use fake data without actually overwriting the campaign's
        // data. If you write to the campaign's data, then going back to the admin overview home
        // page will still have the demo data, and it'll look like the campaign has already raised
        // 70% of their goal. Instead of writing to global state, we'll just pass in faked data to
        // the helper.
        scope.progressData = scProgressDataFactory.getCampaignProgress({
          goal: campaign.goal,
          overview: { percent_to_goal: 70 }
        });
      }

      scope.shortenedGrossAmount = '$' + $filter('friendlyShortNumber')(scope.progressData.totalRaised);

      scope.amountRaisedAsPercent = Math.round(scope.progressData.percentToGoal * 100) + '%';

      scope.regBtnStyle = function () {
        var styles = scope.block.registerButtonStyle;
        styles['background-color'] = scope.block.registerButtonColor || scope.theme.styles.primaryColor;
        return styles;
      };

      scope.donateButtonUnshadedStyle = function () {
        var style = {};
        _.merge(style, scope.block.donateButtonStyle);

        var colorOrTheme = scope.block.donateButtonColor || scope.theme.current.styles.primaryColor;
        scope.block.donateButtonStyle.color = colorOrTheme;

        return scButtonStyleUpdate(style, colorOrTheme);
      };

      scope.donateButtonShadedStyle = function () {
        var style = {};
        _.merge(style, scope.block.donateButtonStyle);

        return scButtonStyleUpdate(style, scope.block.donateButtonColor || $filter('shadeBlendColor')(0.1, scope.theme.current.styles.primaryColor));
      };

      scope.rwfCampaignInactive = scCampaignsService.active.isInactive();
      scope.allowFundraisingPages = scCampaignsService.active.current.allow_fundraising_pages;

      scope.registerButtonStyle = function () {
        if (scope.rwfCampaignInactive) {
          return {};
        }
        var style = {};
        _.merge(style, scope.block.registerButtonStyle);

        var colorOrTheme = scope.block.registerButtonColor || scope.theme.current.styles.primaryColor;

        scope.block.registerButtonStyle.color = colorOrTheme;

        return scButtonStyleUpdate(style, colorOrTheme);
      };

      scope.progressCircleColor = function () {
        return scope.block.progressBarColor || scope.theme.current.styles.primaryColor;
      };
    }
  });
}
})();