(function(){
'use strict';

/**
 * @kind factory
 * @name scBlockTemplateBreakpoint
 *
 * @description
 * Uses the current responsive breakpoint as
 * well as block settings to determine which
 * template to render for a block.
 */
scBlockTemplateBreakpoint.$inject = ["scResponsive"];
angular.module('classy').factory('scBlockTemplateBreakpoint', scBlockTemplateBreakpoint);

function scBlockTemplateBreakpoint(scResponsive) {
  /**
   * @kind function
   * @name getCurrentBreakpoint
   *
   * @parent scBlockTemplateBreakpoint
   *
   * @description
   * Finds where the given width falls within
   * a range of responsive breakpoints.
   *
   * @param  {integer} width The width of the screen.
   * @return {string} The name of the breakpoint range the width falls into.
   */
  return function getCurrentBreakpoint(responsiveSettings) {
    if (!responsiveSettings) {
      return undefined;
    }

    var point = _.filter(scResponsive, function (breakpoint) {
      var breakpointName = breakpoint.name;

      if (Object.prototype.hasOwnProperty.call(responsiveSettings, breakpointName)) {
        var max = breakpoint.width;

        return Modernizr.mq('(max-width: ' + max + 'px)');
      }

      return undefined;
    })[0];

    return point && point.name || false;
  };
}
})();