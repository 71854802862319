(function(){
'use strict';

scProgressDataFactory.$inject = ["scCurrencyService"];
angular.module('classy').factory('scProgressDataFactory', scProgressDataFactory);

function scProgressDataFactory(scCurrencyService) {
  // Helper function to pull out commitment details. Depending on where the data comes from (graphql vs api), the
  // commitment details may be flattened or under a commitment object.
  function getEntityCommitment(entity) {
    if (!entity.commitment && !entity.commitment_deadline) {
      return null;
    }
    if (entity.commitment) {
      return entity.commitment;
    }
    return {
      value: entity.commitment_value,
      deadline: entity.commitment_deadline
    };
  }

  // All entities that aren't tribute pages should have a raw_goal (m11y or not) that is in the same
  // currency as their raw_currency_code
  function getEntityProgress(entity) {
    var percentToGoal = _.get(entity, 'percent_to_goal', 0);

    // APIv2 returns 'raw_goal', but ClassyQL returns 'goal_raw'. Account for both
    var rawGoalString = _.get(entity, 'raw_goal', _.get(entity, 'goal_raw', '0'));
    var rawGoal = parseInt(rawGoalString, 10);

    // for pages without goals on campaigns with donor covered fees (DCF), we can use the progress bar
    // amount to display the amount raised in the raw currency the fundraising page was created with
    var progressBarAmount = _.get(entity, 'progress_bar_amount', null);

    // if a raw goal isn't set (aka 0), we need to use normalized attributes for fundraising pages
    var totalRaisedNormalized = _.get(entity, 'total_raised', 0);

    // normalized_currency_code exists on fundraising pages that come from search. If we only have data from APIv2, then currency_code represents the normalized currency code.
    var currencyCodeNormalized = _.get(entity, 'normalized_currency_code', _.get(entity, 'currency_code', 'USD'));

    var totalRaised = void 0;
    var currency = void 0;
    if (rawGoal) {
      totalRaised = percentToGoal * rawGoal / 100;
      currency = scCurrencyService.getEntityCurrency(entity);
    } else if (progressBarAmount) {
      totalRaised = progressBarAmount;
      currency = scCurrencyService.getEntityCurrency(entity);
    } else {
      totalRaised = totalRaisedNormalized;
      currency = currencyCodeNormalized;
    }

    return {
      percentToGoal: percentToGoal / 100,
      totalRaised: totalRaised,
      goal: rawGoal,
      currency: currency,
      commitment: getEntityCommitment(entity)
    };
  }

  function getCampaignProgress(campaign) {
    var _campaign$raw_goal = campaign.raw_goal,
        goalString = _campaign$raw_goal === undefined ? '0' : _campaign$raw_goal;

    var goal = parseInt(goalString, 10);

    var _ref = campaign.overview || {},
        percentToGoal = _ref.percent_to_goal;

    return {
      percentToGoal: (percentToGoal || 0) / 100,
      totalRaised: percentToGoal * goal / 100,
      goal: goal,
      currency: scCurrencyService.getEntityCurrency(campaign)
    };
  }

  return { getEntityProgress: getEntityProgress, getCampaignProgress: getCampaignProgress };
}
})();