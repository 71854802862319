(function(){
'use strict';

scCrowdfundingBlock.$inject = ["$q", "$state", "$rootScope", "$filter", "mySqlDateTime", "scFroalaOptions", "scBlock", "scCampaignsService", "scCampaignsTemplateService", "SlideshowAPI", "scVideoSvc", "scSwitchFlowPickerService", "registrationEntityFlow", "registrationNavigator", "scCartService", "scThemesService", "scProgressDataFactory", "accessibilityService", "countryBlockService"];
angular.module('classy').directive('scCrowdfundingBlock', scCrowdfundingBlock);

function scCrowdfundingBlock($q, $state, $rootScope, $filter, mySqlDateTime, scFroalaOptions, scBlock, scCampaignsService, scCampaignsTemplateService, SlideshowAPI, scVideoSvc, scSwitchFlowPickerService, registrationEntityFlow, registrationNavigator, scCartService, scThemesService, scProgressDataFactory, accessibilityService, countryBlockService) {
  function getDaysLeft(endedAt) {
    if (!endedAt) {
      return null;
    }

    var diff = Date.parse(endedAt).getElapsed() * -1,
        days = diff / 1000 / 60 / 60 / 24,
        daysRounded = Math.floor(days),
        daysAbsolute = Math.max(0, daysRounded);

    return daysAbsolute;
  }

  return scBlock({
    responsiveSettings: {
      tablet: 'mobile-ls'
    },
    controller: ['$scope', function ($scope) {
      $scope.$watch('block.enableProgressMetrics', function (newVal, oldVal) {
        if (newVal != oldVal) {
          $scope.block.enablePercentOfGoal = newVal;
          $scope.block.enableNumSupporters = newVal;
          $scope.block.enableDaysLeft = newVal;
        }
      });

      var campaignTz = _.get($scope, 'campaign.timezone_identifier', false);
      var timezone = campaignTz || moment.tz.guess();
      var tzDate = mySqlDateTime.convertUtcToTimezone($scope.campaign.started_at, timezone);
      var locks = scCampaignsTemplateService.getLocks({
        crowdfunding: { dateAndTime: { locked: false }, headlineColor: { locked: false } }
      });

      $scope.headlineColor = locks.crowdfunding.headlineColor;

      var dateAndTime = locks.crowdfunding.dateAndTime;

      $scope.prettyStartedAt = moment(tzDate).format('dddd MMMM Do YYYY [\u2022] h:mm a');

      if (!$scope.block.dateAndTime && !dateAndTime.locked) {
        $scope.block.dateAndTime = $scope.prettyStartedAt;
      }

      $scope.displayRegisterBtn = scCampaignsService.active.current.type === 'ticketed' || scCampaignsService.active.current.allow_fundraising_pages;

      $scope.disableJoinButton = function () {
        if (!_.includes(['reg_w_fund', 'p2p'], $scope.campaign.type)) {
          return false;
        }
        return scCampaignsService.active.isInactive();
      };

      $scope.prettyVenue = '';
      if ($scope.campaign.venue) {
        $scope.prettyVenue += $scope.campaign.venue;
      }

      if ($scope.campaign.venue && ($scope.campaign.address1 || $scope.campaign.city || $scope.campaign.state)) {
        $scope.prettyVenue += ', ';
      }

      if ($scope.campaign.address1) {
        $scope.prettyVenue += $scope.campaign.address1 + ', ';
      }

      if ($scope.campaign.city) {
        $scope.prettyVenue += $scope.campaign.city + ', ';
      }

      if ($scope.campaign.state) {
        $scope.prettyVenue += $scope.campaign.state;
      }

      if (!$scope.block.venueLocation) {
        $scope.block.venueLocation = $scope.prettyVenue;
      }

      $scope.layoutClass = {};

      $scope.ticketedEventAlignment = function () {
        switch ($scope.block.layoutPosition) {
          case 'center':
            $scope.layoutClass.row = 'rwf-hero__row';
            $scope.layoutClass.buttons = 'rwf-hero__buttons';
            break;
          case 'left':
            $scope.layoutClass.row = 'rwf-hero__row--left';
            $scope.layoutClass.buttons = 'rwf-hero__buttons--left';
            break;

          default:
          // do nothing
        }
      };

      if (scCampaignsService.active.IS_TICKETED) {
        $scope.$watch('block.layoutPosition', function () {
          $scope.ticketedEventAlignment();
        });
      }

      // Set the join button text
      if (scCampaignsService.active.IS_TICKETED) {
        $scope.ticketTermCta = scThemesService.active.getTicketTerm('cta');
        $scope.block.joinButtonText = $scope.block.joinButtonText || $scope.ticketTermCta;
      } else {
        $scope.block.joinButtonText = $scope.block.joinButtonText || 'Become a Fundraiser';
      }

      // If the user has no button text for 'donate' button, fall back to `Donate`
      $scope.block.donateButtonText = $scope.block.donateButtonText || 'Donate';

      $scope.getBGStyle = function () {
        var style = void 0;
        style = { 'background-color': $scope.theme.styles.background.color };

        if (_.get($scope, 'block.background.color', false)) {
          style = { 'background-color': $scope.block.background.color };
        }

        return style;
      };

      var initialJoinButtonColor = angular.copy($scope.block.joinButtonColor);
      if ((_.isNull(initialJoinButtonColor) || initialJoinButtonColor === $scope.theme.styles.primaryColor) && $scope.campaign.type != 'reg_w_fund') {
        $scope.$watch('theme.styles.primaryColor', function (newVal) {
          $scope.block.joinButtonColor = newVal;
        });
      }

      $scope.donateButtonStyle = function () {
        var style = {};
        _.merge(style, $scope.block.donateButtonStyle);

        var color = $scope.block.donateButtonColor || $scope.theme.styles.donateButtonColor || $scope.theme.styles.primaryColor;
        if (!_.endsWith($scope.block.donateButtonStyle.preset, 'Wire')) {
          style.color = 'white';
          style['background-color'] = color;
          style['border-color'] = color;
          style.border = '3px solid ' + color;
        } else {
          style.color = color;
          style['border-color'] = color;
        }

        return style;
      };

      $scope.rwfShadeButtonStyle = function () {
        var style = {};
        _.merge(style, $scope.block.donateButtonStyle);

        if ($scope.block.donateButtonColor == null) {
          var color = $scope.block.joinButtonColor || $scope.theme.styles.primaryColor;
          var colorOrTheme = $filter('shadeBlendColor')(0.1, color);
          $scope.block.donateButtonStyle.color = colorOrTheme;

          if (!_.endsWith($scope.block.donateButtonStyle.preset, 'Wire')) {
            style.color = 'white';
            style['background-color'] = colorOrTheme;
            style.border = '3px solid ' + colorOrTheme;
          } else {
            style.border = '3px solid ' + colorOrTheme;
          }
        } else {
          style = $scope.donateButtonStyle();
        }

        return style;
      };

      $scope.joinButtonStyle = function () {
        var style = {};
        _.merge(style, $scope.block.joinButtonStyle);

        var colorOrTheme = $scope.disableJoinButton() ? '#d2d2d2' : $scope.block.joinButtonColor || $scope.theme.styles.primaryColor;

        $scope.block.joinButtonStyle.color = colorOrTheme;
        if (!_.endsWith($scope.block.joinButtonStyle.preset, 'Wire')) {
          style.color = 'white';
          style['background-color'] = colorOrTheme;
          style.border = '3px solid ' + colorOrTheme;
        } else {
          style.border = '3px solid ' + colorOrTheme;
        }

        return style;
      };

      $scope.fundraiseSwitch = function () {
        if ($scope.demo) {
          $rootScope.SC.status.banner = {
            type: 'error',
            msg: 'Whoops, this is just a preview of your campaign page. Please publish your page first.'
          };
        } else {
          countryBlockService.checkOrganizationRestrictions(scSwitchFlowPickerService.fundraiseSwitch);
        }
      };

      $scope.handleRegistrationSwitch = function (event) {
        countryBlockService.checkOrganizationRestrictions(function () {
          return $scope.registrationSwitch(event);
        });
      };

      $scope.registrationSwitch = function (event) {
        // eslint-disable-line
        if (event && event.type !== 'click' && !accessibilityService.isValidKeyBoardEvent(event)) return;

        // capture event trigger to refocus on after modal is closed
        var eventTarget = _.get(event, 'currentTarget', null);

        // if RWF and cart already exists, take user back to cart
        if (scCampaignsService.active.IS_TICKETED && _.get(scCartService, 'active.isReserved', false)) {
          registrationNavigator.goToLastState();
        } else {
          registrationEntityFlow(eventTarget);
        }
      };
    }],

    link: function link(scope) {
      scope.froalaOptionsPreview = scFroalaOptions({
        type: 'edit-inline-header'
      }, {
        charCounterCount: true,
        charCounterMax: 180,
        editorClass: 'no-counter',
        ariaLabel: 'Hero Headline',
        toolbarButtons: ['bold', 'italic', 'underline', 'align', 'fontFamily', 'fontSize', scope.headlineColor.locked ? undefined : 'color', 'paragraphStyle'],
        toolbarButtonsMD: ['bold', 'italic', 'underline', 'align', 'fontFamily', 'fontSize', scope.headlineColor.locked ? undefined : 'color', 'paragraphStyle'],
        toolbarButtonsSM: ['bold', 'italic', 'underline', 'align', 'fontFamily', 'fontSize', scope.headlineColor.locked ? undefined : 'color', 'paragraphStyle'],
        toolbarButtonsXS: ['bold', 'italic', 'underline', 'align', 'fontFamily', 'fontSize', scope.headlineColor.locked ? undefined : 'color', 'paragraphStyle']
      });

      scope.registerFroalaOptionsColors = scFroalaOptions({
        type: 'inline-just-colors'
      }, { ariaLabel: 'Hero Register Button' });

      scope.donateNowFroalaOptionsColors = scFroalaOptions({
        type: 'inline-just-colors'
      }, { ariaLabel: 'Hero Donate Now button' });

      scope.daysLeft = getDaysLeft(scope.campaign.ended_at);
      scope.mobileSlideMedia = [];
      scope.supporters = _.get(scope.campaign, 'overview.donors_count', 0);

      var campaignProgress = scProgressDataFactory.getCampaignProgress(scope.campaign);
      scope.goal = campaignProgress.goal;
      scope.total = campaignProgress.totalRaised;
      scope.percentRaised = $filter('scPercent')(campaignProgress.percentToGoal, 0);

      // Slideshows
      scope.slideshowAPI = new SlideshowAPI();
      scope.mobileSlideshowAPI = new SlideshowAPI();
      var IMAGE_SLIDE = '\n        <div class=\'crowdfunding_slide-bg\' data-ng-style="{\n          \'position\': \'absolute\',\n          \'width\': \'100%\',\n          \'height\': \'100%\',\n          \'background-image\':\'url(\' + img.src + \')\',\n          \'background-size\':\'cover\',\n          \'background-repeat\': \'no-repeat\',\n          \'background-position\': \'center center\',\n          \'top\': \'0px\'\n        }"></div>\n      ';

      var COVER_SLIDE = '\n        <h1 class="crowdfunding_header"\n        data-sc-filler-text\n        data-parent-container="\'.slideshow_slide\'"\n        data-title-container="\'.crowdfunding_header-title\'"\n        data-ng-if="block.headline && !block.hideHeadline && !block.replaceHeadline"\n        data-ng-style="{\'color\':block.headlineColor}">\n          <span class="crowdfunding_header-title mobile"\n          data-ng-bind-html="block.headline | scTrustedHtml">\n          </span>\n        </h1>\n        <div class="crowdfunding_headline-image-container crowdfunding_display">\n          <img class="crowdfunding_headline-image" data-ng-if="block.replaceHeadline && block.headlineImage.image.src" ng-src="{{block.headlineImage.image.src}}">\n        </div>\n      ';

      var VIDEO_SLIDE = '\n        <div class="crowdfunding_featured-media-video"\n          data-ng-if="img"\n          data-thumbnail="thumbnail"\n          data-type="type"\n          data-url="{{ url }}"\n          data-sc-video="img.url">\n        </div>\n      ';

      function getVideoThumbnail(url) {
        var type = scVideoSvc.getType(url);
        var deferred = $q.defer();

        if (type == 'vimeo') {
          scVideoSvc.getVimeoThumbnail(url).then(function (data) {
            var thumbnail = _.get(data, 'data[0].thumbnail_large', false);
            scope.thumbnailUrl = thumbnail;
            deferred.resolve({ thumb: thumbnail, type: 'vimeo' });
          }, function () {
            deferred.resolve(null);
          });
        } else if (type == 'youtube') {
          var thumbnail = scVideoSvc.getYoutubeThumbnail(url);
          deferred.resolve({ thumb: thumbnail, type: 'youtube' });
        } else {
          deferred.resolve(null);
        }

        return deferred.promise;
      }

      function populateImageSlides() {
        if (!scope.block.useFeatured) {
          return $q.resolve([]);
        }

        return $q.all(scope.block.featuredImg.map(function (asset) {
          var slideScope = scope.$new();

          if (asset.type == 'video') {
            slideScope.img = asset;
            var url = asset.url;
            slideScope.img.title = url;

            return getVideoThumbnail(url).then(function (data) {
              slideScope.thumbnail = data.thumb;
              slideScope.type = data.type;
              slideScope.url = url;
              return {
                key: asset,
                tpl: VIDEO_SLIDE,
                ctx: slideScope
              };
            }, function () {
              return {
                key: asset,
                tpl: VIDEO_SLIDE,
                ctx: slideScope
              };
            });
          }
          slideScope.img = asset;
          asset.download();
          return {
            key: asset,
            tpl: IMAGE_SLIDE,
            ctx: slideScope
          };
        }));
      }

      scope.$watchCollection('block.featuredImg', function (newVal, oldVal) {
        if (scope.campaign.type == 'crowdfunding') {
          if (newVal === oldVal) {
            populateImageSlides().then(function (data) {
              scope.slideshowAPI.init(data);

              var slides = [{
                key: 'cover',
                tpl: COVER_SLIDE,
                ctx: scope
              }];

              var newSlides = slides.concat(data);
              scope.mobileSlideshowAPI.init(newSlides);
            });
          } else {
            populateImageSlides().then(function (data) {
              scope.slideshowAPI.update(data);

              var slides = [{
                key: 'cover',
                tpl: COVER_SLIDE,
                ctx: scope
              }];
              var newSlides = slides.concat(data);
              scope.mobileSlideshowAPI.update(newSlides);
            });
          }
        }
      });

      if ($state.params.register) {
        scope.registrationSwitch();
      }

      scope.isPreview = function () {
        return $state.is('admin.manage.campaign.step.setup') || $state.is('admin.manage.campaign.step.design.views.content-blocks') || $state.is('admin.manage.campaign.step.design.views.block');
      };
    }
  });
}
})();