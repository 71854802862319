(function(){
'use strict';

angular.module('classy').directive('scSimpleSpinner', scSimpleSpinner);

function scSimpleSpinner() {
  'use strict';

  return {
    scope: {
      customSpinnerOpts: '=?',
      show: '='
    },
    link: function link(scope, element) {
      scope.$watch('show', function (newValue) {
        displaySpinner(newValue);
      });
      // default spinner settings
      var spinjsOpts = {
        lines: 20, // The number of lines to draw
        length: 0, // The length of each line
        width: 1, // The line thickness
        radius: 1.5, // The radius of the inner circle
        scale: 3, // Scales overall size of the spinner
        corners: 1, // Corner roundness (0..1)
        speed: 1.2, // Rounds per second
        rotate: 0, // The rotation offset
        animation: 'spinner-line-shrink', // The CSS animation name for the lines
        direction: 1, // 1: clockwise, -1: counterclockwise
        color: '#ffffff', // CSS color or array of colors
        fadeColor: 'transparent', // CSS color or array of colors
        top: '48%', // Top position relative to parent
        left: '50%', // Left position relative to parent
        shadow: '', // Box-shadow for the lines
        zIndex: 2000000000, // The z-index (defaults to 2e9)
        className: 'spinner', // The CSS class to assign to the spinner
        position: 'relative' // Element positioning
      };

      // extend spinjs settings with custom spinner options if defined
      var elementId = element[0].getAttribute('id'),
          customSpinnerOpts = scope.customSpinnerOpts,
          opts = scope.customSpinnerOpts ? _.extend(spinjsOpts, customSpinnerOpts) : spinjsOpts,
          target = document.getElementById(elementId),
          spinner = new Spinner(opts);

      function displaySpinner() {
        var show = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : true;

        if (show) {
          spinner.spin(target);
        } else {
          $('.spinner').fadeOut(function () {
            spinner.stop();
          });
        }
      }
    }
  };
}
})();