(function(){
'use strict';

/**
 * @kind directive
 * @name scValidateVideoUrl
 * @requires ngModel
 *
 * @description
 * Validates text input as representing a valid YouTube or Vimeo URL.
 */

scValidateVideoUrl.$inject = ["scVideoSvc"];
angular.module('classy').directive('scValidateVideoUrl', scValidateVideoUrl);

function scValidateVideoUrl(scVideoSvc) {
  'use strict';

  return {
    require: 'ngModel',
    // eslint-disable-next-line no-unused-vars
    link: function link(scope, element, attrs, ngModel) {
      ngModel.$validators.videoUrl = function (modelValue, viewValue) {
        var value = modelValue || viewValue;
        return !value || scVideoSvc.validate(value);
      };
    }
  };
}
})();