(function(){
'use strict';

scProgressCircleBlockSettings.$inject = ["scFroalaOptions", "scCampaignsService", "scCampaignsTemplateService", "scQaId"];
angular.module('classy').directive('scProgressCircleBlockSettings', scProgressCircleBlockSettings);

function scProgressCircleBlockSettings(scFroalaOptions, scCampaignsService, scCampaignsTemplateService, scQaId) {
  return {
    scope: true,
    templateUrl: 'global/objects/scBlock/types/progress-circle/settings',
    controller: ['$scope', function ($scope) {
      $scope.campaign = scCampaignsService.active.current;

      $scope.lockDisplayProgress = false;
      var lockedBlock = scCampaignsTemplateService.getBlockByType('progress-circle');
      if (lockedBlock) {
        $scope.lockDisplayProgress = lockedBlock.item_attributes.displayProgress && lockedBlock.item_attributes.displayProgress.locked;
      }

      $scope.headerOptions = scFroalaOptions({ type: 'inline-header' }, {
        ariaLabel: 'Headline'
      });

      $scope.subHeaderOptions = scFroalaOptions({ type: 'inline-header' }, {
        ariaLabel: 'Sub Heading'
      });

      $scope.progressDisplays = {
        percentage: {
          label: 'Percentage'
        },
        dollars: {
          label: 'Dollars'
        }
      };

      $scope.scQaId = scQaId;
    }],

    link: function link() {}
  };
}
})();