(function(){
'use strict';

/**
 * Directive for lazy loading UI items.
 * @name scLazyLoad
 */

scLazyLoad.$inject = ["$q", "$http", "$timeout"];
angular.module('classy').directive('scLazyLoad', scLazyLoad);

function scLazyLoad($q, $http, $timeout) {
  return {
    scope: {
      resource: '=scLazyLoad',
      model: '=ngModel',
      perPage: '=?',
      sort: '=?',
      filter: '=?',
      loading: '=?'
    },

    link: function link(scope, element) {
      var debouncer = void 0;
      scope.currentPage = 1;
      scope.totalPages = 1;
      scope.resolved = false;

      angular.element(window).bind('scroll', function () {
        var triggered = trigger();

        // If you scroll too rapidly, you may not emit enough scroll
        // events to capture one late enough to trigger the callback, so we
        // schedule a promise to check once scrolling is complete.
        if (!triggered) {
          debouncer = $timeout(trigger, 100);
        }
      });

      function trigger() {
        $timeout.cancel(debouncer);

        var pageOffset = window.pageYOffset + angular.element(window).height();
        var endOfElement = element[0].offsetTop + element[0].scrollHeight;

        if (pageOffset > endOfElement) {
          // for scrolling: wait for current request to be resolved
          // before sending next request
          if (scope.resolved) {
            fetchResource(scope.currentPage);
          }
        }
      }

      var canceler = void 0;
      function fetchResource() {
        scope.resolved = false;
        scope.loading = true;

        // build query
        var query = buildQuery();

        // cancel previous request if we're sending in new filters/sort
        if (canceler) {
          canceler.resolve();
        }

        canceler = $q.defer();

        // fetch data
        if (scope.currentPage <= scope.totalPages) {
          $http({
            method: 'GET',
            url: '/frs-api' + scope.resource,
            params: query,
            timeout: canceler.promise
          }).then(function (response) {
            scope.resolved = true;
            scope.loading = false;
            if (scope.currentPage === 1) {
              scope.model = response.data.data;
            } else {
              _.forEach(response.data.data, function (data) {
                scope.model.push(data);
              });
            }

            if (response.data.total > 0) {
              scope.size = response.data.total;
            }

            scope.totalPages = Math.ceil(scope.size / scope.perPage);
            scope.currentPage += 1;
          });
        } else {
          scope.resolved = true;
          scope.loading = false;
        }
      }

      function buildQuery() {
        var query = {};

        if (scope.perPage) {
          query.per_page = scope.perPage;
        }

        if (scope.sort) {
          query.sort = _.map(scope.sort, function (value, key) {
            return key + ':' + value;
          });
        }

        var filterQuery = null;
        if (!_.isEmpty(scope.filter)) {
          _.forEach(scope.filter, function (obj, key) {
            filterQuery = key + obj.operand + encodeURIComponent(obj.value);
          });
          query.filter = filterQuery;
        }

        query.page = scope.currentPage;
        return query;
      }

      function queryChange() {
        scope.currentPage = 1;
        fetchResource(scope.currentPage);
      }

      // init, fetch first page
      scope.reachedEnd = false;
      fetchResource(scope.currentPage);

      scope.$watch('[sort, filter]', function (newVal, oldVal) {
        if (newVal !== oldVal) {
          queryChange();
        }
      }, true);
    }
  };
}
})();