(function(){
'use strict';

recaptchaService.$inject = ["$window", "scCampaignsService", "loadRecaptcha", "bugsnagClient"];
angular.module('classy').factory('recaptchaService', recaptchaService);

function recaptchaService($window, scCampaignsService, loadRecaptcha, bugsnagClient) {
  var siteKey = $window.SC.recaptcha;

  function isRecaptchaEnabled() {
    return scCampaignsService.active.current.effective_recaptcha_settings === 'ENABLED';
  }

  function createToken(action) {
    if (!isRecaptchaEnabled()) {
      return Promise.resolve(true);
    }

    return loadRecaptcha.then(function ($recaptcha) {
      if (!$recaptcha) {
        // If recaptcha is not loaded, don't prevent the donor from transacting.
        return Promise.resolve(true);
      }

      return $recaptcha.execute(siteKey, { action: action }).then(function (token) {
        return token;
      }).catch(function (e) {
        bugsnagClient.notify(e);
        // Don't block if there's an error with creating the token due to a failure with recaptcha
        return true;
      });
    });
  }

  return {
    createToken: createToken,
    isRecaptchaEnabled: isRecaptchaEnabled
  };
}
})();