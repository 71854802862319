(function(){
'use strict';

CFPLogoViewFactory.$inject = ["$q", "scFacebook", "scImg", "scMembersService", "scAnalytics"];
angular.module('classy').factory('CFPLogoView', CFPLogoViewFactory);

function CFPLogoViewFactory($q, scFacebook, scImg, scMembersService, scAnalytics) {
  return function CFPLogoView(parentScope) {
    var $scope = parentScope.$new();

    var deferAccess = $q.defer();
    $scope.isDomainMasked = false;
    /* ---------------------------- ------------------------------------------ *
     * Added Condition to check whether it is Domain Masked or not
     *---------------------------------------------------------------------- */
    if (SC.organization.domain_mask) {
      $scope.isDomainMasked = true;
    }

    /* ---------------------------------------------------------------------- *
     * Get and sync member photo
     * ---------------------------------------------------------------------- */

    $scope.state = {};
    $scope.userAccess = deferAccess.promise;

    // Update if user logs in mid-flow.
    $scope.$watch(function () {
      return _.get(scMembersService, 'active.current.id');
    }, function (ans) {
      $scope.state.profileImgSrc = _.get(scMembersService, 'active.current.thumbnail_large', null);
      $scope.state.useProfile = !!$scope.state.profileImgSrc;
      if (ans) {
        deferAccess.resolve();
      }
      if ($scope.state.useProfile) {
        $scope.fundraiser.current.logo = {
          isProfileImg: true, // We will inspect this on submit
          src: $scope.state.profileImgSrc
        };
      }
    });

    /* ---------------------------------------------------------------------- *
     * Facebook
     * ---------------------------------------------------------------------- */

    $scope.getFacebookProfilePicture = function () {
      scFacebook.getProfilePicture().then(function (result) {
        $scope.fundraiser.current.logo = scImg.fromURL(result.data.url, 'Profile Photo');
        $scope.flow.to('indiv.logo', 'slideRight');
      });
    };

    /* ---------------------------------------------------------------------- *
     * Media Manager
     * ---------------------------------------------------------------------- */
    $scope.mediaManagerConfig = {
      image: {
        model: 'fundraiser.current.userPhoto',
        minWidth: 300,
        minHeight: 300,
        crop: {
          cancel: 'indiv.logo.media'
        },
        guide: {
          title: 'Page Photo',
          message: 'This will appear on your personal fundraising page.',
          size: '300x300'
        }
      },
      buttons: {
        custom: {
          action: 'mmCancel()',
          text: 'Cancel'
        },
        submit: {
          action: 'mmSubmit($images)'
        }
      },
      userAccess: 'userAccess'
    };

    $scope.mmCancel = function () {
      $scope.flow.to('indiv.logo', 'slideRight');
    };

    $scope.mmSubmit = function (images) {
      $scope.fundraiser.current.logo = images[0];
      $scope.flow.to('indiv.logo', 'slideRight');
    };

    $scope.eventBeacon = function () {
      scAnalytics.eventBeacon({
        category: 'frs ' + $scope.campaign.current.type,
        action: 'triggers',
        label: 'Choose Your Photo'
      });
    };

    /* ---------------------------------------------------------------------- *
     * Subviews
     * ---------------------------------------------------------------------- */

    $scope.flowToMediaManager = function () {
      $scope.mediaManagerConfig.title = $scope.fundraiser.current.is_tribute ? 'Choose a photo' : 'Choose your photo';

      if ($scope.fundraiser.current.is_tribute) {
        $scope.mediaManagerConfig.image.guide.message = 'This will appear on your fundraising page.';
      }

      $scope.flow.to({
        template: "<div data-sc-media-manager='mediaManagerConfig'></div>",
        maxWidth: 980,
        context: $scope
      });
    };

    $scope.flow.addView({
      id: 'indiv.logo.switch',
      templateUrl: $scope.flow.template('logo/switch'),
      maxWidth: 415,
      context: $scope
    });

    /* ---------------------------------------------------------------------- *
     * View object
     * ---------------------------------------------------------------------- */

    return {
      id: 'indiv.logo',
      templateUrl: $scope.flow.template('logo'),
      maxWidth: 385,
      context: $scope,
      eventBeacon: $scope.eventBeacon
    };
  };
}
})();