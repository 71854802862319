(function(){
'use strict';

generateAssetUrl.$inject = ["$window"];
angular.module('classy').factory('generateAssetUrl', generateAssetUrl);

function generateAssetUrl($window) {
  var assetsCDNUrl = $window.SC.assetsCDNUrl || '';
  return function (path) {
    var pathWithoutLeadingSlash = path.replace(/^\/+/, '');
    if (pathWithoutLeadingSlash.substring(0, 6) === 'static') {
      return assetsCDNUrl + '/' + pathWithoutLeadingSlash;
    }
    return path;
  };
}
})();