(function(){
'use strict';

var _createClass = function () { function defineProperties(target, props) { for (var i = 0; i < props.length; i++) { var descriptor = props[i]; descriptor.enumerable = descriptor.enumerable || false; descriptor.configurable = true; if ("value" in descriptor) descriptor.writable = true; Object.defineProperty(target, descriptor.key, descriptor); } } return function (Constructor, protoProps, staticProps) { if (protoProps) defineProperties(Constructor.prototype, protoProps); if (staticProps) defineProperties(Constructor, staticProps); return Constructor; }; }();

function _classCallCheck(instance, Constructor) { if (!(instance instanceof Constructor)) { throw new TypeError("Cannot call a class as a function"); } }

angular.module('classy').factory('scPagingButtonsAPI', scPagingButtonsAPIFactory);

var scPagingButtonsAPI = function () {
  function scPagingButtonsAPI(options) {
    _classCallCheck(this, scPagingButtonsAPI);

    if (typeof options === 'undefined') {
      return;
    }

    this.perPage = options.perPage || 0;

    this.currentPage = options.currentPage || 1;

    this.totalPages = options.lastPage || 1;

    this.totalResults = options.total || 0;

    this.pageSelectListener = options.onPageSelect || _.noop;

    this.fetchStartListener = options.onFetchStart || _.noop;

    this.fetchCompleteListener = options.onFetchComplete || _.noop;

    this.pageRange = [];
  }

  /* ------------------------------------------------------------------------ *
   * Setters
   *
   * These alias internal properties to API field names, to make it more
   * straightforward to load data from API results (though the hooks below
   * should generally take care of that automatically).
   * ------------------------------------------------------------------------ */

  _createClass(scPagingButtonsAPI, [{
    key: 'setPerPage',
    value: function setPerPage(perPage) {
      this.perPage = perPage;
    }
  }, {
    key: 'setCurrentPage',
    value: function setCurrentPage(currentPage) {
      this.currentPage = currentPage;
    }
  }, {
    key: 'setLastPage',
    value: function setLastPage(lastPage) {
      var _this = this;

      this.totalPages = lastPage;
      this.pageRange.length = 0;
      _.times(lastPage, function (i) {
        return _this.pageRange.push(i + 1);
      });
    }
  }, {
    key: 'setTotal',
    value: function setTotal(total) {
      this.totalResults = total;
    }

    /* ------------------------------------------------------------------------ *
     * Callbacks
     *
     * This service is intended to be shared by a pager UI service and a data
     * fetching service (which could be a controller, a directive, a factory,
     * etc.) These hooks and callbacks allow each service to stay in sync with
     * what the other is doing.
     *
     * Each callback should receive relevant data - the page selected, the
     * promise from the $http request, or the data returned from the API.
     * ------------------------------------------------------------------------ */

  }, {
    key: 'onPageSelect',
    value: function onPageSelect(fn) {
      this.pageSelectListener = fn;
    }
  }, {
    key: 'pageSelected',
    value: function pageSelected(page) {
      this.currentPage = page;
      this.pageSelectListener(page);
    }
  }, {
    key: 'onFetchStart',
    value: function onFetchStart(fn) {
      this.fetchStartListener = fn;
    }
  }, {
    key: 'fetchStarted',
    value: function fetchStarted(promise) {
      this.fetchStartListener(promise);
    }
  }, {
    key: 'onFetchComplete',
    value: function onFetchComplete(fn) {
      this.fetchCompleteListener = fn;
    }
  }, {
    key: 'fetchComplete',
    value: function fetchComplete(data) {
      if (data) {
        this.setTotal(data.total);
        this.setCurrentPage(data.current_page);
        this.setLastPage(data.last_page);
      }

      this.fetchCompleteListener(data);
    }
  }]);

  return scPagingButtonsAPI;
}();

function scPagingButtonsAPIFactory() {
  return scPagingButtonsAPI;
}
})();