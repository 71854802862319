(function(){
'use strict';

angular.module('classy').directive('scCommentsList', scCommentsList);

function scCommentsList() {
  return {
    replace: true,
    scope: {
      commentable: '=scCommentsList'
    },
    templateUrl: 'global/directives/interaction/scCommentsList/template',
    link: function link() {}
  };
}
})();