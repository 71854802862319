(function(){
'use strict';

scSlideshow.$inject = ["$animate", "$interval", "scRequestAnimationFrame", "SlideshowAPI", "scUserAgent"];
angular.module('classy').directive('scSlideshow', scSlideshow);

function scSlideshow($animate, $interval, scRequestAnimationFrame, SlideshowAPI, scUserAgent) {
  return {
    replace: true,

    scope: {
      hideControls: '=?hideControls',
      interval: '=?interval',
      api: '=scSlideshow'
    },

    templateUrl: 'global/directives/scSlideshow/template',

    link: function link(scope, element) {
      $animate.enabled(element, false);

      var DURATION = 0.3;
      var EASING = Power4.easeOut;

      scope.api = scope.api || new SlideshowAPI();
      var api = scope.api;
      var $content = angular.element('<div class="slideshow_content"></div>');

      /* -------------------------------------------------------------------- *
       * State
       * -------------------------------------------------------------------- */

      var ui = {
        disabled: false,
        auto: null,
        drag: {
          prev: null,
          next: null,
          active: false,
          start: null,
          frameRequested: false,
          width: null
        }
      };

      scope.ui = ui;

      scope.mobileDevice = scUserAgent.isMobile();

      /* -------------------------------------------------------------------- *
       * Display flags
       * -------------------------------------------------------------------- */

      scope.show = {
        controls: function controls() {
          return api.slides.length > 1 && !scope.hideControls;
        }
      };

      /* -------------------------------------------------------------------- *
       * User API
       * -------------------------------------------------------------------- */

      scope.prev = function (byUser) {
        if (ui.disabled) return;
        var newIndex = api.prevIndex;
        if (newIndex === api.activeIndex) return;
        transitionTo(newIndex, 'slideRight', byUser);
      };

      scope.next = function (byUser) {
        if (ui.disabled) return;
        var newIndex = api.nextIndex;
        if (newIndex === api.activeIndex) return;
        transitionTo(newIndex, 'slideLeft', byUser);
      };

      scope.goto = function (index, byUser) {
        if (ui.disabled) return;
        if (index === api.activeIndex) return;
        var transition = api.activeIndex - index < 0 ? 'slideLeft' : 'slideRight';
        transitionTo(index, transition, byUser);
      };

      scope.pause = function () {
        $interval.cancel(ui.auto);
      };

      /* -------------------------------------------------------------------- *
       * Button control utils
       * -------------------------------------------------------------------- */

      function transitionTo(index, transition, byUser) {
        if (byUser) scope.pause();

        var $oldSlide = api.activeSlide;
        var $newSlide = api.compile(index);

        api.setActiveIndex(index);
        api.setActiveSlide($newSlide);

        var width = $content.outerWidth();
        $content.append($newSlide);

        switch (transition) {
          case 'slideLeft':
            TweenMax.from($newSlide[0], DURATION, {
              transform: 'translateX(' + width + 'px)',
              ease: EASING
            });
            if ($oldSlide) {
              TweenMax.to($oldSlide[0], DURATION, {
                transform: 'translateX(-' + width + 'px)',
                ease: EASING,
                onComplete: function onComplete() {
                  return $oldSlide.remove();
                }
              });
            }
            break;

          case 'slideRight':
            TweenMax.from($newSlide[0], DURATION, {
              transform: 'translateX(-' + width + 'px)',
              ease: EASING
            });
            if ($oldSlide) {
              TweenMax.to($oldSlide[0], DURATION, {
                transform: 'translateX(' + width + 'px)',
                ease: EASING,
                onComplete: function onComplete() {
                  return $oldSlide.remove();
                }
              });
            }
            break;

          case 'none':
            if ($oldSlide) {
              $oldSlide.remove();
            }
            break;

          default:
          // do nothing
        }
      }

      /* -------------------------------------------------------------------- *
       * Touch control utils
       * -------------------------------------------------------------------- */

      function startDrag(e) {
        if (ui.disabled) return;

        ui.drag.start = e.originalEvent.touches[0].pageX;
        ui.drag.active = true;
        ui.drag.width = $content.outerWidth();
        ui.drag.intent = 'none';

        var $prevSlide = api.compile('prev');
        ui.drag.prev = $prevSlide;
        $prevSlide.appendTo($content);
        TweenMax.set($prevSlide[0], {
          transform: 'translateX(-' + ui.drag.width + 'px)'
        });

        var $nextSlide = api.compile('next');
        ui.drag.next = $nextSlide;
        $nextSlide.appendTo($content);
        TweenMax.set($nextSlide[0], {
          transform: 'translateX(' + ui.drag.width + 'px)'
        });

        scope.$apply(function () {
          scope.pause();
        });
      }

      function applyDrag(e) {
        // RAF loop
        if (ui.drag.frameRequested) return;
        ui.drag.frameRequested = true;

        scRequestAnimationFrame(function () {
          ui.drag.frameRequested = false;

          if (!ui.drag.active) return;

          var $activeSlide = api.activeSlide;

          var $prevSlide = ui.drag.prev;
          var $nextSlide = ui.drag.next;

          var x = e.originalEvent.touches[0].pageX;
          var diff = x - ui.drag.start;
          var prevTranslation = diff + -1 * ui.drag.width;
          var activeTranslation = diff;
          var nextTranslation = diff + ui.drag.width;

          TweenMax.set($prevSlide[0], {
            transform: 'translateX(' + prevTranslation + 'px)'
          });
          TweenMax.set($activeSlide[0], {
            transform: 'translateX(' + activeTranslation + 'px)'
          });
          TweenMax.set($nextSlide[0], {
            transform: 'translateX(' + nextTranslation + 'px)'
          });

          if (diff > ui.drag.width / 10) {
            ui.drag.intent = 'prev';
          } else if (diff < ui.drag.width / -10) {
            ui.drag.intent = 'next';
          } else {
            ui.drag.intent = 'none';
          }
        });
      }

      function stopDrag() {
        if (!ui.drag.active) return;

        var $activeSlide = api.activeSlide;

        var $prevSlide = ui.drag.prev;
        var $nextSlide = ui.drag.next;

        ui.drag.active = false;
        ui.drag.prev = null;
        ui.drag.next = null;

        switch (ui.drag.intent) {
          case 'none':
            // $prevSlide (ANIMATE & REMOVE)
            TweenMax.to($prevSlide[0], DURATION, {
              transform: 'translateX(-' + ui.drag.width + 'px)',
              ease: EASING,
              onComplete: function onComplete() {
                return $prevSlide.remove();
              }
            });

            // $activeSlide
            TweenMax.to($activeSlide[0], DURATION, {
              transform: 'translateX(0px)',
              ease: EASING
            });

            // $nextSlide (ANIMATE & REMOVE)
            TweenMax.to($nextSlide[0], DURATION, {
              transform: 'translateX(' + ui.drag.width + 'px)',
              ease: EASING,
              onComplete: function onComplete() {
                return $nextSlide.remove();
              }
            });
            break;

          case 'prev':
            // $prevSlide
            TweenMax.to($prevSlide[0], DURATION, {
              transform: 'translateX(0px)',
              ease: EASING
            });

            // $activeSlide (ANIMATE & REMOVE)
            TweenMax.to($activeSlide[0], DURATION, {
              transform: 'translateX(' + ui.drag.width + 'px)',
              ease: EASING,
              onComplete: function onComplete() {
                return $activeSlide.remove();
              }
            });

            // $nextSlide (REMOVE)
            $nextSlide.remove();

            // Update state
            scope.$apply(function () {
              api.setActiveIndex(api.prevIndex);
              api.setActiveSlide($prevSlide);
            });
            break;

          case 'next':
            // $prevSlide (REMOVE)
            $prevSlide.remove();

            // $activeSlide (ANIMATE & REMOVE)
            TweenMax.to($activeSlide[0], DURATION, {
              transform: 'translateX(-' + ui.drag.width + 'px)',
              ease: EASING,
              onComplete: function onComplete() {
                return $activeSlide.remove();
              }
            });

            // $nextSlide
            TweenMax.to($nextSlide[0], DURATION, {
              transform: 'translateX(0px)',
              ease: EASING
            });

            // Update state
            scope.$apply(function () {
              api.setActiveIndex(api.nextIndex);
              api.setActiveSlide($nextSlide);
            });
            break;

          default:
          // do nothing
        }
      }

      /* -------------------------------------------------------------------- *
       * Edit mode events
       * -------------------------------------------------------------------- */

      api.on('init', function (slides) {
        $content.prependTo(element);
        if (!slides.length) return;
        var $activeSlide = api.compile(0);
        $content.append($activeSlide);
        api.setActiveIndex(0);
        api.setActiveSlide($activeSlide);
        ui.disabled = api.slides.length < 2;
      });

      api.on('slide_added', function () {
        // Assumes that slides are added to the end.
        ui.disabled = api.slides.length < 2;
        transitionTo(api.finalIndex, 'slideLeft');
      });

      api.on('slide_removed', function (removedIndex) {
        if (!api.slides.length) {
          api.activeSlide.remove();
          api.setActiveSlide(null);
          api.setActiveIndex(-1);
          return;
        }

        ui.disabled = api.slides.length < 2;

        var newIndex = Math.min(api.activeIndex, api.finalIndex);

        // A different slide was removed; active slide stays the same.
        // Only need to ensure active index is up to date.
        if (removedIndex !== api.activeIndex) {
          api.setActiveIndex(newIndex);
        } else if (removedIndex > api.finalIndex) {
          // The current slide was removed, and was the final slide.
          // Transition to what had been the previous slide.
          transitionTo(newIndex, 'slideRight');
        } else {
          // The active slide was removed, and was not the final slide.
          // Transition to what had been the next slide.
          transitionTo(newIndex, 'slideLeft');
        }
      });

      /* -------------------------------------------------------------------- *
       * Interval watches
       * -------------------------------------------------------------------- */

      scope.$watch('interval', function (ms) {
        if (_.parseInt(ms)) {
          ui.auto = $interval(scope.next, ms);
        } else {
          scope.pause();
        }
      });

      /* -------------------------------------------------------------------- *
       * Touch listeners
       * -------------------------------------------------------------------- */

      element.on('touchstart', startDrag);
      element.on('touchmove', applyDrag);
      element.on('touchend', stopDrag);
      scope.$on('$destroy', function () {
        element.off('touchstart', startDrag);
        element.off('touchmove', applyDrag);
        element.off('touchend', stopDrag);
      });
    }
  };
}
})();