(function(){
'use strict';

angular.module('classy').directive('cpValidateExpiration', cpValidateExpirationFactory);

function cpValidateExpirationFactory() {
  return {
    require: {
      ngModelCtrl: '?ngModel'
    },
    bindToController: {
      paymentModel: '<cpValidateExpiration'
    },
    controllerAs: 'cpValidateExpirationCtrl',
    controller: ["$scope", function controller($scope) {
      this.$onInit = function () {
        var _this = this;

        var ngModelCtrl = this.ngModelCtrl;

        // Find current month and year

        $scope.$watchGroup([function () {
          return _this.paymentModel.expirationMonth;
        }, function () {
          return _this.paymentModel.expirationYear;
        }], function (values) {
          var monthEmpty = values[0] === undefined || values[0] === null;
          var yearEmpty = values[1] === undefined || values[1] === null;
          var month = +values[0];
          var year = +values[1];
          var thisMonth = moment().month() + 1;
          var thisYear = +moment().format('YY');

          if (monthEmpty || yearEmpty) {
            ngModelCtrl.$setValidity('cpExpirationDate', true);
          } else if (year < thisYear) {
            ngModelCtrl.$setValidity('cpExpirationDate', false);
          } else if (year === thisYear && month < thisMonth) {
            ngModelCtrl.$setValidity('cpExpirationDate', false);
          } else {
            ngModelCtrl.$setValidity('cpExpirationDate', true);
          }
        });
      };
    }]
  };
}
})();