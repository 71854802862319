(function(){
'use strict';

/**
 * @kind directive
 * @name scLowerThan
 *
 * @description
 * Check if a value is lower than another model and set validity
 *
 * Used in:
 * Appv2
 *
 */

angular.module('classy').directive('scLowerThan', scLowerThan);

function scLowerThan() {
  'use strict';

  return {
    require: 'ngModel',
    // eslint-disable-next-line no-unused-vars
    link: function link(scope, element, $attrs, ctrl) {
      var validate = function validate(viewValue) {
        var comparisonModel = $attrs.scLowerThan;

        if (!viewValue || !comparisonModel) {
          // It's valid because we have nothing to compare against
          ctrl.$setValidity('scLowerThan', true);
        }

        // It's valid if model is lower than the model we're comparing against
        ctrl.$setValidity('scLowerThan', parseInt(viewValue, 10) <= parseInt(comparisonModel, 10));
        return viewValue;
      };

      ctrl.$parsers.unshift(validate);
      ctrl.$formatters.push(validate);

      $attrs.$observe('scLowerThan', function () {
        return (
          // Whenever the comparison model changes we'll re-validate
          validate(ctrl.$viewValue)
        );
      });
    }
  };
}
})();