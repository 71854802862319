(function(){
'use strict';

scVideo.$inject = ["scVideoSvc", "$compile", "$sce", "$timeout", "scUserAgent"];
angular.module('classy').directive('scVideo', scVideo);

function scVideo(scVideoSvc, $compile, $sce, $timeout, scUserAgent) {
  return {
    scope: {
      video: '=scVideo',
      loop: '=',
      fullWidth: '=',
      thumbnail: '=?',
      type: '=?'
    },
    link: function link(scope, element, attrs) {
      var type = void 0,
          id = void 0,
          template = void 0;

      var $slidePager = angular.element('.slideshow_pager');

      if (scope.thumbnail) {
        template = '<div style="background-image: url({{thumbnail}})" ng-if="iframeSrc" class="embed-responsive-img" data-video="{{iframeSrc}}"><div id="player" data-vimeo-url="' + attrs.url + '"></div></div>';
      } else {
        template = '<iframe src="{{iframeSrc}}" ng-if="iframeSrc" class="embed-responsive-item" type="text/html" frameborder="0" wmode="transparent"></iframe>';
      }

      var isMobile = scUserAgent.isMobile();

      function removeIframe(iframe) {
        angular.element(iframe).remove();
        $slidePager.removeClass('hide');
      }

      var vimeoPlayer = void 0,
          youtubePlayer = void 0;

      function initVimeoPlayer() {
        if (scope.thumbnail) {
          var videoHTML = '<iframe src="' + scope.iframeSrc + (scope.seconds ? '#t=' + scope.seconds : '') + '" class="embed-responsive-item" type="text/html" frameborder="0" wmode="transparent" allow="autoplay"></iframe>';
          angular.element(element).append(videoHTML);
        }

        var iframe = document.querySelector('iframe');

        vimeoPlayer = new Vimeo.Player(iframe);

        vimeoPlayer.ready().then(function () {
          if (!isMobile) {
            vimeoPlayer.play();
          }
        });

        vimeoPlayer.on('pause', function (data) {
          scope.seconds = data.seconds;
          removeIframe(iframe);
        });

        vimeoPlayer.on('ended', function () {
          scope.seconds = 0;
          removeIframe(iframe);
        });
      }

      function onPlayerReady(event) {
        if (!isMobile) {
          var seconds = Math.floor(scope.seconds) || 0;
          youtubePlayer.seekTo(seconds);
          event.target.playVideo();
        }
      }

      function onPlayerStateChange() {
        /**
         * Allow time for state to reach final destination
         */
        $timeout(function () {
          if (youtubePlayer.getPlayerState() == 2 || youtubePlayer.getPlayerState() == 0) {
            scope.seconds = youtubePlayer.getPlayerState() == 0 ? 0 : youtubePlayer.getCurrentTime();
            youtubePlayer.destroy();
            $slidePager.removeClass('hide');
          }
        }, 500);
      }

      function initYoutubePlayer() {
        var url = attrs.url;
        var videoId = scVideoSvc.getVideoId(url);
        youtubePlayer = new YT.Player('player', {
          videoId: videoId,
          enablejsapi: 1,
          events: {
            onReady: onPlayerReady,
            onStateChange: onPlayerStateChange
          }
        });
      }

      angular.element(element).on('click', function () {
        $slidePager.addClass('hide');

        if (scope.type == 'vimeo') {
          initVimeoPlayer();
        } else if (scope.type == 'youtube') {
          initYoutubePlayer();
        }
      });

      scope.$watchCollection('[video, loop, fullWidth]', function (newVal) {
        if (typeof newVal !== 'undefined') {
          type = scVideoSvc.getType(scope.video);
          id = scVideoSvc.getVideoId(scope.video);

          scope.createIframeUrl();
        }
      });

      scope.createIframeUrl = function () {
        var url = void 0;

        if (type == 'youtube') {
          scope.id = id;
          url = '//www.youtube.com/embed/' + id + '?theme=dark';

          if (scope.loop) {
            url += '&loop=1&playlist=' + id;
          }
        } else if (type == 'vimeo') {
          url = 'https://player.vimeo.com/video/' + id + '?badge=0&autoplay=1';
          if (scUserAgent.isIOSx()) url += '&muted=1';
        }

        scope.iframeSrc = $sce.trustAsResourceUrl(url);
      };

      var compiled = $compile(template)(scope);
      element.append(compiled);
    }
  };
}
})();