(function(){
'use strict';

scValidityServiceFactory.$inject = ["scValidityModel"];
angular.module('classy').service('scValidityService', scValidityServiceFactory);

function scValidityServiceFactory(scValidityModel) {
  this.models = {};

  this.create = function () {
    var name = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : 'default';

    this.models[name] = new scValidityModel();
    return this.models[name];
  };

  this.get = function (name) {
    return this.models[name];
  };
}
})();