(function(){
'use strict';

scSupporterNavService.$inject = ["$state", "scFundraisingPagesService", "scFundraisingTeamsService", "scCampaignsService", "scMembersService"];
angular.module('classy').service('scSupporterNavService', scSupporterNavService);

function scSupporterNavService($state, scFundraisingPagesService, scFundraisingTeamsService, scCampaignsService, scMembersService) {
  var _this = this;

  /* State Vars
  ========================================================= */

  // flag to dictate whether the fr page list should be curtailed
  this.initialFrPageLoad = true;
  this.campaignId = scCampaignsService.active.current.id;
  this.canLoadMorePages = function () {
    return !!scMembersService.nextPageRequestPageNumbers[_this.campaignId];
  };
  this.canLoadMoreTeams = function () {
    return !!scMembersService.nextTeamRequestPageNumbers[_this.campaignId];
  };

  // Get all fundraising teams that the current user owns on this campaign.
  this.getFundraisingTeams = function () {
    var teams = _.filter(scFundraisingTeamsService.userTeams, function (team) {
      var isCurrentPage = _.get(scFundraisingTeamsService, 'active.current.id', 0) == team.current.id && $state.current.name.includes('team.index');
      return team.current.status !== 'deleted' && !isCurrentPage;
    });

    return teams;
  };

  // Get all fundraising pages that the current user owns on this campaign.
  this.getFundraisingPages = function () {
    var pages = _.filter(scFundraisingPagesService.userFundraisers, function (page) {
      var isCurrentPage = _.get(scFundraisingPagesService, 'active.current.id', 0) == page.current.id && $state.current.name.includes('fundraiser.index');
      return page.current.status !== 'deleted' && !isCurrentPage;
    });

    // on the first load of the dropdown we want to make sure the user can see that
    // the fundraising teams list can be seen below the fundraising page list, so we
    // only show two items to start, and then proceed with the normal pagination flow
    // from there on.
    if (this.initialFrPageLoad && this.canLoadMorePages()) {
      return pages.slice(0, 2);
    }

    return pages;
  };

  this.loadMoreTeams = function () {
    scMembersService.getNextTeamsPage(this.campaignId).then(function () {
      var teams = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : [];

      teams.forEach(function (team) {
        return scFundraisingTeamsService.add(team);
      });
    });
  };

  this.loadMoreFundraisingPages = function () {
    this.initialFrPageLoad = false;
    scMembersService.getNextPagesPage(this.campaignId).then(function () {
      var pages = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : [];

      pages.forEach(function (page) {
        return scFundraisingPagesService.add(page);
      });
    });
  };
}
})();