(function(){
'use strict';

/**
 * @kind directive
 * @name scTimezonesField
 *
 * @description
 * Displays a timezones select dropdown field.
 *
 * @param {string} label Field label
 * @param {string} containerClass One or more classes to apply to the outer container
 * @param {string} fieldName Field 'name' attribute
 * @param {expression} model Timezone model
 * @param {boolean} required Determines whether the field is required
 * @param {boolean} startCondensed If true, field initializes with a subset of timezones
 * @param {array} errors Errors to be included. See errors documentation for more
 *
 * @param {object} check Object describing the 'check' component:
 *
 *  | Property | Type | Description |
 *  |--|--|--|
 *  | enabled | boolean | Show an 'OK' checkmark if check.condition is met. |
 *  | condition | expression | Condition which must be met for the check to appear. |
 */
scTimezonesField.$inject = ["scField", "accessibilityService"];
angular.module('classy').directive('scTimezonesField', scTimezonesField);

function scTimezonesField(scField, accessibilityService) {
  return scField('scTimezonesField', {
    link: function link(scope) {
      scope.ui = {
        collection: scope.options.startCondensed ? 'condensed' : 'all'
      };

      scope.onKeyDown = function (event) {
        if (event && accessibilityService.isValidKeyBoardEvent(event)) {
          if (scope.ui.collection === 'condensed') scope.ui.collection = 'all';else if (scope.ui.collection === 'all') scope.ui.collection = 'condensed';
        }
      };
    }
  });
}
})();