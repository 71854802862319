(function(){
'use strict';

scExpirationDate.$inject = ["$location"];
var _slicedToArray = function () { function sliceIterator(arr, i) { var _arr = []; var _n = true; var _d = false; var _e = undefined; try { for (var _i = arr[Symbol.iterator](), _s; !(_n = (_s = _i.next()).done); _n = true) { _arr.push(_s.value); if (i && _arr.length === i) break; } } catch (err) { _d = true; _e = err; } finally { try { if (!_n && _i["return"]) _i["return"](); } finally { if (_d) throw _e; } } return _arr; } return function (arr, i) { if (Array.isArray(arr)) { return arr; } else if (Symbol.iterator in Object(arr)) { return sliceIterator(arr, i); } else { throw new TypeError("Invalid attempt to destructure non-iterable instance"); } }; }();

angular.module('classy').directive('scExpirationDate', scExpirationDate);

function scExpirationDate($location) {
  'use strict';

  return {
    replace: true,
    require: ['ngModel', '^?form'],
    scope: {
      classStem: '='
    },
    template: '\n      <span>\n        <span class="form-table__column--two">\n          <select class="form__select--dark"\n          name="scExpirationDateMonth"\n          data-ng-options="month.number as month.label for month in months"\n          data-ng-model="month">\n            <option value="" selected>Month</option>\n          </select>\n        </span>\n        <span class="form-table__column--two">\n          <select class="form__select--dark expiration-"\n          name="scExpirationDateYear"\n          data-ng-options="year for year in years"\n          data-ng-model="year">\n            <option value="" selected>Year</option>\n          </select>\n        </span>\n      </span>\n    ',
    // eslint-disable-next-line no-unused-vars
    link: function link(scope, element, attrs, _ref) {
      var _ref2 = _slicedToArray(_ref, 2),
          ngModelCtrl = _ref2[0],
          formCtrl = _ref2[1];

      if (formCtrl) {
        formCtrl.$removeControl(formCtrl.scExpirationDateMonth);
        formCtrl.$removeControl(formCtrl.scExpirationDateYear);
      }

      // Find current month and year
      var thisYear = moment().year();
      var thisMonth = moment().month() + 1;

      // Sub-Models

      scope.month = '';
      scope.year = '';

      // Collections

      scope.months = [{ number: '01', label: '01 - January' }, { number: '02', label: '02 - February' }, { number: '03', label: '03 - March' }, { number: '04', label: '04 - April' }, { number: '05', label: '05 - May' }, { number: '06', label: '06 - June' }, { number: '07', label: '07 - July' }, { number: '08', label: '08 - August' }, { number: '09', label: '09 - September' }, { number: '10', label: '10 - October' }, { number: '11', label: '11 - November' }, { number: '12', label: '12 - December' }];

      scope.years = [];
      var finalYear = thisYear + 12;
      for (var y = thisYear; y <= finalYear; y += 1) {
        scope.years.push(y.toString());
      }

      scope.parseDate = function (values) {
        // don't try to validate anything if we're on preview
        if ($location.absUrl().includes('/manage/event')) {
          return false;
        }

        var month = values[0];
        var year = values[1];

        ngModelCtrl.$setValidity('monthRequired', !!month);
        ngModelCtrl.$setValidity('yearRequired', !!year);

        // Nullify isExpired validator if date is not complete
        if (!month || !year) {
          ngModelCtrl.$setValidity('isExpired', null);
          return false;
        }

        // +Cast year and month to decimal to check expiration.
        // Note that second argument to $setValidity is "isValid", so
        // $setValidity('isExpired', false) means it HAS expired.
        if (+year < thisYear) {
          ngModelCtrl.$setValidity('isExpired', false);
          return false;
        } else if (+year === thisYear && +month < thisMonth) {
          ngModelCtrl.$setValidity('isExpired', false);
          return false;
        }
        ngModelCtrl.$setValidity('isExpired', true);
        ngModelCtrl.$setTouched();
        ngModelCtrl.$setDirty();
        return {
          month: month,
          year: year
        };
      };

      scope.$watchGroup(['month', 'year'], function (newValues) {
        var parsed = scope.parseDate(newValues) || null;
        ngModelCtrl.$setViewValue(parsed);
      });
    }
  };
}
})();