(function(){
'use strict';

/**
 * @kind directive
 * @name scSelectCountries
 * @requires ngModel
 *
 * @description
 * Dropdown for country selection
 *
 * Prohibited nations are commented out; for now we're using this list:
 * https://www.paypal.com/webapps/mpp/bigcommerce/security/prohibited-countries
 *
 * Additionally Somalia excluded per https://classydev.atlassian.net/browse/FRS-2995
 */

scSelectCountries.$inject = ["$parse", "scCurrencyService", "scQaId", "countryBlockService"];
angular.module('classy').directive('scSelectCountries', scSelectCountries);

function scSelectCountries($parse, scCurrencyService, scQaId, countryBlockService) {
  return {
    require: '?ngModel',
    replace: true,
    template: '<select ng-options="country.value as country.label disable when country.disable for country in countries" qa-id="{{ scQaId.selectField }}">' + '<option value="">Please Select</option></select>',
    // eslint-disable-next-line no-unused-vars
    link: function link(scope, element, attrs) {
      scope.scQaId = scQaId;

      countryBlockService.getCountryBlockedList().then(function (response) {
        scope.countries = response;
      });

      var country = $parse(attrs.ngModel);
      if (!country(scope)) {
        var locale = scCurrencyService.getBrowserLocale() || 'US';
        country.assign(scope, locale);
      }
    }
  };
}
})();