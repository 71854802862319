(function(){
'use strict';

angular.module('classy').directive('scFileDrop', scFileDrop);

function scFileDrop() {
  return {
    scope: {
      cb: '=scFileDrop'
    },
    link: function link(scope, element) {
      element.on('dragover dragenter', function (e) {
        e.preventDefault();
        e.stopPropagation();
        element.addClass('fileover');
      });

      element.on('dragleave dragexit', function (e) {
        e.preventDefault();
        e.stopPropagation();
        element.removeClass('fileover');
      });

      element.on('drop', function (e) {
        e.preventDefault();
        e.stopPropagation();
        element.removeClass('fileover');
        element.addClass('processing');
        var files = e.originalEvent.dataTransfer.files;
        scope.$apply(function () {
          scope.cb(files);
        });
      });
    }
  };
}
})();