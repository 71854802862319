(function(){
'use strict';

/**
 * @name scSimpleProgress
 * @kind directive
 *
 * @description
 * A customizable progress bar.
 */

angular.module('classy').directive('scSimpleProgress', scSimpleProgress);

function scSimpleProgress() {
  'use strict';

  return {
    replace: true,
    scope: {
      goal: '=',
      raised: '=',
      goalColor: '=?',
      raisedColor: '=?',
      bc: '=?',
      height: '=?'
    },
    templateUrl: 'global/directives/scSimpleProgress/template',
    link: function link(scope) {
      scope.bc = scope.bc || 'sc';
      scope.height = scope.height || '';
      scope.goalColor = scope.goalColor || '';
      scope.raisedColor = scope.raisedColor || '';
      scope.percentage = 0;

      // Animates the progress bar for instance on a supporter's donation page
      // we adjust the progress bar to include the donor's donation
      scope.$watch('[goal, raised]', function (newVal) {
        var goal = newVal[0];
        var raised = newVal[1] || 0;
        scope.percentage = goal ? Math.min(100, parseInt(raised, 10)) : 0;
      });
    }
  };
}
})();