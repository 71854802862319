(function(){
'use strict';

angular.module('classy').directive('merge', mergeDirective);

function mergeDirective() {
  return {
    transclude: true,
    // eslint-disable-next-line no-unused-vars
    link: function link(scope, element, attrs, crap, $transclude) {
      $transclude(function (clone) {
        var classList = element.attr('class').split(' ');
        _.forEach(classList, function (className) {
          return element.parent().addClass(className);
        });
        clone.appendTo(element.parent());
        element.remove();
      });
    }
  };
}
})();