(function(){
'use strict';

scContentEditable.$inject = ["$window"];
angular.module('classy').directive('scContentEditable', scContentEditable);

function scContentEditable($window) {
  return {
    require: {
      ngModelCtrl: 'ngModel'
    },
    bindToController: {},
    controllerAs: 'scContentEditableCtrl',
    controller: ["$element", function controller($element) {
      /* ------------------------------------------------------------------ *
       * Life cycle
       * ------------------------------------------------------------------ */

      this.$onInit = function () {
        var _this = this;

        this.ngModelCtrl.$setPristine();
        this.ngModelCtrl.$setUntouched();
        this.ngModelCtrl.$render = function () {
          return _this.render();
        };
      };

      this.$postLink = function () {
        var _this2 = this;

        $element.on('input', function () {
          return _this2.read();
        });
        $element.on('focus', function () {
          return _this2.select();
        });
        this.ngModelCtrl.$render();
      };

      this.read = function () {
        this.ngModelCtrl.$setViewValue($element[0].textContent);
        this.ngModelCtrl.$setDirty();
      };

      this.render = function () {
        $element[0].textContent = this.ngModelCtrl.$viewValue;
      };

      this.select = function () {
        var range = document.createRange();
        range.selectNodeContents($element[0]);
        var sel = $window.getSelection();
        sel.removeAllRanges();
        sel.addRange(range);
        this.ngModelCtrl.$setTouched();
      };
    }]
  };
}
})();