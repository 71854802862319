(function(){
'use strict';

scFundraisingCommitmentService.$inject = ["$http", "scFundraisingPagesService"];
angular.module('classy').service('scFundraisingCommitmentService', scFundraisingCommitmentService);

// scFundraisingCommitmentService will be the single source of truth for supporter update, registration update and commitment update
function scFundraisingCommitmentService($http, scFundraisingPagesService) {
  return {
    putFundraisingCommitment: function putFundraisingCommitment(commitmentData) {
      var fundraisingPageId = _.get(scFundraisingPagesService, 'active.current.id', null);

      return $http({
        method: 'PUT',
        url: '/frs-api/fundraising-pages/' + fundraisingPageId + '/commitment',
        loadingScope: true,
        data: commitmentData
      });
    }
  };
}
})();