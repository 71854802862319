(function(){
'use strict';

/**
 * @name scPrefixAttribute
 * @kind filter
 */
angular.module('classy').filter('scPrefixAttribute', scPrefixAttribute);

function scPrefixAttribute() {
  return function (attribute, value) {
    var prefixes = ['-moz-', '-webkit-', '-o-', '-ms-'];

    var output = {};

    _.forEach(prefixes, function (prefix) {
      output[prefix + attribute] = value;
    });

    return output;
  };
}
})();