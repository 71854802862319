(function(){
'use strict';

classyQL.$inject = ["$http"];
angular.module('classy').service('classyQL', classyQL);

function classyQL($http) {
  this.query = function (_ref) {
    var query = _ref.query,
        variables = _ref.variables;
    return $http.post('/frs-api/classyql', { query: query, variables: variables });
  };

  // todo: more fields,
  // team policy stuff
  this.useApiv2Fields = function (type, entity) {
    var adjustedEntity = void 0;
    if (type === 'page') {
      adjustedEntity = Object.assign({}, entity, {
        alias: entity.name,
        raw_goal: entity.goal_raw,
        intro_text: entity.intro,
        id: parseInt(entity.id, 10),
        logo_id: parseInt(entity.logo_id, 10),
        raw_currency_code: entity.currency_code
      });
    } else if (type === 'team') {
      adjustedEntity = Object.assign({}, entity, {
        raw_goal: entity.goal_raw,
        id: parseInt(entity.id, 10),
        logo_id: parseInt(entity.logo_id, 10),
        team_lead_id: parseInt(entity.member_id, 10),
        raw_currency_code: entity.currency_code
      });
    }
    return adjustedEntity;
  };
}
})();