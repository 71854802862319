(function(){
'use strict';

angular.module('classy').directive('scValidityClasses', scValidityClassesFactory);

function scValidityClassesFactory() {
  return {
    priority: 1,
    require: {
      ngModelCtrl: '^?ngModel'
    },
    bindToController: {
      targetName: '<scValidityClasses'
    },
    controllerAs: 'scValidityClassesCtrl',
    controller: ["$scope", "$element", function controller($scope, $element) {
      this.$onInit = function () {
        /* ------------------------------------------------------------------ *
         * If target name is not provided, and this directive is on a form
         * control that is a validity reporter, grab the target name from the
         * reporter.
         * ------------------------------------------------------------------ */

        if (!this.targetName && this.ngModelCtrl) {
          this.targetName = this.ngModelCtrl.$scValidityReporter.alias;
        }
      };

      this.$postLink = function () {
        var _this = this;

        // eslint-disable-next-line no-unused-vars
        $scope.$on('scValidity.update', function (event, name, data) {
          /* ---------------------------------------------------------------- *
           * Limit to events for the targeted field.
           * ---------------------------------------------------------------- */

          if (name !== _this.targetName) {
            return;
          }

          /* ---------------------------------------------------------------- *
           * Toggle classes for displayed validity.
           * ---------------------------------------------------------------- */

          if (_.isUndefined(data.uiValid)) {
            $element.removeClass('sc-valid');
            $element.removeClass('sc-invalid');
            $element.addClass('sc-pending');
          } else if (data.uiValid) {
            $element.addClass('sc-valid');
            $element.removeClass('sc-invalid');
          } else {
            $element.addClass('sc-invalid');
            $element.removeClass('sc-valid');
          }
        });
      };
    }]
  };
}
})();