(function(){
'use strict';

/**
 * @name scParagraphToHeadings
 * @kind filter
 */
angular.module('classy').filter('scParagraphToHeadings', scParagraphToHeadings);

function scParagraphToHeadings() {
  return function (input) {
    var paragraphs = angular.element(input);
    var counter = 0;
    var replacedParaToHeadings = [];
    _.forEach(paragraphs, function (paragraph) {
      var replacement = counter == 0 ? document.createElement('h1') : document.createElement('h2');
      // copy attributes
      for (var i = 0; i < paragraph.attributes.length; i += 1) {
        var attr = paragraph.attributes[i];
        replacement.setAttribute(attr.name, attr.value);
      }

      // copy content from paragraph to headings
      replacement.innerHTML = paragraph.innerHTML;

      /* or we can use appendChild instead
      paragraph.childNodes.forEach(node => replacement.appendChild(node)) */

      paragraph.replaceWith(replacement);
      counter += 1;
      replacedParaToHeadings.push(replacement.outerHTML);
    });
    return replacedParaToHeadings.join('');
  };
}
})();