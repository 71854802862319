(function(){
'use strict';

/**
 * @kind directive
 * @name scCropModal
 * @scope
 *
 * @description
 * Renders an image cropping modal.
 */

angular.module('classy').directive('scCropModal', scCropModal);

function scCropModal() {
  return {
    restrict: 'A',
    replace: false,
    templateUrl: 'global/media/scCropModal/index',
    scope: {
      config: '=scCropModal'
    },
    controller: ['$scope', function ($scope) {
      var defaults = {
        width: 150,
        height: 150,
        shape: 'rectangle',
        title: 'Edit Thumbnail',
        blockClass: 'sc-crop'
      };

      _.defaults($scope.config, defaults);

      $scope.bc = $scope.config.blockClass;
      $scope.title = $scope.config.title;
      $scope.ready = false;
    }],

    link: function link($scope, $element) {
      var modalJq = void 0,
          loadingJq = void 0,
          canvasJq = void 0,
          // Canvas element in jQuery wrapper
      canvasWidth = void 0,
          canvasHeight = void 0,
          canvasAspect = void 0,
          // Canvas aspect ratio, height / width
      focusX = void 0,
          // X coordinate of the dead center of the canvas
      focusY = void 0,
          // Y coordinate of the dead center of the canvas
      context = void 0,
          // The 2-D rendering context
      rangeJq = void 0,
          // Range element in jQuery wrapper
      imgEl = void 0,
          // Raw source image element
      imgMaxWidth = void 0,
          imgMaxHeight = void 0,
          imgDrawnWidth = void 0,
          imgDrawnHeight = void 0,
          imgX = void 0,
          // Current drawn X coordinate
      imgY = void 0,
          // Current drawn Y coordinate
      imgAspect = void 0,
          // Image aspect ratio, height / width
      vfJq = void 0,
          // The viewfinder HTML element in jQuery wrapper
      vfWidth = void 0,
          // The width of the viewfinder/final cropped image
      vfHeight = void 0,
          // The height of the viewfinder/final cropped image
      vfX = void 0,
          // The X coordinate of the viewfinder box
      vfY = void 0,
          // The Y coordinate of the viewfinder box
      vfAspect = void 0,
          // The aspect ratio of the viewfinder box, height / width
      scaleFactor = void 0,
          // Value from 0 to 1 indicating scale
      requestFrame = void 0,
          // RequestAnimationFrame shim
      dragging = void 0,
          dragStartX = void 0,
          dragStartY = void 0,
          imgBaseX = void 0,
          imgBaseY = void 0;

      // Set up most measurements and positions. Called right away.
      function setupModal() {
        // Define elements
        modalJq = $element.find('#sc-crop-modal');
        loadingJq = $element.find('#sc-crop-loading');
        canvasJq = $element.find('#sc-crop-canvas');
        rangeJq = $element.find('#sc-crop-range');
        vfJq = $element.find('#sc-crop-viewfinder');

        // Dynamic sizing and properties
        canvasWidth = Math.max(350, $scope.config.width + 50 + 50);
        canvasHeight = $scope.config.height + 40 + 40 + 15 + 15;
        canvasAspect = canvasHeight / canvasWidth;

        vfWidth = $scope.config.width;
        vfHeight = $scope.config.height;
        vfAspect = vfHeight / vfWidth;
        vfX = Math.floor((canvasWidth - vfWidth) / 2);
        vfY = 55; // 40px for controls, 15px for padding

        focusX = Math.floor(canvasWidth / 2);
        focusY = Math.floor(canvasHeight / 2);

        canvasJq[0].height = canvasHeight;
        canvasJq[0].width = canvasWidth;

        modalJq.css({
          top: (jQuery(window).innerHeight() - modalJq.outerHeight()) / 2,
          width: canvasWidth
        });

        loadingJq.css({
          top: 0,
          width: '100%',
          height: 200
        });

        vfJq.css({
          top: vfY,
          left: vfX,
          width: vfWidth,
          height: vfHeight
        });

        // Set up context
        context = canvasJq[0].getContext('2d');
      }

      // Set up image-related measurements and positions; called when the
      // image is ready.
      function setupImage() {
        imgEl = $scope.config.image;

        // Define image and properties
        imgMaxWidth = imgEl.width;
        imgMaxHeight = imgEl.height;
        imgAspect = imgMaxHeight / imgMaxWidth;

        // Determine initial image sizing and position
        if (vfAspect == imgAspect) {
          // Aspects match and image fits; fit perfectly
          imgDrawnWidth = Math.min(vfWidth, imgMaxWidth);
          imgDrawnHeight = Math.min(vfHeight, imgMaxHeight);
          imgX = Math.max(vfX, vfX + (vfWidth - imgMaxWidth) / 2);
          imgY = Math.max(vfY, vfY + (vfHeight - imgMaxHeight) / 2);
        } else if (canvasAspect > imgAspect) {
          // Canvas is more portrait than image
          imgDrawnWidth = Math.min(imgMaxWidth, canvasWidth);
          imgDrawnHeight = Math.floor(imgDrawnWidth * imgAspect);
          imgX = Math.max(0, (canvasWidth - imgMaxWidth) / 2);
          imgY = Math.floor((canvasHeight - imgDrawnHeight) / 2);
        } else {
          // Image is more portrait than canvas
          imgDrawnHeight = Math.min(imgMaxHeight, canvasHeight);
          imgDrawnWidth = Math.floor(imgDrawnHeight / imgAspect);
          imgY = Math.max(0, (canvasHeight - imgMaxHeight) / 2);
          imgX = Math.floor((canvasWidth - imgDrawnWidth) / 2);
        }

        // Define initial scaleFactor
        scaleFactor = imgDrawnWidth / imgMaxWidth;

        // Shim requestAnimationFrame
        requestFrame = function () {
          return window.requestAnimationFrame || window.webkitRequestAnimationFrame || window.mozRequestAnimationFrame || function (callback) {
            window.setTimeout(callback, 1000 / 60);
          };
        }();

        // Set up range control
        rangeJq[0].disabled = false;
        rangeJq[0].min = 0;
        rangeJq[0].max = 1000;
        rangeJq.val(scaleFactor * 1000);
        rangeJq.on('input change', function () {
          scaleFactor = rangeJq.val() / 1000;
          render();
        });
      }

      /* ------------------------------------------------------------------ *
       * Canvas utilities
       * ------------------------------------------------------------------ */

      function render() {
        requestFrame(function () {
          context.clearRect(0, 0, canvasWidth, canvasHeight);
          context.globalCompositeOperation = 'source-over';
          context.fillStyle = 'rgba(0,0,0,0.5)';
          context.fillRect(0, 0, canvasWidth, canvasHeight);
          drawViewfinder();
          if (imgEl) {
            drawImage();
          }
        });
      }

      // "Punch a hole" in the canvas in the appropriate viewfinder shape.
      function drawViewfinder() {
        context.save();
        context.globalCompositeOperation = 'destination-out';
        context.fillStyle = '#000';
        switch ($scope.config.shape) {
          case 'rectangle':
            context.beginPath();
            context.fillRect(vfX, vfY, vfWidth, vfHeight);
            break;

          case 'circle':
            context.beginPath();
            context.arc(focusX, focusY, Math.floor(vfHeight / 2), 0, 2 * Math.PI);
            context.fill();
            break;

          default:
          // do nothing
        }
        context.restore();
      }

      // Draw the image underneath the current canvas layers.
      function drawImage() {
        var origWidth = imgDrawnWidth;
        var origHeight = imgDrawnHeight;

        var xFactor = (focusX - imgX) / imgDrawnWidth;
        var yFactor = (focusY - imgY) / imgDrawnHeight;

        imgDrawnWidth = imgMaxWidth * scaleFactor || 1;
        imgDrawnHeight = imgDrawnWidth * imgAspect;

        var dx = imgDrawnWidth - origWidth;
        var dy = imgDrawnHeight - origHeight;
        var xNudge = dx * xFactor;
        var yNudge = dy * yFactor;
        imgX -= xNudge;
        imgY -= yNudge;

        context.save();
        context.globalCompositeOperation = 'destination-over';
        context.drawImage(imgEl, imgX, imgY, imgDrawnWidth, imgDrawnHeight);
        context.restore();
      }

      /* ------------------------------------------------------------------ *
       * Listeners and callbacks
       * ------------------------------------------------------------------ */

      function wire() {
        vfJq.on('dblclick', enlarge);
        vfJq.on('mousedown touchstart', startDrag);
        jQuery(document).on('mousemove touchmove', trackDrag);
        jQuery(document).on('mouseup touchend', stopDrag);
        jQuery('body').on('mouseleave', stopDrag);

        $scope.$on('$destroy', function () {
          jQuery('document').off('mousemove touchmove', trackDrag);
          jQuery(document).off('mouseup touchend', stopDrag);
          jQuery('body').off('mouseleave', stopDrag);
        });

        $scope.cancel = function () {
          $scope.$emit('scCropCancel');
        };

        $scope.save = function () {
          crop();
        };
      }

      function startDrag(e) {
        e.preventDefault();
        dragging = true;
        imgBaseX = imgX;
        imgBaseY = imgY;
        dragStartX = e.type === 'touchstart' ? e.originalEvent.changedTouches[0].clientX : e.clientX;
        dragStartY = e.type === 'touchstart' ? e.originalEvent.changedTouches[0].clientY : e.clientY;
      }

      function trackDrag(e) {
        if (!dragging) {
          return;
        }

        e.preventDefault();
        jQuery('body, #sc-crop-viewfinder').css('cursor', '-webkit-grabbing');
        var evX = e.type === 'touchmove' ? e.originalEvent.changedTouches[0].clientX : e.clientX;
        var evY = e.type === 'touchmove' ? e.originalEvent.changedTouches[0].clientY : e.clientY;
        var dx = evX - dragStartX;
        var dy = evY - dragStartY;
        imgX = imgBaseX + dx;
        imgY = imgBaseY + dy;
        render();
      }

      function stopDrag() {
        if (!dragging) {
          return;
        }

        dragging = false;
        dragStartX = null;
        dragStartY = null;
        imgBaseX = imgX;
        imgBaseY = imgY;
        jQuery('body, #sc-crop-viewfinder').css('cursor', '');
      }

      function enlarge() {
        scaleFactor = Math.min(1, scaleFactor + 0.33);
        rangeJq.val(scaleFactor * 1000);
        render();
      }

      // Crop and return (via scCropSave event).
      function crop() {
        var previewUrl = void 0;

        // Generate preview
        var previewCanvas = document.createElement('canvas');
        previewCanvas.width = vfWidth;
        previewCanvas.height = vfHeight;
        var previewContext = previewCanvas.getContext('2d');
        previewContext.fillStyle = '#000';
        previewContext.fillRect(0, 0, vfWidth, vfHeight);
        var previewX = imgX - vfX;
        var previewY = imgY - vfY;
        previewContext.drawImage(imgEl, previewX, previewY, imgDrawnWidth, imgDrawnHeight);
        try {
          previewUrl = previewCanvas.toDataURL('image/jpeg', 1);
        } catch (e) {}

        // Generate processing data
        var processX = vfX - imgX;
        var processY = vfY - imgY;

        var cropData = {
          transformQueue: [{
            operation: 'scale',
            args: {
              width: parseInt(imgDrawnWidth, 10),
              height: parseInt(imgDrawnHeight, 10)
            }
          }, {
            operation: 'crop',
            args: {
              x: parseInt(processX, 10),
              y: parseInt(processY, 10),
              width: parseInt(vfWidth, 10),
              height: parseInt(vfHeight, 10)
            }
          }],
          preview: previewUrl
        };

        $scope.$emit('scCropSave', cropData);
      }

      /* ------------------------------------------------------------------ *
       * Initialize
       * ------------------------------------------------------------------ */

      setupModal();

      $scope.$watch('config.image', function (image) {
        if (image) {
          setupImage();
          wire();
          render();
          $scope.ready = true;
        } else {
          context.clearRect(0, 0, canvasWidth, canvasHeight);
          imgEl = null;
        }
      });
    }
  };
}
})();