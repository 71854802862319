(function(){
'use strict';

scCartServiceFactory.$inject = ["$http", "$q", "$rootScope", "$window", "ipCookie", "scCampaignsService", "scCartModel", "SC_CART_COOKIE"];
function _toConsumableArray(arr) { if (Array.isArray(arr)) { for (var i = 0, arr2 = Array(arr.length); i < arr.length; i++) { arr2[i] = arr[i]; } return arr2; } else { return Array.from(arr); } }

/* istanbul ignore next */
angular.module('classy').service('scCartService', scCartServiceFactory);

function scCartServiceFactory($http, $q, $rootScope, $window, ipCookie, scCampaignsService, scCartModel, SC_CART_COOKIE) {
  var _this = this;

  /* ------------------------------------------------------------------------ *
   * DEMO
   * ------------------------------------------------------------------------ */

  $window.cartActions = {
    expire: function expire() {
      $rootScope.$apply(function () {
        _this.active.emit('expire');
      });
    },

    warn: function warn() {
      $rootScope.$apply(function () {
        _this.active.emit('warn');
      });
    },

    get: function get() {
      return _this.active;
    },

    failRegistration: function failRegistration() {
      $rootScope.$apply(function () {
        _this.active.metadata.fail_registration = true;
        _this.active.store();
      });
      return 'Cart will fail to process registration payment.';
    },

    passRegistration: function passRegistration() {
      $rootScope.$apply(function () {
        _this.active.metadata.fail_registration = false;
        _this.active.store();
      });
      return 'Cart will attempt to process registration payment.';
    },

    failDonation: function failDonation() {
      $rootScope.$apply(function () {
        _this.active.metadata.fail_donation = true;
        _this.active.store();
      });
      return 'Cart will fail to process donation payment.';
    },

    passDonation: function passDonation() {
      $rootScope.$apply(function () {
        _this.active.metadata.fail_donation = false;
        _this.active.store();
      });
      return 'Cart will attempt to process donation payment.';
    }
  };

  /* ------------------------------------------------------------------------ *
   * Wait for sync() to fill in active property.
   * ------------------------------------------------------------------------ */

  this.active = null;

  /* ------------------------------------------------------------------------ *
   * getLocalData()
   *
   * Checks for a cookie with cart data and, if parsable, returns its
   * parsed contents.
   * ------------------------------------------------------------------------ */

  this.getLocalData = function (campaignId) {
    var cookieName = SC_CART_COOKIE + '_' + campaignId;
    try {
      return ipCookie(cookieName) || null;
    } catch (err) {
      return null;
    }
  };

  /* ------------------------------------------------------------------------ *
   * restore()
   *
   * Retrieves a cart record, wraps it in an scCartModel, and stores it in
   * the service. Returns a promise that ultimately resolves with the new
   * cart model.
   * ------------------------------------------------------------------------ */

  this.restore = function (cartData) {
    var _this2 = this;

    return $http.get('/frs-api/carts/' + cartData.id + '?with=promo_code').then(function (response) {
      if (_this2.active) {
        _this2.active.destroy();
      }

      _this2.active = new scCartModel(response.data, cartData.metadata);
    }).then(function () {
      return _this2.active.refresh();
    }).then(function () {
      return _this2.active;
    });
  };

  // Allow other services and controllers to react to the expiration of the active cart.
  // Pass in a callback to onCartExpired and that will be called any time a cart expires.
  // Make sure to clean up your cart listener during your component cleanup.
  this.expirationListeners = [];
  this.onCartExpired = function (cb) {
    _this.expirationListeners = [].concat(_toConsumableArray(_this.expirationListeners), [cb]);
    // return the callback so that it can easily be used for removing the listener during cleanup
    return cb;
  };

  this.removeCartExpiredListener = function (cb) {
    _this.expirationListeners = _this.expirationListeners.filter(function (el) {
      return el !== cb;
    });
  };

  this.triggerExpireListeners = function () {
    _this.expirationListeners.forEach(function (cb) {
      cb();
    });
  };

  /* ------------------------------------------------------------------------ *
   * create()
   *
   * Creates a new cart model (without a corresponding record in the API),
   * hooks up an expiration handler, and stores it in the service. Returns
   * a promise that resolves with the new cart model.
   * ------------------------------------------------------------------------ */
  this.create = function () {
    var _this3 = this;

    var campaignId = scCampaignsService.active.current.id;
    var cleanup = this.active ? this.active.destroy() : $q.resolve();
    this.active = new scCartModel({
      campaign_id: campaignId,
      donation_amount: null,
      donation_frequency: null,
      donation_fee_on_top: null,
      donation_answers: null,
      team_id: null,
      team_name: null,
      team_description: null,
      team_goal: null,
      team_answers: null
    });
    this.active.on('expire', function () {
      return _this3.create().then(function () {
        // Note: any component which wishes to track when the active cart has expired should
        // be listening to scCartService emitting activeCartExpired. Listening to
        // scCartService.active emitting expire will result in a listener which must be re-attached
        // after every time the current cart expires (because a new 'active' object is created). This
        // prevents bugs where the client might not properly detect carts expiring after cart has
        // already expired once in a user's session.
        _this3.triggerExpireListeners();
      });
    });
    return cleanup.then(function () {
      return _this3.active;
    });
  };

  /* ------------------------------------------------------------------------ *
   * syncStagedItems()
   *
   * Checks if stagedItems has all models needed to reflect the user's cart.
   * ------------------------------------------------------------------------ */
  this.syncStagedItems = function () {
    var stagedItemsLength = _.get(this, 'active.stagedItems.length');
    var currItems = _.get(this, 'active.current.items') || [];

    // populate commitment data since it is not actually stored
    var ticketTypes = _.get(scCampaignsService, 'active.current.ticketTypes');

    if (ticketTypes && ticketTypes.length) {
      var ticketTypesLookup = {};
      ticketTypes.forEach(function (t) {
        ticketTypesLookup[t.id] = t;
      });

      currItems.forEach(function (item) {
        var ticketTypeOfItem = ticketTypesLookup[item.product_id];
        if (ticketTypeOfItem && ticketTypeOfItem.commitment) {
          item.commitment = ticketTypeOfItem.commitment;
        }
        return item;
      });
    }

    if (stagedItemsLength !== currItems.length) {
      this.active.stagedItems = _.cloneDeep(currItems);

      // Ensure that all staged registrations contain attendee forms
      // for the number of allowed entires per registration.
      _.forEach(this.active.stagedItems, function (item) {
        var entries = _.get(item, 'entries', false);
        if (entries && item.registrants.length !== entries) {
          var numBlankAttendees = entries - item.registrants.length;

          _.times(numBlankAttendees, function () {
            item.registrants.push({
              cart_item_id: item.id,
              first_name: '',
              last_name: '',
              email_address: '',
              answers: []
            });
          });
        }
      });
    }
  };

  /* ------------------------------------------------------------------------ *
   * sync()
   *
   * For the given campaign id, restores an active cart from a cookie or
   * generates a new one, wraps it in a model, and populates the .active
   * property with it.
   * ------------------------------------------------------------------------ */

  this.sync = function (campaignId) {
    var _this4 = this;

    var active = this.active;

    // Case 1: The active cart is good to go. Attempt to refresh. If the
    // active cart is actually expired (due to sync or latency issues)
    // fall back to creating a new cart.
    if (active && active.current.campaign_id === _.parseInt(campaignId) && active.isReserved && !active.isComplete) {
      return active.refresh().catch(function () {
        return _this4.create();
      });
    }

    // Case 2: The active cart is for the correct campaign but is expired,
    // completed, or was not reserved. Create a new cart immediately without
    // checking local data.
    if (active && active.current.campaign_id === _.parseInt(campaignId)) {
      return this.create();
    }

    // Case 3: All other cases, including load. Check for a stored cart
    // reference in cookie. If a reference is found, attempt to restore.
    // Otherwise, create a new cart.
    var cartData = this.getLocalData(campaignId);
    if (cartData && cartData.id && cartData.status !== 'complete') {
      return this.restore(cartData).catch(function () {
        return _this4.create();
      });
    }
    return this.create();
  };

  // reusable function to get the current fr minimum details based on the staged items in the cart.
  this.getFrMinimumInfo = function () {
    var cart = this.active;
    var stagedItems = cart.stagedItems;
    var frMinimumRawTotal = 0;
    var frMinimumTotal = 0;
    var firstFrMinimumDue = void 0;

    var commitmentItems = stagedItems.filter(function (si) {
      return !!si.commitment;
    });
    if (commitmentItems.length) {
      var commitmentItemsWithFrMin = commitmentItems.map(function (ci) {
        var registrantCount = ci.registrants ? ci.registrants.length : 0;
        ci.frMin = registrantCount * ci.commitment.value;
        return ci;
      });

      frMinimumRawTotal = commitmentItemsWithFrMin.map(function (ci) {
        return ci.frMin;
      }).reduce(function (a, b) {
        return a + b;
      });
      frMinimumTotal = frMinimumRawTotal;

      // if the add registration fee setting is enabled, we can just subtract the entire total from the fr minimum, otherwise just subtract the donation
      if (scCampaignsService.active.current.add_registration_fee) {
        frMinimumTotal -= cart.total;
      } else if (cart.current.donation_amount) {
        frMinimumTotal -= cart.current.donation_amount;
      }

      if (frMinimumTotal < 0) {
        frMinimumTotal = 0;
      }

      firstFrMinimumDue = commitmentItemsWithFrMin.sort(function (ci1, ci2) {
        return new Date(ci1.commitment.deadline) - new Date(ci2.commitment.deadline);
      })[0];
    }

    return {
      count: commitmentItems.length,
      rawTotal: frMinimumRawTotal,
      total: frMinimumTotal,
      firstDue: firstFrMinimumDue
    };
  };

  this.getAttendees = function () {
    if (!this.active.stagedItems) {
      return [];
    }

    return _.flatMap(this.active.stagedItems, function (si) {
      return si.registrants;
    });
  };
}
})();