(function(){
'use strict';

angular.module('classy').directive('scComment', scComment);

function scComment() {
  return {
    replace: true,
    scope: {
      commentable: '=scComment'
    },
    templateUrl: 'global/directives/interaction/scComment/template',
    link: function link() {}
  };
}
})();