(function(){
'use strict';

scErrorClass.$inject = ["$parse"];
angular.module('classy').directive('scErrorClass', scErrorClass);

function scErrorClass($parse) {
  return {
    require: '^form',
    link: function link(scope, element, attrs, formCtrl) {
      var name = $parse(attrs.scErrorClass)(scope);
      var ngModel = formCtrl[name];
      if (ngModel) {
        var prop = ngModel.$retainFocusValidity ? '$uiInvalid' : '$invalid';
        scope.$watch(function () {
          return _.get(ngModel, prop);
        }, function (newVal) {
          if (newVal) {
            element.addClass('ui-invalid');
          } else {
            element.removeClass('ui-invalid');
          }
        });
      }
    }
  };
}
})();