(function(){
'use strict';

/**
 * @kind factory
 * @name scPrepareBlockComponents
 *
 * @description
 * Evaluates and customizes block components.
 */
scPrepareBlockComponents.$inject = ["$templateCache"];
angular.module('classy').factory('scPrepareBlockComponents', scPrepareBlockComponents);

function scPrepareBlockComponents($templateCache) {
  'use strict';

  return function (components, themeOverrides) {
    // Evaluate
    _.forEach(components, function (component) {
      _.forEach(component, function (value, property) {
        component[property] = _.isFunction(value) ? value() : value;
      });
    });

    // Apply theme overrides
    if (_.isObject(themeOverrides)) {
      _.merge(components, themeOverrides);
    }

    // Expand template URLs if not set by theme
    _.forEach(components, function (component) {
      if (!component.template) {
        component.template = $templateCache.get(component.templateUrl);
      }
    });

    // Convert to array, filtering out inactive components
    return _.filter(components, function (component) {
      return component.active;
    });
  };
}
})();