(function(){
'use strict';

/**
 * @ngDoc service
 * @name scFundraisingPageDefaults
 *
 * @description
 * Default data for the scFundraisingPageModel.
 */
angular.module('classy').service('scFundraisingTeamDefaults', scFundraisingTeamDefaults);

function scFundraisingTeamDefaults() {
  this.name = '';
  this.goal = 0;
  this.description = '';
  this.logo = null;
  this.cover_photo = null;
}
})();