(function(){
'use strict';

scImgAssetRef.$inject = ["scImgAssetsService"];
angular.module('classy').filter('scImgAssetRef', scImgAssetRef);

function scImgAssetRef(scImgAssetsService) {
  return function (html) {
    if (!html) {
      return undefined;
    }

    var replaced = html.replace(/<img.*?sc-img-asset-ref=['"](\d+)['"].*?>/g, function (imgNode, assetId) {
      var asset = scImgAssetsService.get(assetId, 'upload');
      var src = asset.sizes.full;
      return imgNode.replace(/src=['"].*?['"]/, 'src="' + src + '"');
    });

    return replaced;
  };
}
})();