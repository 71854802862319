(function(){
'use strict';

/*
 * Limit results on asynchronous search fundraisers drop-down
 */
limitResults.$inject = ["$q"];
angular.module('classy').filter('limitResults', limitResults);

function limitResults($q) {
  return function (input) {
    var deferred = $q.defer();
    if (!_.isUndefined(input)) {
      input.then(function (result) {
        deferred.resolve(result.slice(0, 5));
      });
    }

    return deferred.promise;
  };
}
})();