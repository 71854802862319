(function(){
'use strict';

scSupporter.$inject = ["scFundraisingPagesService", "scFundraisingTeamsService", "scProgressDataFactory"];
angular.module('classy').directive('scSupporter', scSupporter);

function scSupporter(scFundraisingPagesService, scFundraisingTeamsService, scProgressDataFactory) {
  return {
    scope: {
      bc: '@',
      supporter: '=',
      theme: '=',
      barColor: '=',
      type: '@'
    },
    replace: true,
    templateUrl: 'global/directives/scSupporter/template',
    link: function link(scope) {
      scope.progressData = scProgressDataFactory.getEntityProgress(scope.supporter);

      scope.bc = scope.bc || 'sc-supporter_';

      scope.percentRaised = function () {
        return Math.round(scope.progressData.percentToGoal * 100) + '%';
      };

      scope.getImage = function () {
        if (scope.type === 'fundraiser') {
          return scFundraisingPagesService.getPagePhoto(scope.supporter);
        } else if (scope.type === 'team') {
          return scFundraisingTeamsService.getTeamPhoto(scope.supporter);
        }

        return '/static/global/images/individual_default_image.png';
      };

      scope.getName = function () {
        if (!_.isUndefined(scope.supporter.alias)) {
          return scope.supporter.alias;
        }
        return scope.supporter.name;
      };

      scope.getCount = function () {
        // look for either the APIv2 or ClassyQL property that determines number of pages on a team
        // in the case of the ClassyQL property, we're trying to grab the active count if possible,
        // but fall back to the regular page count
        return _.get(scope, 'supporter.total_fundraisers', _.get(scope, 'supporter.pages_count_active', _.get(scope, 'supporter.pages_count', 0)));
      };
    }
  };
}
})();