(function(){
'use strict';

/**
 * @name scFormatDate
 * @kind filter
 * A thin wrapper around moment, exposing it for use in views.
 * Always assumes a UTC timestamp is coming in.
 * By Default outputs the time with respect to the client time,
 *    could also do the campaign's timezone
 * http://momentjs.com/docs/#/displaying/format/
 */

scFormatDate.$inject = ["scTimeMachineService", "scCampaignsService"];
angular.module('classy').filter('scFormatDate', scFormatDate);

function scFormatDate(scTimeMachineService, scCampaignsService) {
  return function (dateTime) {
    var desiredFormat = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : 'MM/DD/YY HH:mm';

    var campaignTz = _.get(scCampaignsService, 'active.current.timezone_identifier', false);

    if (dateTime) {
      var desiredTimezone = moment.tz.guess();

      var utc = dateTime.split('T').length > 1;
      var date = moment(dateTime);

      if (!utc) {
        var clientTime = moment(dateTime);
        var ts = clientTime.format('x');

        var newTime = date;

        var tzOffset = moment.tz.zone(desiredTimezone).utcOffset(ts);
        var difference = -tzOffset;
        var localish = newTime.add(difference, 'minutes');

        date = localish;

        if (campaignTz) {
          date = scTimeMachineService.campaignTime(localish);
        }
      }

      return date.format(desiredFormat);
    }

    return '';
  };
}
})();