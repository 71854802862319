(function(){
'use strict';

scCurrencyService.$inject = ["$http", "$q", "$location", "scOrganizationsService", "scCampaignsService", "scFundraisingTeamsService", "scIntlService"];
angular.module('classy').service('scCurrencyService', scCurrencyService);

function scCurrencyService($http, $q, $location, scOrganizationsService, scCampaignsService, scFundraisingTeamsService, scIntlService) {
  var currencyData = SC.currencies && SC.currencies.data || [];

  var _getLocaleCurrency = function _getLocaleCurrency() {
    if ($location.search().currency) {
      return undefined;
    }

    return currencyData.find(function (_) {
      return _.currency === SC.localeCurrency;
    }) ? SC.localeCurrency : undefined;
  };

  return {
    /* ---------------------------------------------------------------- *
     * Cache the rates, and keep track of which ones are fetching
     * ---------------------------------------------------------------- */
    rates: {},
    ratesFetching: {},

    /* ---------------------------------------------------------------- *
     * Cache supported currencies
     * ---------------------------------------------------------------- */
    currencies: currencyData,

    // Server-determined currency to display for the user based on their request accept-languages
    // header and IP address. If the determined currency is not part of the valid currencies for
    // this org, then we should not use it. Orgs are able to set whitelists for currencies, so the
    // "best" currency for a given user might not be a valid one.
    localeCurrency: _getLocaleCurrency(),

    getLanguagePreference: function getLanguagePreference() {
      // when karma runs in an ubuntu environment, the native language gets
      // set to some screwy values which crash our i18n shim. While adding
      // test-specific code to production code paths is never awesome,
      // this allows us to avoid some screwy hacks to change the global
      // locale before running tests.
      if (SC.env === 'karma') {
        return 'en-us';
      }

      return window.navigator.languages && window.navigator.languages[0] || window.navigator.language || window.navigator.userLanguage || window.navigator.browserLanguage || 'en-us';
    },


    // We should only ever be pulling the browser locale from the user's first language preference.
    // If the user has selected for their first preferred language a language with no region code,
    // then we should not check for region codes from other languages in the browser list. We are
    // doing this because trying to check other language entries for region codes could lead to
    // mismatches between our guess at the correct region code and the user's actual region. In this
    // case, it would be better to simply fall back to the default currency (based on
    // page/campaign/org) rather than display a *wrong* currency.
    // See https://classydev.atlassian.net/browse/FRS-7184 for discussion of the UX problem and use
    // cases which caused us to decide upon this solution.
    getBrowserLocale: function getBrowserLocale() {
      return (this.getLanguagePreference().split('-')[1] || '').toUpperCase();
    },
    getAllowedDecimals: function getAllowedDecimals(currencyCode) {
      var curr = _.filter(this.currencies, function (currency) {
        return currency.currency == currencyCode;
      });
      return _.get(curr, '[0].decimals', 2);
    },
    getDefaultCurrency: function getDefaultCurrency() {
      return _.get(scOrganizationsService, 'active.current.currency_code') || _.get(scOrganizationsService, 'active.current.currency') || 'USD';
    },
    extendPayCurrencies: function extendPayCurrencies() {
      var _this = this;

      this.currencies.forEach(function (currency) {
        currency.fullSymbol = _this.getCurrencySymbol(currency.currency);
        currency.longText = currency.currency + ' | ' + currency.name.replace(/(?:^|\s)\S/g, function (a) {
          return (
            // capitialize all words in the long version
            a.toUpperCase()
          );
        });
      });
    },
    fetchRate: function fetchRate(from, to) {
      var _this2 = this;

      this.ratesFetching[to] = $http.get('/frs-api/i18n/currency-conversions', {
        params: {
          from: from,
          to: to,
          amount: 1
        }
      }).then(function (response) {
        _this2.rates[to] = response.data.rate;
        return _this2.rates[to];
      });

      return this.ratesFetching[to];
    },


    // rates are always X CUR = 1 NORMALIZED CUR
    getConversionRate: function getConversionRate(currency) {
      var deferred = $q.defer();

      if (!currency || currency == this.getDefaultCurrency()) {
        deferred.resolve(1);
      } else {
        var rate = _.get(this.rates, currency, false);
        if (rate) {
          deferred.resolve(rate);
        } else {
          var waitForAjax = this.ratesFetching[currency] || this.fetchRate(this.getDefaultCurrency(), currency);

          waitForAjax.then(function (response) {
            deferred.resolve(response);
          }, function () {
            deferred.reject(false);
          });
        }
      }

      return deferred.promise;
    },
    convertValue: function convertValue(inputValue, fromCurrency, toCurrency, aesthetic) {
      // No conversion if currencies match
      if (fromCurrency === toCurrency) {
        return $q.resolve(inputValue);
      }

      var from = this.getConversionRate(fromCurrency);
      var to = this.getConversionRate(toCurrency);

      return $q.all([from, to]).then(function (conversions) {
        var rate = conversions[1] / conversions[0];
        if (aesthetic) {
          return aesConvert(inputValue, rate).final;
        }
        return inputValue * rate;
      });
    },


    /* ---------------------------------------------------------------- *
     * Converts a value to the orgs normalized amount based on the raw_currency of the
     * entity passed in.
     * ---------------------------------------------------------------- */

    convert: function convert(entity, value) {
      var deferred = $q.defer();
      var displayCurrency = _.get(entity, 'raw_currency_code', this.getDefaultCurrency());

      var data = {
        convertedAmount: null,
        currency: null
      };

      // no need to convert if display currency matches default currency
      if (displayCurrency === this.getDefaultCurrency()) {
        data.currency = displayCurrency;
        data.convertedAmount = value;
        deferred.resolve(data);
      } else {
        this.getConversionRate(displayCurrency).then(function (rate) {
          deferred.resolve({
            currency: displayCurrency,
            convertedAmount: value * rate
          });
        });
      }

      return deferred.promise;
    },
    getBrowserDefaultCurrencySymbol: function getBrowserDefaultCurrencySymbol() {
      var currency = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : this.getDefaultCurrency();

      return scIntlService.NumberFormat(this.getLanguagePreference(), {
        style: 'currency',
        currency: currency,
        currencyDisplay: 'symbol',
        minimumFractionDigits: 0,
        maximumFractionDigits: 0
      }).format(0).replace('0', '').trim();
    },
    getCurrencySymbol: function getCurrencySymbol() {
      var currency = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : this.getDefaultCurrency();

      var symbol = this.getBrowserDefaultCurrencySymbol(currency);

      if (currency !== this.localeCurrency && symbol === '$') {
        // FRS-5207 unless we are in the same currency as the user users,
        // we should not display plain $
        symbol = currency;
      }
      return symbol;
    },


    /*
     * Getting an entity's display currency involves checking a number of things about that entity.
     * If the entity is a valid fundraiser, then we should look at the entity's currency and fall
     * back to the entity's team currency. If the entity is not a fundraiser, we should just check
     * the team currency.
     */
    getEntityFundraiserCurrency: function getEntityFundraiserCurrency() {
      var entity = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};

      var fundraiserProperty = _.get(entity, 'alias', entity.fundraiser_profile_image);
      if (!_.isUndefined(fundraiserProperty)) {
        return entity.raw_currency_code || this.getEntityTeamCurrency(entity);
      }

      return this.getEntityTeamCurrency(entity);
    },


    /*
     * Getting an entity's display currency involves checking a number of things about that entity.
     * If the entity has a valid team, then we should look at the team's currency and fall
     * back to the entity's campaign's currency. If the entity is not a team, we should just check
     * the campaign's currency.
     */
    getEntityTeamCurrency: function getEntityTeamCurrency() {
      var entity = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};

      var teamProperty = _.get(entity, 'team_lead_id', entity.team_profile_image);
      if (!_.isUndefined(teamProperty)) {
        var team = scFundraisingTeamsService.get(entity.team_id);
        var teamCurrency = entity.team_id ? _.get(team, 'raw_currency_code') : entity.raw_currency_code;
        return teamCurrency || this.getEntityCampaignCurrency(entity);
      }

      return this.getEntityCampaignCurrency(entity);
    },


    /*
     * Getting an entity's display currency involves checking a number of things about that entity.
     * If the entity has a valid campaign, then we should look at the campaign's currency and fall
     * back to the default currency. If the entity does not have a valid campaign, we should just
     * fall back to the default currency.
     */
    getEntityCampaignCurrency: function getEntityCampaignCurrency() {
      var entity = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};

      if (_.get(entity, 'organization_id', false)) {
        var campaign = scCampaignsService.get(entity.campaign_id);
        var campaignCurrency = entity.campaign_id ? _.get(campaign, 'raw_currency_code') : entity.raw_currency_code;

        return campaignCurrency || this.getDefaultCurrency();
      }

      return this.getDefaultCurrency();
    },


    /*
     * Getting an entity's display currency involves checking a number of things about that entity.
     * We want to start by checking for a fundraiser-style currency value, then check for a
     * team-style, then check for a campaign-style, then fall back to the default. The functions
     * are nested because a given entity's display currency should be determined from most specific
     * (the specific fundraising entity's currency) to least specific (the campaign's currency).
     * All of these values are really just overriding the user's default currency, so if any of
     * them are present, we want to display using those. If none of them are present, we want to
     * display currency values based on the user's default currency as determined by their browser
     * settings.
     */
    getEntityCurrency: function getEntityCurrency() {
      var entity = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};

      return this.getEntityFundraiserCurrency(entity);
    }
  };
}
})();