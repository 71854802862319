(function(){
'use strict';

/**
 * @kind factory
 * @name scButtonStyleUpdate
 *
 * @description
 * Update the button styles based on button settings
 */

angular.module('classy').factory('scButtonStyleUpdate', scButtonStyleUpdate);

function scButtonStyleUpdate() {
  return function getStyles() {
    var buttonStyles = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
    var color = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : undefined;

    if (color) {
      var style = {};
      _.merge(style, buttonStyles);

      if (!_.endsWith(buttonStyles.preset, 'Wire')) {
        style.color = 'white';
        style['background-color'] = color;
        style.border = '3px solid ' + color;
      } else {
        style['background-color'] = 'transparent';
        style.color = color;
        style.border = '3px solid ' + color;
      }

      return style;
    }

    return undefined;
  };
}
})();