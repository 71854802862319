(function(){
'use strict';

scMasonFactory.$inject = ["$compile", "$timeout", "scRequestAnimationFrame"];
angular.module('classy').factory('scMason', scMasonFactory);

function scMasonFactory($compile, $timeout, scRequestAnimationFrame) {
  function scMason(scope) {
    var COLUMN_COUNT = 3,
        COLUMN_WIDTH = 150,
        GUTTER = 20;

    var listings = [];
    var columns = [];

    var state = {
      isInitialized: false,
      animationPending: false
    };

    var env = {
      $element: null,
      width: 0,
      height: 0,
      colWidth: 0,
      marginWidth: 0
    };

    this.setElement = function (element) {
      env.$element = element;
    };

    this.push = function (listing) {
      if (state.isInitialized) {
        processListing(listing);
      }
      listings.push(listing);
    };

    this.clear = function () {
      listings = [];
      _.forEach(columns, function (column) {
        column.height = 0;
      });
      if (state.isInitialized) {
        env.$element.empty();
        env.$element.height('auto');
        env.height = env.$element.height();
      }
    };

    this.init = function () {
      env.width = env.$element.width();
      env.height = env.$element.height();

      if (env.width < 600) {
        COLUMN_COUNT = 2;
        COLUMN_WIDTH = env.width / 2;
        GUTTER = 10;
      }

      // Keep looping until our element has rendered
      if (!env.width) {
        $timeout(this.init, 100);
        return;
      }

      var totalGutterWidth = GUTTER * (COLUMN_COUNT - 1);
      var totalColumnWidth = COLUMN_COUNT * COLUMN_WIDTH;

      if (totalGutterWidth + totalColumnWidth > env.width) {
        totalColumnWidth = env.width - totalGutterWidth;
      }

      env.colWidth = Math.floor(totalColumnWidth / COLUMN_COUNT);
      env.marginWidth = Math.floor((env.width - (totalColumnWidth + totalGutterWidth)) / 2);

      if (!columns.length) {
        for (var i = 0; i < COLUMN_COUNT; i += 1) {
          columns.push({});
        }
      }

      _.forEach(columns, function (column, index) {
        column.left = env.marginWidth + (env.colWidth + GUTTER) * index;
        column.width = env.colWidth;
        column.height = 0;
      });

      if (listings.length) {
        _.forEach(listings, function (listing) {
          processListing(listing);
        });
      }

      state.isInitialized = true;
    };

    function getShortestColumn() {
      var shortestColumn = void 0;
      _.forEach(columns, function (column) {
        if (!shortestColumn || column.height < shortestColumn.height) {
          shortestColumn = column;
        }
      });
      return shortestColumn;
    }

    function processListing(listing) {
      var column = getShortestColumn();
      var imgAspect = listing.imageHeight / listing.imageWidth;
      var imgHeight = Math.floor(column.width * imgAspect);
      var imgWidth = column.width;
      var imgTop = column.height;
      var imgLeft = column.left;

      column.height += imgHeight + GUTTER;

      var div = angular.element("<div class='media-library_results-item'></div>");

      div.css({
        width: imgWidth,
        height: imgHeight,
        top: imgTop,
        left: imgLeft
      });

      var pixabay = true;

      div.attr({
        'data-ng-click': 'stage({image: listing, libraryLoaded: ' + pixabay + ' })',
        'data-ng-class': '{\'selected\': pixabaySrc === listing.previewURL}',
        'data-ng-keydown': 'stage({image: listing, keyboardEvent: $event, libraryLoaded: ' + pixabay + ' })'
      });

      var img = angular.element("<img class='media-library_results-item-thumbnail' />");

      img.attr({
        alt: 'tagged with ' + listing.tags + '.',
        'aria-label': '{{ pixabaySrc === listing.previewURL ? "Selected" : "Click to select" }}',
        tabindex: '0'
      });

      img[0].onload = function () {
        div.addClass('loaded');
      };
      img[0].src = listing.previewURL;

      var selector = angular.element("<div class='media-library_results-item-selector'></div>");

      div.append(img);
      div.append(selector);

      var compileScope = scope.$new();
      compileScope.listing = listing;

      var compiled = $compile(div)(compileScope);

      scRequestAnimationFrame(function () {
        env.$element.append(compiled);
        if (column.height > env.height) {
          env.$element.height(column.height);
          env.height = column.height;
        }
      });
    }
  }

  return scMason;
}
})();