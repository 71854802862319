(function(){
'use strict';

angular.module('classy').directive('amTimeAgo', amTimeAgo);

function amTimeAgo() {
  return {
    scope: {
      timeAgo: '<?amTimeAgo'
    },

    template: '<span class="timeago">{{ago}}</span>',

    link: function link(scope) {
      scope.ago = moment(scope.timeAgo).fromNow();
    }
  };
}
})();