(function(){
'use strict';

/**
 * @name encodeURIComponent
 * @kind filter
 */

angular.module('classy').filter('encodeURIComponent', encodeURIComponentFilter);

function encodeURIComponentFilter() {
  return function (input) {
    return encodeURIComponent(input);
  };
}
})();