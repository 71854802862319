(function(){
'use strict';

angular.module('classy').directive('scLockField', scLockField);

function scLockField() {
  'use strict';

  return {
    scope: {
      locked: '=?scLockField',
      tooltipText: '<?',
      opacity: '<?',
      cursor: '<?',
      handleUnlock: '<?'
    },
    link: function link(scope, element, attrs) {
      scope.$watch('locked', function () {
        if (scope.locked) {
          var lockedClass = attrs.sclockedClass;
          var opacity = scope.opacity ? scope.opacity : '0.5';
          var cursor = scope.cursor ? scope.cursor : 'not-allowed';

          element.css({
            opacity: opacity,
            cursor: cursor
          });
          element.prop('disabled', true);
          element.attr('aria-disabled', true);

          element.qtip({
            content: {
              // Runs on every hover
              text: function text() {
                return scope.tooltipText ? scope.tooltipText : 'This field is locked at the template level';
              }
            },
            style: {
              classes: 'qtip-plain qtip-dark admin-campaign-tooltip lock-tooltip-msg ' + lockedClass
            },
            position: {
              my: 'bottom center',
              at: 'top center',
              target: element
            },
            show: {
              delay: 90,
              event: 'mouseenter'
            },
            hide: {
              event: 'mouseleave',
              delay: 90,
              fixed: true
            }
          });
        }

        if (scope.handleUnlock && !scope.locked) {
          var _opacity = scope.opacity ? scope.opacity : '1.0';
          var _cursor = scope.cursor ? scope.cursor : 'allowed';

          element.css({
            opacity: _opacity,
            cursor: _cursor
          });
          element.prop('disabled', false);
          element.attr('aria-disabled', false);

          element.qtip('destroy', true);
        }
      });
    }
  };
}
})();