(function(){
'use strict';

scTextBlockSettings.$inject = ["scFroalaOptions", "scQaId", "$stateParams", "scCampaignsTemplateService"];
angular.module('classy').directive('scTextBlockSettings', scTextBlockSettings);

function scTextBlockSettings(scFroalaOptions, scQaId, $stateParams, scCampaignsTemplateService) {
  return {
    scope: true,
    templateUrl: 'global/objects/scBlock/types/text/settings',
    controller: ['$scope', function ($scope) {
      $scope.isHeadlineLocked = false;
      $scope.contentFroalaOptions = scFroalaOptions({ type: 'inline-with-video' }, {
        ariaLabel: 'Content'
      });

      $scope.headerFroalaOptions = scFroalaOptions({ type: 'inline-header' }, {
        ariaLabel: 'Headline'
      });

      $scope.scQaId = scQaId;
      $scope.disableTextBlock = false;
      if (SC.campaign && SC.campaign.campaignTemplateData) {
        $scope.disableTextBlock = scCampaignsTemplateService.getAddContentToPageBlock($stateParams.blockId);

        var activeBlock = null;
        var foundBlockPayload = scCampaignsTemplateService.getBlockComponent($stateParams.blockId);
        activeBlock = scCampaignsTemplateService.getActiveBlock(foundBlockPayload);
        $scope.isHeadlineLocked = _.get(activeBlock, 'item_attributes.headline.locked', false);
        $scope.isContentLocked = _.get(activeBlock, 'item_attributes.content.locked', false);
        $scope.isBackgroundLocked = _.get(activeBlock, 'item_attributes.background.locked', false);
      }
    }],

    link: function link(scope) {
      scope.bgMediaManagerOptions = {
        title: 'Select a Text Section Background',
        image: {
          guide: {
            title: 'Text Section Background',
            message: "You're not tied to a color. Add a background image.",
            size: '1280 x 720 pixels'
          }
        }
      };
    }
  };
}
})();