(function(){
'use strict';

scDonatePreview.$inject = ["$rootScope"];
angular.module('classy').directive('scDonatePreview', scDonatePreview);

function scDonatePreview($rootScope) {
  return {
    scope: {
      scDonatePreview: '<'
    },

    link: function link(scope, element) {
      element.on('click', function (e) {
        if (scope.scDonatePreview) {
          e.preventDefault();
          $rootScope.SC.status.banner = {
            type: 'error',
            msg: 'Whoops, this is just a preview of a page. You can donate from your published landing page.'
          };
        }
      });
    }
  };
}
})();