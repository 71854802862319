(function(){
'use strict';

/**
 * @name scTrustedHtml
 * @kind filter
 */
scTrustedHtml.$inject = ["$sce"];
angular.module('classy').filter('scTrustedHtml', scTrustedHtml);

function scTrustedHtml($sce) {
  return function (input) {
    return $sce.trustAsHtml(input);
  };
}
})();