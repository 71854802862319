(function(){
'use strict';

scValidityFormReporterFactory.$inject = ["scValidityReporterProto"];
var _createClass = function () { function defineProperties(target, props) { for (var i = 0; i < props.length; i++) { var descriptor = props[i]; descriptor.enumerable = descriptor.enumerable || false; descriptor.configurable = true; if ("value" in descriptor) descriptor.writable = true; Object.defineProperty(target, descriptor.key, descriptor); } } return function (Constructor, protoProps, staticProps) { if (protoProps) defineProperties(Constructor.prototype, protoProps); if (staticProps) defineProperties(Constructor, staticProps); return Constructor; }; }();

function _classCallCheck(instance, Constructor) { if (!(instance instanceof Constructor)) { throw new TypeError("Cannot call a class as a function"); } }

function _possibleConstructorReturn(self, call) { if (!self) { throw new ReferenceError("this hasn't been initialised - super() hasn't been called"); } return call && (typeof call === "object" || typeof call === "function") ? call : self; }

function _inherits(subClass, superClass) { if (typeof superClass !== "function" && superClass !== null) { throw new TypeError("Super expression must either be null or a function, not " + typeof superClass); } subClass.prototype = Object.create(superClass && superClass.prototype, { constructor: { value: subClass, enumerable: false, writable: true, configurable: true } }); if (superClass) Object.setPrototypeOf ? Object.setPrototypeOf(subClass, superClass) : subClass.__proto__ = superClass; }

angular.module('classy').factory('scValidityFormReporter', scValidityFormReporterFactory);

function scValidityFormReporterFactory(scValidityReporterProto) {
  return function (_scValidityReporterPr) {
    _inherits(scValidityFormReporter, _scValidityReporterPr);

    function scValidityFormReporter(alias) {
      _classCallCheck(this, scValidityFormReporter);

      return _possibleConstructorReturn(this, (scValidityFormReporter.__proto__ || Object.getPrototypeOf(scValidityFormReporter)).call(this, alias, 'form'));
    }

    _createClass(scValidityFormReporter, [{
      key: 'update',
      value: function update() {
        this.valid = _.reduce(this.children, function (validSoFar, child) {
          if (_.isUndefined(validSoFar) || _.isUndefined(child.valid)) {
            return undefined;
          }
          return validSoFar && child.valid;
        }, true);

        this.invalid = _.reduce(this.children, function (invalidSoFar, child) {
          if (_.isUndefined(invalidSoFar) || _.isUndefined(child.invalid)) {
            return undefined;
          }
          return invalidSoFar || child.invalid;
        }, false);

        this.error = _.reduce(this.children, function (errorSoFar, child) {
          errorSoFar[child.alias] = _.clone(child.error);
          return errorSoFar;
        }, {});

        this.pending = _.reduce(this.children, function (pendingSoFar, child) {
          pendingSoFar[child.alias] = _.clone(child.pending);
          return pendingSoFar;
        }, {});

        this.uiValid = _.reduce(this.children, function (uiValidSoFar, child) {
          if (_.isUndefined(uiValidSoFar) || _.isUndefined(child.uiValid)) {
            return undefined;
          }
          return uiValidSoFar && child.uiValid;
        }, true);

        this.uiInvalid = _.reduce(this.children, function (uiInvalidSoFar, child) {
          if (_.isUndefined(uiInvalidSoFar) || _.isUndefined(child.uiInvalid)) {
            return undefined;
          }
          return uiInvalidSoFar || child.uiInvalid;
        }, false);

        this.uiError = _.reduce(this.children, function (uiErrorSoFar, child) {
          uiErrorSoFar[child.alias] = _.clone(child.uiError);
          return uiErrorSoFar;
        }, {});

        this.uiPending = _.reduce(this.children, function (uiPendingSoFar, child) {
          uiPendingSoFar[child.alias] = _.clone(child.uiPending);
          return uiPendingSoFar;
        }, {});

        this.emitUpdate();

        if (this.parent) {
          this.parent.update();
        }
      }
    }, {
      key: 'setSubmitState',
      value: function setSubmitState(state) {
        this.emit('submitted', state);

        if (this.parent) {
          this.parent.setSubmitState(state);
        }
      }
    }]);

    return scValidityFormReporter;
  }(scValidityReporterProto);
}
})();