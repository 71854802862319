(function(){
'use strict';

scMediaManagerImageCtrl.$inject = ["$scope"];
angular.module('classy').controller('scMediaManagerImageCtrl', scMediaManagerImageCtrl);

function scMediaManagerImageCtrl($scope) {
  /* ---------------------------------------------------------------------- *
   * API
   * ---------------------------------------------------------------------- */

  $scope.changeCollection = function (collection) {
    $scope.ui.image.search.collection = collection;
    switch (collection) {
      case 'all':
        $scope.ui.image.search.querySuffix = '';
        break;
      case 'humanRights':
        $scope.ui.image.search.querySuffix = 'human rights';
        break;
      default:
        $scope.ui.image.search.querySuffix = collection;
    }
  };
}
})();