(function(){
'use strict';

/**
 * @kind factory
 * @name scImgAssetClientUpload
 * @package scImg
 *
 * @description
 * Handles client upload of an image to Amazon S3.
 *
 * @param {File} file The file object, via ng-file-upload.
 */
scImgAssetClientUploadFactory.$inject = ["$q", "$http", "Upload", "scMembersService"];
angular.module('classy').factory('scImgAssetClientUpload', scImgAssetClientUploadFactory);

function scImgAssetClientUploadFactory($q, $http, Upload, scMembersService) {
  function scImgAssetClientUpload(file) {
    var uploadDeferred = $q.defer();

    var assetId = void 0,
        putUrl = void 0;

    function fetchUrl() {
      var memberId = scMembersService.you.current.id;
      var promise = $http.post('/frs-api/asset/url', { memberId: memberId });
      promise.then(function (response) {
        assetId = response.data.id;
        putUrl = response.data.presigned_put_url;
      }).catch(function () {});

      return promise;
    }

    function uploadAsset() {
      var promise = Upload.http({
        url: putUrl,
        method: 'PUT',
        headers: {
          'Content-Type': file.type
        },
        data: file
      });

      promise.then(function () {
        uploadDeferred.notify({
          progress: 100
        });
      }, null, // Error - caught in main chain
      function (response) {
        uploadDeferred.notify({
          progress: parseInt(100 * response.loaded / response.total, 10)
        });
      }).catch(function () {});
      return promise;
    }

    function saveAsset() {
      var promise = $http.put('/frs-api/asset/save', { assetId: assetId });
      promise.then(function (response) {
        uploadDeferred.resolve(response.data);
      }).catch(function () {});

      return promise;
    }

    fetchUrl().then(uploadAsset).then(saveAsset)
    // Handle all errors
    .catch(function (err) {
      uploadDeferred.reject(err);
    });

    return uploadDeferred.promise;
  }

  return scImgAssetClientUpload;
}
})();