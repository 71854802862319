(function(){
'use strict';

scAboutBlock.$inject = ["scCampaignsTemplateService", "scFroalaOptions", "scBlock"];
angular.module('classy').directive('scAboutBlock', scAboutBlock);

function scAboutBlock(scCampaignsTemplateService, scFroalaOptions, scBlock) {
  var locks = scCampaignsTemplateService.getLocks({
    about: { headlineColor: { locked: false } }
  });

  return scBlock({
    link: function link(scope) {
      scope.headlineColor = locks.about.headlineColor;
      scope.froalaOptionsContent = scFroalaOptions({
        type: 'edit-inline-about'
      }, {
        scImgAssetFroalaUpload: true,
        ariaLabel: 'About Content'
      });

      scope.froalaOptionsTitle = scFroalaOptions({
        type: 'edit-inline-about-title'
      }, {
        charCounterCount: true,
        charCounterMax: 180,
        editorClass: 'no-counter',
        ariaLabel: 'About Headline',
        toolbarButtons: ['bold', 'italic', 'underline', 'align', 'fontFamily', 'fontSize', scope.headlineColor.locked ? undefined : 'color', 'paragraphStyle'],
        toolbarButtonsMD: ['bold', 'italic', 'underline', 'align', 'fontFamily', 'fontSize', scope.headlineColor.locked ? undefined : 'color', 'paragraphStyle'],
        toolbarButtonsSM: ['bold', 'italic', 'underline', 'align', 'fontFamily', 'fontSize', scope.headlineColor.locked ? undefined : 'color', 'paragraphStyle'],
        toolbarButtonsXS: ['bold', 'italic', 'underline', 'align', 'fontFamily', 'fontSize', scope.headlineColor.locked ? undefined : 'color', 'paragraphStyle']
      });

      scope.contentOptions = {
        fontBase: 22,
        fontRatio: 18
      };
    }
  });
}
})();