(function(){
'use strict';

scSupporterParent.$inject = ["scCampaignsService", "scProgressDataFactory", "scCurrencyService", "$q", "generateAssetUrl"];
angular.module('classy').directive('scSupporterParent', scSupporterParent);

function scSupporterParent(scCampaignsService, scProgressDataFactory, scCurrencyService, $q, generateAssetUrl) {
  return {
    replace: true,
    scope: {
      parent: '=scSupporterParent',
      theme: '=',
      type: '@',
      backupImage: '@',
      link: '&',
      bc: '@'
    },
    templateUrl: 'global/objects/scBlock/shared/supporter/scSupporterParent/template',
    link: function link(scope) {
      // This function returns a promise so that we can lazy-load campaign overview data.
      // This allows us to load the whole page without waiting on campaign overview data, which
      // is extremely slow to load and is not necessary to show the rest of the page's data.
      function getTotal() {
        if (scope.type == 'team' || scope.type == 'parentTeam') {
          return $q.resolve(scProgressDataFactory.getEntityProgress(scope.parent));
        }

        // The parent is the raw object. We need to get the scCampaignModel in order to properly pull
        // down the overview data.
        var campaign = scCampaignsService.get(scope.parent.id);

        if (!campaign) {
          return $q.resolve({
            totalRaised: 0,
            currency: scCurrencyService.getDefaultCurrency()
          });
        }

        return campaign.getOverview().then(function () {
          return scProgressDataFactory.getCampaignProgress(campaign.current);
        });
      }

      scope.total_raised = 0;

      getTotal().then(function (progressData) {
        var totalRaised = progressData.totalRaised,
            currency = progressData.currency;

        scope.currency = currency;
        scope.total_raised = totalRaised;
        // So that the progress bar appears full loaded
        scope.attach = true;
      });

      scope.getImg = function () {
        var backupImage = generateAssetUrl(scope.backupImage);
        if (_.isUndefined(scope.parent)) {
          return backupImage;
        }
        var image = scope.backupImage;
        var themeHeaderLogo = void 0;

        switch (scope.type) {
          case 'campaign':
            themeHeaderLogo = _.get(scope, 'theme.headerLogo.asset.src', false);
            if (!themeHeaderLogo) {
              themeHeaderLogo = _.get(scope, 'theme.headerLogo.asset.cdn_url', _.get(scope, 'theme.headerLogo.asset.src', false));
            }
            image = themeHeaderLogo || backupImage;
            break;

          default:
            image = scope.parent.logo ? scope.parent.logo.src || scope.parent.logo.cdn_url : backupImage;
            break;
        }
        return image;
      };

      scope.getTypeText = function () {
        var text = '';
        switch (scope.type) {
          case 'team':
            text = 'My Team';
            break;

          case 'parentTeam':
            text = 'Parent Team';
            break;

          case 'campaign':
            text = 'Campaign';
            break;
          default:
            text = '';
            break;
        }

        return text;
      };
    }
  };
}
})();