(function(){
'use strict';

frsDoubleTheDonationCtrl.$inject = ["$scope", "scCampaignsService", "CHANNEL", "scBanner"];
angular.module('classy').controller('frsDoubleTheDonationCtrl', frsDoubleTheDonationCtrl);

function frsDoubleTheDonationCtrl($scope, scCampaignsService, CHANNEL, scBanner) {
  $scope.state = {
    doubleTheDonationFormEnabled: false
  };

  if (SC.organization.has_double_the_donation_employer_match) {
    scCampaignsService.active.getCampaignChannel(CHANNEL.DTD).then(function (resp) {
      $scope.isEmployerMatchOnDonationPage = _.get(resp, '[0].metadata.employer_match_on_donation_page', null);
      $scope.isEmployerMatchPreselect = _.get(resp, '[0].metadata.employer_match_preselect', false);
      if ($scope.isEmployerMatchPreselect) $scope.state = { doubleTheDonationFormEnabled: true };
    }).catch(function () {
      scBanner('There was an error fetching the campaign channel status.', 'error');
    });
  }

  $scope.show = {
    doubleTheDonationForm: function doubleTheDonationForm() {
      return $scope.state.doubleTheDonationFormEnabled;
    }
  };

  $scope.template = function (name) {
    return 'global/objects/scBlock/types/frs-donation/components/double-the-donation/partials/' + name;
  };

  $scope.$watch('state.doubleTheDonationFormEnabled', function (enabled) {
    if (enabled) {
      $scope.FORM.$addControl($scope.doubleTheDonationForm);
      window.doublethedonation.plugin.load_streamlined_input();
    } else {
      $scope.FORM.$removeControl($scope.doubleTheDonationForm);
    }
  });
}
})();