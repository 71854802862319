(function(){
'use strict';

/*
 * "DAL" stands for data-access layer.
 *
 * In AngularJS, a DAL is a pattern used to isolate all interaction with server APIs
 * AND local caching management of subsequent requests.
 *
 * Interfacing directly with $http in controllers is an AngularJS anti-pattern.
 *
 * Currently, this is Embedded Giving's (EG) only DAL. It supports 1 API request (/checkout)
 * And manages local caching.
 */
/*
 * On EG's Caching Layer:
 *    'dateLastCached' set on initCache and updateCache
 *    Donation Details View:
 *      - donation frequency
 *      - donation amount
 *      - donation currency // @TODO hvnt
 *    Donation Payment View:
 *      - is "fee on top" enabled (fot)
 *      - zip code
 *    Donation Contact View:
 *      - Contact:
 *        - first name
 *        - last name
 *        - email
 *        - is "contact for marketing purposes" enabled
 *    POST /eg-checkout "this.checkout" (for Thank You view)
 *      - total transaction amount
 *      - transaction id
 *         *  General Info:
 *      - 'dateLastCached' is set on initCache() and updateCache()
 *      - Set during initCache(), which is envoked via EmbeddedGivingCtrl initialization process.
 *      - Can be reset via resetCache()
 *      - Explicitly declared below to show schema & for readability purposes */
angular.module('classy').service('EmbeddedGivingDAL', ["$http", function ($http) {
  var _this = this;

  this.cache = {
    // Meta
    dateLastCached: null,
    // Donation / Payment
    amount: 1, // min is 1
    rawAmount: 1,
    fotAmount: 0,
    currency: 'USD',
    frequency: 'one-time', // one-time or recurring monthly, set during init
    dedication: null,
    isFeeOnTopEnabled: null,
    zipCode: null,
    // Contact
    email: null,
    firstName: null,
    lastName: null,
    isContactMeEnabled: null, // "It's ok to contact me for marketing purposes (checkbox bool)"
    /* Transaction - set on response from checkout() (POST /checkout) */
    checkoutTotal: 1, // min donation amount is 1, so min total is also 1
    transactionId: null // id of transaction
  };

  // NOTE: 'dateLastCached' is a pattern for future versions when we want to refresh a
  // stale cache and interface with browser local storage (set in milliseconds)
  this.initCache = function (cacheData) {
    var initCache = cacheData || {};
    initCache.dateLastCached = +new Date();
    _this.updateCache(initCache);
  };

  this.getCached = function (cacheKey) {
    return _this.cache[cacheKey];
  };

  this.updateCache = function (cacheData) {
    _this.cache = _.extend({}, _this.cache, cacheData);
  };

  this.resetCache = function () {
    _this.cache = {
      dateLastCached: null,
      donation: {},
      contact: {},
      transaction: {}
    };
  };

  this.getFullCachedAmount = function () {
    var rawAmount = _this.getCached('rawAmount');
    var fotAmount = _this.getCached('fotAmount');

    return rawAmount + fotAmount;
  };

  this.checkout = function (campaignId, body) {
    var path = '/frs-api/campaign/' + campaignId + '/eg-checkout';
    return $http.post(path, body); // TODO @hvnt handle response, on success set checkout cache, $log appropriately
  };

  this.ia = function (campaignId) {
    var path = '/frs-api/campaigns/' + campaignId + '/intelligent-ask';
    return $http.get(path);
  };
}]);
})();