(function(){
'use strict';

/**
 * @kind directive
 * @name scPaging
 *
 * @description
 * Pagination directive to asyncronously bring in pages based on an Apiv2 resource.
 * Supports any List resource and its query filters:
 * sort, fields, filter, with.
 */
scPagingDirective.$inject = ["$log", "$http", "$window", "scPagingButtonsAPI"];
angular.module('classy').directive('scPaging', scPagingDirective);

function scPagingDirective($log, $http, $window, scPagingButtonsAPI) {
  return {
    scope: {
      resource: '=scPaging',
      model: '=ngModel',
      perPage: '=?',
      sort: '=?',
      customParams: '=?',
      fields: '=?',
      filter: '=?',
      withRelation: '=?with',
      loading: '=?',
      formatFn: '&?format',
      query: '=?'
    },

    // eslint-disable-next-line no-unused-vars
    templateUrl: function templateUrl(tElemnt, tAttrs) {
      return tAttrs.templateUrl || 'global/directives/scPaging/template';
    },
    link: function link(scope) {
      scope.page = 1;

      scope.ui = scope.ui || new scPagingButtonsAPI({
        currentPage: scope.page,
        perPage: scope.perPage,
        onPageSelect: fetchResource
      });

      if (Modernizr.prefixed('matchMedia', window)) {
        scope.isMobile = Modernizr.mq('(max-width: 568px)');
      } else {
        scope.isMobile = angular.element($window).width() <= 568;
      }

      function fetchResource(pageNum) {
        var query = buildQuery();
        query.page = pageNum;
        scope.page = pageNum;
        scope.loading = true;

        if (scope.query) {
          query.q = scope.query;
        }

        var promise = $http({
          method: 'GET',
          url: '/frs-api' + scope.resource,
          params: query
        }).then(function (_ref) {
          var data = _ref.data;
          var items = data.data,
              total = data.total;


          if (scope.formatFn) {
            scope.model = scope.formatFn({ items: items });
          } else {
            scope.model = items;
          }

          scope.size = total;

          var totalPages = Math.ceil(scope.size / scope.perPage);
          scope.totalPages = Math.max(totalPages, 1);

          var lowbound = 1;
          if (scope.page >= totalPages - 1) {
            lowbound = 3 - (totalPages - scope.page);
          }

          var startPageRange = Math.max(scope.page - lowbound, 1);
          var endPageRange = startPageRange + 4;

          endPageRange = endPageRange > totalPages ? totalPages + 1 : endPageRange;
          scope.pageRange = _.range(startPageRange, endPageRange);

          scope.loading = false;
          scope.ui.fetchComplete(data);
        }).catch(function (err) {
          $log.error(err);
          scope.loading = false;
          scope.failure = err;
        });
        scope.ui.setCurrentPage(pageNum);
        scope.ui.fetchStarted(promise);
      }

      function buildQuery() {
        var sortQuery = !scope.sort || _.isEmpty(scope.sort) ? null : _.map(scope.sort, function (value, key) {
          return key + ':' + value;
        });

        var filterMap = scope.filter;
        if (scope.query && scope.query.searchString) {
          _.each(scope.query.fields, function (field) {
            filterMap[field] = {
              operand: '*=',
              value: scope.query.searchString
            };
          });
        }

        var filterQuery = null;

        if (scope.filter || !_.isEmpty(scope.filter)) {
          filterQuery = [];
          _.each(scope.filter, function (value, key) {
            if (value.length > 0) {
              _.each(value, function (filter) {
                filterQuery.push(key + filter.operand + filter.value);
              });
            } else {
              filterQuery.push(key + value.operand + value.value);
            }
          });
        }

        sortQuery = sortQuery && sortQuery.length ? sortQuery.join(',') : null;
        filterQuery = filterQuery && filterQuery.length ? filterQuery.join(',') : null;

        var fieldsQuery = scope.fields && scope.fields.length ? scope.fields.join(',') : null;
        var withQuery = scope.withRelation && scope.withRelation.length ? scope.withRelation.join(',') : null;

        var query = {
          sort: sortQuery,
          fields: fieldsQuery,
          filter: filterQuery,
          with: withQuery
        };

        query.per_page = scope.perPage;

        if (scope.customParams) {
          query = _.merge({}, query, scope.customParams);
        }

        return query;
      }

      function resourceDeleted() {
        var totalPages = Math.max(Math.ceil((scope.size - 1) / scope.perPage), 1);

        if (scope.page > totalPages) {
          fetchResource(Math.max(scope.page - 1, 1));
        } else {
          fetchResource(scope.page);
        }
      }

      function queryChange() {
        fetchResource(1);
      }

      function searchResource(newVal, oldVal) {
        // assumes the same endpoint all the time
        if (newVal != oldVal) {
          fetchResource(1);
        }
      }

      scope.$watch('query', _.debounce(searchResource, 200));
      scope.$watch('resource', function (newVal, old) {
        if (newVal != old) {
          fetchResource(1);
        }
      });

      scope.$watch('[customParams, sort, fields, filter, withRelation]', queryChange, true);

      scope.$on('sc-paging-resource-deleted', resourceDeleted);
    }
  };
}
})();