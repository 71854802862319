(function(){
'use strict';

/* -------------------------------------------------------------------------- *
 * Angular normally listens for changes using the "input" event, but because
 * IE is a dumpster fire and spams this event in incorrect situations,
 * Angular listens for "keydown" on IE instead. Unfortunately this means we
 * don't get notified when the user clicks the ugly/nonstandard/baffling
 * "clear" icon that IE pastes into every input just to spite you.
 * -------------------------------------------------------------------------- */
ieModelFix.$inject = ["$timeout"];
angular.module('classy').directive('ieModelFix', ieModelFix);

function ieModelFix($timeout) {
  return {
    require: 'ngModel',
    // eslint-disable-next-line no-unused-vars
    link: function link(scope, element, attrs, ngModel) {
      element.on('input', function () {
        $timeout(function () {
          if (!element.val()) {
            ngModel.$setViewValue(element.val());
          }
        });
      });
    }
  };
}
})();