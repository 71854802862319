(function(){
'use strict';

/**
 * @kind directive
 * @name scImgListField
 *
 * @description
 * Displays a drag-and-droppable list of images.
 *
 * @param {string} label The label for the control
 * @param {string} containerClass One or more classes to append to the outer container
 * @param {expression} model The model for the control
 * @param {string} description Additional helper text for the user.
 * @param {string} buttonText Text to be shown on the upload button.
 *
 * @param {object} imgList Object of settings to pass to scImgList:
 *
 *  | Property | Type | Description |
 *  |--|--|--|
 *  | scImgInit | expression | Callback function, called when an image is created.
 *      The created image is available to the expression as $scImg. |
 *  | scImgListInit | expression | Callback function, called on first initialization
 *      of the control. The follow entities are available to the expression:
 *      $list, $add, $remove, $change, $select. See scImgList docs for details. |
 *  | preset | expression | An array of scImg objects to preload into the list. |
 *  | limit | number | The maximum number of images to load. |
 *  | mode | string | List mode; one of 'single', 'select', or 'multiple'. |
 *  | selectOnAdd | boolean | If true (and mode is 'select'),
 *      new images will be selected on upload. |
 *  | options | expression | Object; used to display options to the user on a
 *      per-image basis and store those settings in the image itself. Format is:
 *      `{ settingKey: { option1Label: option1Value, option2Label: option2Value ... } }` |
 *  | minWidth | expression | The minimum width that the downloaded image should cover;
 *      used for downloading from Pixabay. |
 *  | minHeight | expression | The minimum height that the downloaded image should cover;
 *       used for downloading from Pixabay. |
 *  | mmConfig | expression | Object of options to pass to the Media Manager. |
 *
 * @param {array} checkboxes Checkboxes to be included under the field.
 *    See checkboxes documentation for more
 * @param {array} errors Errors to be included. See errors documentation for more
 */

scImgListField.$inject = ["scField"];
angular.module('classy').directive('scImgListField', scImgListField);

function scImgListField(scField) {
  return scField('scImgListField');
}
})();