(function(){
'use strict';

scHeaderBlockSettings.$inject = ["scCampaignsService", "scCampaignsTemplateService"];
angular.module('classy').directive('scHeaderBlockSettings', scHeaderBlockSettings);

function scHeaderBlockSettings(scCampaignsService, scCampaignsTemplateService) {
  return {
    scope: true,
    templateUrl: 'global/objects/scBlock/types/header/settings',
    link: function link(scope) {
      var campaign = scCampaignsService.active;
      var defaultLogoLink = scope.campaign.current.type == 'donation' ? 'donation' : 'landing';
      scope.block.logoLink = scope.block.logoLink || defaultLogoLink;

      scope.lockLogoLink = false;
      var lockedBlock = scCampaignsTemplateService.getBlockByType('header');
      if (lockedBlock) {
        scope.lockLogoLink = lockedBlock.item_attributes.logoLink && lockedBlock.item_attributes.logoLink.locked;
      }

      if (SC.campaign && SC.campaign.campaignTemplateData) {
        scope.headerLogoLock = _.get(SC.campaign.campaignTemplateData, 'components.theme.component_items[0].item_attributes.styles.headerLogo.locked', false);
      }

      scope.logoLinkOptions = [{
        label: 'To Donation Page',
        url: 'donation'
      }, {
        label: 'Custom URL',
        url: 'custom'
      }];

      if (campaign.current.type != 'donation') {
        scope.logoLinkOptions.unshift({
          label: 'To Landing Page',
          url: 'landing'
        });
      }

      scope.logoMediaManagerOptions = {
        title: 'Select a header logo',
        image: {
          guide: {
            title: 'Header logo',
            message: 'This image is used to identify your event in the admin area and in search results.',
            size: '300 x 50',
            format: 'JPG, PNG, GIF'
          }
        }
      };

      scope.themeMediaManagerOptions = {
        title: 'Select a Logo',
        image: {
          guide: {
            title: 'Logo',
            message: ''
          }
        }
      };

      scope.backgroundImageMediaManagerOptions = {
        title: 'Select an optional Background Image',
        image: {
          guide: {
            title: 'Header Background Image',
            message: 'Customize your header with a unique background image.',
            size: '1350 x 115 pixels'
          }
        }
      };
    }
  };
}
})();