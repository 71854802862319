(function(){
'use strict';

/**
 * @name scTransientsService
 * @kind service
 *
 * @description
 * A service for passing semi-privileged data between two isolated services,
 * e.g. two isolate scopes. Data is deleted when it is read. This is less about
 * security between points A and B and more about preventing data litter.
 */
angular.module('classy').service('scTransientsService', scTransientsService);

function scTransientsService() {
  var transients = {};

  this.set = function (key, value) {
    transients[key] = value;
  };

  this.get = function (key) {
    var val = transients[key];
    delete transients[key];
    return val;
  };
}
})();