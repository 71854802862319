(function(){
'use strict';

scPhoneInput.$inject = ["intlTelInput"];
angular.module('classy').directive('scPhoneInput', scPhoneInput);

function scPhoneInput(intlTelInput) {
  return {
    require: ['ngModel', '^?form'],
    scope: { class: '@', name: '@', placeholder: '@', ariaLabel: '@', required: '=?' },
    template: '<input \n                inputmode="numeric"\n                type="tel"\n                aria-label="phone number, numbers only" \n                class={{class}} \n                name={{name}} \n                aria-label={{ariaLabel}}\n                placeholder={{placeholder}}\n                data-sc-form-error="highlight if {{name}} fails" \n                ng-required={{required}}\n            />',
    link: function link(scope, element, _attrs, ctrls) {
      var $input = element.find('input');
      var ngModelCtrl = ctrls[0];

      $input.on('input', function (event) {
        var value = event.target.value;
        ngModelCtrl.$setViewValue(value);
        ngModelCtrl.$setDirty();
      });

      $input.on('blur', function () {
        ngModelCtrl.$validate();
      });

      ngModelCtrl.$validators.phoneNumber = function (modelVal, viewVal) {
        var val = modelVal || viewVal;
        if (!val) return true;

        // In case phone field for some reason fails, default to basic validation
        if (!scope.iti) {
          return val.replace(/[^\d]/g, '').length >= 9;
        }

        // Otherwise, validate using intl-tel-input library
        return scope.iti.isValidNumber();
      };

      intlTelInput.then(function () {
        scope.iti = window.intlTelInput($input[0], {
          initialCountry: 'US',
          utilsScript: 'https://cdn.jsdelivr.net/npm/intl-tel-input@23.1.0/build/js/utils.js',
          strictMode: true
        });
      });
    }
  };
}
})();