(function(){
'use strict';

scValidityFieldReporterFactory.$inject = ["scValidityReporterProto"];
var _createClass = function () { function defineProperties(target, props) { for (var i = 0; i < props.length; i++) { var descriptor = props[i]; descriptor.enumerable = descriptor.enumerable || false; descriptor.configurable = true; if ("value" in descriptor) descriptor.writable = true; Object.defineProperty(target, descriptor.key, descriptor); } } return function (Constructor, protoProps, staticProps) { if (protoProps) defineProperties(Constructor.prototype, protoProps); if (staticProps) defineProperties(Constructor, staticProps); return Constructor; }; }();

function _classCallCheck(instance, Constructor) { if (!(instance instanceof Constructor)) { throw new TypeError("Cannot call a class as a function"); } }

function _possibleConstructorReturn(self, call) { if (!self) { throw new ReferenceError("this hasn't been initialised - super() hasn't been called"); } return call && (typeof call === "object" || typeof call === "function") ? call : self; }

function _inherits(subClass, superClass) { if (typeof superClass !== "function" && superClass !== null) { throw new TypeError("Super expression must either be null or a function, not " + typeof superClass); } subClass.prototype = Object.create(superClass && superClass.prototype, { constructor: { value: subClass, enumerable: false, writable: true, configurable: true } }); if (superClass) Object.setPrototypeOf ? Object.setPrototypeOf(subClass, superClass) : subClass.__proto__ = superClass; }

angular.module('classy').factory('scValidityFieldReporter', scValidityFieldReporterFactory);

function scValidityFieldReporterFactory(scValidityReporterProto) {
  return function (_scValidityReporterPr) {
    _inherits(scValidityFieldReporter, _scValidityReporterPr);

    function scValidityFieldReporter(alias) {
      _classCallCheck(this, scValidityFieldReporter);

      return _possibleConstructorReturn(this, (scValidityFieldReporter.__proto__ || Object.getPrototypeOf(scValidityFieldReporter)).call(this, alias, 'field'));
    }

    _createClass(scValidityFieldReporter, [{
      key: 'update',
      value: function update($error, $pending, $ignore) {
        this.valid = _.isEmpty($pending) ? _.isEmpty($error) : undefined;
        this.invalid = _.isEmpty($pending) ? !_.isEmpty($error) : undefined;
        this.error = _.clone($error);
        this.pending = _.clone($pending || {}); // May be undefined
        this.uiValid = $ignore ? true : this.valid;
        this.uiInvalid = $ignore ? false : this.invalid;
        this.uiError = $ignore ? {} : _.clone(this.error);
        this.uiPending = $ignore ? {} : _.clone(this.pending);
        this.emitUpdate();
        if (this.parent) {
          this.parent.update();
        }
      }
    }]);

    return scValidityFieldReporter;
  }(scValidityReporterProto);
}
})();