(function(){
'use strict';

angular.module('classy').filter('reverse', reverse);

function reverse() {
  return function (items) {
    return items.slice().reverse();
  };
}
})();