(function(){
'use strict';

frsDonationFaqCtrl.$inject = ["$scope", "$window", "scMembersService", "scAuth"];
angular.module('classy').controller('frsDonationFaqCtrl', frsDonationFaqCtrl);

function frsDonationFaqCtrl($scope, $window, scMembersService, scAuth) {
  'use strict';

  /* ---------------------------------------------------------------------- *
   * Collections
   * ---------------------------------------------------------------------- */

  $scope.list = {
    faqs: function () {
      if ($scope.demo && !_.get($scope, 'campaign.current.faqs.data.length', 0)) {
        return [{
          question: 'Is this donation tax deductible?',
          answer: "We are a 501(c)3 tax-exempt organization and your donation is tax-deductible within the guidelines of U.S. law. To claim a donation as a deduction on your U.S. taxes, please keep your email donation receipt as your official record. We'll send it to you upon successful completion of your donation."
        }];
      }
      return _.get($scope, 'campaign.current.faqs.data', []);
    }()
  };

  $scope.scAuth = scAuth;
  $scope.loggedIn = !!scMembersService.active.current.id;

  $scope.login = function () {
    scAuth.loginOnly({ callback: $window.location.href });
  };

  $scope.$watch(function () {
    return _.get(scMembersService, 'active.current.id');
  }, function (newVal, oldVal) {
    if (newVal !== oldVal) {
      $scope.loggedIn = !!newVal;
    }
  });
}
})();