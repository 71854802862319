(function(){
'use strict';

/**
 * @kind service
 * @name scAnalyticsServiceSettings
 *
 * @description
 * Stores an object full o' analytics settings
 */
angular.module('classy').service('scAnalyticsServiceSettings', scAnalyticsServiceSettings);

function scAnalyticsServiceSettings() {
  this.analytics = { campaign: {}, organization: {} };

  this.add = function (entity, service) {
    this.analytics[entity][service.service_name] = service;
  };

  this.getById = function (entity, id) {
    return _.get(this, 'analytics.' + entity + '.' + id, false);
  };

  this.getByName = function (entity, name) {
    return _.get(this, 'analytics.' + entity + '.' + name, false);
  };

  this.init = function (analytics) {
    var _this = this;

    if (analytics) {
      _.forEach(analytics.campaign, function (service) {
        _this.add('campaign', service);
      }, this);

      _.forEach(analytics.organization, function (service) {
        _this.add('organization', service);
      }, this);
    }
  };
}
})();