(function(){
'use strict';

angular.module('classy').directive('egDonationCurrency', ["$state", "$location", "EmbeddedGivingColors", "EmbeddedGivingPaths", "scCurrencyService", "scCampaignsService", "scOrganizationsService", "scThemesService", function ($state, $location, EmbeddedGivingColors, EmbeddedGivingPaths, scCurrencyService, scCampaignsService, scOrganizationsService, scThemesService) {
  return {
    templateUrl: EmbeddedGivingPaths.components.donationCurrency,
    scope: {
      entity: '=?currencyEntity',
      model: '=?currencyModel',
      editable: '<?',
      disabled: '<?',
      selected: '<?'
    },
    link: function link(scope) {
      var _this = this;

      // TODO refactor to check for isPreview
      // const colorPrimary = EmbeddedGivingColors.getPrimary(scThemesService.active);
      // const colorAccentOpaque = EmbeddedGivingColors.getAccentOpaque(scThemesService.active);
      this.getColorPrimary = function () {
        return EmbeddedGivingColors.getPrimary(scThemesService.active);
      };
      this.getColorAccentOpaque = function () {
        return EmbeddedGivingColors.getAccentOpaque(scThemesService.active);
      };

      // **************************************************
      // $scope functions
      //
      scope.getCurrencyWrapperStyles = function (selected) {
        var styles = void 0;

        if (selected) {
          styles = {
            color: '#fff',
            'border-color': _this.getColorPrimary(),
            'background-color': _this.getColorPrimary()
          };
        } else {
          styles = {
            'border-color': _this.getColorAccentOpaque(),
            'background-color': _this.getColorAccentOpaque()
          };
        }

        return styles;
      };

      scope.getCurrencyLabelStyles = function (selected) {
        var styles = void 0;

        if (selected) {
          styles = {
            color: '#fff',
            'background-color': _this.getColorPrimary(),
            'background-image': scope.showDropdown ? 'url(/static/global/images/dropdown-arrow.svg' : '',
            'border-radius': '3px 0px 0px 3px'
          };
        } else {
          styles = {
            'border-color': _this.getColorAccentOpaque(),
            'background-color': _this.getColorAccentOpaque(),
            'border-right': '1px solid WHITE'
          };
        }

        return styles;
      };

      scope.syncCurrencyModel = function () {
        scope.model = scope.currency.currency;
      };

      // **************************************************
      // Private functions
      //
      function initCurrency() {
        scope.currency = _.filter(scope.currencyOptions, function (option) {
          return option.currency == scope.entityCurrency;
        })[0] || scope.currencyOptions[0];

        if (!_.get(scope, 'currency.fullSymbol', false)) {
          scope.currency.fullSymbol = scCurrencyService.getCurrencySymbol(scope.entityCurrency);
        }

        scope.syncCurrencyModel();
      }

      // **************************************************
      // Init code
      //
      if (scCampaignsService.active && scCampaignsService.active.current && scCampaignsService.active.current.type !== 'reg_w_fund' && scCampaignsService.active.current.type !== 'ticketed' && $location.path().includes('manage')) {
        scCampaignsService.active.getOverview().then(function (overviewData) {
          if (overviewData.transactions_count > 0) {
            scope.disabled = true;
            scope.tooltipText = 'Goal currency cannot be changed after a campaign has received its first donation.';
          }
        });
      }
      // if the parent context knows whether it wants to make the currency editable, allow
      // it to be set via optional `editable` binding.
      if (!_.isUndefined(scope.editable)) {
        scope.showDropdown = scope.editable;
      } else if (scCampaignsService.active) {
        scope.showDropdown = !scCampaignsService.active.multiCurrencyDisabled();
      } else {
        // campaign model is not yet available when creating a new campaign,
        // so check for campaign type via the state params
        scope.showDropdown = ['crowdfunding', 'p2p', 'donation'].includes($state.params.campaignType) && !scOrganizationsService.active.multiCurrencyDisabled();
      }

      // MORE INIT CODE (?)
      var currency = scope.model || scCurrencyService.localeCurrency;
      scope.currencyOptions = scCurrencyService.currencies;

      var blackListedCurrency = ['RUB', 'BYN'];

      if (blackListedCurrency.includes(currency)) {
        currency = 'USD';
      }

      if (scope.entity === 'donation') {
        scope.currencyOptions = scope.currencyOptions.filter(function (currObj) {
          return !blackListedCurrency.includes(currObj.currency);
        });
      }

      // when a currency param is specified, that takes precedent as long as there are options to choose from and it exists among them
      var currencyParam = $state.params.currency;
      if (currencyParam && scope.currencyOptions.length && scope.currencyOptions.find(function (co) {
        return co.currency === currencyParam.toLocaleUpperCase();
      })) {
        scope.entityCurrency = currencyParam.toLocaleUpperCase();
        initCurrency();
        return;
      }

      var defaultCurrency = scCurrencyService.getDefaultCurrency();

      if (scope.showDropdown) {
        if (scope.entity == 'campaign' && !scope.model && !_.get(scCampaignsService, 'active', false)) {
          scope.entityCurrency = currency || scCurrencyService.getDefaultCurrency();
        } else if (scope.entity != 'donation') {
          if (!scope.model && !_.get(scope, 'entity.id', false)) {
            scope.entityCurrency = currency || scCurrencyService.getEntityCurrency(scope.entity);
          } else if (!scope.model) {
            scope.entityCurrency = scCurrencyService.getEntityCurrency(scope.entity);
          } else {
            scope.entityCurrency = scope.model;
          }
        } else if ($state.current.name.indexOf('setup') > -1 || $state.current.name.indexOf('create') > -1 || $state.current.name.indexOf('design') > -1 || SC.preview) {
          // if previewing donation page, instead of displaying
          // localized currency, display the campaign default currency
          // Note that we also have to check SC.preview because the hashbangs in the path to the
          // donation page during preview mode cause the preview stateParam not to be properly
          // registered
          scope.entityCurrency = scCurrencyService.getEntityCurrency(scCampaignsService.active.current);
        } else {
          if (!scope.currencyOptions.length) {
            scope.showDropdown = 0;
            scope.currencyOptions = [{
              currency: defaultCurrency,
              meta: {
                code: defaultCurrency,
                symbol: scCurrencyService.getCurrencySymbol(defaultCurrency)
              }
            }];
          }
          scope.entityCurrency = currency || defaultCurrency;
        }
        initCurrency();
      } else {
        scope.entityCurrency = scope.model || defaultCurrency;
        initCurrency();
      }
    }
  };
}]);
})();