(function(){
'use strict';

CFPMultiplePagesViewFactory.$inject = ["$state", "$filter", "$q", "scFundraisingTeamsService", "scFundraisingPagesService", "scThemesService"];
function _toConsumableArray(arr) { if (Array.isArray(arr)) { for (var i = 0, arr2 = Array(arr.length); i < arr.length; i++) { arr2[i] = arr[i]; } return arr2; } else { return Array.from(arr); } }

angular.module('classy').factory('CFPMultiplePagesView', CFPMultiplePagesViewFactory);

function CFPMultiplePagesViewFactory($state, $filter, $q, scFundraisingTeamsService, scFundraisingPagesService, scThemesService) {
  // this view is for p2p campaigns when allow_duplicate_fundraisers setting is enabled
  // and when there is at least one already existing fundraising page for a fundraiser
  return function CFPMultiplePagesView(parentScope) {
    var $scope = parentScope.$new();
    $scope.primaryColor = _.get($scope, 'ui.primaryColor', scThemesService.active.current.styles.primaryColor);

    $scope.newPage = function () {
      if ($scope.campaign.type === 'reg_w_fund') {
        $state.go('frs.landing.name.campaign.registration.new.tickets', {
          team_id: $scope.team.current.id,
          team_answers: null,
          campaignId: $scope.campaign.id,
          eventName: $filter('scSlugify')($scope.campaign.name).toLowerCase()
        });
      } else {
        $scope.flow.to('indiv.details');
      }
    };

    function formatList(list) {
      return list.map(function (page) {
        return page.current ? page.current : page;
      }).map(function (_ref) {
        var id = _ref.id,
            raw_currency_code = _ref.raw_currency_code,
            logo = _ref.logo,
            total_raised = _ref.total_raised,
            alias = _ref.alias,
            endedAt = _ref.ended_at;
        return (
          // first we remove all the BS data we don't need
          {
            id: id,
            raw_currency_code: raw_currency_code,
            logo: logo,
            total_raised: total_raised,
            alias: alias,
            // we don't need the end date, just if it's passed or not
            completed: endedAt && now.isAfter(endedAt)
          }
        );
      }
      // and then we sort by completed or not
      ).sort(function (a, b) {
        return a.completed - b.completed;
      });
    }

    $scope.fetch = function () {
      if ($scope.multipleFundraisingCurrentPage < $scope.lastPageOfFundraisers) {
        $scope.multipleFundraisingCurrentPage += 1;
        return $scope.getPages($scope.multipleFundraisingCurrentPage).then(function (response) {
          $scope.listOfPages = [].concat(_toConsumableArray($scope.listOfPages), _toConsumableArray(formatList(_.get(response, 'data.data', []))));
        });
      }
      $scope.stop = true;
      return $q.resolve();
    };

    $scope.useExistingPage = function (id) {
      var page = scFundraisingPagesService.userFundraisers[id];
      if ($scope.options.teamId && $scope.options.teamId != page.current.fundraising_team_id) {
        var team = scFundraisingTeamsService.get($scope.options.teamId);
        parentScope.teamName = team.current.name;
        parentScope.teamId = team.current.id;
        parentScope.newTeam = team.current;
        parentScope.fundraisingPage = page.current;
        parentScope.currentTeam = _.get(scFundraisingTeamsService, 'teams[' + page.current.fundraising_team_id + '].current', {});
        $scope.flow.to('indiv.join-team');
      } else {
        $state.go('frs.fundraiser.index', { fundraisingPageId: id });
        $scope.flow.to('indiv.welcome-back');
        parentScope.redirectToPageId = id;
      }
    };

    /* Init */
    var now = moment().utc();

    // when fetching pages, the page numbers actually start at 1
    $scope.multipleFundraisingCurrentPage = 1;
    $scope.stop = $scope.lastPageOfFundraisers === 1;
    $scope.listOfPages = [];
    $scope.buttonText = $scope.campaign.type === 'reg_w_fund' ? 'Register with a new page' : 'Start a new page';
    $scope.subtitle = $scope.campaign.type === 'reg_w_fund' ? 'Click to continue or register with a new page' : 'Click to continue or start new page';

    // set up the first list of pages we got
    if ($scope.fundraisingPagesList) {
      $scope.listOfPages = [].concat(_toConsumableArray($scope.listOfPages), _toConsumableArray(formatList($scope.fundraisingPagesList)));
    } else {
      // in this instance, we haven't loaded the first page yet, but it should be coming
      // so we are waiting for it before we run the initial list setup
      var unbindWatcher = $scope.$watch('fundraisingPagesList', function (newList) {
        if (newList && newList.length) {
          $scope.listOfPages = [].concat(_toConsumableArray($scope.listOfPages), _toConsumableArray(formatList(newList)));
          unbindWatcher();
        }
      });
    }

    /* ---------------------------------------------------------------------- *
     * View object
     * ---------------------------------------------------------------------- */

    return {
      id: 'indiv.multiple-pages',
      templateUrl: $scope.flow.template('multiple-fundraisers'),
      maxWidth: 411,
      context: $scope
    };
  };
}
})();