(function(){
'use strict';

/**
 * @ngDoc factory
 * @name scFundraisingTeamPostsDemo
 *
 * @description
 * Generates demo posts data for a fundraising team.
 */
scFundraisingTeamPostsDemoFactory.$inject = ["scCampaignsService"];
angular.module('classy').factory('scFundraisingTeamPostsDemo', scFundraisingTeamPostsDemoFactory);

function scFundraisingTeamPostsDemoFactory(scCampaignsService) {
  return function scFundraisingTeamPostsDemo(fundraisingTeamId) {
    return {
      current_page: 1,
      data: [{
        id: 1,
        member_id: 1,
        asset_id: null,
        postable_id: fundraisingTeamId,
        postable_type: 'fundraising-team',
        title: scCampaignsService.active.current.default_team_post_title,
        body: scCampaignsService.active.current.default_team_post_body,
        status: 'published',
        visibility: 'public',
        comments_count: 0,
        likes_count: 0,
        created_at: moment().subtract(1, 'weeks').toString(),
        updated_at: moment().subtract(1, 'weeks').toString(),
        poster_name: 'Leah E. Melo'
      }],
      from: 1,
      last_page: 1,
      next_page_url: null,
      per_page: 20,
      prev_page_url: null,
      to: 1,
      total: 1
    };
  };
}
})();