(function(){
'use strict';

scGetQueryParams.$inject = ["$location"];
angular.module('classy').factory('scGetQueryParams', scGetQueryParams);

function scGetQueryParams($location) {
  return function () {
    var urlParams = {};

    if (_.isEmpty($location.search())) {
      // eslint-disable-next-line no-unused-vars
      window.location.search.replace(/[?&]+([^=&]+)=([^&]*)/gi, function (str, key, value) {
        urlParams[key] = decodeURIComponent(value.replace(/\+/g, '%20'));
      });
      return urlParams;
    }
    return $location.search();
  };
}
})();