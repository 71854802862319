(function(){
'use strict';

scTextBlock.$inject = ["scFroalaOptions", "scBlock"];
angular.module('classy').directive('scTextBlock', scTextBlock);

function scTextBlock(scFroalaOptions, scBlock) {
  return scBlock({
    link: function link(scope) {
      scope.froalaOptionsPreviewHeadline = scFroalaOptions({
        type: 'edit-inline-with-events'
      }, {
        scImgAssetFroalaUpload: true,
        ariaLabel: 'Text Headline'
      });
      scope.froalaOptionsPreviewContent = scFroalaOptions({
        type: 'edit-inline-with-events'
      }, {
        scImgAssetFroalaUpload: true,
        ariaLabel: 'Text Content'
      });
    }
  });
}
})();