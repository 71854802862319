(function(){
'use strict';

scCustomBlock.$inject = ["scBlock"];
angular.module('classy').directive('scCustomBlock', scCustomBlock);

function scCustomBlock(scBlock) {
  return scBlock({
    link: function link() {}
  });
}
})();