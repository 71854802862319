(function(){
'use strict';

scAppealSetService.$inject = ["$http", "$log", "scPagesService", "scCampaignsService"];
angular.module('classy').service('scAppealSetService', scAppealSetService);

function scAppealSetService($http, $log, scPagesService, scCampaignsService) {
  return {
    get: function get(campaignId) {
      return $http({
        method: 'GET',
        url: '/frs-api/campaign/' + campaignId + '/appeal-set'
      });
    },
    post: function post(campaignId, appealSet) {
      return $http({
        method: 'POST',
        url: '/frs-api/campaign/' + campaignId + '/appeal-set',
        data: appealSet
      });
    },
    put: function put(appealSet) {
      return $http({
        method: 'PUT',
        url: '/frs-api/appeal-set/' + appealSet.id,
        data: appealSet
      });
    },


    // recache specific URL input field
    recacheUrl: function recacheUrl(url) {
      return $http({
        method: 'POST',
        url: '/frs-api/rescrape-prerender',
        data: {
          url: url
        }
      });
    },


    /**
     * Calls for prerender to recache page & call Facebook debugger to reset
     * meta tags
     */

    rescrape: function rescrape(type, fbCacheImmediately) {
      var status = _.get(scCampaignsService, 'active.current.status', false);
      if (status == 'active') {
        var pageUrl = scPagesService.getUrl({
          pageType: type || 'landing',
          status: 'active',
          includeBase: true
        }).url;
        var data = { url: pageUrl, fbcachenow: fbCacheImmediately || false };

        $http({
          method: 'POST',
          url: '/frs-api/rescrape',
          data: data
        }).then(function () {
          // yay...
        }, function (error) {
          $log.error('failed to attempt a rescrape');
          $log.error(error);
        });
      }
    }
  };
}
})();