(function(){
'use strict';

/**
 * @kind service
 * @name scPagesService
 *
 * @description
 * Houses all page-data for campaign management. Includes functionality to get,
 * add, and decorate controller $scope with page data. Also includes URLs per page.
 */
scPagesService.$inject = ["$filter", "$location", "scCampaignsService", "scFundraisingTeamsService", "scFundraisingPagesService"];
angular.module('classy').service('scPagesService', scPagesService);

function scPagesService($filter, $location, scCampaignsService, scFundraisingTeamsService, scFundraisingPagesService) {
  var _this = this;

  this.pages = {};

  this.add = function (campaignType, pageType, pageDef) {
    _.set(_this.pages, [campaignType, pageType], pageDef);
  };

  this.get = function (campaignType, pageType) {
    return _.get(_this.pages, [campaignType, pageType], null);
  };

  this.currentPageType = function () {
    var url = $location.absUrl();

    if (url.indexOf('/campaign/') > -1) {
      return 'campaign';
    } else if (url.indexOf('/team/') > -1) {
      return 'team';
    } else if (url.indexOf('/fundraiser/') > -1) {
      return 'fundraiser';
    } else if (url.indexOf('/give/') > -1) {
      // hmmm
      var location = document.location.href;
      var id = location.match('/give/(.*)/#');
      if (id && id[1]) {
        if (id[1][0] == 't') {
          return 'team';
        } else if (id[1][0] == 'f') {
          return 'fundraiser';
        }
        return 'campaign';
      }
      return 'campaign';
    }
    // wheretf are you?
    return 'campaign';
  };

  this.getShareableUrl = function () {
    var type = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : this.currentPageType();

    var pageType = type;
    // will give you the correct campaign/team/fundraiser url for an optional pageType
    // without pageType it will return the url to the landing page,
    // depending on which type of page you are on
    if (scCampaignsService.active.current.type === 'donation') {
      pageType = 'donation';
    }

    var shareable = this.getUrl({
      status: 'active',
      pageType: pageType,
      includeBase: true
    }).url;

    return shareable;
  };

  this.getLandingPageRedirect = function () {
    var currentCampaign = scCampaignsService.active ? scCampaignsService.active.current : SC.campaign;
    var landingRedirect = _.get(currentCampaign, 'external_url', false);

    // temporarily limit landing redirections to crowdfunding.
    var crowdfunding = _.get(currentCampaign, 'type', false) == 'crowdfunding';

    if (landingRedirect && crowdfunding) {
      return landingRedirect;
    }
    return false;
  };

  this.getPageOwner = function () {
    if (this.currentPageType() === 'fundraiser') {
      var fallbackAlias = _.get(scFundraisingPagesService, 'active.current.name', false);
      return _.get(scFundraisingPagesService, 'active.current.alias', fallbackAlias);
    } else if (this.currentPageType() === 'team') {
      return _.get(scFundraisingTeamsService, 'active.current.name', false);
    }
    return false;
  };

  this.getUrl = function (options) {
    var currentCampaign = scCampaignsService.active ? scCampaignsService.active.current : SC.campaign;
    var currentFundraiser = _.get(scFundraisingPagesService, 'active.current', false);
    var currentTeam = _.get(scFundraisingTeamsService, 'active.current', false);

    var pageObj = '';

    var status = options.status || currentCampaign.status;
    var previewLink = '';
    // specifically used to remove the header unpublish bar
    // for team/fundraiser previews
    var published = 'true';
    if (status !== 'active') {
      published = '';
      previewLink = '?preview=true';
    }

    var example = options.example || status !== 'active';
    var campaignName = options.campaignName || currentCampaign.name;
    var campaignId = options.campaignId || currentCampaign.id;
    var campaignType = options.campaignType || currentCampaign.type;
    var organizationId = options.organizationId || currentCampaign.organization_id;
    var teamId = options.teamId || currentTeam.id;
    var fundraiserId = options.fundraiserId || currentFundraiser.id;

    var cTerm = _.includes(['crowdfunding', 'p2p'], campaignType) ? 'campaign' : 'event';
    var cTermAbbr = cTerm === 'campaign' ? 'c' : 'e';

    switch (options.pageType) {
      case 'landing':
      case 'campaign':
        pageObj = {
          url: '/' + cTerm + '/' + $filter('scSlugify')(campaignName).toLowerCase() + '/' + cTermAbbr + campaignId + previewLink,
          name: 'Landing Page'
        };

        break;

      case 'search':
        pageObj = {
          url: '/' + cTerm + '/' + $filter('scSlugify')(campaignName).toLowerCase() + '/' + cTermAbbr + campaignId + '/search' + previewLink,
          name: 'Search Page'
        };
        break;

      case 'donation':
        pageObj = {
          url: '/give/' + campaignId + '/' + previewLink + '#!/donation/checkout',
          name: 'Donation Page'
        };

        if (scCampaignsService.active.hasEmbeddedGiving()) {
          pageObj.url = '/give/' + campaignId + '/' + previewLink + '#!/donation/checkout?eg=true';
        }

        break;

      case 'fundraiserDonation':
        pageObj = {
          url: '/give/f' + fundraiserId + '/' + previewLink + '#!/donation/checkout',
          name: 'Donation Page'
        };
        break;

      case 'teamDonation':
        pageObj = {
          url: '/give/t' + teamId + '/' + previewLink + '#!/donation/checkout',
          name: 'Donation Page'
        };
        break;

      case 'thank-you':
        pageObj = {
          url: '/give/' + campaignId + '/' + previewLink + '#!/donation/thank-you',
          name: 'Thank You Page'
        };

        if (scCampaignsService.active.hasEmbeddedGiving()) {
          pageObj.url = '/give/' + campaignId + '/' + previewLink + '#!/donation/checkout?eg=true&egp=ty';
        }

        break;

      case 'team':
        pageObj = {
          url: '/team/' + teamId,
          name: 'Team Page'
        };

        if (example) {
          pageObj.url = '/team/example?preview=true&campaignId=' + campaignId + '&organizationId=' + organizationId + '&published=' + published;
        }
        break;

      case 'fundraiser':
        pageObj = {
          url: '/fundraiser/' + fundraiserId,
          name: 'Fundraiser Page'
        };

        if (example) {
          pageObj.url = '/fundraiser/example?preview=true&campaignId=' + campaignId + '&organizationId=' + organizationId + '&published=' + published;
        }
        break;

      default:
        break;
    }

    if (options.includeBase) {
      pageObj.url = document.location.origin + pageObj.url;
    }

    return pageObj;
  };

  this.getCheckoutRedirect = function () {
    var options = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};

    var campaign = _.get(scCampaignsService, 'active.current', SC.campaign);
    var campaignName = options.campaignName || _.get(campaign, 'name', false);
    var campaignId = options.campaignId || _.get(campaign, 'id', false);

    var checkoutUrl = '/event/' + _.kebabCase(campaignName) + '/e' + campaignId + '/register/new/select-tickets';

    if (!campaignId) {
      return undefined;
    }

    return checkoutUrl;
  };

  this.decorate = function ($scope, campaignType, pageType) {
    var pageData = this.get(campaignType, pageType);

    if (pageData) {
      _.merge($scope, pageData.scope);
    }

    if (pageData && _.isFunction(pageData.init)) {
      pageData.init($scope);
    }
  };
}
})();