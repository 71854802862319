(function(){
'use strict';

/**
 * @kind directive
 * @name scModalLink
 * @scope
 *
 * @requires scModal (as a DESCENDANT, not ancestor)
 *
 * @description
 * Manages opening nested modal.
 */

scModalLink.$inject = ["$timeout", "$rootScope"];
angular.module('classy').directive('scModalLink', scModalLink);

function scModalLink($timeout, $rootScope) {
  'use strict';

  return {
    replace: true,
    templateUrl: 'global/directives/modal/scModalLink',
    transclude: true,
    scope: {
      scModalLink: '@',
      linkClass: '@',
      linkId: '@',
      linkColor: '=?',
      hideButton: '=?',
      ngDisabled: '=?ngDisabled',
      openCallback: '&',
      opened: '=?',
      disableClose: '=?'
    },
    controller: ['$scope', function ($scope) {
      this.scope = $scope;

      this.disableClose = $scope.disableClose;

      this.addModal = function (modal) {
        $scope.modal = modal;
      };

      // Track modal state
      $rootScope.modal = {
        isOpen: false
      };

      var binder = function binder($event) {
        if ($event.keyCode === 27) {
          $rootScope.$broadcast('sc-close-modal');
        }
      };

      // Escape key closes modal
      this.bindEscape = function () {
        angular.element(document).on('keyup', binder);
      };

      $scope.bindEscape = this.bindEscape;

      this.unbindEscape = function () {
        angular.element(document).off('keyup', binder);
      };
    }],
    link: function link(scope) {
      scope.open = function () {
        var open = scope.openCallback();

        if (angular.isUndefined(open)) {
          open = true;
        }

        if (open) {
          scope.openModal();
          $rootScope.modal.isOpen = true;
        }
      };

      scope.openModal = function () {
        if (scope.ngDisabled) {
          return false;
        }

        $timeout(function () {
          scope.modal.show = true;

          angular.element('body').addClass('hide-overflow');
        });

        if (!scope.disableClose) {
          scope.bindEscape();
        }

        return undefined;
      };

      /*
       * Open modal when opened is switch from false to true and vice versa
       */
      scope.$watch('opened', function (newVal) {
        if (newVal) {
          scope.openModal();
        } else {
          $rootScope.$broadcast('sc-close-modal');
        }
      });

      if (scope.disableClose && scope.modal) {
        scope.modal.disableClose = scope.disableClose;
      }
    }
  };
}
})();