(function(){
'use strict';

/**
 * @name htmlToPlaintext
 * @kind filter
 */

angular.module('classy').filter('htmlToPlaintext', htmlToPlaintext);

function htmlToPlaintext() {
  return function (text) {
    var textWithoutTags = text ? String(text).replace(/<[^>]+>/gm, '') : '';
    var txt = document.createElement('textarea');
    txt.innerHTML = textWithoutTags;

    return txt.value;
  };
}
})();