(function(){
'use strict';

/**
 * @name donationLabel
 * @kind directive
 *
 * @description
 * Trims label whitespace so that "required" asterisks can adhere to the last
 * word of the label with a nonbreaking space, rather than wrap to the next
 * line on their own. HTML uglification would probably be a less bad solution
 * to this problem, but we don't have time for that right now.
 */
angular.module('classy').directive('donationLabel', donationLabelDirective);

function donationLabelDirective() {
  return {
    priority: -1,
    restrict: 'C',
    template: function template(tElement) {
      var element = tElement.text(tElement.text().trim());
      return element.html();
    }
  };
}
})();