(function(){
'use strict';

CPPaymentModelFactory.$inject = ["cpAPI"];
angular.module('classy').factory('CPPaymentModel', CPPaymentModelFactory);

var DEFAULTS = {
  applicationId: null,
  method: null,
  token: null,
  nonce: null,
  currency: 'USD',
  firstName: null,
  lastName: null,
  email: null,
  address1: null,
  address2: null,
  city: null,
  state: null,
  postalCode: null,
  country: 'US',
  brand: null,
  isAmex: false,
  lastFour: null,
  expirationMonth: null,
  expirationYear: null,
  securityCode: null,
  crediteePageId: null,
  crediteeTeamId: null
};

function CPPaymentModelFactory(cpAPI) {
  return function CPPaymentModel(data) {
    _.merge(this, DEFAULTS, data);
    this.applicationId = cpAPI.getApplicationId();
  };
}
})();