(function(){
'use strict';

scDonationBlock.$inject = ["scBlock"];
angular.module('classy').directive('scDonationBlock', scDonationBlock);

function scDonationBlock(scBlock) {
  return scBlock({
    responsiveSettings: {
      'mobile-ls': 'mobile-ls'
    }
  });
}
})();