(function(){
'use strict';

/**
 * @ngDoc directive
 * @name scSvgFill
 *
 * @description
 * Bind the fill of an svg object to the model.
 */

angular.module('classy').directive('scSvgFill', scSvgFill);

function scSvgFill() {
  return function (scope, element, attrs) {
    scope.$watch(attrs.scSvgFill, function (newVal) {
      element[0].setAttribute('fill', newVal);
    });
  };
}
})();