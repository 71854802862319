(function(){
'use strict';

/**
 * @name scPercent
 * @kind filter
 */

scPercent.$inject = ["$window"];
angular.module('classy').filter('scPercent', scPercent);

function scPercent($window) {
  return function (input, decimals, suffix) {
    var decimalPlaces = angular.isNumber(decimals) ? decimals : 3;
    var suffixStr = !_.isString(suffix) ? '%' : suffix;

    if ($window.isNaN(input)) {
      return '';
    }

    return Math.round(input * Math.pow(10, decimalPlaces + 2)) / Math.pow(10, decimalPlaces) + suffixStr;
  };
}
})();