(function(){
'use strict';

scCampaignProgress.$inject = ["$filter", "scProgressDataFactory", "scUserAgent"];
angular.module('classy').directive('scCampaignProgress', scCampaignProgress);

function scCampaignProgress($filter, scProgressDataFactory, scUserAgent) {
  return {
    scope: {
      entity: '=',
      active: '=?',
      hasStatsHidden: '=?',
      hasRaisedHidden: '=?',
      endsAt: '=?',
      supporters: '=?',
      enableSupporters: '=?',
      btnColor: '=?',
      barColor: '=?',
      backgroundColor: '=',
      campaignType: '=',
      styleClass: '@?',
      isEndedAtHidden: '=?',
      isPercentHidden: '=?',
      enableProgressBar: '=?'
    },
    template: '<div ng-include="getTemplateUrl()"></div>',
    link: function link(scope) {
      update(scope.entity);

      if (scope.active && scope.active.registerObserverCallback) {
        scope.active.registerObserverCallback(update);
      }

      function update(current) {
        var progressData = void 0;

        if (current && current.classy_mode_enabled != null) {
          // random entity property to check whether campaign or not
          progressData = scProgressDataFactory.getCampaignProgress(current);
        } else {
          progressData = scProgressDataFactory.getEntityProgress(current);
        }

        scope.currency = progressData.currency;
        var isMobile = scUserAgent.isMobile();

        if (isMobile && scope.currency === 'USD') {
          scope.rawGoalAmount = '$' + $filter('friendlyShortNumber')(progressData.goal);
        } else {
          scope.rawGoalAmount = $filter('scFormatCurrency')(progressData.goal, scope.currency, 0);
        }

        scope.total_raised = progressData.totalRaised;
        scope.percentRaised = $filter('scPercent')(progressData.percentToGoal, 0, '');
      }

      scope.getTemplateUrl = function () {
        var template = void 0;

        switch (scope.campaignType) {
          case 'p2p':
            template = 'global/directives/scCampaignProgress/p2p/template';
            break;

          case 'crowdfunding':
            template = 'global/directives/scCampaignProgress/crowdfunding/template';
            break;

          default:
            template = 'global/directives/scCampaignProgress/template';
            break;
        }

        return template;
      };
    }
  };
}
})();