(function(){
'use strict';

scCrowdfundingBlockSettings.$inject = ["scFroalaOptions", "scCampaignsTemplateService"];
angular.module('classy').directive('scCrowdfundingBlockSettings', scCrowdfundingBlockSettings);

function scCrowdfundingBlockSettings(scFroalaOptions, scCampaignsTemplateService) {
  return {
    scope: true,
    templateUrl: 'global/objects/scBlock/types/crowdfunding/settings',
    controller: ['$scope', function ($scope) {
      var locksQuery = {
        crowdfunding: { featuredImg: { locked: false }, useFeatured: { locked: false } }
      };
      $scope.lockLayoutPosition = false;
      $scope.isHeroImgLocked = false;
      $scope.locks = scCampaignsTemplateService.getLocks(locksQuery).crowdfunding;

      if (SC.campaign && SC.campaign.campaignTemplateData) {
        var lockedBlock = scCampaignsTemplateService.getBlockByType('crowdfunding');
        if (lockedBlock) {
          $scope.lockLayoutPosition = lockedBlock.item_attributes.layoutPosition && lockedBlock.item_attributes.layoutPosition.locked;
        }

        $scope.isHeroImgLocked = _.get(SC.campaign.campaignTemplateData, 'components.theme.component_items[0].item_attributes.styles.background.image.locked', false);
      }

      $scope.headerOptions = scFroalaOptions({ type: 'inline-header' }, {
        ariaLabel: 'Headline'
      });

      $scope.headlineImgMediaManagerOptions = {
        mmOptions: {
          title: 'Select a Headline Image',
          image: {
            guide: {
              title: 'Headline Image',
              message: 'Put an artistic spin on your headline text.'
            }
          }
        }
      };

      $scope.featuredImgMediaManagerOptions = {
        title: 'Add Your Featured Media',
        image: {
          guide: {
            title: 'Featured Image',
            message: 'Choose a photo that adds context to your campaign story.',
            size: '628 x 353 pixels'
          }
        }
      };

      $scope.setFeaturedImgDefaults = function (asset, isNew) {
        if (isNew && asset.type != 'video') {
          asset.options.position = 'cover';
        }
      };

      $scope.bgImgUploadOptions = {
        hideActionButtons: true,
        description: 'For best quality, use a 1280 x 720 px image.',
        preview: 550,
        mmOptions: {
          // Media Manager props that determine the configs for the React Image Editor.
          // reactEditorOptions are merged as options passed to mediaManagerEditor
          // reactEditorOptions only applies if useReactEditor is set to true.
          useReactEditor: true,
          reactEditorOptions: {
            heroFullHeight: _.get($scope, 'block.dynamicHeight', false)
          }
        }
      };
    }],

    link: function link(scope) {
      if (scope.campaign.current.is_ended_at_hidden) {
        scope.block.enableDaysLeft = false;
      }

      scope.$watch('block.enableDaysLeft', function (newVal) {
        scope.campaign.current.is_ended_at_hidden = !newVal;
      });

      scope.$watch('block.dynamicHeight', function (newVal) {
        scope.bgImgUploadOptions.mmOptions.reactEditorOptions.heroFullHeight = newVal;
      });

      scope.$watch('block.showAdvanced', function (v) {
        if (v) {
          scope.visible = true;
        }
      });

      scope.$watch('block.disable', function (newVal, oldVal) {
        if (oldVal != newVal && newVal === true && scope.block.replaceHeadline && !scope.block.headlineImage.image) {
          // if we're disabling the block and the headlineimage is on but there is no image,
          // just toggle the headlineimage off so that we can avoid weird validation cases
          scope.block.replaceHeadline = false;
        }
      });
    }
  };
}
})();