(function(){
'use strict';

/**
 * @name scCharLimit
 * @kind directive
 *
 * @description
 * Renders a "characters left" message based on input in a different field.
 *
 * The value of this attribute should be the same as the expression fed to
 * ngModel on the relevant field.
 *
 * If used in conjunction with ngMaxlength, scCharLimit can probably be replaced
 * with its attribute value instead. ngMaxLength also triggers validation flags,
 * which could be useful. ngTrim can also be used to prevent going over the limit.
 *
 * Used in:
 * - classyapp/application/views/scripts/checkout/donation.phtml
 * - classyapp/application/views/scripts/reports/edit-donation.phtml
 *
 * @param {attribute/expression} Count- the maximum amount of characters for
 *   the field this is referencing.
 * @example
 * <input ng-model='myField' />
 * <span data-sc-char-limit='myField' data-count='20'
 * data-count-typed='true' aria-live='polite' data-suffix=' Characters Left'></span>
 */

angular.module('classy').directive('scCharLimit', scCharLimit);

function scCharLimit() {
  'use strict';

  return function (scope, element, attrs) {
    var suffix = attrs.suffix ? attrs.suffix : '';

    scope.$watch(attrs.scCharLimit, function (newVal) {
      if (newVal) {
        if (attrs.countTyped) {
          element.text(newVal.length + ' ' + suffix);
        } else {
          element.text(attrs.count - newVal.length + ' ' + suffix);
        }
      } else {
        // CASE: completely empty model
        element.text(attrs.count + ' ' + suffix);
      }
    });
  };
}
})();