(function(){
'use strict';

scSupporterLeader.$inject = ["$filter", "scCurrencyService", "scResponsiveWidth", "scFundraisingTeamsService", "scFundraisingPagesService"];
angular.module('classy').directive('scSupporterLeader', scSupporterLeader);

function scSupporterLeader($filter, scCurrencyService, scResponsiveWidth, scFundraisingTeamsService, scFundraisingPagesService) {
  return {
    scope: {
      bc: '@?',
      title: '=',
      leader: '=scSupporterLeader',
      theme: '=',
      block: '=?',
      imgSize: '@?',
      mobile: '=',
      hideTitle: '=?',
      leaderboard: '=?',
      placeholder: '=?'
    },
    replace: true,
    template: '<div ng-include="templateUrl" class="{{ bc }}_leader-container"></div>',
    link: function link(scope) {
      scope.bc = scope.bc || 'supporter_';

      scope.photoSize = function () {
        return {
          width: scope.imgSize ? parseFloat(scope.imgSize) : 115,
          height: scope.imgSize ? parseFloat(scope.imgSize) : 115,
          borderRadius: scope.imgSize ? parseFloat(scope.imgSize) : 115
        };
      };

      scope.scResponsiveWidth = scResponsiveWidth;

      scope.getLeaderImage = function () {
        if (scope.leader.type === 'fundraiser') {
          return scFundraisingPagesService.getPagePhoto(scope.leader);
        } else if (scope.leader.type === 'team') {
          return scFundraisingTeamsService.getTeamPhoto(scope.leader);
        }
        return undefined;
      };

      scope.getAriaLabel = function (leader, defaultText) {
        var initialAriaLabel = 'View';
        if (defaultText && leader.name) {
          return initialAriaLabel + ' ' + defaultText + ', ' + leader.name;
        } else if (defaultText && leader.alias) {
          return initialAriaLabel + ' ' + defaultText + ', ' + leader.alias;
        } else if (defaultText && !leader.alias && !leader.name) {
          return initialAriaLabel + ' ' + defaultText;
        }
        return initialAriaLabel + ' ' + (leader.name || leader.alias);
      };

      function changeTemplate(mobile) {
        scope.templateUrl = 'global/objects/scBlock/shared/supporter/scSupporterLeader/' + (mobile ? 'mobile-template' : 'template');
      }

      scope.$watch('scResponsiveWidth.isMobile', function (v) {
        changeTemplate(v);
      });

      if (scope.block && scope.block.type == 'fundraiser') {
        scope.hideTitle = true;
      }

      scope.leader.type = scope.leader.type || 'fundraiser';
      scope.count = undefined;

      var weeklyWinner = scope.leader.title == 'Weekly Winner';
      var onlyRaised = !_.includes(['Top Fundraiser', 'Top Team', 'Team Fundraiser', 'Most Raised', 'Most Donors', 'Most Members', 'Most Activity'], scope.leader.title);
      if (scope.leader.title == 'Top Fundraiser' || scope.leader.title == 'Top Team' || scope.leader.title == 'Team Fundraiser' || scope.leader.title == 'Most Raised' || weeklyWinner || onlyRaised) {
        if (weeklyWinner) {
          scope.defaultText = 'Teammate with the most money raised this week';
          scope.defaultIcon = 'business-money-time';
        } else if (onlyRaised) {
          scope.leader.metric = 'Raised';
          /*
           * `total_raised` includes fees.
           * To align with progress bars, leaderboards should remove fees and use the below calc instead:
           * NOTE : This logic is already applied to top pages in fundraising-teams/getTopPages
           */
          scope.leader.total_raised = scope.leader.goal * scope.leader.percent_to_goal / 100;
        } else {
          scope.defaultIcon = 'business-payment';
          scope.defaultText = 'Teammate with the most money raised';
        }

        if (!weeklyWinner) {
          scCurrencyService.convert(scope.leader, scope.leader.total_raised).then(function (response) {
            scope.count = $filter('scFormatCurrency')(response.convertedAmount, scope.leader.raw_currency_code);
          }, function () {});
        } else {
          scCurrencyService.convertValue(scope.leader.weekly_raised, scope.leader.normalized_currency_code, scope.leader.raw_currency_code).then(function (result) {
            scope.count = $filter('scFormatCurrency')(result, scope.leader.raw_currency_code);
          });
        }
      } else if (scope.leader.title == 'Most Donors') {
        scope.defaultIcon = 'handshake';
        scope.defaultText = 'Teammate with the most donors';
        var donorsCount = !_.isUndefined(scope.leader.transactions_count) ? scope.leader.transactions_count : scope.leader.total_donations;

        scope.count = parseInt(donorsCount, 10) || 0;

        if (scope.count == 1) {
          scope.leader.metric = 'donor';
        }
      } else if (scope.leader.title == 'Most Members') {
        scope.defaultText = 'Team with the most members';
        scope.count = parseInt(scope.leader.total_fundraisers, 10) || 0;

        if (scope.count == 1) {
          scope.leader.metric = 'member';
        }
      } else if (scope.leader.title == 'Most Activity') {
        scope.defaultIcon = 'chat-icon';
        scope.defaultText = 'Teammate with the most wall activity';
        scope.count = scope.leader.total_comments;
      }

      scope.showCount = !scope.placeholder || !scope.leaderboard;
      scope.hideCount = scope.placeholder && scope.leaderboard;
    }
  };
}
})();