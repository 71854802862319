(function(){
'use strict';

angular.module('classy').directive('scModalGroup', scModalGroup);

function scModalGroup() {
  return {
    restrict: 'A',
    scope: true,
    controller: ['$scope', '$rootScope', function ($scope, $rootScope) {
      var modal = void 0;

      $scope.modalData = {};

      this.setFilterClass = function (className) {
        $scope.filterClass = className;
      };

      // Add modal view
      this.addModal = function (scope) {
        modal = scope;
      };

      // Open modal with new data
      this.openModal = function (data, opener) {
        // Set active element
        $scope.active = jQuery(opener).parent('.' + $scope.filterClass);

        // Set next and previous elements based on active element
        var nextFiltered = $scope.active.nextAll('.' + $scope.filterClass),
            prevFiltered = $scope.active.prevAll('.' + $scope.filterClass);

        // Find the opener for the next element, apply to scope
        if (nextFiltered[0]) {
          $scope.nextElem = angular.element(nextFiltered[0]).children('[sc-modal-group-opener], [data-sc-modal-group-opener]');
        } else {
          $scope.nextElem = false;
        }

        // Find the opener for the previous element, apply to scope
        if (prevFiltered[0]) {
          $scope.prevElem = angular.element(prevFiltered[0]).children('[sc-modal-group-opener], [data-sc-modal-group-opener]');
        } else {
          $scope.prevElem = false;
        }

        // Add modal data from scModalGroupContent
        $scope.modalData = data;

        if (!$rootScope.$$phase) {
          $scope.$apply();
        }

        // Trigger open
        modal.open();
      };
    }],
    // eslint-disable-next-line no-unused-vars
    link: function link(scope, element, attrs, ctrl) {
      ctrl.setFilterClass(attrs.filterClass);

      scope.closeModal = function () {
        ctrl.closeModal();
      };
    }
  };
}
})();