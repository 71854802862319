(function(){
'use strict';

/**
 * @name nullBase
 * @kind filter
 */
angular.module('classy').filter('nullBase', nullBase);

function nullBase() {
  return function (input) {
    return angular.isUndefined(input) || input === null ? '' : '' + input;
  };
}
})();