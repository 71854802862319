(function(){
'use strict';

scPostListsService.$inject = ["$http", "$q"];
angular.module('classy').factory('scPostListsService', scPostListsService);

function scPostListsService($http, $q) {
  'use strict';

  function getPostNumber(postId, postList) {
    var postIdx = getIndex(postId, postList);
    return postList.length - postIdx;
  }

  function getIndex(postId, postList) {
    var index = void 0;
    var listSize = postList.length;
    for (index = 0; index < listSize; index += 1) {
      if (postId == postList[index]) {
        break;
      }
    }
    return index;
  }

  function queryPosts(queryUrl) {
    var def = $q.defer();
    // first call will get all of them if less than default max (50)
    // or get the total and then we can query every single one
    $http({
      url: '/frs-api' + queryUrl,
      method: 'GET',
      params: {
        per_page: 50
      }
    }).then(function (response) {
      // if there are more posts than the default (50), gotta grab more
      if (response.data.data.length !== response.data.total) {
        $http({
          url: '/frs-api' + queryUrl,
          method: 'GET',
          params: {
            per_page: response.data.total
          }
        }).then(function (resp) {
          def.resolve(_.map(resp.data.data, 'id'));
        });
      } else {
        def.resolve(_.map(response.data.data, 'id'));
      }
    });
    return def.promise;
  }

  return {
    getIndex: getIndex,
    queryPosts: queryPosts,
    getPostNumber: getPostNumber
  };
}
})();