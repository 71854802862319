(function(){
'use strict';

scAddressLookup.$inject = ["$timeout", "loadGoogleMapsAPI"];
angular.module('classy').directive('scAddressLookup', scAddressLookup);

function scAddressLookup($timeout, loadGoogleMapsAPI) {
  return {
    require: ['ngModel', '^?form'],
    scope: { class: '@', name: '@', placeholder: '@', parser: '&', required: '=?' },
    template: ' \n        <style>\n          .prediction-list {\n            position: absolute;\n            top: 100%;\n            left: 0;\n            right: 0;\n            max-height: 200px;\n            overflow-y: auto;\n            background-color: white;\n            border: 1px solid #ccc;\n            border-top: none;\n            box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.2);\n            z-index: 1000;\n            padding: 0;\n            margin: 0;\n            list-style-type: none;\n          }\n\n          .prediction-list li {\n            padding: 10px;\n            cursor: pointer;\n            border-bottom: 1px solid #eee;\n          }\n\n          .prediction-list li:last-child {\n            border-bottom: none;\n          }\n\n          .prediction-list li:hover {\n            background-color: #f1f1f1;\n          }\n        </style>\n        <div style="position: relative;">\n          <input type="text" class={{class}} name={{name}} placeholder={{placeholder}} required data-sc-form-error="highlight if {{name}} fails" />\n          <ul ng-show="showPredictions()" class="prediction-list">\n            <li ng-repeat="prediction in predictions" ng-click="selectPrediction(prediction)">\n              {{ prediction.description }}\n            </li>\n          </ul>\n        </div>\n      ',
    link: function link(scope, element, attrs, ctrls) {
      var ngModelCtrl = ctrls[0],
          $input = element.find('input');

      var $predictionsList = element.find('ul');

      scope.autocomplete = null;
      scope.placeholder = attrs.placeholder;
      scope.predictions = [];

      var debounceTimeout = void 0;
      var autocompleteService = void 0;
      var placeService = void 0;

      /* -------------------------------------------------------------------- *
       * Prevent form submission on enter
       * -------------------------------------------------------------------- */

      $input.on('keydown', function (event) {
        // Check if 'esc' key was pressed, if so lose focus
        if (event.keyCode == 27) {
          $input.blur();
        }

        if (event.keyCode == 13) {
          event.preventDefault();

          // Stop propagation to prevent form submission
          event.stopPropagation();
        }
      });

      $input.on('input', function (event) {
        var value = event.target.value;
        ngModelCtrl.$setViewValue(value);

        if (debounceTimeout) {
          $timeout.cancel(debounceTimeout);
        }

        if (value.length >= 3) {
          debounceTimeout = $timeout(function () {
            getAutocompletePredictions(value);
          }, 400);
        } else {
          scope.$apply(function () {
            scope.predictions = [];
          });
        }
      });

      $input.on('focus', function () {
        scope.$apply(function () {
          scope.isFocused = true;
        });

        // This is a workaround to prevent the browser from auto-filling the address field
        // when the user selects an address from the Places Autocomplete widget.
        // Chrome won't accept the 'off' value, so we use a non-standard value instead.
        $input.attr('autocomplete', 'non-standard-value');
      });

      $input.on('blur', function () {
        // Hide predictions only if a prediction was not clicked
        if (!scope.isClickingPrediction) {
          scope.$apply(function () {
            scope.isFocused = false;
          });
        }

        // When the field loses focus, we remove the autocomplete attribute.
        // This allows the browser to auto-fill the address field when the user selects an address
        // through the other fields in the form.
        $input.removeAttr('autocomplete');
      });

      /* -------------------------------------------------------------------- *
       * Handle Clicks on Predictions to Prevent Premature Hiding
       * -------------------------------------------------------------------- */

      $predictionsList.on('mousedown', function () {
        scope.isClickingPrediction = true;
      });

      $predictionsList.on('mouseup', function () {
        scope.isClickingPrediction = false;
      });

      /* -------------------------------------------------------------------- *
       * Load and Init
       * -------------------------------------------------------------------- */

      loadGoogleMapsAPI.then(function () {
        autocompleteService = new google.maps.places.AutocompleteService();
        placeService = new google.maps.places.PlacesService($input[0]);
      });

      function getAutocompletePredictions(input) {
        if (autocompleteService) {
          autocompleteService.getPlacePredictions({ input: input, types: ['address'] }, function (results, status) {
            scope.$apply(function () {
              scope.predictions = status === google.maps.places.PlacesServiceStatus.OK ? results : [];
            });
          });
        }
      }

      scope.showPredictions = function () {
        return scope.isFocused && scope.predictions.length > 0;
      };

      scope.selectPrediction = function (prediction) {
        scope.predictions = []; // Hide the predictions list once a selection is made

        if (placeService) {
          placeService.getDetails({ placeId: prediction.place_id }, function (place, status) {
            if (status === google.maps.places.PlacesServiceStatus.OK && place.address_components) {
              var interpreted = interpret(place);
              $input.val(interpreted.address);
              scope.parser({ response: interpreted });
              ngModelCtrl.$setViewValue(interpreted.address);
            }
          });
        }
      };

      /* -------------------------------------------------------------------- *
       * Utils
       * -------------------------------------------------------------------- */

      function interpret(place) {
        var interpreted = {
          subpremise: '',
          number: '',
          street: '',
          city: '',
          state: '',
          country: '',
          postalCode: '',
          postalCodeSuffix: ''
        };

        // Find the country first, as it helps determine how we should identify the city.
        var country = '';

        _.forEach(place.address_components, function (component) {
          if (component.types.indexOf('country') > -1) {
            country = component.short_name;
          }
        });

        var cityTypes = [];

        if (country == 'US') {
          cityTypes = ['administrative_area_level_5', // no U.S. equivalent, but just in case
          'administrative_area_level_4', // no U.S. equivalent, but just in case
          'administrative_area_level_3', // no U.S. equivalent, but just in case
          'sublocality_level_1', // relevant to cities like NYC, ex: Brooklyn, Staten Island
          'locality'];
        } else {
          cityTypes = ['administrative_area_level_5', // no U.S. equivalent
          'administrative_area_level_4', // no U.S. equivalent
          'administrative_area_level_3', // no U.S. equivalent
          'administrative_area_level_2', // In Brazil, for example, this is the city (county in US)
          'postal_town', // In the UK, this is the city. no U.S. equivalent
          'locality'];
        }

        // Attempt to squeeze Google's administrative levels into U.S. buckets.
        // Best option should be last.
        // See https://developers.google.com/maps/documentation/geocoding/intro#Types
        var types = {
          subpremise: ['subpremise'],
          number: ['street_number'],
          street: ['route'],
          city: cityTypes,
          state: ['administrative_area_level_1'],
          country: ['country'],
          postalCode: ['postal_code'],
          postalCodeSuffix: ['postal_code_suffix']
        };

        _.forEach(types, function (type, humanLabel) {
          _.forEach(type, function (typeLabel) {
            _.forEach(place.address_components, function (component) {
              if (component.types.indexOf(typeLabel) > -1) {
                if (humanLabel == 'country' || humanLabel == 'state') {
                  interpreted[humanLabel] = component.short_name;
                } else {
                  interpreted[humanLabel] = component.long_name;
                }
              }
            });
          });
        });

        interpreted.address = (interpreted.number + ' ' + interpreted.street).trim();
        interpreted.postalCode += interpreted.postalCodeSuffix ? '-' + interpreted.postalCodeSuffix : '';

        return interpreted;
      }
    }
  };
}
})();