(function(){
'use strict';

scBigTip.$inject = ["linkBigTip", "scThemesService", "$parse", "$templateCache"];
angular.module('classy').directive('scBigTip', scBigTip);

function scBigTip(linkBigTip, scThemesService, $parse, $templateCache) {
  return function (scope, element, attrs) {
    var event = $parse(attrs.on)(scope) || 'click';
    var templateUrl = $parse(attrs.scBigTip)(scope);
    var template = $templateCache.get(templateUrl);
    var width = $parse(attrs.width)(scope) || 400;
    var bg = $parse(attrs.bg)(scope) || '#ffffff';
    var scroll = $parse(attrs.scroll)(scope) || true;

    scope.bigTip = linkBigTip({
      event: event,
      template: template,
      scope: scope,
      element: element,
      width: width,
      scroll: scroll,
      bg: bg
    });

    scope.bigTip.primaryColor = _.get(scThemesService, 'active.current.styles.primaryColor');
  };
}
})();