(function(){
'use strict';

angular.module('classy').factory('stripDollar', stripDollar);

function stripDollar() {
  'use strict';

  /*
   *   Strip $ and . from dollar string and parse to float
   * */

  return function (amt) {
    var cleanAmt = amt ? amt.toString().replace('$', '').replace(/,/g, '') : 0;
    return parseFloat(cleanAmt);
  };
}
})();