(function(){
'use strict';

sourceCodesFactory.$inject = ["$state", "$location", "ipCookie"];
function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

function _toConsumableArray(arr) { if (Array.isArray(arr)) { for (var i = 0, arr2 = Array(arr.length); i < arr.length; i++) { arr2[i] = arr[i]; } return arr2; } else { return Array.from(arr); } }

angular.module('classy').factory('scSourceCodes', sourceCodesFactory);
/*
  Note: See https://classydev.atlassian.net/browse/FRS-6618 for the overarching epic describing
  the marketing source codes feature.

  The short explanation is that non-profits want to be able to add special query params on to URLs
  which identify where a donor found a specific link. The intent is that they can use a different
  code for each marketing campaign, and use the generated data to track the effectiveness of a given
  campaign.

  We track the values of the "c_src" and "c_src2" query params only. Every time a user lands on any
  FRS page with values for one or both of these query params, we record an entry. Each entry
  contains the c_src and c_src2 values, the timestamp, and the request referrer.

  { c_src, c_src2, referrer, timestamp }

  We store the entries in the user's cookie under the "c_src" field. The cookie is structured as a
  map from campaign ID to array of data entries:

  {
    5: [<latest entry>, <another entry>, ...],
    6: [<latest entry>],
    ...
  }

  We index the map by campaign ID because it is easily possible that a given donor may see multiple
  Classy campaigns via any number of marketing campaigns.

  This factory provides a method for getting the latest entry. This is the value which should be
  recorded with any transactions looking to store source code data. It is possible for a user to
  have any number of entries for a given campaign, if they have seen multiple marketing campaigns or
  visited the same marketing campaign link multiple times. While we do not currently plan to deliver
  the full set of data to non-profits, we're gathering it now for our own potential future use.

  An entry should only be recorded if there is a c_src or c_src2 value (or both) AND the request
  referrer is different from the current URL origin. This means that we should not record any
  data if the user has the query params but is navigating around the current site. For example,
  if I am on classy.org/foo and click a link to classy.org/bar?c_src=baz, no value should be
  recorded. We only want to generate entries for marketing clicks from external sources, not from
  internal traffic.

  Note: while we are always recording the "latest" marketing source code, that does not
  mean that the user visited the site with that source code on their current visit. It is possible
  that a user could find a Classy campaign through a marketing campaign but then not go back to it
  for another week -- at which point they may not have the marketing source codes in the URL they
  use to return. However, it is important that we always ensure that if the user saw the campaign
  through a marketing campaign in the last two weeks, we include that marketing campaign in the data
  we send back. The timestamp will help non-profits track the effectiveness of a marketing campaign
  over time, which might help them figure out that certain marketing campaigns are more or less
  effective a week later.
 */

var COOKIE_KEY = 'c_src';

function sourceCodesFactory($state, $location, ipCookie) {
  // These functions have been pulled out into an object so that they may be spied upon / mocked
  // by tests. We reference them by doing `fns.functionName` in order to ensure that we're getting
  // the spied-upon methods.
  var fns = {
    record: record,
    getLatest: getLatest,
    // These methods exposed only for testing purposes
    getNewData: getNewData,
    referrerIsOrigin: referrerIsOrigin,
    getReferrer: getReferrer,
    getOrigin: getOrigin,
    newData: {} // set in the record function
  };

  function getNewData() {
    // If $state.params isn't empty, then we aren't running this on the initial page load, but on a
    // navigation. We don't want that (we only want events to be recorded once per session).
    // We only want to log events if the referrer and our location are different
    // That is, don't log events for traffic between internal endpoints
    // We need to do comparisons like this, rather than checking for classy-specific URLs, to ensure
    // this works on domain-masked pages
    if (!_.isEmpty($state.params) || fns.referrerIsOrigin()) {
      return {};
    }
    // Get the query params
    // Note: c_src and c_src2 have been added to scConstants, which must be added on to every
    // ui-router path which needs to support marketing source codes. If there is a URL which is
    // not recording marketing source code, it probably does not have the scConstants values
    // being added on to it. The best way to test this is to check whether visiting that URL with
    // the c_src query param actually shows the params in the URL. If they do not, then your
    // endpoint does not properly support these query params, and support must be added.

    var params = $location.search();

    // TODO [CL-45020]: Revert once META integration / resource tracking codes are improved.
    // Check to see if using instagram or facebook in-app browser
    // Temporary fix for CL-45005 in order to track GDV from META integration
    var parser = new UAParser();
    var browser = _.get(parser.getBrowser(), 'name');
    var os = _.get(parser.getOS(), 'name');

    var isIOS = os === 'iOS';
    var isMeta = browser === 'Instagram' || browser === 'Facebook';

    var csrc = params.c_src;
    var referrer = fns.getReferrer();

    if (isIOS && isMeta) {
      csrc = csrc || 'meta-' + browser.toLowerCase();
      referrer = browser;
    }

    return {
      c_src: csrc,
      c_src2: params.c_src2,
      referrer: referrer,
      timestamp: Date.now()
    };
  }

  function getReferrer() {
    return document.referrer;
  }

  function getOrigin() {
    return window.location.origin;
  }

  function referrerIsOrigin() {
    return fns.getReferrer().indexOf(fns.getOrigin()) !== -1;
  }

  function record(campaignId) {
    if (!campaignId) {
      return;
    }

    var newData = fns.getNewData();

    // If there is no source code, do not log anything to the cookie
    if (!newData.c_src && !newData.c_src2) {
      return;
    }

    var oldCookieData = ipCookie(COOKIE_KEY);
    var oldCampaignData = oldCookieData && oldCookieData[campaignId] || [];

    // Update the cookie so that it has the new values in the proper locations -- rather than
    // overwriting the old stuff, we need to merge our new data into the old data in order to ensure
    // that old data is maintained. While we currently only log the most recent entry, we may
    // eventually want to include logs of *all* of the marketing sources which a user saw leading
    // up to a success
    var newCookieData = Object.assign({}, oldCookieData, _defineProperty({}, campaignId, [newData].concat(_toConsumableArray(oldCampaignData))));

    // Kept code here (for now)
    // See: https://classydev.atlassian.net/browse/CL-23211
    // ipCookie(
    //   COOKIE_KEY,
    //   newCookieData,
    //   { expires: 14, path: '/' } // defaults to 'days', so this cookie should be maintained for 2 weeks
    // );

    // Embedded Giving, which is in an iframe, requires SameSite=None;Secure
    // However, ipCookie does not support that configuration
    // Therefore, this code was refactored to interface natively with cookie
    // See: https://classydev.atlassian.net/browse/CL-23211
    function setCookie(cname, cvalue, exdays) {
      var d = new Date();
      d.setTime(d.getTime() + exdays * 24 * 60 * 60 * 1000);
      var expires = 'expires=' + d.toUTCString();
      var cookieStr = cname + '=' + JSON.stringify(cvalue) + ';' + expires + ';path=/;SameSite=None;Secure';
      document.cookie = cookieStr;
    }

    setCookie(COOKIE_KEY, newCookieData, 14);

    fns.newData = newData;
  }

  // Note: It should be sufficient for any logging of source codes to merge the result of this
  // function into any existing metadata.
  // Ex:
  // scAnalytics.logSomething("some event", metadata)
  // scAnalytics.logSomething("some event", _.merge({}, metadata, scSourceCodes.getLatest))
  function getLatest(campaignId) {
    var data = (ipCookie(COOKIE_KEY) || {})[campaignId] || [];
    data.sort(function (a, b) {
      return b.timestamp - a.timestamp;
    });
    return { source_code: data[0] };
  }

  return fns;
}
})();